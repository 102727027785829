import { universalFetch } from "./common";

export const reactions = async (listingId: string, type: string) => {
  try {
    const response = await universalFetch(
      `/listings/${listingId}/reactions`,
      "POST",
      { [type]: {} }
    );
    console.log("reactions response", response);
    return response;
  } catch (error) {
    console.error("Failed reactions", error);
    throw error;
  }
};
