import { create } from 'zustand'
import { formatPrice } from '../utils/formatPrice';
import { convertToFormattedAmount } from '../utils/convertAmount';

interface City {
	id: number;
	name: string;
}

const recommendedCities:City[] = [
	{ id: 11, name: "Pickering, ON" },
	{ id: 12, name: "Ajax, ON" },
	{ id: 13, name: "Whitby, ON" },
	{ id: 14, name: "Oshawa, ON" },
];

interface DebtFormValues {
    carLoans: string;
    studentLoans: string;
    creditCards: string;
    lineOfCredit: string;
}
interface Finance {
    completedSteps: {[key:number]:boolean}
    completeStep: (step:number) => void
    resetSteps: (step: number) => void


    totalPriceCurrentQualification: string;
    setTotalPriceCurrentQualification: (totalPriceCurrentQualification: string) => void;

    incomePrice: string;
    setIncomePrice: (incomePrice: string) => void;

    savingPrice: string;
    setSavingPrice: (savingPrice: string) => void;

    creditScoreValue: string;
    setCreditScoreValue: (creditScoreValue: string) => void;

    totalDebt: string;
    setTotalDebt: (totalDebt: string) => void;

    // Debt form state
    debtFormValues: DebtFormValues;
    setDebtFormValue: (name: keyof DebtFormValues, value: string) => void;

    anotherDebtFormValues: DebtFormValues
    setAnotherDebtFormValues: (name:keyof DebtFormValues, value:string) => void

    basedBuyingGoal:boolean
    setBasedBuyingGoal:(basedBuyingGoal:boolean) => void

    buyingHomeData: string[];
    setBuyingHomeData: (buyingHomeData: string[]) => void;

  

    planSpendData: string;
    setPlanSpendData: (planSpendData: string) => void;

    spendPrice: string[]
    setSpendPrice: (updater:(prev:string[]) => string[] | string[]) => void



    whenBuyHomeData: string;
    setWhenBuyHomeData: (whenBuyHomeData: string) => void;

    selectedCities: City[];
    setSelectedCities:(updater:(prev : City[]) => City[]) => void

    remainingRecommendedCities: City[],
    setRemainingRecommendedCities: (updater: (prev:City[]) => City[]) => void

    whenBuyHomeSelect:string,
    setWhenBuyHomeSelect:(whenBuyHomeSelect:string) => void

    selectDate:string
    setSelectDate: (selectDate:string) => void

    qualification:boolean
    setQualification: (qualification:boolean) => void
}


const useFinanceStore = create<Finance>((set) => ({
    completedSteps: {
        1:false,
        2:false,
        3:false,
        4:false
    },

    basedBuyingGoal: false,
    setBasedBuyingGoal:(value:boolean) => set(()=> ({
        basedBuyingGoal:value,
    })),
    
    
    completeStep: (step:number) => set((state) => ({
        completedSteps : {
            ...state.completedSteps,
            [step]: true
        }
    })),

    resetSteps: (step: number) => set((state) => {
        const newSteps = { ...state.completedSteps }
        for (let i = step + 1; i <= 4; i++) {
            newSteps[i] = false
        }
        return { completedSteps: newSteps , basedBuyingGoal: false }
    }),
    
    totalPriceCurrentQualification:'',
    setTotalPriceCurrentQualification:(state:string) => set({
        totalPriceCurrentQualification: state
    }),

    incomePrice: '',
    setIncomePrice: (incomePrice: string) => set({ incomePrice }),

    savingPrice: '',
    setSavingPrice: (savingPrice: string) => set({ savingPrice }),

    creditScoreValue: '',
    setCreditScoreValue: (creditScoreValue: string) => set({ creditScoreValue }),

    totalDebt: '',
    setTotalDebt: (totalDebt: string) => set({ totalDebt }),


    debtFormValues: {
        carLoans: formatPrice(convertToFormattedAmount(0)),
        studentLoans: formatPrice(convertToFormattedAmount(0)),
        creditCards: formatPrice(convertToFormattedAmount(0)),
        lineOfCredit: formatPrice(convertToFormattedAmount(0)),
    },

    anotherDebtFormValues: {
        carLoans: formatPrice(convertToFormattedAmount(0)),
        studentLoans: formatPrice(convertToFormattedAmount(0)),
        creditCards: formatPrice(convertToFormattedAmount(0)),
        lineOfCredit: formatPrice(convertToFormattedAmount(0)),
    },

    buyingHomeData: [],
    setBuyingHomeData: (buyingHomeData: string[]) => set({ buyingHomeData }),

    planSpendData: 'Set goal',
    setPlanSpendData: (planSpendData: string) => set({ planSpendData }),

    spendPrice: [],
    setSpendPrice: (updater) => set((state) => ({
        spendPrice: typeof updater === 'function' ? updater(state.spendPrice) : updater
    })),

    whenBuyHomeData: 'Set goal',
    setWhenBuyHomeData: (whenBuyHomeData: string) => set({ whenBuyHomeData }),

    selectedCities: [],
    setSelectedCities: (updater) => set((state) => ({
        selectedCities: typeof updater === 'function' ? updater(state.selectedCities): updater
    })),

    remainingRecommendedCities: recommendedCities,
    setRemainingRecommendedCities: (updater) => set((state) => ({
        remainingRecommendedCities: typeof updater === 'function' ? updater(state.remainingRecommendedCities) : updater
    })),

    whenBuyHomeSelect:'' ,
    setWhenBuyHomeSelect: (whenBuyHomeSelect:string) => set({whenBuyHomeSelect}),
    
    selectDate:'',
    setSelectDate:(selectDate:string) => set({selectDate}),

    qualification:false,
    setQualification: (qualification:boolean) => set({qualification}),

   
    setAnotherDebtFormValues: (name, value) =>
        set((state) => ({
            anotherDebtFormValues: {
                ...state.anotherDebtFormValues,
                [name]: formatPrice(Number.parseInt(value.replace(/[^\d]/g, '')) || 0),
            },
        })),
    
    setDebtFormValue: (name, value) =>
        set((state) => ({
            debtFormValues: {
                ...state.debtFormValues,
                [name]: formatPrice(Number.parseInt(value.replace(/[^\d]/g, '')) || 0),
            },
        }))

    }))
export {useFinanceStore}