import { useEffect } from "react";
import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { LifestyleSelectGroup } from "./selectionButton";
import { updatePreferences } from "../../services/preferences";
import { useUserProfileStore } from "../../stores/profileStore";
import { usePropertySearchingStore } from "../../stores/propertySearchingStore";
import {
	Nightlife,
	ChildrenIcon,
	ParkIcon,
	CookingIcon,
	OutdoorIcon,
	DiyIcon,
	PetsIcon,
} from "../../assets/icons";
import { fetchAccountData } from "../../services/account";

const mapToBackendFormat = (lifestyle: string) => {
	const lifestyleMap: Record<string, string> = {
		Children: "CHILDREN",
		Nightlife: "NIGHTLIFE",
		Parks: "PARKS",
		Cooking: "COOKING",
		Outdoor: "OUTDOOR",
		Pets: "PETS",
		DIY: "DIY",
	};
	return lifestyleMap[lifestyle] || lifestyle;
};

const mapToDisplayFormat = (lifestyle: string) => {
	const displayMap: Record<string, string> = {
		CHILDREN: "Children",
		NIGHTLIFE: "Nightlife",
		PARKS: "Parks",
		COOKING: "Cooking",
		OUTDOOR: "Outdoor",
		PETS: "Pets",
		DIY: "DIY",
	};
	return displayMap[lifestyle] || lifestyle;
};

const LifeStyle = () => {
	const navigate = useNavigate();
	const { setSelectedProperty, selectedProperties } =
		usePropertySearchingStore();
	const { selectedLifestyles, setSelectedLifestyles } =
		usePropertySearchingStore();
	const displayedLifestyles = selectedLifestyles.map(mapToDisplayFormat);
	const { userProfile, setUserProfile } = useUserProfileStore();

	useEffect(() => {
		const userLifestyles = userProfile?.matchingPreferences?.lifeStyles ?? [];

		if (Array.isArray(userLifestyles) && selectedLifestyles.length === 0) {
			const mappedLifestyles = userLifestyles.map(mapToBackendFormat);
			setSelectedLifestyles(mappedLifestyles);
		}

		if (selectedProperties.length === 0) {
			const propertyType =
				userProfile?.matchingPreferences?.filters?.propertyType ?? [];
			if (JSON.stringify(propertyType) !== JSON.stringify(selectedProperties)) {
				setSelectedProperty(propertyType);
			}
		}
	}, [
		userProfile,
		selectedLifestyles.length,
		selectedProperties,
		setSelectedLifestyles,
		setSelectedProperty,
	]);

	const handleLifestyleChange = (selected: string[]) => {
		const mappedLifestyles = selected.map(mapToBackendFormat);
		setSelectedLifestyles(mappedLifestyles);
		console.log("selected", selectedLifestyles);
	};

	const handleNav = async () => {
		setSelectedLifestyles(selectedLifestyles);
		await updatePreferences(
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			selectedLifestyles,
		);
		console.log("selected", selectedLifestyles);

		const accountData = await fetchAccountData();

		if (accountData) {
			setUserProfile(accountData);
		}
		navigate("/profile/needs-wants");
	};

	const lifestyleOptions = [
		{ value: "Children", icon: <ChildrenIcon fill="" /> },
		{ value: "Nightlife", icon: <Nightlife fill="" /> },
		{ value: "Parks", icon: <ParkIcon fill="" /> },
		{ value: "Cooking", icon: <CookingIcon fill="" /> },
		{ value: "Outdoor", icon: <OutdoorIcon fill="" /> },
		{ value: "DIY", icon: <DiyIcon fill="" /> },
		...(selectedProperties.includes("CONDO") && selectedProperties.length === 1
			? []
			: [{ value: "Pets", icon: <PetsIcon fill="" /> }]),
	];

	return (
		<div className="px-4 pt-2">
			<h2 className="text-z-shark text-2xl font-bold mb-2">
				What's your lifestyle
			</h2>
			<span className="text-z-shark text-sm">A minimum of 3 is required</span>

			<LifestyleSelectGroup
				textClassName="!font-semibold"
				gap="12px"
				parentClassName="justify-between"
				wrap={true}
				width="95px"
				height="95px"
				selectedValue={displayedLifestyles}
				onChange={handleLifestyleChange}
				options={lifestyleOptions}
				customClassName="border-z-dark-moss-green text-z-shark !min-w-[95px] mt-7 !justify-between p-5 w-full"
				defaultIconFill="#32302f"
				selectedIconFill="#ffffff"
			/>
			<Button
				customClassName={`text-lg font-semibold absolute left-1/2 -translate-x-1/2 ${
					window.bridge ? "bottom-bridge-inset-bottom" : "bottom-5"
				}`}
				onClick={handleNav}
				radius="60px"
				width="330px"
				height="md"
				disable={selectedLifestyles.length < 3}
			>
				Continue
			</Button>
		</div>
	);
};

export { LifeStyle };
