// src/components/common/googleAddressLoop/AddressAutocomplete.tsx
import { useRef, useEffect } from "react";

interface AddressAutocompleteProps {
	onSelect: (address: string, latitude: number, longitude: number) => void;
	placeholder?: string;
	className?: string;
}

const AddressAutocomplete = ({
	onSelect,
	placeholder,
	className,
}: AddressAutocompleteProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!inputRef.current || !window.google) return;

		const autocomplete = new window.google.maps.places.Autocomplete(
			inputRef.current,
			{
				componentRestrictions: { country: "ca" },
				fields: ["address_component", "geometry", "formatted_address"],
			},
		);

		autocomplete.addListener("place_changed", () => {
			const place = autocomplete.getPlace();

			if (!place.geometry || !place.geometry.location) {
				alert(`No details available for input: '${place.name}'`);
				return;
			}

			let address = place.formatted_address || "";
			address = address.replace(/,\s*Canada$/, "");
			const latitude = place.geometry.location.lat();
			const longitude = place.geometry.location.lng();

			onSelect(address, latitude, longitude);

			if (inputRef.current) {
				inputRef.current.value = "";
			}
		});

		return () => {
			window.google.maps.event.clearInstanceListeners(autocomplete);
		};
	}, [onSelect]);

	return (
		<input
			ref={inputRef}
			type="text"
			placeholder={placeholder || "Enter an address"}
			className={className}
		/>
	);
};

export { AddressAutocomplete };
