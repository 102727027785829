import { useRef, useState } from "react";
import NeighbourhoodInformationIcon from "../../assets/Listing/neighbourhoodInformationIcon.svg";
import GrayArrowDownIcon from "../../assets/grayArrowDownIcon.svg";
import {
	mockDemographicResponse,
	mockGooglePlaces,
} from "../../mocks/newMockData/neighbourhoodInformationData";
import { Demographics } from "./demographics";
import { MapSelector } from "./mapSelector";
import { NeighbourhoodMap } from "./neighbourhoodMap";
const NeighbourhoodInformation = () => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [nearbyType, setNearbyType] = useState<string>("school");
	const sectionRef = useRef<HTMLDivElement>(null);
	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	return (
		<div className="border bg-white mt-10 border-[#D9D9D9] shadow-[0_2px_12.6px_0_rgba(0,0,0,0.25)] rounded-[30px] xl:py-[45px] py-[40px] xl:px-10 px-6">
			<div
				className="text-black font-semibold text-xl flex items-center justify-between w-full cursor-pointer"
				onClick={toggleSection}
				onKeyDown={handleKeyDown}
			>
				<div className="flex items-center gap-4">
					<img width={40} src={NeighbourhoodInformationIcon} alt="" />
					<span className="text-[#333] text-2xl">
						Neighbourhood Information
					</span>
				</div>

				<img
					src={GrayArrowDownIcon}
					alt="Toggle Section"
					className={`transition-transform duration-300 ${
						isExpanded ? "rotate-180" : ""
					}`}
				/>
			</div>
			<div
				id="analysis-content"
				className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
					isExpanded ? "max-h-auto" : "max-h-0"
				}`}
			>
				<div className="space-y-4 mt-12">
					<h2 className="font-semibold text-xl">What’s in Rouge E10</h2>
					<h3 className="text-sm text-[#909090] !mt-2">{`See what's located around the neighbourhood`}</h3>
					<div>
						<MapSelector
							nearbyType={nearbyType}
							setNearbyType={setNearbyType}
						/>

						<NeighbourhoodMap
							listing={mockDemographicResponse}
							nearbyType={nearbyType}
							googlePlaces={mockGooglePlaces}
						/>
					</div>
				</div>

				<div
					id="neighbourhood-content"
					ref={sectionRef}
					className="space-y-6 overflow-hidden transition-max-height duration-300 ease-in-out"
				>
					<hr className="h-px bg-[#F3F3F3] border-0 mt-[65px] mx-4" />

					<Demographics listingId={"2135"} />
				</div>
			</div>
		</div>
	);
};

export { NeighbourhoodInformation };
