import { universalFetch } from "./common";
// import { listingData } from "../mocks/listingData";
import type { FetchListingResponse, Listing } from "../interfaces/listing";
import { reactions } from "./reactions";

export const fetchListing = async (zlid: string): Promise<Listing> => {
	try {
		const response = await universalFetch(`/listings/${zlid}`, "GET");
		await reactions(zlid, "OPEN_LISTING_DETAILS");
		console.log("fetchListing response:", response);

		const listingResponse = response as FetchListingResponse;
		return listingResponse.listing;
	} catch (error) {
		console.error("Failed fetchListing", error);
		throw error;
	}
};
