import {useNavbarStore} from "../../../stores/navBarStatus";
import { useFilterPanelStore } from "../../../stores/filterStore";
import NoPropertiesLeftIcon from "../../../assets/Matching/noPropertiesIcon.svg";

import "swiper/css";

const NoListingsCard = () => {
	const { hideNavbar } = useNavbarStore();

	const { showFilterPanel } = useFilterPanelStore();

	const handleFilterClick = () => {
		showFilterPanel();
		hideNavbar();
	};

	return (
		<div className="text-black flex flex-col items-center text-center h-full justify-center space-y-6 w-full">
			<div className="space-y-1">
				<div className="font-semibold text-4xl">Oops !</div>
				<div>
					No <span className="text-[#FF5757]">Properties</span> Left
				</div>
			</div>
			<div>
				<img src={NoPropertiesLeftIcon} alt="" />
			</div>
			<div className="w-full space-y-1 pb-2 px-2">
				<div>Expand your filters</div>
				<button
					type="button"
					onClick={handleFilterClick}
					onKeyDown={handleFilterClick}
					className="font-semibold text-white rounded-full text-lg py-3 bg-z-dark-moss-green w-full"
				>
					Edit preferences
				</button>
			</div>
		</div>
	);
};

export { NoListingsCard };
