interface SettingsIconProps {
	fill: string;
}
export const SettingsIcon = ({ fill }: SettingsIconProps) => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.3421 9.63839C13.2288 8.94458 13.876 7.99317 14.1937 6.91651C14.5114 5.83985 14.4837 4.69148 14.1146 3.63117C13.7455 2.57087 13.0532 1.65135 12.1341 1.00054C11.215 0.349732 10.1148 0 8.98659 0C7.85834 0 6.75814 0.349732 5.83906 1.00054C4.91997 1.65135 4.22771 2.57087 3.85858 3.63117C3.48945 4.69148 3.4618 5.83985 3.77948 6.91651C4.09717 7.99317 4.74439 8.94458 5.6311 9.63839C4.1117 10.2438 2.78598 11.2479 1.79525 12.5436C0.80453 13.8394 0.185949 15.3783 0.00545546 16.9961C-0.0076096 17.1143 0.00284753 17.2338 0.0362296 17.3479C0.0696116 17.462 0.125265 17.5684 0.200012 17.6612C0.35097 17.8484 0.570537 17.9683 0.810411 17.9946C1.05028 18.0208 1.29082 17.9512 1.47909 17.8011C1.66736 17.651 1.78796 17.4326 1.81434 17.194C2.01295 15.4357 2.85598 13.8118 4.18238 12.6326C5.50877 11.4533 7.22554 10.8014 9.00468 10.8014C10.7838 10.8014 12.5006 11.4533 13.827 12.6326C15.1534 13.8118 15.9964 15.4357 16.195 17.194C16.2196 17.4151 16.3256 17.6192 16.4927 17.767C16.6597 17.9149 16.8759 17.9959 17.0994 17.9946H17.1989C17.436 17.9674 17.6527 17.8482 17.8018 17.6629C17.9509 17.4776 18.0203 17.2411 17.9948 17.0051C17.8135 15.3827 17.1916 13.8399 16.1958 12.5421C15.2 11.2443 13.8678 10.2406 12.3421 9.63839ZM8.98659 8.99976C8.27106 8.99976 7.5716 8.78874 6.97666 8.3934C6.38172 7.99805 5.91802 7.43613 5.6442 6.7787C5.37037 6.12126 5.29873 5.39784 5.43832 4.69991C5.57792 4.00198 5.92248 3.36089 6.42843 2.85771C6.93439 2.35453 7.57901 2.01187 8.28079 1.87304C8.98257 1.73421 9.70999 1.80546 10.371 2.07778C11.0321 2.3501 11.5971 2.81126 11.9947 3.40293C12.3922 3.99461 12.6044 4.69023 12.6044 5.40183C12.6044 6.35606 12.2232 7.27121 11.5447 7.94595C10.8663 8.62069 9.94608 8.99976 8.98659 8.99976Z"
			fill={fill}
		/>
	</svg>
);
