import clsx from "clsx";

const PropertyTypeSelector = ({
	label,
	options,
	selectedValue,
	onChange,
	firstClass = false,
	width = "40px",
	height = "40px",
	styleIndex,
	customClassName,
	wrap,
	gap = "16px",
	activeColor = "#456930",
	activeBg = "#456930",
	variant = "primary",
	flexCol = false,
	customBorderColor,
	display = "flex",
	parentClassName,
	uppercaseText = false,
	filterPanel = false,
}: any) => {
	return (
		<div className="mb-4 flex flex-col space-y-4">
			{label && (
				<span className="font-medium text-z-shark text-lg">{label}</span>
			)}
			<div
				style={{ gap: gap, display: display }}
				className={clsx(
					"items-center overflow-x-auto no-scrollbar",
					wrap && "flex-wrap",
					flexCol && "flex-col",
					filterPanel ? "overflow-hidden" : "overflow-x-auto no-scrollbar",
					parentClassName,
				)}
			>
				{options.map((option: any, index: any) => {
					const isSelected = selectedValue.includes(option.value);
					const backgroundColor = isSelected ? activeBg : "white";
					const textColor =
						variant === "primary"
							? isSelected
								? "white"
								: activeColor
							: isSelected
								? activeColor
								: "#82B8FF";
					const borderColor = customBorderColor
						? isSelected
							? customBorderColor
							: "rgba(0,0,0,0.03)"
						: isSelected
							? "transparent"
							: activeBg;

					return (
						<div
							key={option}
							style={{
								width,
								height,
								minWidth: width,
								backgroundColor,
								color: textColor,
								border: `2px solid ${borderColor}`,
							}}
							className={clsx(
								firstClass && index === 0 && "!w-[85px] !min-w-[85px]",
								"border flex items-center justify-center rounded-full",
								width === "fit" && "w-fit",
								styleIndex && "w-[90px]",
								customClassName,
							)}
							onClick={() => onChange(option.value)}
							onKeyDown={() => onChange(option.value)}
						>
							<span className="text-base font-bold">
								{uppercaseText ? option.value.toUpperCase() : option.value}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export { PropertyTypeSelector };
