interface IconProps {
	fill?: string;
}
const MinusIcon = ({ fill = "black" }: IconProps) => {
	return (
		<svg
			width="16"
			height="2"
			viewBox="0 0 16 2"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="16" height="2" fill="#B0B0B0" />
			<g id="Buyers Side - Aug 9">
				<path
					d="M-5869 -2459C-5869 -2460.1 -5868.1 -2461 -5867 -2461H17856C17857.1 -2461 17858 -2460.1 17858 -2459V10857C17858 10858.1 17857.1 10859 17856 10859H-5867C-5868.1 10859 -5869 10858.1 -5869 10857V-2459Z"
					fill="#202020"
				/>
				<path
					d="M-5867 -2460H17856V-2462H-5867V-2460ZM17857 -2459V10857H17859V-2459H17857ZM17856 10858H-5867V10860H17856V10858ZM-5868 10857V-2459H-5870V10857H-5868ZM-5867 10858C-5867.55 10858 -5868 10857.6 -5868 10857H-5870C-5870 10858.7 -5868.66 10860 -5867 10860V10858ZM17857 10857C17857 10857.6 17856.6 10858 17856 10858V10860C17857.7 10860 17859 10858.7 17859 10857H17857ZM17856 -2460C17856.6 -2460 17857 -2459.55 17857 -2459H17859C17859 -2460.66 17857.7 -2462 17856 -2462V-2460ZM-5867 -2462C-5868.66 -2462 -5870 -2460.66 -5870 -2459H-5868C-5868 -2459.55 -5867.55 -2460 -5867 -2460V-2462Z"
					fill="black"
					fillOpacity="0.1"
				/>
				<g id="BuyerCitySelectionView" clipPath="url(#clip0_236_2)">
					<rect
						width="430"
						height="932"
						transform="translate(-374 -613)"
						fill="white"
					/>
					<g id="Group 1000002589">
						<rect
							id="Rectangle 5239"
							x="-347"
							y="-30"
							width="376"
							height="62"
							rx="7"
							fill={fill}
						/>
						<g id="Group 1000002587">
							<g id="Group 1000002580">
								<g id="&#240;&#159;&#166;&#134; icon &#34;plus&#34;">
									<path
										id="Vector"
										d="M1 1H14.3889"
										stroke={fill}
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_236_2">
					<rect
						width="430"
						height="932"
						fill="white"
						transform="translate(-374 -613)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default MinusIcon;
