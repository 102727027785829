import { universalFetch } from "./common";
import type { StatisticsResponse } from "../interfaces/statistics";

export const fetchGraph = async (
  listingId: string
): Promise<StatisticsResponse> => {
  try {
    const response = await universalFetch(
      `/listings/${listingId}/statistics`,
      "GET"
    );

    console.log("finance response:", response);
    return response as StatisticsResponse;
  } catch (error) {
    console.error("Failed to fetch finance", error);
    throw error;
  }
};
