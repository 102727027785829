interface IconProps {
	fill: string;
}
const HeartCustomIcon = ({ fill }: IconProps) => {
	return (
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2077 4.08404C13.7335 4.08404 14.2585 4.15904 14.7577 4.32571C17.8335 5.32571 18.9419 8.70071 18.016 11.6507C17.4924 13.1585 16.6353 14.5286 15.5085 15.659C13.8992 17.2174 12.1309 18.6028 10.2327 19.7924L10.0244 19.9174L9.8077 19.784C7.89713 18.5981 6.11797 17.2126 4.5002 15.6507C3.37842 14.5192 2.52146 13.1531 1.99103 11.6507C1.04936 8.70071 2.1577 5.32571 5.26686 4.30904C5.50853 4.22571 5.7577 4.16738 6.0077 4.13404H6.1077C6.34186 4.10071 6.57436 4.08404 6.8077 4.08404H6.89936C7.42436 4.10071 7.9327 4.19238 8.4252 4.35904H8.47436C8.5077 4.37571 8.5327 4.39238 8.54936 4.40904C8.73353 4.46738 8.9077 4.53404 9.07436 4.62571L9.39103 4.76738C9.4677 4.80904 9.55353 4.87071 9.6277 4.92571C9.67436 4.95904 9.71686 4.98988 9.74936 5.00904L9.79103 5.03404C9.86186 5.07571 9.93686 5.11904 9.99936 5.16738C10.9187 4.45972 12.0476 4.07854 13.2077 4.08404ZM15.4244 10.084C15.766 10.0757 16.0577 9.80071 16.0827 9.45071V9.35071C16.0967 8.78408 15.9353 8.22696 15.6206 7.75558C15.3058 7.2842 14.8531 6.9216 14.3244 6.71738C14.1575 6.66173 13.9754 6.6743 13.8177 6.75235C13.6601 6.8304 13.5396 6.96759 13.4827 7.13404C13.366 7.48404 13.5494 7.86738 13.8994 7.99238C14.4344 8.19238 14.791 8.71738 14.791 9.30071V9.32571C14.7744 9.51199 14.8312 9.69741 14.9494 9.84238C15.066 9.98404 15.241 10.0674 15.4244 10.084Z" fill={fill}/>
        </svg>
    )
}
export default HeartCustomIcon;