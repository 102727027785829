import { formatPrice } from "../../../utils/formatPrice";

interface MortgageCalculatoreBoxProps {
  price: string;
  downPayment: string;
  rateValue: string;
  amortizationPeriod: string;
}

class MortgageCalculator {
  propertyPrice: number;
  interestRate: number;
  amortizationPeriod: number;
  downPayment: number;

  constructor(
    propertyPrice: number,
    interestRate: number,
    amortizationPeriod: number,
    downPayment: number
  ) {
    this.propertyPrice = propertyPrice;
    this.interestRate = interestRate / 100; 
    this.amortizationPeriod = amortizationPeriod * 12;
    this.downPayment = downPayment;
  }

  calculateLoanAmount(): number {
    return this.propertyPrice - this.downPayment;
  }

  // Calculate the monthly interest rate
  calculateMonthlyInterestRate(): number {
    return this.interestRate / 12;
  }

  // Calculate the monthly mortgage payment
  calculateMonthlyPayment(): number {
    const loanAmount = this.calculateLoanAmount();
    const monthlyInterestRate = this.calculateMonthlyInterestRate();
    const numberOfPayments = this.amortizationPeriod;

    // Formula: M = P[r(1+r)^n] / [(1+r)^n - 1]
    const numerator =
      loanAmount *
      monthlyInterestRate *
      (1 + monthlyInterestRate) ** numberOfPayments; // Using ** operator instead of Math.pow
    const denominator = (1 + monthlyInterestRate) ** numberOfPayments - 1; // Using ** operator instead of Math.pow
    return numerator / denominator;
  }

  // Calculate the total mortgage amount (monthly payment * number of payments)
  calculateTotalMortgageAmount(): number {
    return this.calculateMonthlyPayment() * this.amortizationPeriod;
  }

  // Calculate the total interest paid (total mortgage amount - loan amount)
  calculateTotalInterestPayment(): number {
    return this.calculateTotalMortgageAmount() - this.calculateLoanAmount();
  }

  // Function to return only the monthly mortgage payment and total interest payment
  getResults() {
    return {
      monthlyMortgagePayment: this.calculateMonthlyPayment(),
      totalInterestPayment: this.calculateTotalInterestPayment(),
    };
  }
}

const MortgageCalculatoreBox = ({
  price,
  downPayment,
  rateValue,
  amortizationPeriod,
}: MortgageCalculatoreBoxProps) => {
  const parsedPrice = Number.parseInt(price.replace(/[^0-9]/g, ""), 10) || 0;
  const parsedDownPayment =
    Number.parseInt(downPayment.replace(/[^0-9]/g, ""), 10) || 0;

  const zownDownPaymentContribution = Math.round(parsedPrice * 0.015);
  const clientDownPaymentContribution =
    parsedDownPayment - zownDownPaymentContribution;

  const totalMortgageAmount = parsedPrice - parsedDownPayment;

  const interestRate = Number(rateValue.replace("%", ""));

  const amortizationYears = Number(amortizationPeriod.replace(/\D/g, ""));

  const mortgageCalculator = new MortgageCalculator(
    parsedPrice,
    interestRate,
    amortizationYears,
    parsedDownPayment
  );
  const mortgagePaymentAmount = mortgageCalculator.calculateMonthlyPayment();
  const totalInterest = mortgageCalculator.calculateTotalInterestPayment();
  const totalPurchaseCost = parsedPrice + totalInterest;
  return (
    <div className="rounded-[16.38px] text-white pb-10 pt-6 px-6 space-y-3 ease-linear bg-gradient-to-r from-transparent via-transparent to-[#10583A] bg-[#4B8238]">
      <div className="font-semibold text-lg">Mortgage Details</div>
      <div className="space-y-3">
        <div className="space-y-2">
          <div className="w-full flex items-center justify-between">
            <div>Total Down Payment:</div>
            <div className="text-lg font-light">
              $ {formatPrice(parsedDownPayment)}
            </div>
          </div>
          <div className="pl-6 space-y-2">
            <div className="w-full flex items-center justify-between">
              <div className="italic text-sm font-light">
                Your Contribution:
              </div>
              <div className="text-lg font-light">
                $ ({formatPrice(clientDownPaymentContribution)})
              </div>
            </div>
            <div className="w-full flex items-center justify-between">
              <div className="italic text-sm font-light">
                Zown's Contribution:
              </div>
              <div className="text-lg font-light">
                $ ({formatPrice(zownDownPaymentContribution)})
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-between">
            <div>Total Mortgage Amount:</div>
            <div className="text-lg font-light">
              $ {formatPrice(totalMortgageAmount)}
            </div>
          </div>
          <div className="w-full flex items-center justify-between">
            <div>Total Interest Payment:</div>
            <div className="text-lg font-light">
              $ {formatPrice(totalInterest)}
            </div>
          </div>
          <div className="w-full flex items-center justify-between">
            <div>Total Purchase Cost:</div>
            <div className="text-lg font-light">
              $ {formatPrice(totalPurchaseCost)}
            </div>
          </div>
        </div>
        <hr />
        <div className="w-full flex items-center justify-between font-semibold">
          <div>Mortgage Payment:</div>
          <div className="text-xl">$ {formatPrice(mortgagePaymentAmount)}</div>
        </div>
      </div>
    </div>
  );
};

export { MortgageCalculatoreBox };
