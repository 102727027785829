interface SearchIconIconProps {
	fill: string;
}
export const SearchIcon = ({ fill }: SearchIconIconProps) => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1417_7208)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.1 1.8C4.62061 1.8 1.8 4.62061 1.8 8.1C1.8 11.5794 4.62061 14.4 8.1 14.4C11.5794 14.4 14.4 11.5794 14.4 8.1C14.4 4.62061 11.5794 1.8 8.1 1.8ZM0 8.1C0 3.62649 3.62649 0 8.1 0C12.5735 0 16.2 3.62649 16.2 8.1C16.2 12.5735 12.5735 16.2 8.1 16.2C3.62649 16.2 0 12.5735 0 8.1Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5468 12.5473C12.8983 12.1958 13.4681 12.1958 13.8196 12.5473L17.7346 16.4623C18.0861 16.8138 18.0861 17.3836 17.7346 17.7351C17.3831 18.0866 16.8133 18.0866 16.4618 17.7351L12.5468 13.8201C12.1953 13.4686 12.1953 12.8988 12.5468 12.5473Z"
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id="clip0_1417_7208">
				<rect width="18" height="18" fill={fill} />
			</clipPath>
		</defs>
	</svg>
);
