import { useEffect, useState } from "react";
import { flinksData } from "../../../services/account";
import { useFinanceStore } from "../../../stores/financeStore";
import { useModalStore } from "../../../stores/handleModalStore";

const searchParams = new URLSearchParams({
  demo: "true", // comment in production
  consentEnable: "false",
  customerName: "Zown Realty Inc.",
  headerEnable: "false",
  accountSelectorMultiple: "true",
  fetchAllAccounts: "true",
  showAllAccounts: "true",
  daysOfTransactions: "Days365",
  monthsOfStatements: "Months12",
  detailsAndStatementEnable: "true",
});

interface BenefitItemProps {
  title: string;
  description: string;
}

const BenefitItem = ({ title, description }: BenefitItemProps) => (
  <div className="flex items-center gap-5 w-full">
    <div className="flex justify-center items-center flex-shrink-0">
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
      <svg
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.4582 2.29248L8.76818 14.9364L3 9.18915"
          stroke="#0A9D57"
          strokeWidth="4.14402"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
    <div className="text-left flex-grow">
      <div className="font-semibold text-lg">{title}</div>
      <div className="font-light text-sm">{description}</div>
    </div>
  </div>
);

interface FlinksBoxProps {
  setConnectFlinks: (value: boolean) => void;
}

const FlinksBox = ({ setConnectFlinks }: FlinksBoxProps) => {
  const [showAddAccount, setShowAddAccount] = useState(false);
  const { setQualification } = useFinanceStore();
  const { setFlinksModal } = useModalStore();

  useEffect(() => {
    const handleMessage = async (e: MessageEvent) => {
      console.log("message", e.data);
      const { step, loginId, requestId, institution } = e.data;
      if (step === "REDIRECT" && loginId && requestId && institution) {
        try {
          setShowAddAccount(true);
          await flinksData(loginId, requestId, institution);
        } catch (error) {
          console.error("Failed to fetch Flinks data:", error);
          setShowAddAccount(true);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleAdd = () => {
    setShowAddAccount(false);
  };

  const handleSkip = () => {
    setQualification(false);
    setConnectFlinks(true);
    setFlinksModal(false);
  };

  return (
    <div className="h-full w-full">
      {showAddAccount ? (
        <div className="flex flex-col items-center justify-center w-full p-2">
          <div className="text-center mb-5">
            <div className="font-semibold text-2xl">Add more accounts</div>
            <div className="font-medium text-lg text-gray-500">
              Connect another bank account
            </div>
          </div>
          <div className="bg-blue-100 bg-opacity-50 rounded-xl flex flex-col justify-center items-center gap-5 p-5 w-full max-w-2xl mb-5">
            <BenefitItem
              title="Increased Credibility"
              description="Demonstrates financial stability and responsibility."
            />
            <BenefitItem
              title="Higher Asset Base"
              description="Reflects a higher total amount of assets, improving financial profile."
            />
            <BenefitItem
              title="Proof of Savings"
              description="Clearly shows sufficient funds for down payment and closing costs."
            />
          </div>
          <button
            type="button"
            className="h-16 rounded-full w-full font-semibold text-lg bg-black text-white mb-3"
            onClick={handleAdd}
          >
            Add another account
          </button>
          <button
            type="button"
            className="h-16 rounded-full w-full font-semibold border-4 text-lg bg-white text-gray-900 border-3 border-gray-900"
            onClick={handleSkip}
          >
            Skip
          </button>
        </div>
      ) : (
        <iframe
          src={`https://zown-iframe.private.fin.ag/v2/?${searchParams.toString()}`}
          className="w-full h-full"
          title="Zown Website"
          width="100%"
          height="100%"
          style={{
            border: "none",
          }}
        />
      )}
    </div>
  );
};

export { FlinksBox };
