import clsx from "clsx";
import { cloneElement, useEffect, type ReactElement, type ReactNode } from "react";

interface MultiSelectOption {
	value: string;
	icon?: ReactNode;
}

interface MultiSelectProps {
	label?: string;
	options: MultiSelectOption[];
	selectedValue: string[];
	onChange: (value: string[]) => void;
	width?: "fit" | string;
	height?: string;
	styleIndex?: number;
	customClassName?: string;
	firstClass?: boolean;
	wrap?: boolean;
	gap?: string;
	defaultIconFill?: string;
	selectedIconFill?: string;
	display?: string;
	parentClassName?: string;
	textClassName?: string;
}

const MultiSelectGroup = ({
	label,
	options,
	selectedValue,
	onChange,
	firstClass = false,
	width = "40px",
	height = "40px",
	styleIndex,
	customClassName,
	wrap,
	gap = "16px",
	defaultIconFill = "#000000",
	selectedIconFill = "#FFFFFF",
	display = "flex",
	parentClassName,
	textClassName,
}: MultiSelectProps) => {
	const handleMultiSelect = (value: string) => {
		if (value === "Any") {
			onChange(["Any"]);
		} else if (selectedValue.includes("Any")) {
			onChange([value]);
		} else if (selectedValue.includes(value)) {
			const updatedValues = selectedValue.filter((item) => item !== value);
			onChange(updatedValues.length === 0 ? ["Any"] : updatedValues);
		} else {
			onChange([...selectedValue, value]);
		}
	};

	useEffect(() => {
		if (selectedValue.length === 0) {
			onChange(["Any"]);
		}
	}, [selectedValue, onChange]);

	return (
		<div className="mb-4 flex flex-col space-y-4">
			<span className="font-medium text-z-shark text-lg">{label}</span>
			<div
				style={{ gap: gap, display: display }}
				className={clsx(
					"items-center overflow-x-auto",
					wrap && "flex-wrap",
					parentClassName,
				)}
			>
				{options.map((option, index) => {
					const isSelected = selectedValue.includes(
						option.value === "5" ? "5" : option.value,
					);
					return (
						<div
							key={option.value}
							style={{ width, height, minWidth: width }}
							className={clsx(
								firstClass && index === 0 && "!w-[85px] !min-w-[85px]",
								"border flex items-center justify-center rounded-full",
								isSelected
									? "bg-z-dark-moss-green !text-white !border-transparent"
									: "bg-white text-z-dark-moss-green",
								width === "fit" && "w-fit",
								styleIndex && "w-[90px]",
								customClassName,
								option.icon !== "" && "flex-col",
							)}
							onKeyDown={() => handleMultiSelect(option.value)}
							onClick={() => handleMultiSelect(option.value)}
						>
							{option.icon &&
								cloneElement(option.icon as ReactElement, {
									fill: isSelected ? selectedIconFill : defaultIconFill,
								})}
							<span className={clsx("text-base font-bold", textClassName)}>
								{option.value === "5" ? "5+" : option.value}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export { MultiSelectGroup };
