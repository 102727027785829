import clsx from "clsx";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import ZownLogoBlueWide from "../../../assets/zown-logo-blue-wide.svg";
import { Button } from "../Button";
interface HeaderProps {
	customClassName?: string;
}
const Header = ({ customClassName }: HeaderProps) => {
	return (
		<div
			className={clsx(
				"bg-white h-[102px] flex items-center border-b border-[#E4E2E1]",
				customClassName,
			)}
		>
			<div className="container mx-auto">
				<div className="flex items-center justify-between w-full">
					<img className="h-11" src={ZownLogoBlueWide} alt="zown logo" />
					<div className="text-z-shark text-lg flex items-center space-x-28">
						<Link to="">
							<span>How it works</span>
						</Link>
						<div className="flex items-center">
							<span>About us</span>
							<BiChevronDown className="text-3xl" />
						</div>
						<Link to="">
							<span>Media center</span>
						</Link>
					</div>
					<div className="flex items-center space-x-5">
						<Button
							radius="50px"
							customClassName="border-[1px] px-4 py-3"
							isOutlined
							variant="dark"
							width="fit"
						>
							Contact Us
						</Button>
						<Button radius="50px" customClassName="px-6 py-3" width="fit">
							Get started
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Header };
