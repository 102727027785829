import { useEffect, useRef, useState } from "react";
import { LuCalendarDays } from "react-icons/lu";
import { RiHomeLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useShowingCartStore } from "../../stores/showingCartStore";
import { formatPrice } from "../../utils/formatPrice";
import { Calendar } from "../ShowingDateSelectionView/calender";
import { Button } from "../common";

interface DateItem {
	id: string;
	date: string;
}

const useDateState = () => {
	const [dates, setDates] = useState<DateItem[]>([]);
	const setStoreDates = useShowingCartStore((state) => state.setDates);

	useEffect(() => {
		const convertedDates = dates.map((item) => ({
			...item,
			date: new Date(item.date).toISOString(),
		}));
		setStoreDates(convertedDates.map((item) => item.date));
	}, [dates, setStoreDates]);

	const reorderDates = (startIndex: number, endIndex: number) => {
		const reorderedDates = Array.from(dates);
		const [movedItem] = reorderedDates.splice(startIndex, 1);
		reorderedDates.splice(endIndex, 0, movedItem);
		setDates(reorderedDates);
	};

	const removeDate = (id: string) => {
		const updatedDates = dates.filter((item) => item.id !== id);
		setDates(updatedDates);
	};

	return { dates, setDates, reorderDates, removeDate };
};

const GreatDeal = () => {
	const navigate = useNavigate();
	const { dates, setDates, removeDate } = useDateState();
	const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
	const [showCalendar, setShowCalendar] = useState(false);
	const calendarRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				calendarRef.current &&
				!calendarRef.current.contains(event.target as Node)
			) {
				setShowCalendar(false);
			}
		};

		if (showCalendar) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showCalendar]);

	return (
		<div>
			<div className="flex flex-col sticky top-6">
				<div className="bg-z-prussian-blue px-5 py-6 rounded-[20px] flex flex-col divide-y-[1px] divide-[#B3B3B3]">
					<div className="flex items-center justify-between pb-5">
						<span className="text-[32px] text-white font-bold">
							${formatPrice(1100999)}
						</span>
						<div className="flex items-center space-x-1">
							<RiHomeLine className="text-2xl text-z-abbey" />
							<div className="text-sm font-bold text-white">Great deal</div>
						</div>
					</div>
					<div>
						<div className="pt-5 text-2xl font-bold text-white">
							Down payment boost <br /> breakdown
						</div>
						<div className="space-y-4 mt-4">
							<div className="flex justify-between items-center text-z-shark bg-white rounded-full p-3">
								<div className="font-medium text-sm">
									Min. down payment needed
								</div>
								<div className="font-bold">${formatPrice(35000)}</div>
							</div>
							<div className="flex justify-between items-center text-z-shark bg-z-abbey rounded-full p-3">
								<div className="font-medium text-sm">
									Zown’s contribution (30%)
								</div>
								<div className="font-bold">${formatPrice(10800)}</div>
							</div>
							<div className="flex justify-between items-center text-z-shark bg-white rounded-full p-3">
								<div className="font-medium text-sm">
									New down payment needed
								</div>
								<div className="font-bold">${formatPrice(24200)}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="rounded-[20px] relative top-0 bg-z-abbey flex flex-col justify-center items-center py-6 space-y-3 mt-3">
					<div className="text-2xl font-bold">Interested in this home?</div>
					<Link
						target="_blank"
						to="https://calendly.com/d/cks5-fh8-pmq/zown-down-payment-consultation"
					>
						<Button
							width="240px"
							radius="50px"
							customClassName="!bg-white h-11 space-x-3"
						>
							<span className="text-z-shark font-bold">Contact Us</span>
							<LuCalendarDays className="text-z-shark text-lg" />
						</Button>
					</Link>
					{showCalendar && (
						<div
							ref={calendarRef}
							className="w-[350px] !mt-0 h-auto p-6 rounded-[20px] absolute top-1/2 -translate-y-1/2 -left-[360px] z-10"
							style={{
								background:
									"linear-gradient(180deg, #FECD07 0%, #FFBE55 100%), linear-gradient(97deg, #4B8238 0.19%, #10583A 100%)",
							}}
						>
							<div className="text-center mb-5 text-z-shark text-2xl font-bold">
								Select a date
							</div>
							<Calendar
								singleSelect
								setButtonEnabled={setButtonEnabled}
								dates={dates}
								setDates={setDates}
							/>
							<Button
								radius="50px"
								onClick={() => {
									setShowCalendar(false);
									console.log("date", dates);
								}}
								customClassName="h-11 mt-3"
								variant="dark"
								width="full"
							>
								<span className="font-bold mr-3">Book Showing</span>
								<LuCalendarDays className="text-2xl" />
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export { GreatDeal };
