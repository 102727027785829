import { LoadingCircle } from "../common";
import { formatPrice } from "../../utils/formatPrice";
import { useUserProfileStore } from "../../stores/profileStore";
import { EditIconStroke, EditIconWhite } from "../../assets/icons";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import { useState, useRef, type ChangeEvent, useEffect } from "react";
import { fetchAccountData, updateFinancesData } from "../../services/account";
import {
	convertToFormattedAmount,
	convertToNumberAndMultiplyBy100,
} from "../../utils/convertAmount";

const MaximumAffordabilityView = () => {
	const [income, setIncome] = useState<number | undefined>();
	const [savings, setSavings] = useState<number | undefined>();
	const [debt, setDebt] = useState<number | undefined>();
	const [inputValue, setInputValue] = useState<string>("");
	const [maximumAffordability, setMaximumAffordability] = useState<number>(0);
	const [selectedBox, setSelectedBox] = useState<string | null>(null);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const { userProfile, setUserProfile } = useUserProfileStore();

	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		setIncome(
			convertToFormattedAmount(
				userProfile?.finances?.income?.[0]?.amount.amount || 0,
			),
		);
		setSavings(
			convertToFormattedAmount(
				userProfile?.finances?.savings?.[0]?.amount.amount || 0,
			),
		);
		setDebt(
			convertToFormattedAmount(
				userProfile?.finances?.monthlyDebt?.[0]?.amount.amount || 0,
			),
		);

		setMaximumAffordability(
			convertToFormattedAmount(
				userProfile?.finances?.affordability?.amount || 0,
			),
		);
	}, [userProfile]);

	const handleBoxClick = (box: string, currentValue: number | undefined) => {
		if (selectedBox === box) {
			setSelectedBox(null);
		} else {
			setSelectedBox(box);
			setInputValue(currentValue ? currentValue.toString() : "");

			setTimeout(() => {
				inputRef.current?.focus();
			}, 0);
		}
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const unformattedValue = e.target.value.replace(/,/g, "");
		setInputValue(unformattedValue);
	};

	const handleSave = async () => {
		setButtonDisabled(true);

		const newValue = Number(inputValue.replace(/,/g, ""));

		if (selectedBox === "income") {
			setIncome(newValue);
		} else if (selectedBox === "savings") {
			setSavings(newValue);
		} else if (selectedBox === "debt") {
			setDebt(newValue);
		}

		const financesPayload = {
			totalIncome: {
				amount: convertToNumberAndMultiplyBy100(
					((selectedBox === "income" ? newValue : income) || 0).toString(),
				),
				currency: "CAD",
			},
			totalSavings: {
				amount: convertToNumberAndMultiplyBy100(
					((selectedBox === "savings" ? newValue : savings) || 0).toString(),
				),
				currency: "CAD",
			},
			totalMonthlyDebts: {
				amount: convertToNumberAndMultiplyBy100(
					((selectedBox === "debt" ? newValue : debt) || 0).toString(),
				),
				currency: "CAD",
			},
		};

		try {
			setLoading(true);
			await updateFinancesData(financesPayload);
			const accountData = await fetchAccountData();
			setUserProfile(accountData);
			setLoading(false);
			setButtonDisabled(false);
		} catch (error) {
			console.error("Error updating data:", error);
			setButtonDisabled(false);
		}
		setSelectedBox(null);
	};

	return (
		<div className="pb-2 h-full px-4">
			<div className="h-full flex flex-col justify-center space-y-10">
				<div className="space-y-6">
					<div className="text-2xl font-bold">
						Based on your financials, your maximum affordability is
					</div>
					{!loading && (
						<div className="bg-z-dark-moss-green w-fit text-white font-bold text-4xl py-2 px-3 rounded-[10px]">
							${formatPrice(maximumAffordability)}
						</div>
					)}
				</div>
				{loading && (
					<div className="flex items-center justify-center w-full">
						<LoadingCircle />
					</div>
				)}
				{!loading && (
					<>
						<div className="flex items-center justify-between gap-2">
							<div
								className={`${
									selectedBox === "income"
										? "bg-z-dark-moss-green text-white"
										: "bg-white"
								} flex flex-col w-full items-center justify-center space-y-2 p-2 border-2 border-z-dark-moss-green rounded-[10px] cursor-pointer`}
								onClick={() => handleBoxClick("income", income)}
								onKeyDown={() => handleBoxClick("income", income)}
							>
								<div className="font-medium">Income</div>
								<div className="text-lg font-bold">
									${formatPrice(income || 0)}
								</div>
								<img
									src={
										selectedBox === "income" ? EditIconWhite : EditIconStroke
									}
									alt="Edit Icon"
								/>
							</div>

							<div
								className={`${
									selectedBox === "savings"
										? "bg-z-dark-moss-green text-white"
										: "bg-white"
								} flex flex-col w-full items-center justify-center space-y-2 p-2 border-2 border-z-dark-moss-green rounded-[10px] cursor-pointer`}
								onClick={() => handleBoxClick("savings", savings)}
								onKeyDown={() => handleBoxClick("savings", savings)}
							>
								<div className="font-medium">Savings</div>
								<div className="text-lg font-bold">
									${formatPrice(savings || 0)}
								</div>
								<img
									src={
										selectedBox === "savings" ? EditIconWhite : EditIconStroke
									}
									alt="Edit Icon"
								/>
							</div>

							<div
								className={`${
									selectedBox === "debt"
										? "bg-z-dark-moss-green text-white"
										: "bg-white"
								} flex flex-col w-full items-center justify-center space-y-2 p-2 border-2 border-z-dark-moss-green rounded-[10px] cursor-pointer`}
								onClick={() => handleBoxClick("debt", debt)}
								onKeyDown={() => handleBoxClick("debt", debt)}
							>
								<div className="font-medium">Debt</div>
								<div className="text-lg font-bold">
									${formatPrice(debt || 0)}/m
								</div>
								<img
									src={selectedBox === "debt" ? EditIconWhite : EditIconStroke}
									alt="Edit Icon"
								/>
							</div>
						</div>

						{selectedBox && (
							<div className="mt-4">
								<div className="border-z-dark-moss-green border-2 rounded-[10px] flex items-center justify-center h-full cursor-pointer">
									<div
										className="flex flex-col w-full pl-2 py-2"
										onClick={() => inputRef.current?.focus()}
										onKeyDown={() => inputRef.current?.focus()}
									>
										<div className="font-medium">
											Edit {capitalizeFirstLetter(selectedBox)}
										</div>
										<div className="flex items-center gap-0.5 font-bold text-2xl">
											$
											<input
												type="tel"
												ref={inputRef}
												value={formatPrice(inputValue ? Number(inputValue) : 0)}
												onChange={handleInputChange}
												className="w-full"
												placeholder="Enter new value"
											/>
										</div>
									</div>
									<button
										type="button"
										onClick={handleSave}
										className="bg-transparent h-full px-2"
										disabled={buttonDisabled}
									>
										<div className="bg-z-dark-moss-green font-bold text-white px-5 py-2 rounded-[10px]">
											Save
										</div>
									</button>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export { MaximumAffordabilityView };
