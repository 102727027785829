interface IconProps {
	bg?: string;
}
const CreditScoreIcon = ({ bg = "#EEF7FB" }: IconProps) => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="credit score messaging">
				<g id="credit score meter">
					<circle id="Ellipse 2399" cx="8" cy="8" r="8" fill={bg} />
					<g id="Group 1000002263">
						<path
							id="Ellipse 2395"
							d="M13.6 9.40156C13.6 8.6399 13.45 7.88569 13.1585 7.182C12.867 6.47831 12.4398 5.83892 11.9012 5.30034C11.3626 4.76176 10.7233 4.33454 10.0196 4.04306C9.31588 3.75158 8.56167 3.60156 7.8 3.60156C7.03833 3.60156 6.28412 3.75158 5.58044 4.04306C4.87675 4.33454 4.23736 4.76176 3.69878 5.30034C3.1602 5.83892 2.73298 6.47831 2.4415 7.182C2.15002 7.88569 2 8.6399 2 9.40156H3.61352C3.61352 8.85179 3.72181 8.30739 3.9322 7.79947C4.14259 7.29154 4.45096 6.83003 4.83971 6.44128C5.22846 6.05253 5.68998 5.74415 6.1979 5.53376C6.70583 5.32337 7.25022 5.21509 7.8 5.21509C8.34978 5.21509 8.89417 5.32337 9.4021 5.53376C9.91002 5.74415 10.3715 6.05253 10.7603 6.44128C11.149 6.83003 11.4574 7.29154 11.6678 7.79947C11.8782 8.30739 11.9865 8.85179 11.9865 9.40156H13.6Z"
							fill="#C1E3EE"
						/>
						<path
							id="Ellipse 2398"
							d="M13.6 9.40156C13.6 8.28835 13.2796 7.19866 12.6772 6.26257C12.0747 5.32649 11.2156 4.58356 10.2023 4.12247C9.1891 3.66138 8.06458 3.50163 6.96303 3.66227C5.86147 3.82291 4.82941 4.29717 3.99006 5.02842L5.04996 6.245C5.65581 5.71718 6.40076 5.37486 7.19587 5.25891C7.99098 5.14295 8.80266 5.25827 9.53402 5.59108C10.2654 5.92389 10.8855 6.46015 11.3204 7.13582C11.7552 7.81149 11.9865 8.59804 11.9865 9.40156H13.6Z"
							fill="#159CC1"
						/>
						<path
							id="Ellipse 2397"
							d="M13.6 9.40156C13.6 7.94333 13.0507 6.53861 12.0615 5.46718C11.0723 4.39575 9.71587 3.73624 8.26228 3.62001L8.13368 5.2284C9.18289 5.3123 10.162 5.78833 10.876 6.5617C11.59 7.33506 11.9865 8.349 11.9865 9.40156H13.6Z"
							fill="#5ABB46"
						/>
						<path
							id="Ellipse 2396"
							d="M13.6 9.40156C13.6 8.61591 13.4404 7.83844 13.1308 7.11634C12.8213 6.39424 12.3682 5.74255 11.7992 5.20082L10.6867 6.36944C11.0974 6.76046 11.4244 7.23086 11.6478 7.75207C11.8713 8.27329 11.9865 8.83447 11.9865 9.40156H13.6Z"
							fill="#0E8742"
						/>
						<path
							id="Vector"
							d="M8.30879 9.09542C8.26526 9.19581 8.18663 9.27681 8.08772 9.32322L7.32973 9.62048C6.98957 9.59612 6.86651 9.53155 6.69773 9.28887L6.50952 8.49516C6.49118 8.38724 6.51281 8.27634 6.57029 8.18325L8.93434 5.08614C9.22709 4.6122 9.72778 4.8692 9.50603 5.38038L8.30879 9.09542Z"
							fill="#FF0000"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CreditScoreIcon;
