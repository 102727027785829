import BedIcon from "../../assets/bedIcon.svg";
import { useNavigate } from "react-router-dom";
import BathIcon from "../../assets/bathIcon.svg";
import SqftIcon from "../../assets/sqftIcon.svg";
import Xbutton from "../../assets/xButtonIcon.svg";
import { reactions } from "../../services/reactions";
import { formatPrice } from "../../utils/formatPrice";
import SaveIcon from "../../assets/Cart/saveIcon.svg";
import TrashIcon from "../../assets/Cart/trashIcon.svg";
import { removeFromCart } from "../../services/outings";
import { convertToFormattedAmount } from "../../utils/convertAmount";
import type { CartProperties } from "../../interfaces/cartProperties";
import {
	useState,
	useRef,
	useEffect,
	useCallback,
	type ChangeEvent,
} from "react";

interface PropertyCardProps {
	property: CartProperties;
	checkoutProperty?: boolean;
	isChecked?: boolean;
	onCheckboxChange?: (listingId: string, isChecked: boolean) => void;
	onRemoveProperty?: (listingId: string) => void;
}

export const PropertyCard = ({
	property,
	checkoutProperty = false,
	isChecked = false,
	onRemoveProperty,
	onCheckboxChange,
}: PropertyCardProps) => {
	const [showOptions, setShowOptions] = useState(false);
	const optionsRef = useRef<HTMLDivElement | null>(null);
	const navigate = useNavigate();

	const toggleOptions = () => {
		setShowOptions(!showOptions);
	};

	const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (onCheckboxChange) {
			onCheckboxChange(property.listingId, e.target.checked);
		}
	};
	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (
			optionsRef.current &&
			!optionsRef.current.contains(event.target as Node)
		) {
			setShowOptions(false);
		}
	}, []);

	useEffect(() => {
		if (showOptions) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showOptions, handleClickOutside]);

	useEffect(() => {
		if (showOptions && optionsRef.current) {
			const optionsRect = optionsRef.current.getBoundingClientRect();
			const isOffScreen = optionsRect.right > window.innerWidth;

			if (isOffScreen) {
				optionsRef.current.style.left = `-${
					optionsRect.right - window.innerWidth + 20
				}px`;
			}
		}
	}, [showOptions]);

	const handleRemoveFromCart = async () => {
		await removeFromCart(property.listingId);
		if (onRemoveProperty) {
			onRemoveProperty(property.listingId);
		}
	};

	const handleSaveForLater = async () => {
		await reactions(property.listingId, "SAVE_LISTING");
		await removeFromCart(property.listingId);
		if (onRemoveProperty) {
			onRemoveProperty(property.listingId);
		}
	};

	return (
		<div className="flex items-center h-full gap-3 px-2">
			<div className="w-[150px] h-full">
				<img
					className="h-[144px] w-full object-cover rounded-[10px]"
					src={property.displayImageUrl}
					alt={property.address.address1}
					onClick={() => navigate(`/listing/${property.listingId}`)}
					onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							navigate(`/listing/${property.listingId}`);
						}
					}}
				/>
			</div>
			<div className="w-full space-y-2">
				<div>
					<div className="font-semibold text-sm flex justify-between z-20">
						<div
							className="w-full"
							onClick={() => navigate(`/listing/${property.listingId}`)}
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									navigate(`/listing/${property.listingId}`);
								}
							}}
						>
							{property.address.address1}
						</div>
						{checkoutProperty && (
							<div className="inline-flex items-center">
								<label className="flex items-center cursor-pointer relative">
									<input
										type="checkbox"
										checked={isChecked}
										onChange={handleCheckboxChange}
										className="peer h-[28px] w-[28px] cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md shadow-z-dark-moss-green/50 border border-slate-300 checked:bg-z-dark-moss-green checked:border-z-dark-moss-green"
									/>
									<span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
										{/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-3.5 w-3.5"
											viewBox="0 0 20 20"
											fill="currentColor"
											stroke="currentColor"
											strokeWidth="1"
										>
											<path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
										</svg>
									</span>
								</label>
							</div>
						)}
						{!checkoutProperty && (
							<div
								className="relative w-[40px] h-[20px] flex items-start justify-end"
								onClick={toggleOptions}
								onKeyDown={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										toggleOptions();
									}
								}}
							>
								<img
									src={Xbutton}
									alt="Options"
									className="w-3 cursor-pointer"
									aria-expanded={showOptions}
								/>
								{showOptions && (
									<div
										ref={optionsRef}
										className="absolute top-full mt-2 shadow-lg drop-shadow-md rounded-[10px] w-[199px] bg-white z-10"
									>
										<button
											onClick={handleRemoveFromCart}
											type="button"
											className="px-2 py-3 flex w-full items-center gap-2 cursor-pointer border-b border-[#DCDCDC]"
										>
											<img src={TrashIcon} alt="" />
											<div>Remove from Cart</div>
										</button>
										<button
											type="button"
											onClick={handleSaveForLater}
											className="px-2 py-4 flex w-full items-center gap-2 cursor-pointer"
										>
											<img src={SaveIcon} alt="" />
											<div>Save for Later</div>
										</button>
									</div>
								)}
							</div>
						)}
					</div>
					<div className="font-light text-xs">{property.address.city}</div>
				</div>
				<div
					onClick={() => navigate(`/listing/${property.listingId}`)}
					onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							navigate(`/listing/${property.listingId}`);
						}
					}}
					className="flex justify-between items-start w-full border-2 border-[#E9E9E9] gap-1 text-center p-3"
				>
					<div className="flex items-center flex-col justify-center">
						<div className="font-semibold text-xs">
							$
							{formatPrice(convertToFormattedAmount(property.listPrice.amount))}
						</div>
						<div className="text-[#8C8C8C] text-xs font-light">Price</div>
					</div>
					<div className="flex items-center flex-col justify-center">
						<div className="font-semibold text-xs">
							$
							{formatPrice(
								convertToFormattedAmount(property.downPaymentGiven.amount),
							)}
						</div>
						<div className="text-[#8C8C8C] text-xs font-light text-wrap">
							Down Given
						</div>
					</div>
					<div className="flex items-center flex-col justify-center">
						<div className="font-semibold text-xs">
							$
							{formatPrice(
								convertToFormattedAmount(property.downPaymentNeeded.amount),
							)}
						</div>
						<div className="text-[#8C8C8C] text-xs font-light text-wrap">
							Down Needed
						</div>
					</div>
				</div>
				<div
					onClick={() => navigate(`/listing/${property.listingId}`)}
					onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							navigate(`/listing/${property.listingId}`);
						}
					}}
					className="flex items-center gap-1 justify-between w-full text-xs text-[#7D7D7D]"
				>
					<div className="flex items-center gap-1.5">
						<img src={BedIcon} alt="Beds" className="w-[14px]" />
						<div>{property.bedrooms} Beds</div>
					</div>
					<div className="flex items-center gap-1.5">
						<img src={BathIcon} alt="Baths" className="w-[14px]" />
						<div>{property.bathrooms} Baths</div>
					</div>
					<div className="flex items-center gap-1.5">
						<img src={SqftIcon} alt="Sqft" className="w-[14px]" />
						<div>{property.squareFeet}</div>
					</div>
				</div>
			</div>
		</div>
	);
};
