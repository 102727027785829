import { create } from "zustand";
import propertyTypesData from "../lifeStyle.json";

interface PropertySearchingStore {
  selectedProperties: string[];
  selectedLifestyles: string[];
  mustHaves: string[];
  niceHaves: string[];
  setMustHaves: (mustHaves: string[]) => void;
  setNiceHaves: (niceHaves: string[]) => void;
  setSelectedProperty: (selectedProperties: string[]) => void;
  setSelectedLifestyles: (lifestyles: string[]) => void;
  filteredItems: () => { type: string; displayName: string }[];
  propertySearchingPrice: number[];
  setPropertySearchingPrice: (price: number[]) => void;
}

const usePropertySearchingStore = create<PropertySearchingStore>(
  (set, get) => ({
    selectedProperties: [],
    selectedLifestyles: [],
    mustHaves: [],
    niceHaves: [],
    setMustHaves: (mustHaves: string[]) => {
      set({ mustHaves });
    },

    setNiceHaves: (niceHaves: string[]) => {
      set({ niceHaves });
    },

    setSelectedProperty: (properties: string[]) => {
      const { selectedProperties } = get();

      const updatedProperties = [...selectedProperties];

      for (const property of properties) {
        const propertyIndex = updatedProperties.indexOf(property);
        if (propertyIndex !== -1) {
          updatedProperties.splice(propertyIndex, 1);
        } else {
          updatedProperties.push(property);
        }
      }

      set({
        selectedProperties: updatedProperties,
      });
    },

    setSelectedLifestyles: (lifestyles: string[]) => {
      set({ selectedLifestyles: lifestyles });
    },

    filteredItems: () => {
      const { selectedProperties, selectedLifestyles } = get();

      if (selectedProperties.length === 0 || selectedLifestyles.length === 0) {
        return [];
      }

      const filteredPropertyTypes = propertyTypesData.filter((propertyType) =>
        selectedProperties.includes(propertyType.type)
      );

      const filteredItems: { type: string; displayName: string }[] = [];

      for (const propertyType of filteredPropertyTypes) {
        for (const lifestyleCategory of propertyType.items) {
          if (selectedLifestyles.includes(lifestyleCategory.type)) {
            filteredItems.push(...lifestyleCategory.items);
          }
        }
      }

      return filteredItems;
    },

    propertySearchingPrice: [500],
    setPropertySearchingPrice: (price: number[]) =>
      set({ propertySearchingPrice: price }),
  })
);

export { usePropertySearchingStore };
