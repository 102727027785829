import type SwiperCore from "swiper";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Xbutton from "../../assets/xButtonIcon.svg";
import { useImagesStore } from "../../stores/imagesStore";
import XbuttonWhite from "../../assets/xButtonIconWhite.svg";
import {
	useState,
	useEffect,
	useRef,
	type TouchEvent,
	type MouseEvent,
	type TouchList,
	type WheelEvent,
} from "react";

import "swiper/css";

interface Position {
	x: number;
	y: number;
}

const useImageZoom = (initialScale: number) => {
	const [scale, setScale] = useState(initialScale);
	const [dragStart, setDragStart] = useState<Position | null>(null);
	const [translate, setTranslate] = useState<Position>({ x: 0, y: 0 });
	const [initialPinchDistance, setInitialPinchDistance] = useState<
		number | null
	>(null);

	const getPinchDistance = (touches: TouchList): number => {
		const [touch1, touch2] = Array.from(touches) as [Touch, Touch];
		const dx = touch2.clientX - touch1.clientX;
		const dy = touch2.clientY - touch1.clientY;
		return Math.sqrt(dx * dx + dy * dy);
	};

	const zoomImage = (
		imgRef: HTMLImageElement | null,
		clientX: number,
		clientY: number,
		deltaY: number,
	) => {
		if (imgRef) {
			const { left, top, width, height } = imgRef.getBoundingClientRect();
			const offsetX = clientX - left;
			const offsetY = clientY - top;
			const x = (offsetX / width) * 100;
			const y = (offsetY / height) * 100;
			imgRef.style.transformOrigin = `${x}% ${y}%`;
			const newScale = scale + deltaY * -0.001;
			setScale(Math.min(Math.max(1, newScale), 3));
		}
	};

	return {
		scale,
		setScale,
		initialPinchDistance,
		setInitialPinchDistance,
		translate,
		setTranslate,
		dragStart,
		setDragStart,
		getPinchDistance,
		zoomImage,
	};
};

const ImagesView = () => {
	const navigate = useNavigate();
	const { images } = useImagesStore();
	const startY = useRef<number | null>(null);
	const imgRef = useRef<HTMLImageElement>(null);
	const swiperRef = useRef<SwiperCore | null>(null);
	const [initialIndex, setInitialIndex] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isClickable, setIsClickable] = useState(false);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);

	const {
		scale,
		setScale,
		initialPinchDistance,
		setInitialPinchDistance,
		translate,
		setTranslate,
		setDragStart,
		getPinchDistance,
		zoomImage,
	} = useImageZoom(1);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsClickable(true);
		}, 100);
		return () => clearTimeout(timer);
	}, []);

	const handleImageClick = (src: string, index: number) => {
		if (isClickable) {
			setSelectedImage(src);
			setInitialIndex(index);
			setCurrentIndex(index);
		}
	};

	const handleWheel = (e: WheelEvent) => {
		if (selectedImage && scale > 1) {
			e.preventDefault();
			const { clientX, clientY } = e;
			zoomImage(imgRef.current, clientX, clientY, e.deltaY);
		}
	};

	const handleMouseMove = (e: MouseEvent) => {
		if (imgRef.current && scale > 1) {
			const { movementX } = e;
			setTranslate((prev) => ({
				x: prev.x + movementX,
				y: 0,
			}));
			imgRef.current.style.transform = `scale(${scale}) translate(${translate.x}px, 0)`;
		}
	};

	const handleTouchStart = (e: TouchEvent) => {
		startY.current = e.touches[0].clientY;
		if (e.touches.length === 2) {
			const distance = getPinchDistance(e.touches);
			setInitialPinchDistance(distance);
			if (swiperRef.current) {
				swiperRef.current.allowTouchMove = false;
			}
		}
	};

	const handleTouchMove = (e: TouchEvent) => {
		if (e.touches.length === 2 && initialPinchDistance !== null) {
			const distance = getPinchDistance(e.touches);
			const newScale = (distance / initialPinchDistance) * scale;
			setScale(Math.min(Math.max(1, newScale), 3));

			if (imgRef.current) {
				imgRef.current.style.transform = `scale(${scale})`;
			}
		}
	};

	const handleTouchEnd = (e: TouchEvent) => {
		const endY = e.changedTouches[0].clientY;
		const distanceY = Math.abs(endY - (startY.current || 0));

		if (distanceY > 200) {
			navigate(-1);
		}

		if (swiperRef.current) {
			swiperRef.current.allowTouchMove = true;
		}
		setDragStart(null);
	};

	const assignSwiper = (swiper: SwiperCore) => {
		swiperRef.current = swiper;
	};

	return (
		<div
			className={`fixed inset-0 z-50 overflow-y-auto ${
				window.bridge ? "pt-bridge-inset-top" : "pt-2"
			} ${selectedImage ? "bg-black" : "bg-[#F6F6F7] px-4 pt-2 pb-6"}`}
		>
			<div className="fixed flex justify-between items-center w-full z-10">
				<button
					type="button"
					className={`z-50 flex items-center justify-start h-[60px] w-[60px] ${selectedImage ? "px-4" : ""}`}
					onClick={() => {
						setSelectedImage(null);
						navigate(-1);
						setScale(1);
						setTranslate({ x: 0, y: 0 });
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							e.preventDefault();
							setSelectedImage(null);
							navigate(-1);
							setScale(1);
							setTranslate({ x: 0, y: 0 });
						}
					}}
				>
					<img src={selectedImage ? XbuttonWhite : Xbutton} alt="Close" />
				</button>
				{selectedImage && (
					<div className="text-white px-4 font-semibold">
						{currentIndex + 1}/{images.length}
					</div>
				)}
			</div>
			{selectedImage ? (
				<div
					className="flex items-center justify-center h-full w-full"
					onWheel={handleWheel}
				>
					<Swiper
						initialSlide={initialIndex}
						slidesPerView={1}
						loop={true}
						onSlideChange={(swiper) => {
							setSelectedImage(images[swiper.realIndex]);
							setCurrentIndex(swiper.realIndex);
							setScale(1);
							setTranslate({ x: 0, y: 0 });
						}}
						onSwiper={assignSwiper}
						className="flex items-center h-full justify-center w-full"
						style={{ overflow: "hidden" }}
					>
						{images.map((src) => (
							<SwiperSlide
								key={src}
								className="flex items-center justify-center h-full w-full"
							>
								<div
									className="relative overflow-hidden h-full w-full flex items-center justify-center"
									onMouseMove={handleMouseMove}
									onTouchStart={handleTouchStart}
									onTouchMove={handleTouchMove}
									onTouchEnd={handleTouchEnd}
								>
									<img
										ref={imgRef}
										src={src}
										alt="Property"
										className="max-w-full w-full max-h-full object-contain transition-transform duration-300 ease-in-out cursor-grab"
										style={{
											transform: `scale(${scale}) translate(${translate.x}px, 0)`,
										}}
										onClick={(e) => e.stopPropagation()}
										onKeyDown={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												e.preventDefault();
												handleImageClick(src, images.indexOf(src));
											}
										}}
									/>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			) : (
				<div className="space-y-4 pt-12">
					{images.map((src) => (
						<img
							key={src}
							src={src}
							alt="Property"
							className="w-full h-[253px] object-cover rounded-[20px] cursor-pointer"
							onClick={() => handleImageClick(src, images.indexOf(src))}
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									e.preventDefault();
									handleImageClick(src, images.indexOf(src));
								}
							}}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export { ImagesView };
