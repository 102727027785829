import { useEffect, useState } from "react";
import { useAuth } from "../context/authProvider.tsx";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Bridge } from "../bridge.mts";

const AuthLayout = ({ type }: { type: "public" | "private" }) => {
	const { authenticated } = useAuth();
	const location = useLocation();
	const [isApp, setIsApp] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		console.log("Current route:", location.pathname);
	}, [location]);

	useEffect(() => {
		const fetchBridgeVersion = async () => {
			try {
				const version = await Bridge.getBridgeVersion();
				console.log("Bridge version:", version);
				if (version) {
					setIsApp(true);
				}
			} catch (error) {
				console.error("Failed to get bridge version", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchBridgeVersion();
	}, []);

	if (type === "public") {
		return authenticated ? <Navigate to="/" /> : <Outlet />;
	}

	if (type === "private" && !isLoading) {
		if (window?.webkit && isApp) {
			return authenticated ? <Outlet /> : <Navigate to="/login" />;
		}

		// Browser
		return authenticated ? <Outlet /> : <Navigate to="/login/intro" />;
	}

	return null;
};

export { AuthLayout };
