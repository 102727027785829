import {
	type ChangeEvent,
	type KeyboardEvent,
	type RefObject,
	useEffect,
	useRef,
	useState,
} from "react";
import { Range } from "react-range";
import GrayArrowDownIcon from "../../assets/grayArrowDownIcon.svg";
import { formatPrice } from "../../utils/formatPrice";
import { MortgageCalculatoreBox } from "./mortgageCalculatoreBox";

const amortizationOptions = [
	"5 years",
	"10 years",
	"15 years",
	"20 years",
	"25 years",
	"30 years",
];

interface MortgageCalculatoreProps {
	listingPrice: string;
	mortgageRate: string;
	minDownPaymentNeeded: number;
}

const MortgageCalculatore = ({
	listingPrice,
	mortgageRate,
	minDownPaymentNeeded,
}: MortgageCalculatoreProps) => {
	const priceInputRef = useRef<HTMLInputElement>(null);
	const downPaymentInputRef = useRef<HTMLInputElement>(null);
	const rateInputRef = useRef<HTMLInputElement>(null);
	const [price, setPrice] = useState(
		formatPrice(Number.parseInt(listingPrice, 10)),
	);
	const [downPayment, setDownPayment] = useState(
		formatPrice(minDownPaymentNeeded),
	);
	const [rateValue, setRateValue] = useState(`${mortgageRate}%`);
	const [sliderValue, setSliderValue] = useState([0]);
	const [percentageValue, setPercentageValue] = useState("0%");
	const [amortizationPeriod, setAmortizationPeriod] = useState("25 years");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleFocusInput = (inputRef: RefObject<HTMLInputElement>) => {
		inputRef.current?.focus();
	};

	const dropdownButtonRef = useRef<HTMLButtonElement>(null);
	const dropdownRef = useRef<HTMLUListElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownButtonRef.current &&
				!dropdownButtonRef.current.contains(event.target as Node) &&
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsDropdownOpen(false);
			}
		};
		if (isDropdownOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isDropdownOpen]);

	useEffect(() => {
		const listingPriceValue =
			Number.parseInt(price.replace(/[^0-9]/g, ""), 10) || 0;
		const downPaymentValue =
			Number.parseInt(downPayment.replace(/[^0-9]/g, ""), 10) || 0;

		if (listingPriceValue > 0) {
			const percentage = Math.round(
				(downPaymentValue / listingPriceValue) * 100,
			);
			setPercentageValue(`${percentage}%`);
			setSliderValue([percentage]);
		} else {
			setPercentageValue("0%");
			setSliderValue([0]);
		}
	}, [price, downPayment]);

	const handleKeyDown = (
		e: KeyboardEvent<HTMLDivElement>,
		inputRef: RefObject<HTMLInputElement>,
	) => {
		if (e.key === "Enter" || e.key === " ") {
			handleFocusInput(inputRef);
		}
	};

	const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
		const numericValue = Number.parseInt(
			e.target.value.replace(/[^0-9]/g, "").replace(/^0+(?=\d)/, ""),
			10,
		);

		if (numericValue > 0) {
			const formattedPrice = formatPrice(numericValue);
			setPrice(formattedPrice);

			const updatedDownPayment = Math.round(
				(numericValue * sliderValue[0]) / 100,
			);
			setDownPayment(formatPrice(updatedDownPayment));
		} else {
			setPrice(formatPrice(0));
			setDownPayment("0");
			setPercentageValue("0%");
			setSliderValue([0]);
		}
	};

	const handleDownPaymentChange = (e: ChangeEvent<HTMLInputElement>) => {
		const numericValue = Number.parseInt(
			e.target.value.replace(/[^0-9]/g, "").replace(/^0+(?=\d)/, ""),
			10,
		);

		const listingPriceValue =
			Number.parseInt(price.replace(/[^0-9]/g, ""), 10) || 0;

		if (numericValue > 0 && listingPriceValue > 0) {
			const formattedDownPayment = formatPrice(numericValue);
			setDownPayment(formattedDownPayment);

			const percentage = Math.round((numericValue / listingPriceValue) * 100);
			setPercentageValue(`${percentage}%`);
			setSliderValue([percentage]);
		} else {
			setDownPayment("0");
			setPercentageValue("0%");
			setSliderValue([0]);
		}
	};

	const handleRateChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^0-9.]/g, "");
		if (value === "") {
			setRateValue("0%");
			return;
		}
		const parsedValue = Number.parseFloat(value);
		if (!Number.isNaN(parsedValue) && parsedValue <= 10) {
			setRateValue(`${value}%`);
		} else if (parsedValue > 10) {
			setRateValue("10%");
		}
	};

	const handleRateKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Backspace") {
			const valueWithoutPercent = rateValue.replace("%", "");
			const newValue = valueWithoutPercent.slice(0, -1);

			if (newValue === "") {
				setRateValue("0%");
			} else {
				setRateValue(`${newValue}%`);
			}
			e.preventDefault();
		}
	};

	const handlePercentageChange = (e: ChangeEvent<HTMLInputElement>) => {
		const numericValue = e.target.value.replace(/[^0-9]/g, "");
		let parsedValue = Number.parseInt(numericValue, 10);
		if (Number.isNaN(parsedValue)) {
			parsedValue = 0;
		}
		if (parsedValue > 100) {
			parsedValue = 100;
		}

		setPercentageValue(`${parsedValue}%`);
		setSliderValue([parsedValue]);

		const listingPriceValue =
			Number.parseInt(price.replace(/[^0-9]/g, ""), 10) || 0;
		if (listingPriceValue > 0) {
			const newDownPayment = Math.round(
				(listingPriceValue * parsedValue) / 100,
			);
			setDownPayment(formatPrice(newDownPayment));
		} else {
			setDownPayment("0");
		}
	};

	const handleSliderChange = (values: number[]) => {
		setSliderValue(values);
		setPercentageValue(`${values[0]}%`);

		const listingPriceValue =
			Number.parseInt(price.replace(/[^0-9]/g, ""), 10) || 0;
		if (listingPriceValue > 0) {
			const newDownPayment = Math.round((listingPriceValue * values[0]) / 100);
			setDownPayment(formatPrice(newDownPayment));
		} else {
			setDownPayment("0");
		}
	};

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleDropdownKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
		if (e.key === "Enter" || e.key === " ") {
			toggleDropdown();
		}
	};

	const handleOptionKeyDown = (
		e: KeyboardEvent<HTMLLIElement>,
		option: string,
	) => {
		if (e.key === "Enter" || e.key === " ") {
			selectAmortization(option);
		}
	};

	const selectAmortization = (option: string) => {
		setAmortizationPeriod(option);
		setIsDropdownOpen(false);
	};

	return (
		<div className="">
			<div className="font-semibold text-xl mb-9">Mortgage Calculator</div>
			<div className="grid grid-cols-2 xl:gap-10 gap-5">
				<div className="space-y-4">
					<div
						className="border-[1px] border-[#E1E0E3] relative rounded-[12px] h-[64px] flex flex-col justify-center px-3"
						onClick={() => handleFocusInput(priceInputRef)}
						onKeyDown={(e) => handleKeyDown(e, priceInputRef)}
					>
						<div className="text-xs text-[#5D5E5A] absolute bg-white -top-2 left-2 px-1">
							Price
						</div>
						<div className="flex w-full font-semibold">
							<div>$</div>
							<input
								type="tel"
								className="w-full"
								ref={priceInputRef}
								value={price}
								onChange={handlePriceChange}
							/>
						</div>
					</div>

					<div
						className="border-[1px] border-[#E1E0E3] relative rounded-[12px] h-[64px] flex flex-col justify-center px-3"
						onClick={() => handleFocusInput(rateInputRef)}
						onKeyDown={(e) => handleKeyDown(e, rateInputRef)}
					>
						<div className="text-xs text-[#5D5E5A] absolute bg-white -top-2 left-2 px-1">
							Rate
						</div>
						<div className="flex w-full font-semibold items-center">
							<input
								type="tel"
								className="w-full"
								ref={rateInputRef}
								value={rateValue}
								onChange={handleRateChange}
								onKeyDown={handleRateKeyDown}
							/>
						</div>
					</div>

					<div className="relative">
						<button
							type="button"
							className="border-[1px]  border-[#E1E0E3] relative rounded-[12px] px-4 h-[54px] py-2 w-full text-left flex items-center justify-between"
							onClick={toggleDropdown}
							onKeyDown={handleDropdownKeyDown}
							ref={dropdownButtonRef}
						>
							<div className="flex flex-col">
								<div className="text-xs text-[#5D5E5A] absolute bg-white -top-2 left-2 px-1">
									Amortization
								</div>
								<div className="font-semibold">{amortizationPeriod}</div>
							</div>

							<img
								src={GrayArrowDownIcon}
								alt=""
								className={`${
									isDropdownOpen
										? "transition-transform duration-300 rotate-180"
										: ""
								}`}
							/>
						</button>

						{isDropdownOpen && (
							<ul
								ref={dropdownRef}
								className="absolute w-full border shadow-lg drop-shadow-lg border-[#E1E0E3] rounded-[12px] mt-1 bg-white z-10"
							>
								{amortizationOptions.map((option, i) => (
									<li
										key={option}
										className={`px-4 py-2 hover:bg-gray-200 cursor-pointer ${
											i === 0 ? "rounded-t-[12px]" : ""
										} ${
											i === amortizationOptions.length - 1
												? "rounded-b-[12px]"
												: "border-b border-b-[#E1E0E3]"
										} ${option === amortizationPeriod ? "bg-[#E1E0E3]" : ""}`}
										onClick={() => selectAmortization(option)}
										onKeyDown={(e) => handleOptionKeyDown(e, option)}
									>
										{option}
									</li>
								))}
							</ul>
						)}
					</div>

					<div
						className="border-[1px] relative border-[#E1E0E3] rounded-[12px] h-[64px] flex flex-col justify-center px-3"
						onClick={() => handleFocusInput(downPaymentInputRef)}
						onKeyDown={(e) => handleKeyDown(e, downPaymentInputRef)}
					>
						<div className="text-xs text-[#5D5E5A] absolute bg-white -top-2 left-2 px-1">
							Down Payment
						</div>
						<div className="flex w-full font-semibold">
							<div>$</div>
							<input
								type="tel"
								className="w-full"
								ref={downPaymentInputRef}
								value={downPayment}
								onChange={handleDownPaymentChange}
							/>
						</div>
					</div>

					<div className="space-y-2 flex items-center">
						<div className="flex items-center font-semibold">
							<input
								type="tel"
								value={percentageValue}
								onChange={handlePercentageChange}
								className="w-[100px] mr-4 border-[1px] border-gray-300 rounded-[12px] px-2 py-2"
							/>
						</div>
						<div className="w-full space-y-2">
							<div className="w-full flex items-center justify-between text-xs text-[#676473]">
								<div>0%</div>
								<div>100%</div>
							</div>
							<Range
								step={1}
								min={0}
								max={100}
								values={sliderValue}
								onChange={handleSliderChange}
								renderTrack={({ props, children }) => (
									<div
										{...props}
										className="h-[4px] w-full bg-[#E2E2E2] relative rounded-full"
										style={{
											background: `linear-gradient(to right, #456930 0%, #456930 ${sliderValue[0]}%, #E2E2E2 ${sliderValue[0]}%, #E2E2E2 100%)`,
										}}
									>
										{children}
									</div>
								)}
								renderThumb={({ props }) => (
									<div {...props} className="relative focus:outline-none">
										<div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-z-dark-moss-green text-white text-xs font-bold px-2 py-1 rounded-[8px]">
											{sliderValue[0]}%
											<div className="w-0 h-0 border-l-[6px] border-r-[6px] border-t-[7px] border-transparent border-t-z-dark-moss-green absolute -bottom-[6px] left-1/2 -translate-x-1/2" />
										</div>
										<div className="w-[24px] h-[24px] border-2 border-z-dark-moss-green bg-white rounded-full shadow" />
									</div>
								)}
							/>
						</div>
					</div>
				</div>
				<MortgageCalculatoreBox
					price={price}
					downPayment={downPayment}
					rateValue={rateValue}
					amortizationPeriod={amortizationPeriod}
				/>
			</div>
		</div>
	);
};

export { MortgageCalculatore };
