// Mock data for SearchListingInterface with embedded Map and BuyerSavedListing

import {
	type BuyerMatchingPreferences,
	type BuyerMatchingPreferencesPoi,
	BuyerMatchingPreferencesPoiTypeEnum,
	BuyerMatchingPreferencesPrioritiesEnum,
	BuyerMatchingPreferencesPropertyTypeEnum,
} from "../../interfaces/buyerMatchingPreferences";
import type { SearchListingInterface } from "../../services/exploreListings";

export const mockListings: SearchListingInterface[] = [
	{
		listingId: "12345",
		images: [
			"https://example.com/image1.jpg",
			"https://example.com/image2.jpg",
		],
		displayImageUrl: "https://example.com/displayImage.jpg",
		listPrice: 750000,
		originalPrice: 575000,
		lastStatus: "Active",
		numBathrooms: 2,
		numBedrooms: 3,
		sqft: 1500,
		repliersUpdatedOn: "2024-10-15",
		downPaymentContribution: 50000,
		liked: true,
		address: {
			address1: "123 Main St",
			address2: "Apt 4B",
			city: "Springfield",
			postalCode: "12345",
			state: "IL",
		},
		listDate: "2023-09-20",
		map: {
			type: "Point",
			coordinates: [-89.6501, 39.7817],
		},
		coordinates: { lat: 39.7817, long: -89.6501 },
		displayAddress: "123 Main St, Apt 4B, Springfield, IL 12345",
		propertyType: "Detached",
	},
	{
		listingId: "67890",
		images: [
			"https://example.com/image3.jpg",
			"https://example.com/image4.jpg",
		],
		displayImageUrl: "https://example.com/displayImage2.jpg",
		listPrice: 450000,
		originalPrice: 475000,
		lastStatus: "Sold",
		numBathrooms: 1,
		numBedrooms: 2,
		sqft: 1200,
		repliersUpdatedOn: "2024-10-12",
		liked: false,
		address: {
			address1: "456 Elm St",
			address2: "",
			city: "Shelbyville",
			postalCode: "54321",
			state: "IL",
		},
		listDate: "2023-09-15",
		map: {
			type: "Point",
			coordinates: [-89.6474, 39.792],
		},
		coordinates: { lat: 39.792, long: -89.6474 },
		displayAddress: "456 Elm St, Shelbyville, IL 54321",
		propertyType: "Condo",
	},
	{
		listingId: "67891",
		images: [
			"https://example.com/image3.jpg",
			"https://example.com/image4.jpg",
		],
		displayImageUrl: "https://example.com/displayImage2.jpg",
		listPrice: 450000,
		originalPrice: 475000,
		lastStatus: "Sold",
		numBathrooms: 1,
		numBedrooms: 2,
		sqft: 1200,
		repliersUpdatedOn: "2024-10-19",
		liked: false,
		address: {
			address1: "456 Elm St",
			address2: "",
			city: "Shelbyville",
			postalCode: "54321",
			state: "IL",
		},
		listDate: "2023-09-15",
		map: {
			type: "Point",
			coordinates: [-81.6474, 32.792],
		},
		coordinates: { lat: 32.792, long: -81.6474 },
		displayAddress: "456 Elm St, Shelbyville, IL 54321",
		propertyType: "Condo",
	},
];

export const mockPointsOfInterest: BuyerMatchingPreferencesPoi[] = [
	{
		designation: "Springfield Elementary",
		location: {
			type: "Point",
			coordinates: [-89.65, 39.78],
		},
		type: BuyerMatchingPreferencesPoiTypeEnum.School,
	},
	{
		designation: "Downtown Train Station",
		location: {
			type: "Point",
			coordinates: [-89.64, 39.79],
		},
		type: BuyerMatchingPreferencesPoiTypeEnum.PublicTransit,
	},
	{
		designation: "Springfield Rec Center",
		location: {
			type: "Point",
			coordinates: [-89.65, 39.77],
		},
		type: BuyerMatchingPreferencesPoiTypeEnum.Other,
	},
];

// Mock data for BuyerMatchingPreferences

export const mockBuyerPreferences: BuyerMatchingPreferences = {
	pointsOfInterests: mockPointsOfInterest,
	priorities: [
		BuyerMatchingPreferencesPrioritiesEnum.NearbyPark,
		BuyerMatchingPreferencesPrioritiesEnum.FencedYard,
		BuyerMatchingPreferencesPrioritiesEnum.TwoPlusParking,
	],
	requiredFeatures: ["Finished Basement", "Backyard", "Garage"],
	optionalFeatures: ["Swimming Pool", "Pet Friendly"],
	lifeStyles: ["Urban", "Eco-Friendly"],
	filters: {
		selectedCities: ["Springfield", "Shelbyville"],
		numberOfBedrooms: [2, 3],
		numberOfBathrooms: [1, 2],
		numberOfParkingSpaces: [1],
		dens: true,
		propertyType: [
			BuyerMatchingPreferencesPropertyTypeEnum.Detached,
			BuyerMatchingPreferencesPropertyTypeEnum.Condo,
		],
	},
};
