import { create } from "zustand";

interface ImagesStoreState {
  images: string[];
  setImages: (images: string[]) => void;
}

const useImagesStore = create<ImagesStoreState>((set) => ({
  images: [],
  setImages: (images) => set({ images }),
}));

export { useImagesStore };
