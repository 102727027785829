import { useState } from "react";
import { Button } from "../../common";
import ReactPlayer from "react-player";
import { Modal } from "../../common/modal";
import { openLink } from "../../../services/openLink";
import { useFinanceStore } from "../../../stores/financeStore";
import { useModalStore } from "../../../stores/handleModalStore";
import { ChatIcon, PlayCircleIcon, ReplayIcon } from "../../../assets/icons";

const WatchVideo = () => {
	const { completeStep } = useFinanceStore();
	const { watchVideoModal, setWatchVideoModal } = useModalStore();
	const [showOptions, setShowOptions] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	const handlePlayVideo = () => {
		setIsPlaying(true);
		setShowOptions(false);
	};

	const handleVideoEnd = () => {
		setIsPlaying(false);
		setShowOptions(true);
	};

	const handleContinue = () => {
		completeStep(1);
		setWatchVideoModal(false);
		setShowOptions(false);
	};
	return (
		<>
			{showOptions ? (
				<Modal
					className="w-full"
					isOpen={showOptions}
					bg="transparent"
					setIsOpen={setShowOptions}
				>
					<div className="flex flex-col space-y-5 ">
						<div
							className="bg-white rounded-2xl h-[240px] flex items-center justify-center"
							onClick={handlePlayVideo}
							onKeyUp={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									handlePlayVideo();
								}
							}}
						>
							<img src={ReplayIcon} alt="replay icon" />
						</div>
						<div className="bg-z-dark-moss-green rounded-2xl p-8">
							<div className="text-white text-2xl font-bold">
								Have questions?
							</div>
							<div className="mt-6 space-y-2">
								<span className="text-white">Chat with our experts</span>
								<button
									type="button"
									onClick={() =>
										openLink(
											"https://wa.me/12898166416?text=Hello,%20I%20am%20looking%20for%20assistance%20with%20the%20Zown%20mobile%20app.%20Can%20you%20help%20me%20with%20this",
										)
									}
									className="text-xl font-bold text-z-dark-moss-green h-[53px] bg-white rounded-full w-fit px-5 flex items-center space-x-2"
								>
									<ChatIcon fill="#456930" />
									<span>Chat with us</span>
								</button>
							</div>
							<div className="mt-6 space-y-2">
								<span className="text-white">Video chat with us</span>
								<button
									type="button"
									onClick={() =>
										openLink(
											"https://calendly.com/d/cn9z-652-npw/zown-s-down-payment-consultation",
										)
									}
									className="text-xl font-bold text-z-dark-moss-green h-[53px] bg-white rounded-full w-fit px-5 flex items-center space-x-2"
								>
									<ChatIcon fill="#456930" />
									<span>Book a meeting</span>
								</button>
							</div>
						</div>
						<Button
							customClassName="!bg-white text-xl font-bold text-z-shark mx-auto"
							width="170px"
							height="50px"
							onClick={handleContinue}
						>
							Continue
						</Button>
					</div>
				</Modal>
			) : (
				<Modal
					className="h-[calc(100vh_-_300px)] w-full flex justify-center items-center"
					closeIcon={true}
					setIsOpen={setWatchVideoModal}
					isOpen={watchVideoModal}
				>
					<div className="w-full h-full flex items-center justify-center">
						{isPlaying ? (
							<ReactPlayer
								url="https://s3.amazonaws.com/webflow-prod-assets/65ba768a063fafb3f87c1aa6/65f390faafabb51f31d96593_3%20(1).mp4"
								playing={isPlaying}
								onEnded={handleVideoEnd}
								controls={true}
								width="100%"
								height="100%"
								className="watch-movie-fit"
								style={{ borderRadius: "20px" }}
							/>
						) : (
							<div
								onClick={handlePlayVideo}
								onKeyUp={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										handlePlayVideo();
									}
								}}
							>
								<img src={PlayCircleIcon} alt="play circle icon" />
							</div>
						)}
					</div>
				</Modal>
			)}
		</>
	);
};

export { WatchVideo };
