import clsx from "clsx";
import { Fragment, type RefObject, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { FaChevronRight } from "react-icons/fa";
import { IoChevronBackSharp, IoChevronForward } from "react-icons/io5";
import type { SearchListingInterface } from "../../services/exploreListings";
import { SortOptions } from "../common/sortOptions";
import { ListCard } from "./Card/listCard";

interface ListCardSidebarProps {
	propertiesToDisplay: SearchListingInterface[];
	showSortOptions: boolean;
	optionsRef: RefObject<HTMLDivElement>;
	toggleOptions: () => void;
	selectedSortOption: string;
	setSelectedSortOption: (selectedSortOption: string) => void;
	loading: boolean;
}
const ListCardSidebar = ({
	propertiesToDisplay,
	showSortOptions,
	optionsRef,
	toggleOptions,
	selectedSortOption,
	setSelectedSortOption,
	loading,
}: ListCardSidebarProps) => {
	const [showListCard, setShowListCard] = useState(true);
	return (
		<div
			className={clsx(
				"absolute flex flex-col z-10 text-black transition-colors h-[calc(100%_-_194px)] left-0 bottom-0 pt-4 w-[444px]",
				showListCard ? "bg-white" : "bg-transparent",
			)}
		>
			<div
				className={clsx(
					"absolute z-30 top-4 rounded-[10px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]",
					showListCard ? "left-[460px]" : "left-5",
				)}
				onClick={() => setShowListCard((prev) => !prev)}
				onKeyDown={() => setShowListCard((prev) => !prev)}
			>
				<div className="w-[54px] h-[54px] bg-white rounded-[10px] cursor-pointer flex justify-center items-center">
					<IoChevronForward
						className={clsx(
							"text-black text-4xl transition-transform ease-in-out",
							showListCard ? "rotate-180" : "rotate-0",
						)}
					/>
				</div>
			</div>
			<div
				className={clsx(
					"h-full transition-transform",
					showListCard ? "translate-x-0" : "-translate-x-[500px]",
				)}
			>
				<div className="border-[1px] mb-4 border-[rgba(0,0,0,0.08)] rounded-lg mx-8 px-3 py-4 flex items-center justify-between">
					<div className="text-[rgba(50,48,47,0.70)] text-sm flex items-center">
						Results:
						<div className="text-z-shark flex items-center font-normal pl-1">
							{propertiesToDisplay?.length}
							<div className="ml-1 font-medium">properties</div>
						</div>
					</div>
					<SortOptions
						desktopMode={true}
						showOptions={showSortOptions}
						optionsRef={optionsRef}
						onToggleOptions={toggleOptions}
						selectedOption={selectedSortOption}
						setSelectedOption={setSelectedSortOption}
					/>
				</div>
				<div className="overflow-y-auto pl-5 pr-5 space-y-4 h-[calc(100%-60px)] pb-[30px] mx-3">
					{!loading &&
						propertiesToDisplay.length > 0 &&
						propertiesToDisplay.map((property) => (
							<div key={property.listingId}>
								<ListCard key={property.listingId} property={property} />
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export { ListCardSidebar };
