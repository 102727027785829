import {
	type KeyboardEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { cartComparisonProperty } from "../../../mocks/newMockData/comparisonProperties";
import { usePropertyStore } from "../../../stores/newStores/cartComparisonStore";

export const useCartComparison = () => {
	const {
		properties,
		pinnedProperty,
		loading,
		expandedSections,
		showOptions,
		compareProperties,
		handlePin,
		toggleSection,
		toggleOptions,
		handleAddProperty,
		handleDeletePropertyToCompare,
		removeAllProperties,
		setLoading,
		setProperties,
	} = usePropertyStore();
	const scrollRef = useRef<HTMLDivElement>(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollAmount = 310;

	const fetchProperties = useCallback(async () => {
		try {
			setLoading(true);
			setProperties(cartComparisonProperty);
			setLoading(false);
		} catch (error) {
			console.error("Failed to fetch listing data", error);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchProperties();
	}, [fetchProperties]);

	const handleKeyDown = (event: KeyboardEvent, zlid: string) => {
		if (event.key === "Enter" || event.key === " ") {
			event.preventDefault();
			handlePin(zlid);
		}
	};

	const handleSectionKeyDown = (
		event: KeyboardEvent<HTMLElement>,
		sectionTitle: string,
	) => {
		if (event.key === "Enter" || event.key === " ") {
			toggleSection(sectionTitle);
		}
	};

	const optionsRef = useRef<HTMLDivElement | null>(null);

	const handleRemoveAllProperties = () => {
		removeAllProperties();
	};

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (
				optionsRef.current &&
				!optionsRef.current.contains(event.target as Node)
			) {
				toggleOptions();
			}
		},
		[toggleOptions],
	);

	useEffect(() => {
		if (showOptions) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			removeEventListener("mousedown", handleClickOutside);
		};
	}, [showOptions, handleClickOutside]);

	const scrollLeft = () => {
		if (scrollRef.current) {
			const newPosition = Math.max(0, scrollPosition - scrollAmount);
			scrollRef.current.scrollTo({ left: newPosition, behavior: "smooth" });
			setScrollPosition(newPosition);
		}
	};

	const scrollRight = () => {
		if (scrollRef.current) {
			const newPosition = scrollPosition + scrollAmount;
			scrollRef.current.scrollTo({ left: newPosition, behavior: "smooth" });
			setScrollPosition(newPosition);
		}
	};

	return {
		handlePin,
		toggleSection,
		toggleOptions,
		handleKeyDown,
		handleSectionKeyDown,
		handleAddProperty,
		handleDeletePropertyToCompare,
		handleRemoveAllProperties,
		scrollRight,
		scrollLeft,
		properties,
		pinnedProperty,
		loading,
		showOptions,
		compareProperties,
		scrollRef,
		scrollPosition,
		optionsRef,
		expandedSections,
	};
};
