import { Bar } from "react-chartjs-2";
import { useListingStore } from "../../../stores/exploreListings";
import { testExploreHomesData } from "../../../mocks/searchTestData";
import type { SearchListingInterface } from "../../../services/exploreListings";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

interface BarChartProps {
	listings: SearchListingInterface[];
}

const stylingOptions = {
	responsive: true,
	barThickness: 3,
	borderColor: "black",

	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			ticks: {
				display: false,
			},
			grid: {
				display: false,
			},
			border: {
				color: "transparent",
				width: 0,
			},
		},
		y: {
			min: 0,
			max: 5,
			ticks: {
				display: false,
			},
			grid: {
				display: false,
			},
			border: {
				color: "transparent",
				width: 0,
			},
		},
	},
};

export const preprocessData = (data: { listPrice: { amount: number } }[]) => {
	const maxPrice = 2000000;
	const binSize = 50000;
	const numBins = Math.ceil(maxPrice / binSize);
	const bins = new Array(numBins).fill(0);
	for (const item of data) {
		const price = Number.parseInt(item.listPrice) / 100;
		const binIndex = Math.floor(price / binSize);
		if (binIndex < numBins) {
			bins[binIndex]++;
		}
	}
	const labels = Array.from({ length: numBins }, (_, i) => {
		const start = i * binSize;
		const end = Math.min(start + binSize, maxPrice);
		return `${start.toLocaleString()} - ${end.toLocaleString()}`;
	});

	return { labels, chartData: bins };
};

const BarChart = ({ listings }: BarChartProps) => {
	const { labels, chartData } = listings && preprocessData(listings);

	const data = {
		labels,
		datasets: [
			{
				label: "Prices",
				data: chartData,
				backgroundColor: "black",
			},
		],
	};
	return <Bar data={data} options={stylingOptions} />;
};

export { BarChart };
