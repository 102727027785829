import type { Libraries } from "@react-google-maps/api";

/**
 * Google Maps API key used to authenticate requests.
 *
 * @constant {string}
 */
export const GOOGLE_MAPS_API_KEY = "AIzaSyD1jxU3CIzQBqgNPJYqoaX56TItnH1SsYQ";

/**
 * List of libraries to be loaded with the Google Maps API.
 *
 * @constant {Libraries}
 *
 * @see {@link https://developers.google.com/maps/documentation/javascript/libraries | Google Maps Libraries}
 */
export const LIBRARIES: Libraries = ["places", "marker", "geometry"];

/**
 * Configuration options for loading the Google Maps API.
 *
 * @constant {object}
 * @property {string} googleMapsApiKey - The API key used to authenticate requests to Google Maps API.
 * @property {Libraries} libraries - The Google Maps libraries to load.
 * @property {string} version - The version of the Google Maps API to load, set to "weekly" for up-to-date features.
 * @property {string} language - The language for the Google Maps API interface.
 * @property {string} region - The region bias for Google Maps geocoding (set to "US" here).
 *
 * @see {@link https://developers.google.com/maps/documentation/javascript/overview | Google Maps JavaScript API Overview}
 */
export const loaderOptions = {
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  libraries: LIBRARIES,
  version: "weekly",
  language: "en",
  region: "US",
};
