import { usePropertyStore } from "../../stores/newStores/cartComparisonStore";
import { Button, LoadingCircle } from "../common";
import { CartComparisonTable } from "./cartComparisonTable";
import { useCartComparison } from "./hooks/useCartComparison";

const CartComparison = () => {
	const { properties, loading } = usePropertyStore();

	const { handleRemoveAllProperties } = useCartComparison();

	return (
		<div className="">
			<div className="flex justify-between items-center">
				<div className="text-z-shark text-[40px] font-semibold">
					Compare Properties
				</div>
				<div className="flex items-center space-x-5">
					<Button radius="50px" customClassName="px-6 py-3" width="fit">
						DOWNLOAD
					</Button>
					<Button
						radius="50px"
						customClassName="border-[1px] px-6 py-3"
						isOutlined
						variant="dark"
						width="fit"
						onClick={handleRemoveAllProperties}
					>
						REMOVE ALL
					</Button>
				</div>
			</div>
			<div
				className={`w-full text-black space-y-6 pt-5 pb-6 ${
					loading || properties.length === 0 ? "h-full" : ""
				} bg-white`}
			>
				{loading && (
					<div className="flex items-center justify-center h-full">
						<LoadingCircle />
					</div>
				)}
				{!loading && properties.length === 0 && (
					<div className="flex items-center text-center justify-center flex-col h-full w-full px-4 space-y-8 mt-40">
						<div className="space-y-3">
							<div className="font-bold text-4xl">Oops !</div>
							<div className="text-3xl">
								No added <span className="text-[#FF5757]">properties</span> to
								compare
							</div>
						</div>
						<button
							type="button"
							className="bg-z-dark-moss-green shadow-xl text-white w-[31.25rem] font-bold text-xl py-3.5 rounded-full"
							// onClick={() => navigate("/profile/liked-homes")}
						>
							Add homes
						</button>
					</div>
				)}
				<CartComparisonTable />
			</div>
		</div>
	);
};

export { CartComparison };
