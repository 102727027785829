import {
	type MouseEvent,
	type TouchEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { FaSearch } from "react-icons/fa";
import type { BuyerMatchingPreferencesPropertyTypeEnum } from "../../interfaces/buyerMatchingPreferences";
import {
	type SearchListingInterface,
	fetchExploreListings,
	mapStringToEnum,
	parseBasement,
	parseDaysOnMarket,
	parseGarage,
	parseNumKitchen,
	parseStyle,
	transformFilterValues,
} from "../../services/exploreListings";
import { useSearchedCitiesStore } from "../../stores/exploreListings";
import {
	useFilteredPropertiesStore,
	useListingStore,
} from "../../stores/exploreListings";
import { useMapFiltersStore } from "../../stores/filterMapStore";
import { useFilterPanelStore } from "../../stores/filterStore";
import { useNavbarStore } from "../../stores/navBarStatus";
import { convertToNumberAndMultiplyBy100 } from "../../utils/convertAmount";
import { mapPropertyTypeForPost } from "../common/FilterPanel/filterPanel";
import { LoadingCircle } from "../common/loading";
import { SortOptions } from "../common/sortOptions";
import { ListCard } from "./Card/listCard";
import { NoListingsCard } from "./NoListings/noListingsCard";
import { SearchMapView } from "./SearchMap/searchMap";
import { SearchField } from "./searchField";

import "swiper/css";
import "./exploreListingsViewStyles.css";
import clsx from "clsx";
import { BiChevronRight } from "react-icons/bi";
import { mockListings } from "../../mocks/newMockData/buyerExploreListingsData";
import { useMapStore } from "../../stores/mapCordinations";
import { PropertyCard } from "../BrowseListing/propertyCard";
import { Header } from "../common/BrowseListing/header";
import { ListCardSidebar } from "./listCardSidebar";

const BuyerExploreListingsView = () => {
	const [loading, setLoading] = useState(false);
	const [properties, setProperties] = useState<SearchListingInterface[]>([]);
	const [showSortOptions, setShowSortOptions] = useState(false);
	const [selectedSortOption, setSelectedSortOption] = useState("Default");

	const [mapOpen, setMapOpen] = useState<boolean>(true);
	const { filterPanelVisible } = useFilterPanelStore();
	const { showNavbar, hideNavbar } = useNavbarStore();
	const { mapLatitude, mapLongitude, mapZoom } = useMapStore();
	const { setListings } = useListingStore();
	const optionsRef = useRef<HTMLDivElement | null>(null);
	const { filteredProperties, setFilteredProperties } =
		useFilteredPropertiesStore();
	const {
		minPrice,
		maxPrice,
		beds,
		baths,
		dens,
		parking,
		propertyType,
		numberOfKitchens,
		garageType,
		basement,
		style,
		daysOnMarket,
		mapSelectedCities,
		reloadMapCities,
		setReloadMapCities,
		setSortFilter,
		sortFilter,
	} = useMapFiltersStore();
	const { cities } = useSearchedCitiesStore();

	const propertiesToDisplay: SearchListingInterface[] =
		filteredProperties?.length > 0 ? filteredProperties : properties;

	const toggleMap = () => {
		setMapOpen(!mapOpen);
	};

	const toggleOptions = () => {
		setShowSortOptions(!showSortOptions);
	};

	const [startY, setStartY] = useState<number | null>(null);

	const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
		setStartY(event.touches[0].clientY);
	};
	useEffect(() => {
		if (mapOpen) {
			setSelectedSortOption("Default");
		}
	}, [mapOpen]);
	const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
		if (startY !== null) {
			const currentY = event.touches[0].clientY;
			if (startY - currentY > 50) {
				setMapOpen(false);
				setStartY(null);
			}
		}
	};

	const handleMouseMove = (event: MouseEvent) => {
		if (startY !== null) {
			const currentY = event.clientY;
			if (startY - currentY > 50) {
				setMapOpen(false);
				setStartY(null);
			}
		}
	};

	const handleMouseDown = (event: MouseEvent) => {
		setStartY(event.clientY);
	};

	const fetchProperties = useCallback(async () => {
		setLoading(true);
		const fetchedProperties = mockListings;
		// const fetchedProperties = await fetchExploreListings(
		// 	sortFilter.page,
		// 	sortFilter.limit,
		// 	sortFilter.sortBy,
		// 	sortFilter.sortOrder,
		// 	mapLongitude ?? undefined,
		// 	mapLatitude ?? undefined,
		// 	mapZoom ?? undefined,
		// 	cities && cities.length > 0 ? (cities[0] as "") : "",

		// 	{
		// 		numberOfBedrooms: beds.length > 0 ? transformFilterValues(beds) : null,
		// 		numberOfBathrooms:
		// 			baths.length > 0 ? transformFilterValues(baths) : null,

		// 		numberOfParkingSpaces:
		// 			parking.length > 0 ? transformFilterValues(parking) : null,

		// 		dens: dens,
		// 		priceRange: [
		// 			convertToNumberAndMultiplyBy100(minPrice.toString()),
		// 			convertToNumberAndMultiplyBy100(maxPrice.toString()),
		// 		],
		// 		propertyType:
		// 			propertyType.length > 0
		// 				? propertyType
		// 						.map(mapPropertyTypeForPost)
		// 						.filter(
		// 							(pt): pt is BuyerMatchingPreferencesPropertyTypeEnum =>
		// 								pt !== null,
		// 						)
		// 				: null,

		// 		numKitchens: parseNumKitchen(numberOfKitchens),
		// 		garage: parseGarage(garageType),
		// 		daysOnMarket: parseDaysOnMarket(daysOnMarket),
		// 		style: parseStyle(style),
		// 		basement: parseBasement(basement),
		// 		selectedCities:
		// 			mapSelectedCities.length > 0
		// 				? mapStringToEnum(mapSelectedCities)
		// 				: null,
		// 	},
		// );

		setListings(fetchedProperties);
		setProperties(fetchedProperties);
		setLoading(false);
	}, [
		sortFilter,
		cities,
		setListings,
		beds,
		baths,
		dens,
		parking,
		minPrice,
		maxPrice,
		propertyType,
		numberOfKitchens,
		garageType,
		daysOnMarket,
		style,
		basement,
		mapSelectedCities,
		mapLatitude,
		mapLongitude,
		mapZoom,
	]);

	useEffect(() => {
		const fetchNewProperties = async () => {
			await fetchProperties();
		};

		if (reloadMapCities) {
			setFilteredProperties([]);
			fetchNewProperties();

			setReloadMapCities(false);
		}
	}, [
		reloadMapCities,
		setReloadMapCities,
		setFilteredProperties,
		fetchProperties,
	]);

	useEffect(() => {
		const initializePage = async () => {
			await fetchProperties();
		};

		initializePage();
	}, [fetchProperties]);

	useEffect(() => {
		mapOpen && !filterPanelVisible ? hideNavbar() : showNavbar();
	}, [mapOpen, hideNavbar, showNavbar, filterPanelVisible]);

	useEffect(() => {
		if (showSortOptions && optionsRef.current) {
			const optionsRect = optionsRef.current.getBoundingClientRect();
			const isOffScreen = optionsRect.right > window.innerWidth;

			if (isOffScreen) {
				optionsRef.current.style.left = `-${
					optionsRect.right - window.innerWidth + 20
				}px`;
			}
		}
	}, [showSortOptions]);

	useEffect(() => {
		const defaultPage = "1";
		const defaultLimit = "100";

		switch (true) {
			case selectedSortOption === "Default":
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "desc",
				});
				break;
			case selectedSortOption === "Newest":
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "desc",
				});
				break;
			case selectedSortOption === "Oldest":
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "asc",
				});
				break;
			case selectedSortOption === "Price Low to High":
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "price",
					sortOrder: "asc",
				});
				break;
			case selectedSortOption === "Price High to Low":
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "price",
					sortOrder: "desc",
				});
				break;
			default:
				break;
		}
	}, [selectedSortOption, setFilteredProperties, setSortFilter]);
	return (
		<div
			className={`relative ${mapOpen && "overflow-hidden h-[100vh]"} ${!mapOpen && "pb-24"}`}
		>
			<Header customClassName="absolute w-full top-0 z-20" />
			{mapOpen && (
				<div className="h-[100vh]">
					<SearchMapView />
					<ListCardSidebar
						propertiesToDisplay={propertiesToDisplay}
						showSortOptions={showSortOptions}
						optionsRef={optionsRef}
						toggleOptions={toggleOptions}
						selectedSortOption={selectedSortOption}
						setSelectedSortOption={setSelectedSortOption}
						loading={loading}
					/>
				</div>
			)}

			<div
				className={`green-header ${
					mapOpen ? "-translate-y-[120vh]" : "translate-y-0"
				}`}
			/>

			<div className="w-full h-[92px] absolute inset-x-0 top-[102px] z-20">
				<SearchField />
			</div>

			{/* <div
				onClick={toggleMap}
				onKeyDown={toggleMap}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				className={`show-map-tab ${
					mapOpen
						? "translate-y-[58vh] w-full h-[11vh] py-0 overflow-hidden"
						: "-translate-y-[3vh] show-map-tab-height"
				}`}
			>
				{mapOpen && (
					<hr className="w-1/4 absolute top-3 border-t-4 border-gray-300" />
				)}
				<div className="flex items-center">
					{!mapOpen && <FaSearch className="text-black mr-2" />}
					<span className="text-black font-[550]">
						{mapOpen
							? `${filteredProperties.length} Homes In Search`
							: "Show Map"}
					</span>
				</div>
			</div> */}

			{!mapOpen && (
				<div className="listings-container translate-y-0">
					<div
						className={`w-full flex flex-col gap-4 relative ${
							filterPanelVisible ? "overflow-hidden" : "overflow-scroll"
						}`}
					>
						<div className="flex items-center justify-between p-4 shadow-sm">
							<span className="text-black font-semibold">
								{propertiesToDisplay?.length} Listings
							</span>
							<SortOptions
								showOptions={showSortOptions}
								optionsRef={optionsRef}
								onToggleOptions={toggleOptions}
								selectedOption={selectedSortOption}
								setSelectedOption={setSelectedSortOption}
							/>
						</div>
						{!loading &&
							propertiesToDisplay.length > 0 &&
							propertiesToDisplay.map((property) => (
								<div key={property.listingId} className="px-2">
									<ListCard key={property.listingId} property={property} />
								</div>
							))}

						{!loading && propertiesToDisplay.length === 0 && (
							<>
								<div>No Listings Card</div>
								<NoListingsCard />
							</>
						)}
						{loading && (
							<div className="flex items-center justify-center h-full w-full">
								<LoadingCircle />
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export { BuyerExploreListingsView };
