import { useEffect, useState } from "react";
import type { KeyboardEvent } from "react";
import { ZownForecast } from "./zownForecast";
import { PropertyCard } from "./propertyCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { ComparableSales } from "./comparableSales";
import type { Listing } from "../../../interfaces/listing";
import { MortgageCalculatore } from "./mortgageCalculator";
import GrayArrowDownIcon from "../../../assets/grayArrowDownIcon.svg";
import { convertToFormattedAmount } from "../../../utils/convertAmount";
import type { StatisticsResponse } from "../../../interfaces/statistics";
import PropertyDetailsIcon from "../../../assets/Listing/propertyDetailsIcon.svg";

import "swiper/css";
import { comparableListings } from "../../../services/comparableListings";
import type { ComparablesData } from "../../../interfaces/comparablesInterface";

interface AnalysisProps {
	listing: Listing;
	statistics?: StatisticsResponse | null;
}

const Analysis = ({ listing, statistics }: AnalysisProps) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [comparableData, setComparableData] = useState<ComparablesData | null>(
		null,
	);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchListingData = async () => {
			try {
				setLoading(true);
				const response = await comparableListings(listing.listingId);

				setComparableData(response);

				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch listing data", error);
				setComparableData(null);
			}
		};

		fetchListingData();
	}, [listing.listingId]);

	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	return (
		<div className="w-full bg-white flex justify-between rounded-[20px] py-4 items-start shadow-md drop-shadow-lg">
			<div className="w-full">
				<div
					className="text-black font-semibold text-xl flex items-center justify-between w-full px-4 cursor-pointer"
					onClick={toggleSection}
					onKeyDown={handleKeyDown}
				>
					<div className="flex items-center gap-2">
						<img src={PropertyDetailsIcon} alt="" />
						Analysis
					</div>

					<img
						src={GrayArrowDownIcon}
						alt="Toggle Section"
						className={`transition-transform duration-300 ${
							isExpanded ? "rotate-180" : ""
						}`}
					/>
				</div>

				<div
					id="analysis-content"
					className={`overflow-hidden transition-[max-height] duration-300 ease-in-out space-y-6 ${
						isExpanded ? "max-h-auto" : "max-h-0"
					}`}
				>
					<hr className="h-px bg-[#F3F3F3] border-0 mt-6" />

					<MortgageCalculatore
						listingPrice={convertToFormattedAmount(
							listing.listPrice,
						).toString()}
						mortgageRate={listing.mortgageRate}
						minDownPaymentNeeded={convertToFormattedAmount(
							listing.minDownPaymentNeeded,
						)}
					/>

					{statistics?.zownForecast
						?.history_price_estimations_and_future_forecasts && (
						<>
							<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
							<ZownForecast
								listing={listing}
								zownForecast={statistics.zownForecast}
							/>
						</>
					)}
					<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />

					<ComparableSales
						numberComparableSales={comparableData?.statistics.count}
						medianSalePrice={convertToFormattedAmount(
							comparableData?.statistics.statistics.soldPrice.med,
						)}
						avgDom={comparableData?.statistics.statistics.daysOnMarket.avg}
						mostRecentSale={
							comparableData?.statistics.statistics.daysOnMarket
								.mostRecentSaleInDays
						}
					/>
					<div className="w-full flex justify-center items-center">
						{comparableData?.comparables && (
							<Swiper
								slidesPerView={1.2}
								spaceBetween={15}
								centeredSlides={true}
								loop={false}
								grabCursor={true}
								className="w-full h-full"
							>
								{comparableData?.comparables.map((property) => (
									<SwiperSlide key={property.listingId} className="pb-7">
										<PropertyCard
											propertyType={property.propertyType}
											status={"SOLD"}
											price={convertToFormattedAmount(property.soldPrice)}
											date={property.soldDate}
											beds={property.bed}
											baths={property.bath}
											similarListings={false}
											imageUrl={property.imageUrl}
											address={property.address}
											sqft={property.sqft}
											listingId={property.listingId}
											percentOverAsking={property.percentOverAsking}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						)}
					</div>

					{comparableData?.similar && (
						<>
							<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
							<div className="space-y-2 w-full">
								<div className="text-black text-lg font-semibold px-4">
									Similar Listings
								</div>
								<Swiper
									slidesPerView={1.2}
									spaceBetween={15}
									centeredSlides={true}
									loop={false}
									grabCursor={true}
									className=" h-full"
								>
									{comparableData?.similar.map((property) => (
										<SwiperSlide key={property.listingId} className="pb-7">
											<PropertyCard
												propertyType={property.propertyType}
												status={"FOR SALE"}
												price={convertToFormattedAmount(property.listPrice)}
												downPayment={convertToFormattedAmount(
													property.downPaymentContribution,
												)}
												date={property.listDate}
												beds={property.bed}
												baths={property.bath}
												similarListings={true}
												imageUrl={property.imageUrl}
												address={property.address}
												sqft={property.sqft}
												listingId={property.listingId}
											/>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export { Analysis };
