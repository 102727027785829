import { Bridge } from "../../bridge.mts";
import { useNavigate } from "react-router-dom";
import TimeIcon from "../../assets/timeIcon.svg";
import HomeIcon from "../../assets/homeIcon.svg";
import { PropertiesCard } from "./propertiesCard";
import { LoadingCircle } from "../common/loading";
import { reactions } from "../../services/reactions";
import { setupHammer } from "../../utils/setupHammer";
import PassIcon from "../../assets/Matching/passIcon.svg";
import { fetchAccountData } from "../../services/account";
import LikeIcon from "../../assets/Matching/likeIcon.svg";
// import { OverLayCard } from "./TutorialCards/overlayCard";
import { useStep } from "../../stores/buyerHomeFeaturesStore";
import { useUserProfileStore } from "../../stores/profileStore";
import BubbleImage from "../../assets/mainViwBubblesImage.webp";
// import { SwipeLeftCard } from "./TutorialCards/swipeLeftCard";
// import { SwipeRightCard } from "./TutorialCards/swipeRightCard";
import { useRestTopPicksStore } from "../../stores/resetTopPicksStore";
import { useButtonListeners } from "../../utils/swiperButtonListeners";
// import { GetStartedSwipeCard } from "./TutorialCards/getStartedSwipeCard";
import NoPropertiesLeftIcon from "../../assets/Matching/noPropertiesIcon.svg";
import type { MainMatchingProperties } from "../../interfaces/cartProperties";
import { useShowTopMatchesModalStore } from "../../stores/showTopMatchesModalStore";
import {
	useEffect,
	useRef,
	type MutableRefObject,
	useState,
	useCallback,
} from "react";

const useHammer = (
	cardsRef: MutableRefObject<NodeListOf<HTMLDivElement> | null>,
	initCards: () => void,
	onSwipeLeft: (card: HTMLDivElement) => void,
	onSwipeRight: (card: HTMLDivElement) => void,
) => {
	useEffect(() => {
		if (cardsRef.current) {
			for (const el of cardsRef.current) {
				setupHammer(el, initCards, onSwipeLeft, onSwipeRight);
			}
		}
	}, [cardsRef, initCards, onSwipeLeft, onSwipeRight]);
};

const BuyerMainView = () => {
	const { setCurrentStep } = useStep();
	const swiperContainerRef = useRef<HTMLDivElement>(null);
	const cardsRef = useRef<NodeListOf<HTMLDivElement> | null>(null);
	const nopeButtonRef = useRef<HTMLButtonElement>(null);
	const loveButtonRef = useRef<HTMLButtonElement>(null);
	const [loading, setLoading] = useState(false);
	const [properties, setProperties] = useState<MainMatchingProperties[]>([]);
	const [skipDemo, setSkipDemo] = useState(false);
	const [showDemo, setShowDemo] = useState(false);
	const [demoCards, setDemoCardsCount] = useState(4);
	const [noMoreProperties, setNoMoreProperties] = useState(false);
	const [preview, setPreview] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);
	const { userProfile, setUserProfile } = useUserProfileStore();
	const navigate = useNavigate();
	const { setShowTopMatchesModal } = useShowTopMatchesModalStore();

	const checkNotificationStatus = useCallback(async () => {
		try {
			const status = await Bridge.getPushNotificationAuthorizationStatus();
			const notificationSkipTimestamp = localStorage.getItem(
				"notificationSkipTimestamp",
			);

			if (
				status !== "AUTHORIZED" &&
				!notificationSkipTimestamp &&
				status !== "DENIED"
			) {
				navigate("/profile/enable-notifications");
			}
		} catch (error) {
			console.error(
				"Error checking push notification authorization status",
				error,
			);
		}
	}, [navigate]);

	// useEffect(() => {
	// 	const getUserProfile = async () => {
	// 		try {
	// 			const accountData = await fetchAccountData();
	// 			const newProperties = accountData.matchedListings || [];
	// 			setProperties((prevProperties) => [
	// 				...prevProperties,
	// 				...newProperties,
	// 			]);
	// 		} catch (error) {
	// 			console.error("Failed to check authentication", error);
	// 			setLoading(false);
	// 		}
	// 	};

	// 	getUserProfile();
	// }, []);

	useEffect(() => {
		if (
			!userProfile?.matchingPreferences?.filters?.selectedCities ||
			userProfile?.matchingPreferences?.filters?.selectedCities.length === 0
		) {
			setPreview(true);
		}
	}, [userProfile]);

	useEffect(() => {
		const shouldShowTopMatchesModal =
			(userProfile?.matchingPreferences?.filters?.selectedCities?.length ?? 0) >
				0 &&
			!localStorage.getItem("hasSeenTopMatchesModal") &&
			(properties.length ?? 0) > 0;

		if (shouldShowTopMatchesModal) {
			setShowTopMatchesModal(true);
			localStorage.setItem("hasSeenTopMatchesModal", "true");
		}
	}, [userProfile, setShowTopMatchesModal, properties]);

	useEffect(() => {
		const shouldShowDemo = localStorage.getItem("hasSeenDemo") !== "true";
		setShowDemo(shouldShowDemo);
	}, []);
	// useEffect(() => {
	// 	if (
	// 		userProfile?.matchingPreferences?.filters?.selectedCities &&
	// 		userProfile.matchingPreferences.filters?.selectedCities.length !== 0 &&
	// 		(userProfile?.matchedListings?.length === 0 ||
	// 			!userProfile.matchedListings)
	// 	) {
	// 		setWaiting(true);
	// 	}
	// }, [userProfile]);

	// const countdown = useRestTopPicksStore((state) => state.countdown);
	// const setEndDate = useRestTopPicksStore((state) => state.setEndDate);

	// useEffect(() => {
	// 	if (userProfile?.lastListingRefresh) {
	// 		const lastListingDate = new Date(userProfile.lastListingRefresh);
	// 		lastListingDate.setDate(lastListingDate.getDate() + 2);
	// 		setEndDate(lastListingDate);
	// 	}
	// }, [setEndDate, userProfile]);
	useEffect(() => {
		const fetchProperties = async () => {
			try {
				const response = await fetchAccountData();
				const newProperties = response.matchedListings || [];

				if (newProperties.length > 0) {
					setProperties((prevProperties) => [
						...prevProperties,
						...newProperties,
					]);
				}
				return newProperties.length;
			} catch (error) {
				console.error("Failed to fetch account data:", error);
				return 0;
			}
		};

		const fetchIfNeeded = async () => {
			if (
				!preview &&
				(properties.length === 0 || properties.length <= currentIndex)
			) {
				await new Promise((resolve) => setTimeout(resolve, 500));
				const response = await fetchProperties();

				if (userProfile?.lastListingRefresh && response === 0) {
					setNoMoreProperties(true);
				}
			}
		};

		fetchIfNeeded();
	}, [preview, properties.length, currentIndex, userProfile]);

	const initCards = useCallback(() => {
		const newCards = document.querySelectorAll(".swiper--card:not(.removed)");
		for (const [index, card] of Array.from(newCards).entries()) {
			const cardElement = card as HTMLDivElement;
			cardElement.style.zIndex = `${newCards.length - index}`;
		}

		if (swiperContainerRef.current) {
			swiperContainerRef.current.classList.add("loaded");
		}
	}, []);

	const handleSwipeLeft = useCallback(
		async (card: HTMLDivElement) => {
			const zlid = card.getAttribute("data-zlid");
			if (!card.classList.contains("swiped")) {
				setCurrentIndex((prevIndex) => prevIndex + 1);
				card.classList.add("swiped");
				if (zlid) {
					try {
						await reactions(zlid, "SWIPE_LEFT_TO_DISLIKE_LISTING");
					} catch (error) {
						console.error("Failed to record left swipe reaction", error);
					}
				}

				if (showDemo) {
					setDemoCardsCount((prevCount) => {
						if (prevCount === 1) {
							localStorage.setItem("hasSeenDemo", "true");
						}
						return prevCount - 1;
					});
				}
			}
		},
		[showDemo],
	);

	const handleSwipeRight = useCallback(
		async (card: HTMLDivElement) => {
			const zlid = card.getAttribute("data-zlid");
			if (!card.classList.contains("swiped")) {
				card.classList.add("swiped");

				setCurrentIndex((prevIndex) => prevIndex + 1);
				if (zlid) {
					try {
						await reactions(zlid, "SWIPE_RIGHT_TO_LIKE_LISTING");
					} catch (error) {
						console.error("Failed to record right swipe reaction", error);
					}
				}
				if (showDemo) {
					setDemoCardsCount((prevCount) => {
						if (prevCount === 1) {
							localStorage.setItem("hasSeenDemo", "true");
						}
						return prevCount - 1;
					});
				}
			}
		},
		[showDemo],
	);

	useHammer(cardsRef, initCards, handleSwipeLeft, handleSwipeRight);
	useButtonListeners(
		nopeButtonRef,
		loveButtonRef,
		initCards,
		handleSwipeLeft,
		handleSwipeRight,
	);

	useEffect(() => {
		if (properties.length > 0) {
			cardsRef.current = document.querySelectorAll(".swiper--card");
			initCards();
			for (const el of cardsRef.current) {
				setupHammer(el, initCards, handleSwipeLeft, handleSwipeRight);
			}
		}
	}, [initCards, handleSwipeLeft, handleSwipeRight, properties]);

	useEffect(() => {
		if (currentIndex === 10) {
			checkNotificationStatus();
		}
	}, [currentIndex, checkNotificationStatus]);

	// const handleSkipDemo = () => {
	//   setSkipDemo(true);
	//   setDemoCardsCount(0);
	//   setShowDemo(false);
	//   localStorage.setItem("hasSeenDemo", "true");
	//   if (properties) {
	//     cardsRef.current = document.querySelectorAll(".swiper--card");
	//     initCards();
	//     for (const el of cardsRef.current) {
	//       setupHammer(el, initCards, handleSwipeLeft, handleSwipeRight);
	//     }
	//   }
	// };

	return (
		<div
			className={`h-full overflow-hidden space-y-2  ${
				preview ? "" : "px-4 pt-4"
			}`}
			style={{
				paddingBottom: `${
					window.bridge
						? (window.bridge?.adjustedContentInset?.bottom ?? 0) + 25
						: 55
				}px`,
			}}
		>
			{preview && (
				<div className="space-y-1">
					<img src={BubbleImage} alt="" className="h-full w-full" />
					<div className="space-y-4 px-4">
						<div className="text-4xl font-bold">
							<div className="text-black">Find Your</div>
							<div className="text-z-dark-moss-green">Top Matches</div>
						</div>
						<div className="text-black">
							Find your perfect match! Answer a few quick questions, and we'll
							curate personalized listings tailored to your buying power.
						</div>
						<button
							type="button"
							onClick={() => {
								navigate("/buyer-input-flow/max-affordability");
								setCurrentStep(5);
							}}
							className="py-3.5 rounded-full text-white bg-z-dark-moss-green font-bold text-[20px] px-4 w-1/2 max-w-[300px]"
						>
							Get started
						</button>
					</div>
				</div>
			)}

			{/* {!(preview || loading) &&
				properties.length > 0 &&
				properties.length > currentIndex && (
					<div className="w-full flex justify-between items-center">
						<div className="w-full text-black flex items-center gap-2 justify-start">
							{learningPreferences && (
								<>
									<div>
										<img src={HomeIcon} alt="" />
									</div>
									<div className="flex flex-col">
										<div className="font-bold text-sm">
											Learning your preferences
										</div>
										<div className="text-xs font-light">
											Every reaction goes a long way
										</div>
									</div>
								</>
							)}
						</div>
						<div className="w-fit flex justify-center">
							<div className="bg-[#E9F3FF] w-fit text-black font-extrabold px-4 py-1 rounded-full">
								{currentIndex + 1}/{properties.length}
							</div>
						</div>
						{!learningPreferences && (
							<div className="w-full flex justify-end" />
						)}
					</div>
				)} */}

			<div
				className="w-full relative h-full flex justify-center items-center"
				ref={swiperContainerRef}
			>
				{!noMoreProperties &&
					!preview &&
					!loading &&
					properties.length <= currentIndex && (
						<div className="h-full w-full flex flex-col text-black justify-center items-center text-center space-y-6">
							<div className="font-bold text-3xl">
								We're finding more perfect matches
							</div>
							<div>Give us a few moments.</div>
							<LoadingCircle />
						</div>
						// <div className="text-black flex flex-col items-center mt-4 text-center h-full justify-center space-y-10 w-full max-w-[85vw] z-20">
						// 	<div className="space-y-1">
						// 		<div className="font-semibold text-3xl">
						// 			That's all...for now
						// 		</div>
						// 		<div className="text-[20px]">Book showings from your cart</div>
						// 	</div>
						// 	<div>
						// 		<img src={NoPropertiesLeftIcon} alt="" />
						// 	</div>
						// 	<div className="w-full space-y-4">
						// 		<div className="text-[20px]">Your top picks will reset in</div>
						// 		<div className="font-bold text-4xl">{countdown}</div>
						// 		<button
						// 			type="button"
						// 			className="font-semibold text-white rounded-full text-lg py-3 shadow-md drop-shadow-lg bg-z-dark-moss-green w-full"
						// 			onClick={() => navigate("/cart")}
						// 		>
						// 			Book showings
						// 		</button>
						// 	</div>
						// </div>
					)}

				{/* {!loading && waiting && (
					<div className="text-black flex flex-col items-center mt-4 text-center h-full justify-center space-y-10 w-full max-w-[85vw] z-20">
						<div className="flex flex-col items-center justify-center space-y-14">
							<div className="space-y-8 text-center w-full flex flex-col items-center">
								<div className="space-y-4">
									<div className="font-semibold text-3xl">
										We're finding your perfect matches
									</div>
									<div>
										Give us a few moments. You can explore the app in the
										meantime.
									</div>
								</div>
								<div className="flex flex-col items-center">
									<div className="flex gap-1 items-center text-sm">
										<img src={TimeIcon} alt="" />
										Est. time
									</div>
									<div className="font-bold text-2xl">30 seconds</div>
								</div>
							</div>
						</div>
					</div>
				)} */}

				{noMoreProperties && (
					<div className="text-black flex flex-col items-center mt-4 text-center h-full justify-center space-y-10 w-full max-w-[85vw] z-20">
						<div className="space-y-1">
							<div className="font-semibold text-3xl">No Matches Found</div>
							<div className="text-[20px]">
								Adjust your city or preferences to discover more options
							</div>
						</div>
						<div>
							<img src={NoPropertiesLeftIcon} alt="" />
						</div>
						<div className="w-full space-y-4">
							<button
								type="button"
								className="font-semibold text-white rounded-full text-lg py-3 shadow-md drop-shadow-lg bg-z-dark-moss-green w-full"
								onClick={() => {
									navigate("/buyer-input-flow/max-affordability");
									setCurrentStep(5);
								}}
							>
								Edit Preferences
							</button>
						</div>
					</div>
				)}

				<div className="absolute top-0 bottom-0 right-0 left-0 z-10 flex items-center justify-center">
					{/* {!hasShowings && showDemo &&
            !skipDemo &&
            !loading &&
            properties.length > 0 &&
            !preview &&!waiting&& (
              <>
                <GetStartedSwipeCard
                  nopeButtonRef={nopeButtonRef}
                  setSkipDemo={handleSkipDemo}
                />
                <SwipeRightCard />
                <SwipeLeftCard />
                <OverLayCard />
              </>
            )} */}

					{!preview &&
						properties.map((property, i) => (
							<PropertiesCard key={i as number} property={property} />
						))}
				</div>
				{loading && <LoadingCircle />}

				<div className="absolute flex bottom-0 translate-y-1/2 justify-center space-x-4 z-30">
					<button
						type="button"
						id="nope"
						ref={nopeButtonRef}
						className={`drop-shadow-lg w-[85px] h-[85px] rounded-full ${
							preview ||
							loading ||
							noMoreProperties ||
							properties.length === 0 ||
							properties.length <= currentIndex
								? "hidden"
								: "block"
						}`}
					>
						<img src={PassIcon} alt="Pass" />
					</button>
					<button
						type="button"
						id="love"
						ref={loveButtonRef}
						className={`drop-shadow-lg w-[85px] h-[85px] rounded-full ${
							preview ||
							loading ||
							noMoreProperties ||
							properties.length === 0 ||
							properties.length <= currentIndex
								? "hidden"
								: "block"
						}`}
					>
						<img src={LikeIcon} alt="Like" />
					</button>
				</div>
			</div>
		</div>
	);
};

export { BuyerMainView };
