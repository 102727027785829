import { useEffect, useState } from "react";
import { Range } from "react-range";
import { convertToFormattedAmount } from "../../utils/convertAmount";
import { formatPrice } from "../../utils/formatPrice";

interface ChanceOfferAcceptedProps {
	listingPrice: string;
	inGta: boolean;
	dom: number;
}

const ChanceOfferAccepted = ({
	listingPrice,
	inGta,
	dom,
}: ChanceOfferAcceptedProps) => {
	const formattedListingPrice = convertToFormattedAmount(listingPrice);
	const parsedListingPrice =
		typeof formattedListingPrice === "number" ? formattedListingPrice : 0;

	const [values, setValues] = useState([parsedListingPrice]);
	const [offerProbability, setOfferProbability] = useState(50);
	const [reachedMax, setReachedMax] = useState(false);

	const minPrice = Math.max(0, parsedListingPrice - 200000);
	const maxPrice = parsedListingPrice + 200000;

	const parsedDom = dom;

	useEffect(() => {
		let initialProbability = 50;

		if (inGta) {
			if (!Number.isNaN(parsedDom)) {
				if (parsedDom >= 0 && parsedDom <= 7) {
					initialProbability = 10;
				} else if (parsedDom > 7 && parsedDom <= 14) {
					initialProbability = 20;
				} else if (parsedDom > 14) {
					initialProbability = 50;
				}
			}
		} else {
			initialProbability = 50;
		}

		setOfferProbability(initialProbability);
	}, [inGta, parsedDom]);

	useEffect(() => {
		const priceDifference = values[0] - parsedListingPrice;

		if (values[0] === maxPrice) {
			setReachedMax(true);
		} else if (values[0] < maxPrice && reachedMax) {
			setReachedMax(false);
			setOfferProbability(50);
		}

		if (!reachedMax) {
			let updatedProbability = offerProbability;
			const increase = Math.floor(Math.max(0, priceDifference) / 10000) * 6;
			const decrease = Math.floor(Math.max(0, -priceDifference) / 10000) * 3;

			updatedProbability = 50 + increase - decrease;
			updatedProbability = Math.max(10, Math.min(updatedProbability, 90));

			setOfferProbability(updatedProbability);
		}
	}, [values, parsedListingPrice, reachedMax, maxPrice, offerProbability]);

	return (
		<div className="space-y-8 relative px-4 pb-10">
			<div className="space-y-7">
				<div>
					<div className="text-sm font-medium lg:text-xl">
						Offer Probability:
					</div>
					<div className="font-bold text-lg lg:text-2xl">
						Chance your offer gets accepted
					</div>
				</div>
				<div className="flex w-full gap-3">
					<div className="w-full flex flex-col items-center text-center rounded-[8px] text-[#0075FF] bg-[#EFF6FF] justify-center p-2 lg:p-5">
						<div className="text-sm lg:text-lg">Property Purchase Price</div>
						<div className="font-bold text-xl lg:text-3xl pt-1">
							${formatPrice(values[0])}
						</div>
					</div>
					<div className="w-full flex flex-col items-center text-center rounded-[8px] text-[#0A9D57] bg-[#EAFFF5] justify-center p-2 lg:p-5">
						<div className="text-sm lg:text-lg">Offer Accept Probability</div>
						<div className="font-bold text-xl lg:text-3xl pt-1">
							{offerProbability}%
						</div>
					</div>
				</div>
			</div>
			<div className="text-xs lg:text-lg text-center font-extralight italic">
				Slide to change purchase price
			</div>
			<div className="relative">
				<Range
					label="Select your value"
					step={10000}
					min={minPrice}
					max={maxPrice}
					values={values}
					onChange={(values) => setValues(values)}
					renderTrack={({ props, children }) => (
						<div
							{...props}
							className="h-[3px] w-full"
							style={{
								background: `linear-gradient(to right, #456930 ${
									((values[0] - minPrice) / (maxPrice - minPrice)) * 100
								}%, #E2E2E2 ${
									((values[0] - minPrice) / (maxPrice - minPrice)) * 100
								}%)`,
							}}
						>
							{children}
						</div>
					)}
					renderThumb={({ props }) => (
						<div
							{...props}
							className="bg-white shadow-lg focus:outline-none rounded-full drop-shadow-lg w-[32px] h-[32px] border-2 border-[#ECECEC]"
						/>
					)}
				/>
			</div>
		</div>
	);
};

export { ChanceOfferAccepted };
