export const authBasePath = "https://api.app.zown.ca";

async function universalFetch<T = unknown>(
	path: string,
	method: string,
	postData?: object,
): Promise<T> {
	const baseUrl = authBasePath;

	async function fetchWithRefresh(
		url: string,
		options: RequestInit,
	): Promise<Response> {
		try {
			const response = await fetch(url, options);

			if (response.status === 403) {
				const refreshResponse = await fetch(`${baseUrl}/auth/refresh`, {
					method: "POST",
					credentials: "include",
				});

				if (refreshResponse.ok) {
					return fetch(url, options);
				}

				throw new Error("Failed to refresh token");
			}

			return response;
		} catch (error) {
			throw new Error(`Fetch with refresh failed: ${(error as Error).message}`);
		}
	}

	const url = `${baseUrl}${path}`;
	const options: RequestInit = {
		method: method.toUpperCase(),
		credentials: "include",
		headers: {},
	};

	if (method.toUpperCase() !== "DELETE") {
		options.headers = {
			...options.headers,
			"Content-Type": "application/json",
		};
	}

	if (postData) {
		options.body = JSON.stringify(postData);
	}

	try {
		const response = await fetchWithRefresh(url, options);
		console.log(`Response from ${url}: ${response.status}`);

		if (!response.ok) {
			switch (response.status) {
				case 400:
					throw new Error("Bad Request");
				case 401:
					throw new Error("Unauthorized");
				case 403:
					throw new Error("Forbidden");
				case 404:
					throw new Error("Not Found");
				case 500:
					throw new Error("Internal Server Error");
				default:
					throw new Error(`HTTP error! status: ${response.status}`);
			}
		}

		const contentType = response.headers.get("content-type");
		if (contentType?.includes("application/json")) {
			try {
				return (await response.json()) as T;
			} catch (error) {
				throw new Error("Failed to parse JSON response");
			}
		} else {
			return response as unknown as T;
		}
	} catch (error) {
		throw new Error(`Universal fetch failed: ${(error as Error).message}`);
	}
}

export { universalFetch };
