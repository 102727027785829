import { StatisticsResponse } from "../../interfaces/statistics";

export const mockStatistics: StatisticsResponse = {
	medianSoldPrice: {
	  page: 1,
	  numPages: 1,
	  pageSize: 10,
	  count: 3,
	  statistics: {
		soldPrice: {
		  med: 330000,
		  mth: {
			'April 2023': { med: 320000, count: 30 },
			'May 2023': { med: 335000, count: 40 },
			'June 2023': { med: 345000, count: 35 },
		  },
		},
	  },
	  listings: [],
	},
	soldActiveListings: {
	  page: 1,
	  numPages: 1,
	  pageSize: 10,
	  count: 3,
	  statistics: {
		available: {
		  mth: {
			'April 2023': 50,
			'May 2023': 60,
			'June 2023': 55,
		  },
		},
		closed: {
		  count: 105,
		  mth: {
			'April 2023': { count: 30 },
			'May 2023': { count: 40 },
			'June 2023': { count: 35 },
		  },
		},
	  },
	  listings: [],
	},
	averageDaysOnMarket: {
	  page: 1,
	  numPages: 1,
	  pageSize: 10,
	  count: 3,
	  statistics: {
		soldPrice: {
		  med: 325000,
		  mth: {
			'April 2023': { med: 320000, count: 30 },
			'May 2023': { med: 330000, count: 40 },
			'June 2023': { med: 335000, count: 35 },
		  },
		},
		daysOnMarket: {
		  avg: 28,
		  mth: {
			'April 2023': { avg: 25, count: 30 },
			'May 2023': { avg: 30, count: 40 },
			'June 2023': { avg: 28, count: 35 },
		  },
		},
	  },
	  listings: [],
	},
	percentageOverAskingPrice: {
	  '2023 Q2': {
		'under -10 %': 5,
		'-10 to -5': 10,
		'-5 to 0': 25,
		'0 to 5': 30,
		'5 to 10': 15,
		'over 10 %': 15,
	  },
	  '2023 Q3': {
		'under -10 %': 4,
		'-10 to -5': 8,
		'-5 to 0': 22,
		'0 to 5': 35,
		'5 to 10': 18,
		'over 10 %': 13,
	  },
	  average_dom: 28,
	  suggested_offer: 0.98,
	},
	listingStatistics: {
	  avgSoldPrice: 325000,
	  avgDaysOnMarket: 28,
	  homesSold: 105,
	},
	zownForecast: {
	  '2023 Q4': 1.05,
	  '2024 Q1': 1.02,
	},
	bestMonthsToSell: {
	  page: 1,
	  numPages: 1,
	  pageSize: 10,
	  count: 3,
	  statistics: {
		soldPrice: {
		  avg: 335000,
		  mth: {
			'April 2023': { avg: 320000, count: 30 },
			'May 2023': { avg: 335000, count: 40 },
			'June 2023': { avg: 345000, count: 35 },
		  },
		},
	  },
	  listings: [],
	},
  };