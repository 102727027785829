interface IconProps {
	fill?: string;
}
const InfoIcon = ({ fill = "#fff" }: IconProps) => {
	return (
		<svg
			width="15"
			height="15"
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="7.5" cy="7.5" r="7" stroke={fill} />
			<path
				d="M6.62953 11V5.54545H8.36603V11H6.62953ZM7.49956 4.9098C7.25571 4.9098 7.04619 4.82931 6.871 4.66832C6.69582 4.50497 6.60822 4.30848 6.60822 4.07884C6.60822 3.85156 6.69582 3.65743 6.871 3.49645C7.04619 3.3331 7.25571 3.25142 7.49956 3.25142C7.74577 3.25142 7.95529 3.3331 8.12811 3.49645C8.3033 3.65743 8.39089 3.85156 8.39089 4.07884C8.39089 4.30848 8.3033 4.50497 8.12811 4.66832C7.95529 4.82931 7.74577 4.9098 7.49956 4.9098Z"
				fill={fill}
			/>
		</svg>
	);
};

export default InfoIcon;
