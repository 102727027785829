import { useState } from "react";
import { Bars3BottomLeft } from "../../assets/icons";
import { usePropertySearchingStore } from "../../stores/propertySearchingStore";
import {
	DragDropContext,
	Draggable,
	Droppable,
	type DropResult,
} from "@hello-pangea/dnd";

const PrioritiesView = () => {
	const { niceHaves, filteredItems } = usePropertySearchingStore();
	const [priorities, setPriorities] = useState<string[]>([...niceHaves]);

	const allItems = filteredItems();

	const mapTypeToDisplayName = (type: string) => {
		const foundItem = allItems.find((item) => item.type === type);
		return foundItem ? foundItem.displayName : type;
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const reorderedPriorities = Array.from(priorities);
		const [removed] = reorderedPriorities.splice(result.source.index, 1);
		reorderedPriorities.splice(result.destination.index, 0, removed);

		setPriorities(reorderedPriorities);
	};

	return (
		<div className="h-full px-4">
			<div className="pb-4">
				<h2 className="text-z-shark text-2xl font-bold mb-3">
					Prioritize what's most important to you
				</h2>
				<span className="text-z-shark text-sm">
					Drag the items to rearrange them
				</span>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="priorities">
						{(provided) => (
							<ul
								{...provided.droppableProps}
								ref={provided.innerRef}
								className="mt-4 space-y-4"
							>
								{priorities.map((priority, index) => (
									<Draggable
										key={priority}
										draggableId={priority}
										index={index}
									>
										{(provided) => (
											<li
												ref={provided.innerRef}
												{...provided.draggableProps}
												className="h-11 rounded bg-white flex items-center"
											>
												<span className="mr-2 text-z-shark">{index + 1}.</span>
												<div className="bg-[#F9F9F9] rounded-xl w-full h-full items-center flex justify-between pl-5">
													<span className="font-semibold text-z-shark">
														{mapTypeToDisplayName(priority)}
													</span>
													<div
														{...provided.dragHandleProps}
														className="w-24 h-full pr-5 flex items-center justify-end"
													>
														<img
															src={Bars3BottomLeft}
															alt="bar bottom left icon"
														/>
													</div>
												</div>
											</li>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</div>
	);
};

export { PrioritiesView };
