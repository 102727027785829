import type { GooglePlace, Listing } from "../../interfaces/listing";

export const mockDemographicResponse:Listing = {
  id: 1,
  listingId: "123456",
  address: "123 Maple Street, Toronto, ON",
  listPrice: "999000",
  bed: "3",
  bath: "2",
  images: ["image1.jpg", "image2.jpg"],
  mlsNumber: "W1234567",
  municipality: "Toronto",
  sqft: "1500",
  propertyType: "Detached",
  listingDate: new Date("2023-10-15"),
  status: "Active",
  listingHistory: [],
  description: "A beautiful 3-bedroom detached home located in the heart of Toronto.",
  listingAgent: "John Doe",
  tax: "4500",
  buildingAge: "30 years",
  size: "1500 sqft",
  lot: "30x120 ft",
  basement: "Finished",
  dataSource: "MLS",
  statusChange: "None",
  dom: 15,
  parking: "2-car garage",
  updatedDate: new Date("2023-10-20"),
  style: "2-Storey",
  community: "Downtown Toronto",
  colling: "Central Air",
  cooling: "Central Air",
  rooms: [
    { label: "Living Room", size: "20x15 ft", details: "Spacious, open concept" },
    { label: "Kitchen", size: "12x10 ft", details: "Modern with island" },
  ],
  heatingType: "Forced Air",
  heatingFuel: "Natural Gas",
  amenities: "Nearby park, schools",
  garageType: "Attached",
  crossStreet: "Maple St & Elm St",
  lat: 43.6532,
  long: -79.3832,
  downPaymentContribution: "20000",
  minDownPaymentNeeded: "100000",
  newDownPaymentNeeded: "80000",
  valuation: "950000",
  averageDom: "30",
  mortgageRate: "5%",
  zownContribution: "1000",
  comparableSales: [],
  similarListings: [],
  googlePlaces: [],
};


export const mockGooglePlaces:GooglePlace[] = [
  {
    business_status: "OPERATIONAL",
    geometry: {
      location: { lat: 43.6535, lng: -79.3829 },
      viewport: {
        northeast: { lat: 43.6545, lng: -79.3819 },
        southwest: { lat: 43.6525, lng: -79.3839 },
      },
    },
    icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/school-71.png",
    icon_background_color: "#FF9E67",
    icon_mask_base_uri: "https://maps.gstatic.com/mapfiles/place_api/icons/v2/school_pinlet",
    name: "Toronto Public School",
    place_id: "ChIJywtkGTF2K4gRbwph2fZxRE4",
    plus_code: {
      compound_code: "942X+WJ Toronto, ON",
      global_code: "87M5942X+WJ",
    },
    rating: 4.2,
    reference: "ChIJywtkGTF2K4gRbwph2fZxRE4",
    scope: "GOOGLE",
    types: ["school", "secondary_school"],
    user_ratings_total: 100,
    vicinity: "456 Oak Street",
    distance: "500m",
    opening_hours: {
      open_now: true,
    },
    photos: [
      {
        height: 400,
        html_attributions: ["<a href='https://maps.google.com'>Photo by User</a>"],
        photo_reference: "CmRaAAAAxzU-yPOlU2XYZ",
        width: 600,
      },
    ],
  },
  {
    business_status: "OPERATIONAL",
    geometry: {
      location: { lat: 43.6545, lng: -79.385 },
      viewport: {
        northeast: { lat: 43.6555, lng: -79.384 },
        southwest: { lat: 43.6535, lng: -79.386 },
      },
    },
    icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/grocery-71.png",
    icon_background_color: "#34A853",
    icon_mask_base_uri: "https://maps.gstatic.com/mapfiles/place_api/icons/v2/grocery_pinlet",
    name: "Green Valley Supermarket",
    place_id: "ChIJywtkGR3F4gRrwph2fZxRE4",
    plus_code: {
      compound_code: "942X+JJ Toronto, ON",
      global_code: "87M5942X+JJ",
    },
    rating: 4.5,
    reference: "ChIJywtkGR3F4gRrwph2fZxRE4",
    scope: "GOOGLE",
    types: ["supermarket", "grocery_or_supermarket"],
    user_ratings_total: 150,
    vicinity: "789 Pine Avenue",
    distance: "300m",
    opening_hours: {
      open_now: false,
    },
    photos: [
      {
        height: 500,
        html_attributions: ["<a href='https://maps.google.com'>Photo by Store</a>"],
        photo_reference: "CmRbBBBBxzZ-pAOiO9XYZ",
        width: 800,
      },
    ],
  },
  {
    business_status: "OPERATIONAL",
    geometry: {
      location: { lat: 43.6528, lng: -79.3848 },
      viewport: {
        northeast: { lat: 43.6538, lng: -79.3838 },
        southwest: { lat: 43.6518, lng: -79.3858 },
      },
    },
    icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
    icon_background_color: "#FF6347",
    icon_mask_base_uri: "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
    name: "Maple Diner",
    place_id: "ChIJwytkGTF5K4gJrwph2fZxRE4",
    plus_code: {
      compound_code: "942X+H4 Toronto, ON",
      global_code: "87M5942X+H4",
    },
    rating: 4.8,
    reference: "ChIJwytkGTF5K4gJrwph2fZxRE4",
    scope: "GOOGLE",
    types: ["restaurant", "cafe"],
    user_ratings_total: 200,
    vicinity: "100 Maple Ave",
    distance: "200m",
    opening_hours: {
      open_now: true,
    },
    photos: [
      {
        height: 600,
        html_attributions: ["<a href='https://maps.google.com'>Photo by Diner</a>"],
        photo_reference: "CmRcCCCCxzT-POmO1XYZ",
        width: 900,
      },
    ],
  },
];

export interface DemographicResponse {
	counts: { countName: string; value: number }[];
	percentages: { percentageName: string; value: number }[];
	pies: { pieName: string; sliceName: string; value: number }[][];
}

export const mockDemographic: DemographicResponse = {
	counts: [
		{ countName: "populationData", value: 1200 },
		{ countName: "avgIncomeData", value: 85000 },
		{ countName: "avgHouseholdSizeData", value: 2.9 },
		{ countName: "avgAgeData", value: 40 },
	],
	percentages: [
		{ percentageName: "Renter", value: 30.5 },
		{ percentageName: "Not married and not living common-law", value: 50.2 },
		{ percentageName: "Not in the labour force", value: 25.4 },
		{ percentageName: "Not in the labour", value: 25.4 },
		{ percentageName: "Households with Children", value: 41.9 },
	],
	pies: [
		// Household Income Pie Data
		[
			{ pieName: "Income", sliceName: "$0 - $29,999 (190%)", value: 10 },
			{ pieName: "Income", sliceName: "$30,000 - $59,999 (215%)", value: 40 },
			{ pieName: "Income", sliceName: "$60,000 - $79,999 (95%)", value: 25 },
			{ pieName: "Income", sliceName: "$80,000 - $99,999 (55%)", value: 30 },
			{ pieName: "Income", sliceName: "$100,000 - $149,999 (70%)", value: 20 },
			{ pieName: "Income", sliceName: "$150,000 - $199,999 (10%)", value: 20 },
		],
		// Property Type Pie Data
		[
			{ pieName: "Property", sliceName: "Detached House", value: 60 },
			{ pieName: "Property", sliceName: "Condo", value: 20 },
			{ pieName: "Property", sliceName: "Townhouse", value: 15 },
			{ pieName: "Property", sliceName: "Apartment", value: 5 },
		],
		// Commute Method Pie Data
		[
			{ pieName: "Commute", sliceName: "Car", value: 70 },
			{ pieName: "Commute", sliceName: "Public Transit", value: 20 },
			{ pieName: "Commute", sliceName: "Bicycle", value: 5 },
			{ pieName: "Commute", sliceName: "Walk", value: 5 },
		],
		// Year Built Pie Data
		[
			{ pieName: "Poc", sliceName: "Before 1980", value: 60 },
			{ pieName: "Poc", sliceName: "1980-2000", value: 30 },
			{ pieName: "Poc", sliceName: "2000-2010", value: 20 },
			{ pieName: "Poc", sliceName: "After 2010", value: 15 },
		],
		[
			{ pieName: "Age", sliceName: "Before 1980", value: 25 },
			{ pieName: "Age", sliceName: "1980-2000", value: 10 },
			{ pieName: "Age", sliceName: "2000-2010", value: 40 },
			{ pieName: "Age", sliceName: "After 2010", value: 15 },
		],
		[
			{ pieName: "Occupation", sliceName: "Before 1980", value: 3 },
			{ pieName: "Occupation", sliceName: "1980-2000", value: 40 },
			{ pieName: "Occupation", sliceName: "2000-2010", value: 20 },
			{ pieName: "Occupation", sliceName: "After 2010", value: 20 },
		],
		[
			{ pieName: "Ethnicity", sliceName: "Before 1980", value: 25 },
			{ pieName: "Ethnicity", sliceName: "1980-2000", value: 40 },
			{ pieName: "Ethnicity", sliceName: "2000-2010", value: 20 },
			{ pieName: "Ethnicity", sliceName: "After 2010", value: 15 },
		],
		[
			{ pieName: "Language", sliceName: "Before 1980", value: 25 },
			{ pieName: "Language", sliceName: "1980-2000", value: 70 },
			{ pieName: "Language", sliceName: "2000-2010", value: 20 },
			{ pieName: "Language", sliceName: "After 2010", value: 75 },
		],
	],
};