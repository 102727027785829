import { Input } from "../common";
import { type ChangeEvent, useEffect, useRef } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { handleKeyPress } from "../../utils/keyboardUtils";
import DollarIcon from "../../assets/icons/dollarIcon.svg";
import { useAdvertisementUserStore } from "../../stores/advertisementFlowStore";
import { useUserProfileStore } from "../../stores/profileStore";
import { convertToFormattedAmount } from "../../utils/convertAmount";

const DebtView = () => {
	const { debt, setDebt, debtError } = useAdvertisementUserStore();
	const inputRef = useRef<HTMLInputElement>(null);
	const { userProfile } = useUserProfileStore();


	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^\d]/g, "");
		setDebt(formatPrice(value || 0));
	};
	useEffect(() => {
		if (
			userProfile?.finances?.monthlyDebt &&
			userProfile.finances.monthlyDebt.length > 0
		) {
			const incomeAmount = userProfile.finances.monthlyDebt[0].amount?.amount;
			if (incomeAmount) {
				setDebt(formatPrice(convertToFormattedAmount(incomeAmount)));
			}
		}
	}, [userProfile, setDebt]);
	return (
		<div
			className="space-y-8 px-4 w-full"
			onKeyDown={(e) => handleKeyPress(e, inputRef)}
		>
			<h3 className="text-3xl font-bold">
				Enter your{" "}
				<span className="text-z-dark-moss-green">total<br/> monthly debt</span>
			</h3>
			<div className="text-z-dark-moss-green font-semibold">
				(credit cards, loans, car payments)
			</div>
			<Input
				inputType="tel"
				ref={inputRef}
				value={debt}
				onChange={handleChange}
                isError={debtError}
				startIcon={DollarIcon}
				radius="20px"
				label="Estimated Monthly Debt"
			/>
		</div>
	);
};

export { DebtView };
