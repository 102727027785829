import clsx from "clsx";
import { usePropertySearchingStore } from "../../stores/propertySearchingStore";

const removeDuplicates = (options: { type: string; displayName: string }[]) => {
	return options.filter(
		(option, index, self) =>
			index === self.findIndex((o) => o.type === option.type),
	);
};

const NeedsVsWantsView = () => {
	const { mustHaves, setMustHaves, niceHaves, setNiceHaves, filteredItems } =
		usePropertySearchingStore();

	const filteredOptions = removeDuplicates(filteredItems());

	const handleMustHave = (value: { type: string }) => {
		const newMustHaves = mustHaves.includes(value.type)
			? mustHaves.filter((type) => type !== value.type)
			: [...mustHaves, value.type];

		setMustHaves(newMustHaves);
		localStorage.setItem("mustHavesSelect", JSON.stringify(newMustHaves));
	};

	const handleNiceHave = (value: { type: string }) => {
		const newNiceHaves = niceHaves.includes(value.type)
			? niceHaves.filter((type) => type !== value.type)
			: [...niceHaves, value.type];

		setNiceHaves(newNiceHaves);
		localStorage.setItem("niceHavesSelect", JSON.stringify(newNiceHaves));
	};

	return (
		<div className="h-full px-4">
			<div className="pb-4">
				<h2 className="text-z-shark text-2xl font-bold mb-2">Needs vs Wants</h2>
				<span className="text-z-shark text-sm">Select as many as you like</span>

				<div className="mt-12">
					<span className="text-xl font-semibold text-z-shark">Must Haves</span>
					<div className="">
						<div className="mb-4 flex flex-col space-y-4">
							<div
								style={{ gap: "12px" }}
								className="flex flex-row flex-wrap items-center overflow-x-auto"
							>
								{filteredOptions.map((option) => {
									const isSelected = mustHaves.includes(option.type);

									return (
										<div
											key={option.type}
											style={{
												width: "fit-content",
												height: "32px",
												minWidth: "fit-content",
											}}
											className={clsx(
												"border flex items-center justify-center rounded-full w-fit border-z-dark-moss-green whitespace-nowrap text-sm py-5 px-4",
												isSelected
													? "bg-z-dark-moss-green !text-white !border-transparent"
													: "bg-white text-z-dark-moss-green",
											)}
											onClick={() => handleMustHave(option)}
											onKeyDown={() => handleMustHave(option)}
										>
											<span className="text-base font-bold">
												{option.displayName}
											</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>

				<div className="mt-12">
					<span className="text-xl font-semibold text-z-shark">
						Nice to Haves
					</span>
					<div className="">
						<div className="mb-4 flex flex-col space-y-4">
							<div
								style={{ gap: "12px" }}
								className="flex flex-row flex-wrap items-center overflow-x-auto"
							>
								{filteredOptions.map((option) => {
									const isSelected = niceHaves.includes(option.type);

									return (
										<div
											key={option.type}
											style={{
												width: "fit-content",
												height: "32px",
												minWidth: "fit-content",
											}}
											className={clsx(
												"border flex items-center justify-center rounded-full w-fit border-z-dark-moss-green whitespace-nowrap text-sm py-5 px-4",
												isSelected
													? "bg-z-dark-moss-green !text-white !border-transparent"
													: "bg-white text-z-dark-moss-green",
											)}
											onClick={() => handleNiceHave(option)}
											onKeyDown={() => handleNiceHave(option)}
										>
											<span className="text-base font-bold">
												{option.displayName}
											</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { NeedsVsWantsView };
