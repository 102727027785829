/**
 * Converts a number to a formatted string without decimals.
 * Divides the number by 100 before formatting.
 * @param amount - The amount to be formatted.
 * @returns A string representing the formatted amount, or `null`/`0` if the input amount is `null`/`0`.
 */
export const convertToFormattedAmount = (
  amount: number | string | undefined
): number => {
  if (
    amount === null ||
    amount === undefined ||
    amount === 0 ||
    amount === "0"
  ) {
    return 0;
  }

  const numericAmount = Number(amount);
  if (Number.isNaN(numericAmount)) {
    throw new Error("Invalid amount");
  }

  return numericAmount / 100;
};

/**
 * Converts a formatted string to a number and multiplies it by 100.
 * @param value - The value to be converted and multiplied.
 * @returns A number that is the input value multiplied by 100.
 */
export const convertToNumberAndMultiplyBy100 = (value: string): number => {
  const numericValue = Number.parseFloat(value.replace(/,/g, ""));
  return Number.isNaN(numericValue) ? 0 : numericValue * 100;
};
