interface IconProps {
	fill: string;
}
const CookingIcon = ({ fill }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="45"
			height="33"
			viewBox="0 0 45 33"
			fill="none"
		>
			<path
				d="M43.1727 8.29807C43.1727 4.21894 39.6582 -0.0488001 39.6582 1.2312C39.6582 2.5112 40.3083 19.5107 40.3083 19.5107L39.6582 30.6273C39.6582 31.3854 40.2728 32 41.0309 32C41.7891 32 42.4037 31.3854 42.4037 30.6273L42.0406 19.4469C42.0406 19.4469 43.1727 12.3772 43.1727 8.29807Z"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M6.09357 1.73828C6.09357 1.73828 6.1701 4.59218 6.1701 7.30181C6.1701 9.33406 5.55286 10.6103 4.67321 11.1214L4.69277 30.6266C4.69277 31.3847 4.0782 31.9993 3.32005 31.9993C2.56189 31.9993 1.94732 31.3847 1.94732 30.6266L2.50382 11.1255C1.62047 10.617 1 9.33935 1 7.30181C1 4.59218 1.07653 1.73828 1.07653 1.73828"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.58203 1.73828V8.31701"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.9604 28.6278C30.5259 28.6278 36.659 22.4947 36.659 14.9291C36.659 7.36357 30.5259 1.23047 22.9604 1.23047C15.3948 1.23047 9.26172 7.36357 9.26172 14.9291C9.26172 22.4947 15.3948 28.6278 22.9604 28.6278Z"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.9679 23.299C27.5734 23.299 31.3069 19.5656 31.3069 14.9601C31.3069 10.3546 27.5734 6.62109 22.9679 6.62109C18.3624 6.62109 14.6289 10.3546 14.6289 14.9601C14.6289 19.5656 18.3624 23.299 22.9679 23.299Z"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CookingIcon;
