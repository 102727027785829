import { NavLink, useLocation } from "react-router-dom";
import { TopPicks } from "../../assets/BottomBar/TopPicks";
import { CartIcon } from "../../assets/BottomBar/CartIcon";
import { useEffect, useState, useRef, useMemo } from "react";
import { SearchIcon } from "../../assets/BottomBar/SearchIcon";
import { SettingsIcon } from "../../assets/BottomBar/SettingsIcon";
// import { FinancialsIcon } from "../../assets/BottomBar/FinancialsIcon";

const NavigationBar = () => {
	const location = useLocation();
	const [sliderStyle, setSliderStyle] = useState({});
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const buttonRefs = useRef<(HTMLAnchorElement | null)[]>([]);

	const [activeTab, setActiveTab] = useState<string | null>("matches");

	useEffect(() => {
		const firstPathSegment = location.pathname.split("/")[1] || "matches";
		switch (firstPathSegment) {
			case "profile":
				setActiveTab("profile");
				break;
			case "cart":
				setActiveTab("cart");
				break;
			case "explore":
				setActiveTab("search");
				break;

			default:
				setActiveTab("matches");
				break;
		}
	}, [location]);

	const buttons = useMemo(
		() => [
			{
				id: "matches",
				label: "Matches",
				icon: (
					<TopPicks fill={activeTab === "matches" ? "#FFFFFF" : "#BCBCBC"} />
				),
				isActive: activeTab === "matches",
				to: "/",
			},
			// {
			//   id: "financials",
			//   label: "Financials",
			//   icon: (
			//     <FinancialsIcon
			//       fill={activeTab === "financials" ? "#FFFFFF" : "#BCBCBC"}
			//     />
			//   ),
			//   isActive: activeTab === "financials",
			//   to: "/finances",
			// },
			{
				id: "search",
				label: "Search",
				icon: (
					<SearchIcon fill={activeTab === "search" ? "#FFFFFF" : "#BCBCBC"} />
				),
				isActive: activeTab === "search",
				to: "/explore",
			},

			{
				id: "cart",
				label: "Cart",
				icon: <CartIcon fill={activeTab === "cart" ? "#FFFFFF" : "#BCBCBC"} />,
				isActive: activeTab === "cart",
				to: "/cart",
			},
			{
				id: "profile",
				label: "Profile",
				icon: (
					<SettingsIcon
						fill={activeTab === "profile" ? "#FFFFFF" : "#BCBCBC"}
					/>
				),
				isActive: activeTab === "profile",
				to: "/profile",
			},
		],
		[activeTab],
	);

	useEffect(() => {
		const index = buttons.findIndex((button) => button.id === activeTab);
		if (index !== -1) {
			setActiveIndex(index);
		}
	}, [activeTab, buttons]);

	useEffect(() => {
		const activeButton = buttonRefs.current[activeIndex];
		if (activeButton) {
			setSliderStyle({
				width: `${activeButton.offsetWidth}px`,
				transform: `translateX(${activeButton.offsetLeft}px)`,
			});
		}
	}, [activeIndex]);

	const handleButtonClick = (buttonId: string) => {
		setActiveTab(buttonId);
	};

	return (
		<div className="relative h-full w-full flex justify-between items-center p-2">
			<div
				className="absolute top-0 left-0 h-full bg-z-dark-moss-green rounded-full transition-all duration-500 shadow-lg drop-shadow-md"
				style={sliderStyle}
			/>
			{buttons.map((button, index) => {
				const setRef = (el: HTMLAnchorElement | null) => {
					buttonRefs.current[index] = el;
				};
				return (
					<NavLink
						key={button.id}
						to={button.to}
						ref={setRef}
						className={`relative w-full z-10 flex items-center justify-center h-full px-3 rounded-full transition-all duration-700 text-[12px] font-bold ${
							button.isActive ? "text-white" : "text-z-dark-moss-green"
						}`}
						onClick={() => handleButtonClick(button.id)}
						aria-label={button.label}
					>
						{button.icon}
						{button.isActive && (
							<span className="ml-2 opacity-100 whitespace-nowrap transition-opacity duration-700">
								{button.label}
							</span>
						)}
					</NavLink>
				);
			})}
		</div>
	);
};

export { NavigationBar };
