import Hammer from "hammerjs";
import { handlePan, handlePanEnd } from "./handlePan";

export const setupHammer = (
  el: HTMLDivElement,
  initCards: () => void,
  onSwipeLeft: (card: HTMLDivElement) => void,
  onSwipeRight: (card: HTMLDivElement) => void
) => {
  const hammertime = new Hammer(el);
  hammertime.get("pan").set({ direction: Hammer.DIRECTION_ALL });

  hammertime.on("pan", (event) => {
    event.preventDefault();
    handlePan(event, el);
  });

  hammertime.on("panend", (event) => {
    event.preventDefault();
    handlePanEnd(event, el, initCards, onSwipeLeft, onSwipeRight);
  });
};
