import type { ReactNode } from "react";

export const Tooltip = ({
	children,
	title,
}: { children: ReactNode; title: string }) => {
	return (
		<span className="relative inline-block group">
			{children}
			<span className="absolute bottom-full left-1/2 transform font-normal -translate-x-1/2 mb-2 w-64 bg-white text-z-shark shadow-md rounded-md text-sm p-2 transition-opacity duration-300 opacity-0 group-hover:opacity-100 group-hover:block hidden">
				{title}
			</span>
		</span>
	);
};
