import { create } from "zustand";

interface Modal {
    bankConnectionModal: boolean
    setBankConnectionModal: (bankConnectionModal:boolean) => void
    paymentAssistanceInfoModal: boolean
    setPaymentAssistanceInfoModal: (paymentAssistanceInfoModal:boolean) => void
    watchVideoModal: boolean
    setWatchVideoModal: (watchVideoModal:boolean) => void
    startShoppingModal: boolean
    setStartShoppingModal: (startShoppingModal:boolean) => void

    minCartModal: boolean
    setMinCartModal: (minCartModal:boolean) => void
    cartModal: boolean
    setCartModal: (cartModal:boolean) => void
    homeBuyingPlanModal: boolean
    setHomeBuyingPlanModal: (homeBuyingPlanModal:boolean) => void
    debtOverPageState:boolean
    setDebtOverPageState: (debtOverPageState:boolean) => void
    financeSavingsModal:boolean
    setFinanceSavingsModal: (financeSavingsModal:boolean) => void
    financeIncomeModal:boolean
    setFinanceIncomeModal: (financeSavingsModal:boolean) => void

    flinksModal:boolean
    setFlinksModal: (flinksModal:boolean) => void


    anotherDebtOverPageState:boolean
    setAnotherDebtOverPageState: (debtOverPageState:boolean) => void

    editHouseGoalOverPageState:boolean
    setEditHouseGoalOverPageState: (editHouseGoalOverPageState:boolean) => void
    buyingHomeState: boolean;
    setBuyingHomeState: (buyingHomeState: boolean) => void;
    planSpendHomeState: boolean;
    setPlanSpendHomeState: (planSpendHomeState: boolean) => void;
    whenBuyHomeState: boolean;
    setWhenBuyHomeState: (whenBuyHomeState: boolean) => void;
    financialReportOverPage: boolean,
    setFinancialReportOverPage:(financialReportOverPage:boolean) => void
}

const useModalStore = create<Modal>((set) => ({
    bankConnectionModal: false,
    setBankConnectionModal:(bankConnectionModal:boolean) => set({bankConnectionModal}),
    paymentAssistanceInfoModal: false,
    setPaymentAssistanceInfoModal:(paymentAssistanceInfoModal:boolean) => set({paymentAssistanceInfoModal}),
    watchVideoModal: false,
    setWatchVideoModal:(watchVideoModal:boolean) => set({watchVideoModal}),
    cartModal:false,
    minCartModal:false,
    setMinCartModal: (minCartModal:boolean) => set({minCartModal}),

    setCartModal: (cartModal:boolean) => set({cartModal}),
    startShoppingModal:false,
    setStartShoppingModal: (startShoppingModal:boolean) => set({startShoppingModal}),
    homeBuyingPlanModal:false,
    setHomeBuyingPlanModal:(homeBuyingPlanModal:boolean) => set({homeBuyingPlanModal}),
    financeSavingsModal:false,
    setFinanceSavingsModal:(financeSavingsModal:boolean) => set({financeSavingsModal}),
    financeIncomeModal:false,
    setFinanceIncomeModal:(financeIncomeModal:boolean) => set({financeIncomeModal}),
    flinksModal:false,
    setFlinksModal:(flinksModal:boolean) => set({flinksModal}),
    
    debtOverPageState:false,
    setDebtOverPageState:(debtOverPageState:boolean) => set({debtOverPageState}),
    anotherDebtOverPageState:false,
    setAnotherDebtOverPageState:(anotherDebtOverPageState:boolean) => set({anotherDebtOverPageState}),
    editHouseGoalOverPageState:false,
    setEditHouseGoalOverPageState: (editHouseGoalOverPageState:boolean) => set({editHouseGoalOverPageState}),
    buyingHomeState: false,
    setBuyingHomeState: (buyingHomeState:boolean) => set({buyingHomeState}),
    planSpendHomeState: false,
    setPlanSpendHomeState: (planSpendHomeState: boolean) => set({ planSpendHomeState }),
    whenBuyHomeState: false,
    setWhenBuyHomeState: (whenBuyHomeState: boolean) => set({ whenBuyHomeState }),
    financialReportOverPage:false,
    setFinancialReportOverPage:(financialReportOverPage:boolean) => set({financialReportOverPage}),
}))

export {useModalStore}