import type { Feature, FeatureCollection } from "geojson";
import millify from "millify";
import { useEffect, useRef, useState } from "react";
import ReactMap, { Layer, type MapRef, Source } from "react-map-gl";
import HomeMapIconUrl from "../../assets/mapDotIcon.png";
import type { GooglePlace, Listing } from "../../interfaces/listing";
import {
	homeIconLayerStyle,
	homeLayerStyle,
	nearbyLayerStyle,
} from "./neighbourhoodMapLayerStyles";

const StarIcon = ({ filled }: { filled: boolean }) => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 24 24"
		fill={filled ? "#FECD07" : "none"}
		stroke="#FECD07"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
	</svg>
);

const MAPBOX_TOKEN =
	"pk.eyJ1IjoiYmVhcmNvZGVzODcwIiwiYSI6ImNtMHBmMjVqZzAwZWkycXEwYmcxcHJjMGwifQ.UeHMFKKaRuaTR-NqOPPsnw";

interface NeighborhoodMapProps {
	listing: Listing;
	nearbyType: string;
	googlePlaces: GooglePlace[];
}

const typeMappings: Record<string, string[]> = {
	school: ["school", "secondary_school", "university"],
	grocery: ["supermarket", "drugstore", "pharmacy"],
	nearby: ["restaurant", "cafe"],
	homeSale: [],
};

interface PlaceProperties {
	[name: string]: unknown;
	name: string;
	address: string;
	icon: string;
	distance: string;
	rating?: number;
}

const createHomeGeoJson = (
	listing: Listing,
	isHomeIcon: boolean,
): FeatureCollection => {
	return {
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [listing.long, listing.lat],
				},
				properties: {
					name: listing.address,
					price: `$${millify(Number.parseInt(listing.listPrice))}`,
					icon: isHomeIcon ? "home" : "default",
				},
			},
		],
	};
};

const createGooglePlacesGeoJson = (
	places: GooglePlace[],
): FeatureCollection => {
	const features: Feature[] = places.map((place) => ({
		type: "Feature",
		geometry: {
			type: "Point",
			coordinates: [place.geometry.location.lng, place.geometry.location.lat],
		},
		properties: {
			name: place.name,
			address: place.vicinity,
			icon: place.types[0],
			distance: place.distance,
			rating: place.rating,
		} as PlaceProperties,
	}));

	return {
		type: "FeatureCollection",
		features,
	};
};

const mergeGeoJsonFeatures = (
	homeGeoJson: FeatureCollection,
	newFeatures: FeatureCollection,
): FeatureCollection => {
	return {
		type: "FeatureCollection",
		features: [...homeGeoJson.features, ...newFeatures.features],
	};
};

const filterGooglePlacesByType = (
	googlePlaces: GooglePlace[],
	type: string,
): GooglePlace[] => {
	const typesToFilter = typeMappings[type];
	return googlePlaces.filter((place) =>
		place.types.some((placeType) => typesToFilter.includes(placeType)),
	);
};

const NeighbourhoodMap = ({
	listing,
	nearbyType,
	googlePlaces,
}: NeighborhoodMapProps) => {
	const [viewState, setViewState] = useState({
		longitude: listing.long,
		latitude: listing.lat,
		zoom: 14,
	});
	const [popupInfo, setPopupInfo] = useState<null | {
		longitude: number;
		latitude: number;
		properties: PlaceProperties;
	}>(null);
	const mapRef = useRef<MapRef | null>(null);

	const isHomeIcon = true;

	const filteredGooglePlaces = filterGooglePlacesByType(
		googlePlaces,
		nearbyType,
	);
	const googlePlacesGeoJson = createGooglePlacesGeoJson(filteredGooglePlaces);
	const homeGeoJson = createHomeGeoJson(listing, isHomeIcon);
	const mergedGeoJson = mergeGeoJsonFeatures(homeGeoJson, googlePlacesGeoJson);

	useEffect(() => {
		const map = mapRef.current?.getMap();
		if (!map) {
			return;
		}

		const addHomeMapIcon = (retryCount = 0) => {
			const img = new Image();
			img.src = HomeMapIconUrl;
			img.onload = () => {
				if (!map.hasImage("home")) {
					map.addImage("home", img);
				}

				if (!map.getSource("homeSource")) {
					map.addSource("homeSource", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: [
								{
									type: "Feature",
									geometry: {
										type: "Point",
										coordinates: [listing.long, listing.lat],
									},
									properties: {},
								},
							],
						},
					});
				}

				if (!map.getLayer("homeLayer")) {
					map.addLayer({
						id: "homeLayer",
						type: "symbol",
						source: "homeSource",
						layout: {
							"icon-image": "home",
							"icon-size": 0.5,
							"icon-allow-overlap": true,
						},
					});
				}
			};

			img.onerror = (error) => {
				console.error("Error loading home icon:", error);
			};

			// Retry if the image has not been added
			if (!map.hasImage("home") && retryCount < 5) {
				setTimeout(() => {
					addHomeMapIcon(retryCount + 1);
				}, 1000);
			}
		};

		const onLoad = () => {
			addHomeMapIcon();
		};

		map.on("load", onLoad);

		return () => {
			map.off("load", onLoad);
		};
	}, [listing]);

	const handleMapClick = (event: mapboxgl.MapMouseEvent) => {
		const features = (event.features as Feature[] | undefined) || [];

		if (features.length > 0) {
			const feature = features[0];
			const properties = feature.properties as PlaceProperties;

			if (feature.geometry.type === "Point") {
				const [longitude, latitude] = feature.geometry.coordinates;
				setPopupInfo({
					longitude,
					latitude,
					properties,
				});
			}
		} else {
			setPopupInfo(null);
		}
	};

	const renderStars = (rating: number) => {
		const fullStars = Math.floor(rating);
		const stars = new Array(5)
			.fill(null)
			.map((_, index) => (
				<StarIcon
					key={`star-${popupInfo?.properties.name}-${index}`}
					filled={index < fullStars}
				/>
			));
		return stars;
	};

	return (
		<div className="relative rounded-2xl overflow-hidden">
			<ReactMap
				mapboxAccessToken={MAPBOX_TOKEN}
				{...viewState}
				onMove={(evt) => setViewState(evt.viewState)}
				dragPan={true}
				mapStyle={"mapbox://styles/mapbox/streets-v9"}
				ref={mapRef}
				style={{ width: "100%", height: "300px" }}
				interactiveLayerIds={["homeLayer", "homeIconLayer", "nearbyLayer"]}
				onClick={handleMapClick}
			>
				<Source id="my-data" type="geojson" data={mergedGeoJson}>
					<Layer {...homeLayerStyle} />
					<Layer {...homeIconLayerStyle} />
					<Layer {...nearbyLayerStyle} />
				</Source>
			</ReactMap>

			{popupInfo && (
				<div className="h-full mx-2">
					<div className="absolute px-3 py-2 top-5 left-3 w-[188px] border border-[#DCDCDC] bg-white rounded-lg text-sm">
						<div className="w-full space-y-2">
							<div className="flex justify-between items-start w-full">
								<div className="font-bold text-xs">
									{popupInfo.properties.name}
								</div>
							</div>
							{popupInfo.properties.address && (
								<div className="flex flex-col">
									<div className="text-[10px] text-[#4F4F4F]">
										{popupInfo.properties.address}
									</div>
									{popupInfo.properties.distance && (
										<div className="text-[10px] text-[#4F4F4F]">
											<div>{popupInfo.properties.distance} away</div>
										</div>
									)}
								</div>
							)}
							{popupInfo.properties.rating && (
								<div className="flex items-start gap-0.5">
									{renderStars(popupInfo.properties.rating)}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export { NeighbourhoodMap };
