import { useState, useEffect } from "react";

interface DateItem {
  id: string;
  date: string;
}

interface CalendarProps {
  setButtonEnabled: (value: boolean) => void;
  dates: DateItem[];
  setDates: (dates: DateItem[]) => void;
  onSelectDate?: (formattedDate: DateItem) => void;
  singleSelect?: boolean;
  showCurrentDay?: boolean;
}

const Calendar = ({
  setButtonEnabled,
  dates,
  setDates,
  onSelectDate,
  singleSelect = false,
  showCurrentDay = false,
}: CalendarProps) => {
  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState<number>(
    currentDate.getMonth()
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    currentDate.getFullYear()
  );
  const [daysInMonth, setDaysInMonth] = useState<(number | null)[]>([]);

  useEffect(() => {
    const days: (number | null)[] = [];
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
    const numberOfDaysInMonth = new Date(
      selectedYear,
      selectedMonth + 1,
      0
    ).getDate();

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(null);
    }

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      days.push(i);
    }

    setDaysInMonth(days);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    setButtonEnabled(dates.length === 2);
  }, [dates, setButtonEnabled]);

  const handlePrevMonth = () => {
    if (selectedMonth === 0) {
      setSelectedMonth(11);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (selectedMonth === 11) {
      setSelectedMonth(0);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const handleDayClick = (day: number) => {
    const selectedDate = new Date(
      selectedYear,
      selectedMonth,
      day
    ).toDateString();
    const dateItem = {
      id: `${selectedYear}-${selectedMonth}-${day}`,
      date: selectedDate,
    };

    if (singleSelect) {
      console.log("singleSelect");

      setDates([
        { id: `${selectedYear}-${selectedMonth}-${day}`, date: selectedDate },
      ]);
    } else {
      console.log("multiSelect");

      if (
        dates.length < 2 &&
        !dates.find((date) => date.date === selectedDate)
      ) {
        setDates([
          ...dates,
          { id: `${selectedYear}-${selectedMonth}-${day}`, date: selectedDate },
        ]);
      }
    }

    onSelectDate?.(dateItem);
  };

  const isPastDate = (day: number): boolean => {
    const today = new Date();
    const dateToCheck = new Date(selectedYear, selectedMonth, day);
    return dateToCheck < today;
  };

  const isCurrentDate = (day: number): boolean => {
    return (
      day === currentDate.getDate() &&
      selectedMonth === currentDate.getMonth() &&
      selectedYear === currentDate.getFullYear()
    );
  };

  const isSelectedDate = (day: number): boolean => {
    const dateToCheck = new Date(
      selectedYear,
      selectedMonth,
      day
    ).toDateString();
    return dates.some((date) => date.date === dateToCheck);
  };

  const DayButton = ({ day }: { day: number | null }) => {
    if (day === null) {
      return <div className="m-px size-10 flex justify-center items-center" />;
    }
    const key = `${selectedYear}-${selectedMonth}-${day}`;
    const isPast = isPastDate(day);
    const isSelected = isSelectedDate(day);
    const isToday = showCurrentDay && isCurrentDate(day);

    return (
      <div key={key}>
        <button
          type="button"
          className={`m-px size-10 flex justify-center items-center border border-transparent text-sm ${`
            ${isPast ? "text-gray-200 cursor-not-allowed" : ""}
            ${isSelected ? "text-white bg-z-dark-moss-green font-bold" : ""}
            ${
              isToday
                ? "text-z-shark border-2 border-z-dark-moss-green font-bold"
                : "text-gray-800"
            } 
            `} rounded-[13px]`}
          onClick={() => handleDayClick(day)}
          disabled={isPast}
        >
          {day}
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="space-y-0.5">
        <div className="grid grid-cols-5 items-center gap-x-3 mx-1.5 pb-3">
          <div className="col-span-1">
            <button
              type="button"
              onClick={handlePrevMonth}
              className="size-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none"
            >
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Previous Month</title>
                <path d="m15 18-6-6 6-6" />
              </svg>
            </button>
          </div>

          <div className="col-span-3 flex justify-center items-center gap-x-1">
            <span className="text-lg font-medium">
              {new Date(selectedYear, selectedMonth).toLocaleString("default", {
                month: "long",
              })}{" "}
              {selectedYear}
            </span>
          </div>

          <div className="col-span-1 flex justify-end">
            <button
              type="button"
              onClick={handleNextMonth}
              className="size-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none"
            >
              <svg
                className="flex-shrink-0 size-4"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Next Month</title>
                <path d="m9 18 6-6-6-6" />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex pb-1.5">
          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
            <span
              key={day}
              className="m-px w-10 block text-center text-sm text-gray-500"
            >
              {day}
            </span>
          ))}
        </div>

        <div className="grid grid-cols-7">
          {daysInMonth.map((day) => (
            <DayButton
              key={
                day !== null
                  ? `${selectedYear}-${selectedMonth}-${day}`
                  : `empty-${Math.random()}`
              }
              day={day}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export { Calendar };
