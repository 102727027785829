export const addressLookup = async (input: string) => {
  try {
    const response = await fetch(
      `https://cfoiil653rb5i57vze6f6w2jfy0riqkm.lambda-url.ca-central-1.on.aws/find?Text=${input}`,
      { method: "GET" }
    );
    const data = await response.json();
    return data.Items;
  } catch (error) {
    console.error("Error fetching suggestions:", error);
    throw error;
  }
};

export const addressLookupSearchQuery = async (input: string) => {
  const response = await fetch(
    `https://cfoiil653rb5i57vze6f6w2jfy0riqkm.lambda-url.ca-central-1.on.aws/find?Text=${input}`,
    { method: "GET" }
  );
  return await response.json();
};

export const getAddressType = async (id: string) => {
  const response = await fetch(
    `https://cfoiil653rb5i57vze6f6w2jfy0riqkm.lambda-url.ca-central-1.on.aws/retrieve?Id=${encodeURIComponent(
      id
    )}`,
    { method: "GET" }
  );

  return response.json();
};
