import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPreApproved } from "../../services/account";
import { useUserProfileStore } from "../../stores/profileStore";
import PaperPlaneImage from "../../assets/Profile/paperPlane.png";
import { useGetPreApprovalStore } from "../../stores/getPreApprovalStore";

const GetPreApprovedCheckInboxView = () => {
	const navigate = useNavigate();
	const [buttonLabel, setButtonLabel] = useState("Resend email");
	const [isDisabled, setIsDisabled] = useState(false);
	const { email } = useGetPreApprovalStore();
	const { userProfile } = useUserProfileStore();

	const handleResendEmail = async () => {
		setIsDisabled(true);
		setButtonLabel("Sent");

		if (userProfile?.emailAddress === email) {
			await getPreApproved();
		} else {
			await getPreApproved(email);
		}

		setTimeout(() => {
			setButtonLabel("Resend email");
			setIsDisabled(false);
		}, 5000);
	};

	return (
		<div className="flex flex-col justify-center items-center text-black space-y-8 px-2 pt-2 pb-6">
			<div className="space-y-2 flex flex-col justify-center items-center h-full w-full">
				<img src={PaperPlaneImage} alt="" />
				<div className="space-y-4 w-full flex flex-col items-center text-center justify-center max-w-[302px]">
					<div className="font-bold text-3xl">Check your inbox</div>
					<div className="space-y-3">
						<div>An email has been sent to</div>
						<div>{email}</div>
						<div>
							This email contains a link for you to start your pre-approval
							application
						</div>
					</div>
				</div>
			</div>
			<div className="w-full">
				<button
					type="button"
					className="text-white bg-z-dark-moss-green rounded-full py-3.5 font-bold w-full"
					onClick={handleResendEmail}
					disabled={isDisabled}
				>
					{buttonLabel}
				</button>
				<button
					type="button"
					className="text-z-dark-moss-green py-3.5 font-medium w-full"
					onClick={() => navigate("/profile/get-preapproved/email-view")}
				>
					Send to another email address
				</button>
			</div>
		</div>
	);
};

export { GetPreApprovedCheckInboxView };
