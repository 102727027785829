import type { Listing } from "../../interfaces/listing";

export const zownForecastListing: Listing = {
	id: 1,
	listingId: "12345",
	address: "123 Main Street",
	listPrice: "560000",
	bed: "3",
	bath: "2",
	images: ["image1.jpg", "image2.jpg"],
	mlsNumber: "MLS123456",
	municipality: "Toronto",
	sqft: "1200",
	propertyType: "Detached",
	listingDate: new Date("2024-09-01"),
	status: "Active",
	listingHistory: [],
	description: "A beautiful 3-bedroom house in the heart of the city.",
	listingAgent: "John Doe",
	tax: "5000",
	buildingAge: "10 years",
	size: "1500 sqft",
	lot: "50x100",
	basement: "Finished",
	dataSource: "MLS",
	statusChange: "None",
	dom: 10,
	parking: "2-car garage",
	updatedDate: new Date("2024-09-15"),
	style: "Modern",
	community: "Downtown",
	colling: "Central Air",
	cooling: "Central Air",
	rooms: [
		{ label: "Living Room", size: "12x14", details: "Spacious" },
		{ label: "Kitchen", size: "10x12", details: "Modern Appliances" },
	],
	heatingType: "Forced Air",
	heatingFuel: "Natural Gas",
	amenities: "Pool, Gym",
	garageType: "Attached",
	crossStreet: "Main St & First Ave",
	lat: 43.6532,
	long: -79.3832,
	downPaymentContribution: "50000",
	minDownPaymentNeeded: "112000",
	newDownPaymentNeeded: "120000",
	valuation: "550000",
	averageDom: "30",
	mortgageRate: "3.25%",
	zownContribution: "15000",
	comparableSales: [],
	similarListings: [],
	googlePlaces: [],
};

const mockZownForecast: Record<string, number> = {
	"2019": 10000,
	"2020": 20000,
	"2021": 30000,
	"2022": 40000,
	"2023": 50000,
	"2024": 60000,
	"2025": 70000,
	"2026": 80000,
	"2027": 90000,
	"2028": 100000,
	"2029": 110000,
	"2010": 120000,
	"2011": 50000,
	"2012": 50000,
	"2013": 50000,
};

export const mockStatistics = {
	zownForecast: mockZownForecast,
};
