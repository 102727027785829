import { useEffect } from "react";
import { Range, getTrackBackground } from "react-range";
import LightBulb from "../../assets/icons/lightBulb.svg";
import { PropertyTypeSelector } from "./propertyTypeSelector";
import { useUserProfileStore } from "../../stores/profileStore";
import { usePropertySearchingStore } from "../../stores/propertySearchingStore";

interface PropertySearching {
	value: string;
	price?: number[];
}

const propertySearchingData: PropertySearching[] = [
	{ value: "Detached" },
	{ value: "Semi-Detached" },
	{ value: "Townhouse" },
	{ value: "Condo Townhouse" },
	{ value: "Condo" },
];

const STEP = 1;
const MIN = 0;
const MAX = 1000;

const mapToBackendPropertyType = (selectedProperties: string[]) => {
	const propertyTypeMap: Record<string, string> = {
		Detached: "DETACHED",
		"Semi-Detached": "SEMI_DETACHED",
		Townhouse: "TOWNHOUSE",
		"Condo Townhouse": "CONDO_TOWNHOUSE",
		Condo: "CONDO",
	};
	return selectedProperties.map(
		(property) => propertyTypeMap[property] || property,
	);
};

const mapToFrontendPropertyType = (backendProperties: string[]) => {
	const propertyTypeMap: Record<string, string> = {
		DETACHED: "Detached",
		SEMI_DETACHED: "Semi-Detached",
		TOWNHOUSE: "Townhouse",
		CONDO_TOWNHOUSE: "Condo Townhouse",
		CONDO: "Condo",
	};
	return backendProperties.map(
		(property) => propertyTypeMap[property] || property,
	);
};

const PropertySearchingView = () => {
	const {
		selectedProperties,
		setSelectedProperty,
		propertySearchingPrice,
		setPropertySearchingPrice,
	} = usePropertySearchingStore();
	const { userProfile, setUserProfile } = useUserProfileStore();

	const frontendSelectedProperties =
		mapToFrontendPropertyType(selectedProperties);

	useEffect(() => {
		setSelectedProperty(
			userProfile?.matchingPreferences?.filters?.propertyType ?? [],
		);
	}, [userProfile, setSelectedProperty]);

	return (
		<div className="h-full px-4">
			<div className="space-y-8 pb-6">
				<div className="text-z-shark text-2xl font-bold">
					What type of property are you searching for?
				</div>
				<PropertyTypeSelector
					display="grid"
					gap="14px"
					customClassName="min-w-[145px] w-full"
					parentClassName="grid-cols-2"
					options={propertySearchingData}
					selectedValue={frontendSelectedProperties}
					onChange={(value: string) => {
						setSelectedProperty(mapToBackendPropertyType([value]));
					}}
					width="fit"
					height="50px"
				/>
				{(frontendSelectedProperties.includes("Condo") ||
					frontendSelectedProperties.includes("Condo Townhouse")) && (
					<>
						<div className="text-z-shark text-xl font-semibold pb-6">
							What is the maximum you would spend on condo fees?
						</div>
						<div className="mx-8">
							<Range
								values={propertySearchingPrice}
								step={STEP}
								min={MIN}
								max={MAX}
								onChange={(values) => setPropertySearchingPrice(values)}
								renderTrack={({ props, children }) => (
									<div
										{...props}
										className="w-full h-2 bg-gray-300 rounded"
										style={{
											...props.style,
											background: getTrackBackground({
												values: propertySearchingPrice,
												colors: ["#456930", "rgba(53, 105, 48, 0.2)"],
												min: MIN,
												max: MAX,
											}),
										}}
									>
										{children}
									</div>
								)}
								renderThumb={({ props }) => (
									<div
										{...props}
										className="h-7 w-7 bg-white rounded-full flex items-center justify-center focus:outline-none relative"
										style={{
											...props.style,
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
											border: "1px solid rgba(0, 0, 0, 0.10)",
										}}
									>
										<div className="text-sm font-bold bg-z-dark-moss-green text-white w-[72px] text-center rounded-lg py-1 absolute -top-[42px]">
											${propertySearchingPrice[0]}
											<div className="w-0 h-0 border-l-[6px] border-r-[6px] border-t-[7px] border-transparent border-t-z-dark-moss-green absolute -bottom-[6px] left-1/2 -translate-x-1/2" />
										</div>
									</div>
								)}
							/>
						</div>
						<div className="flex items-center justify-center bg-[#FAFAFA] px-4 h-[80px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] rounded-xl !mb-2">
							<img src={LightBulb} alt="light bulb" />
							<div className="flex flex-col text-z-shark font-light text-sm pl-6">
								The average condo fees average from
								<span className="font-bold">$500 - $550</span>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export { PropertySearchingView };
