import { useEffect } from "react";
import { LifestyleSelectGroup } from "./selectionButton";
import { useUserProfileStore } from "../../stores/profileStore";
import { usePropertySearchingStore } from "../../stores/propertySearchingStore";
import {
	Nightlife,
	ChildrenIcon,
	ParkIcon,
	CookingIcon,
	OutdoorIcon,
	DiyIcon,
	PetsIcon,
} from "../../assets/icons";

const mapToBackendFormat = (lifestyle: string) => {
	const lifestyleMap: Record<string, string> = {
		Children: "CHILDREN",
		Nightlife: "NIGHTLIFE",
		Parks: "PARKS",
		Cooking: "COOKING",
		Outdoor: "OUTDOOR",
		Pets: "PETS",
		DIY: "DIY",
	};
	return lifestyleMap[lifestyle] || lifestyle;
};

const mapToDisplayFormat = (lifestyle: string) => {
	const displayMap: Record<string, string> = {
		CHILDREN: "Children",
		NIGHTLIFE: "Nightlife",
		PARKS: "Parks",
		COOKING: "Cooking",
		OUTDOOR: "Outdoor",
		PETS: "Pets",
		DIY: "DIY",
	};
	return displayMap[lifestyle] || lifestyle;
};

const LifestyleView = () => {
	const { selectedLifestyles, setSelectedLifestyles, selectedProperties } =
		usePropertySearchingStore();
	const { userProfile } = useUserProfileStore();

	useEffect(() => {
		const userLifestyles = userProfile?.matchingPreferences?.lifeStyles ?? [];
		const mappedLifestyles = userLifestyles.map(mapToBackendFormat);
		setSelectedLifestyles(mappedLifestyles);
	}, [setSelectedLifestyles, userProfile]);
	const displayedLifestyles = selectedLifestyles.map(mapToDisplayFormat);

	const handleLifestyleChange = (selected: string[]) => {
		const mappedLifestyles = selected.map(mapToBackendFormat);
		setSelectedLifestyles(mappedLifestyles);
	};

	const lifestyleOptions = [
		{ value: "Children", icon: <ChildrenIcon fill="" /> },
		{ value: "Nightlife", icon: <Nightlife fill="" /> },
		{ value: "Parks", icon: <ParkIcon fill="" /> },
		{ value: "Cooking", icon: <CookingIcon fill="" /> },
		{ value: "Outdoor", icon: <OutdoorIcon fill="" /> },
		{ value: "DIY", icon: <DiyIcon fill="" /> },
		...(selectedProperties.includes("CONDO") && selectedProperties.length === 1
			? []
			: [{ value: "Pets", icon: <PetsIcon fill="" /> }]),
	];

	return (
		<div className="px-4 h-full">
			<div className="pb-4">
				<h2 className="text-z-shark text-2xl font-bold mb-2">
					What's your lifestyle
				</h2>
				<span className="text-z-shark text-sm">A minimum of 3 is required</span>

				<LifestyleSelectGroup
					textClassName="!font-semibold"
					gap="12px"
					parentClassName="justify-between"
					wrap={true}
					width="95px"
					height="95px"
					selectedValue={displayedLifestyles}
					onChange={handleLifestyleChange}
					options={lifestyleOptions}
					customClassName="border-z-dark-moss-green text-z-shark !min-w-[95px] mt-4 !justify-between p-5 w-full"
					defaultIconFill="#32302f"
					selectedIconFill="#ffffff"
				/>
			</div>
		</div>
	);
};

export { LifestyleView };
