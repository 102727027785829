import { useRef } from "react";
import { Chart, type TooltipItem } from "chart.js";
import type { SoldActiveListingsInterface } from "../../../interfaces/statistics";

interface SoldActiveListingsProps {
	soldActiveListings: SoldActiveListingsInterface;
}

interface GraphResponse {
	statistics: {
		available: {
			mth: Record<string, number>;
		};
		closed: {
			mth: Record<string, { count: number }>;
		};
	};
}

const SoldActiveListings = ({
	soldActiveListings,
}: SoldActiveListingsProps) => {
	const chartRef = useRef<Chart | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	const availableData = soldActiveListings?.statistics?.available?.mth;
	const closedData = soldActiveListings?.statistics?.closed?.mth;

	if (!availableData || !closedData || !canvasRef.current) {
		return;
	}

	const xValues = Object.keys(availableData).sort();

	const activeListingsValues = xValues.map(
		(month) => availableData[month] || 0,
	);
	const soldValues = xValues.map((month) => closedData[month]?.count || 0);

	if (canvasRef.current) {
		if (chartRef.current) {
			chartRef.current.destroy();
		}

		const ctx = canvasRef.current.getContext("2d");
		if (!ctx) {
			return;
		}

		const gradient = ctx.createLinearGradient(0, 0, 0, 600);
		gradient.addColorStop(0, "rgba(10, 157, 87, 0.33)");
		gradient.addColorStop(1, "rgba(241, 120, 182, 0)");

		chartRef.current = new Chart(ctx, {
			type: "bar",
			data: {
				labels: xValues,
				datasets: [
					{
						type: "line",
						label: "Total Sold",
						data: soldValues,
						fill: true,
						backgroundColor: gradient,
						pointRadius: 0,
						borderColor: "#0A9D57",
						tension: 0.3,
						yAxisID: "y",
					},
					{
						type: "bar",
						label: "Active Listings",
						data: activeListingsValues,
						backgroundColor: "rgba(83, 152, 42, 0.46)",
						borderWidth: 1,
						yAxisID: "y1",
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: true,
					},
					tooltip: {
						mode: "index",
						intersect: false,
						callbacks: {
							label: (tooltipItem: TooltipItem<"line" | "bar">) => {
								if (tooltipItem.datasetIndex === 0) {
									return `Sold: ${tooltipItem.formattedValue}`;
								}
								return `Active Listings: ${tooltipItem.formattedValue}`;
							},
						},
					},
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
					},
					y: {
						display: false,
						type: "linear",
						position: "right",
						ticks: {
							stepSize: 0.2,
						},
						grid: {
							drawOnChartArea: false,
						},
					},
					y1: {
						type: "linear",
						position: "left",
						ticks: {
							stepSize: 0.2,
						},
					},
				},
			},
		});
	}

	return (
		<div className="space-y-4 px-4">
			<div>
				<div className="font-semibold text-lg">Sold & Active Listings</div>
				<canvas ref={canvasRef} id="myChart" className="h-auto max-h-[230px]" />
			</div>
		</div>
	);
};

export { SoldActiveListings };
