import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import { formatPrice } from "../../utils/formatPrice";

const tabs = [{ name: "Home Facts" }, { name: "Details" }, { name: "Rooms" }];

interface TabProps {
	name: string;
	isActive: boolean;
	onClick: () => void;
}

interface DetailsProps {
	propertyType: string;
	style: string;
	community: string;
	municipality: string;
	bed: string;
	bath: string;
	colling: string;
	rooms: { label: string; size: string; details: string }[];
	heatingType: string;
	heatingFuel: string;
	buildingAge: string;
	amenities: string;
	garageType: string;
	parking: string;
	crossStreet: string;
	size: string;
	textPosition?: string;
}

const Tab = ({ name, isActive, onClick }: TabProps) => (
	<li className="flex justify-between w-full">
		<button
			type="button"
			onClick={onClick}
			className="inline-block text-black py-2 w-full rounded-t-lg font-semibold"
			style={{
				borderBottom: isActive
					? "6px solid #456930"
					: "1px solid rgba(69, 105, 48, 0.49)",
			}}
			aria-current={isActive ? "page" : undefined}
		>
			{name}
		</button>
	</li>
);

interface HomeFactsProps {
	tax: string;
	year:string;
	propertyType: string;
	buildingAge: string;
	size: string;
	lot: string;
	parking: string;
	basement: string;
	mlsNumber: string;
	dataSource: string;
	statusChange: string;
	listingDate: Date;
	updatedDate: Date;
	textPosition?: string;
}
const HomeFacts = ({
	tax,
	year,
	propertyType,
	buildingAge,
	size,
	lot,
	parking,
	basement,
	mlsNumber,
	dataSource,
	statusChange,
	listingDate,
	updatedDate,
	textPosition = "text-start",
}: HomeFactsProps) => {
	const daysOnMarket = moment().diff(moment.utc(listingDate), "days");
	const facts = [
		{ label: "Tax", value: `$${formatPrice(tax)} / ${year}` },
		{ label: "Property Type", value: propertyType },
		{ label: "Building Age", value: buildingAge },
		{ label: "Size", value: `${formatPrice(size)} sqft` },
		{ label: "Lot Size", value: lot },
		{ label: "Parking", value: parking },
		{ label: "Basement", value: basement },
		{ label: "Listing #", value: mlsNumber },
		{ label: "Data Source", value: dataSource },
		{ label: "Status Change", value: statusChange },
		{ label: "Days on Market", value: `${daysOnMarket} days` },
		{ label: "Listed on", value: moment.utc(listingDate).format("YYYY-MM-DD") },
		{
			label: "Updated on",
			value: moment.utc(updatedDate).format("YYYY-MM-DD"),
		},
	];

	return (
		<div className="space-y-2 text-black">
			{facts.map((fact) => (
				<div
					key={`${fact.label}-${fact.value}`}
					className="flex justify-between"
				>
					<div className="text-[#B6B9CC] font-medium w-full text-sm">
						{fact.label}
					</div>
					<div
						className={clsx(
							"text-black font-semibold w-full text-sm",
							textPosition,
						)}
					>
						{fact.value}
					</div>
				</div>
			))}
		</div>
	);
};

interface DetailsSectionProps {
	title: string;
	items: { label: string; value: string }[];
	textPosition?: string;
}
const DetailsSection = ({
	title,
	items,
	textPosition = "text-start",
}: DetailsSectionProps) => (
	<div className="space-y-2">
		<div className="text-xl font-semibold text-black">{title}</div>
		{items.map((item) => (
			<div key={`${item.label}-${item.value}`} className="flex justify-between">
				<div className="text-[#B6B9CC] font-medium w-full text-sm">
					{item.label}
				</div>
				<div
					className={clsx(
						"text-black font-semibold w-full text-sm",
						textPosition,
					)}
				>
					{item.value}
				</div>
			</div>
		))}
	</div>
);

const Details = ({
	propertyType,
	style,
	community,
	municipality,
	bed,
	bath,
	colling,
	rooms,
	heatingType,
	heatingFuel,
	buildingAge,
	amenities,
	garageType,
	parking,
	crossStreet,
	size,
	textPosition,
}: DetailsProps) => {
	const detailsData = {
		property: [
			{ label: "Property Type", value: propertyType },
			{ label: "Style", value: style },
			{ label: "Community", value: community },
			{ label: "Municipality", value: municipality },
		],
		interior: [
			{ label: "Bedrooms", value: bed },
			{ label: "Bathrooms", value: bath },
			{ label: "Rooms", value: rooms.length.toString() },
		],
		utilities: [
			{ label: "Cooling", value: colling },
			{ label: "Heating Type", value: heatingType },
			{ label: "Heating Fuel", value: heatingFuel },
		],
		building: [
			{ label: "Size", value: `${formatPrice(size)} sqft` },
			{ label: "Building Age", value: buildingAge },
			{ label: "Amenities", value: amenities },
			{ label: "Construction", value: "Brick" },
		],
		parking: [
			{ label: "Garage Type", value: garageType },
			{ label: "Total Parking Space", value: parking },
		],
		land: [{ label: "Cross Street", value: crossStreet }],
	};

	return (
		<div className="space-y-4">
			{Object.entries(detailsData).map(([key, value]) => (
				<DetailsSection
					key={key}
					title={key.charAt(0).toUpperCase() + key.slice(1)}
					items={value}
					textPosition={textPosition}
				/>
			))}
		</div>
	);
};

interface RoomDetailProps {
	label: string;
	size: string;
	details: string;
}
const RoomDetail = ({ label, size, details }: RoomDetailProps) => (
	<div className="text-z-shark py-6">
		<div className="flex items-start justify-between">
			<div className="flex flex-col flex-1 space-y-2">
				<div className="font-semibold">{label}</div>
				<div className="text-sm font-normal">{details}</div>
			</div>
			<div className="text-end min-w-[fit-content] font-normal ml-2">{size}</div>
		</div>
	</div>
);

interface RoomsProps {
	rooms: { label: string; size: string; details: string }[];
}
const Rooms = ({ rooms }: RoomsProps) => (
	<div className="space-y-2 divide-y divide-[#D8D8D8]">
		{rooms
			.filter((room) => room.label)
			.map((room) => (
				<RoomDetail key={`${room.label}-${room.size}`} {...room} />
			))}
	</div>
);

interface ListingPropertyInfoProps {
	tax: string;
	year:string;
	propertyType: string;
	buildingAge: string;
	size: string;
	lot: string;
	parking: string;
	basement: string;
	mlsNumber: string;
	dataSource: string;
	statusChange: string;
	listingDate: Date;
	updatedDate: Date;
	style: string;
	community: string;
	municipality: string;
	bed: string;
	bath: string;
	colling: string;
	rooms: { label: string; size: string; details: string }[];
	heatingType: string;
	heatingFuel: string;
	amenities: string;
	garageType: string;
	crossStreet: string;
	textPosition?: string;
}

const ListingPropertyInfo = ({
	tax,
	year,
	propertyType,
	buildingAge,
	size,
	lot,
	parking,
	basement,
	mlsNumber,
	dataSource,
	statusChange,
	listingDate,
	updatedDate,
	style,
	community,
	municipality,
	bed,
	bath,
	colling,
	rooms,
	heatingType,
	heatingFuel,
	amenities,
	garageType,
	crossStreet,
	textPosition,
}: ListingPropertyInfoProps) => {
	const [activeTab, setActiveTab] = useState("Home Facts");

	const handleTabClick = (tabName: string) => {
		setActiveTab(tabName);
	};

	return (
		<div className="w-full bg-white flex flex-col rounded-[20px] px-4">
			<div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
				<ul className="flex justify-center">
					{tabs.map((tab) => (
						<Tab
							key={tab.name}
							name={tab.name}
							isActive={tab.name === activeTab}
							onClick={() => handleTabClick(tab.name)}
						/>
					))}
				</ul>
			</div>
			<div className="mt-4">
				{activeTab === "Home Facts" && (
					<HomeFacts
						tax={tax}
						year={year}
						propertyType={propertyType}
						buildingAge={buildingAge}
						size={size}
						lot={lot}
						parking={parking}
						basement={basement}
						mlsNumber={mlsNumber}
						dataSource={dataSource}
						statusChange={statusChange}
						listingDate={listingDate}
						updatedDate={updatedDate}
						textPosition={textPosition}
					/>
				)}
				{activeTab === "Details" && (
					<Details
						propertyType={propertyType}
						style={style}
						community={community}
						municipality={municipality}
						bed={bed}
						bath={bath}
						colling={colling}
						rooms={rooms}
						heatingType={heatingType}
						heatingFuel={heatingFuel}
						buildingAge={buildingAge}
						amenities={amenities}
						garageType={garageType}
						parking={parking}
						crossStreet={crossStreet}
						size={size}
						textPosition={textPosition}
					/>
				)}
				{activeTab === "Rooms" && <Rooms rooms={rooms} />}
			</div>
		</div>
	);
};

export { ListingPropertyInfo };
