import { create } from "zustand";

interface GetPreApprovalStore {
  email: string;
  setEmail: (email: string) => void;
}

const useGetPreApprovalStore = create<GetPreApprovalStore>((set) => ({
  email: "",
  setEmail: (newEmail: string) => set({ email: newEmail }),
}));

export { useGetPreApprovalStore };
