import { formatPrice } from "../../utils/formatPrice";

interface DownPaymentAssistanceBreakDownProps {
	minDownPaymentNeeded: number;
	newDownPaymentNeeded: number;
	downPaymentContribution: number;
}

const DownPaymentAssistanceBreakDown = ({
	minDownPaymentNeeded,
	newDownPaymentNeeded,
	downPaymentContribution,
}: DownPaymentAssistanceBreakDownProps) => {
	return (
		<div className="flex flex-col text-black relative bg-z-prussian-blue p-5 space-y-3 rounded-[20px]">
			<div className="font-extrabold text-2xl text-white">
				Down payment
				<br /> assistance breakdown
			</div>
			<div className="rounded-full p-3 bg-white flex items-center justify-between w-full">
				<div className="text-sm font-medium">Min. down payment needed</div>
				<div className="text-lg font-bold">
					${formatPrice(minDownPaymentNeeded)}
				</div>
			</div>
			<div className="rounded-full p-3 bg-z-abbey flex items-center justify-between w-full">
				<div className="text-sm font-medium">Zown's contribution</div>
				<div className="text-lg font-bold">
					${formatPrice(downPaymentContribution)}
				</div>
			</div>
			<div className="rounded-full p-3 bg-white flex items-center justify-between w-full">
				<div className="text-sm font-medium">New down payment needed</div>
				<div className="text-lg font-bold">
					${formatPrice(newDownPaymentNeeded)}
				</div>
			</div>
		</div>
	);
};

export { DownPaymentAssistanceBreakDown };
