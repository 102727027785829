import moment from "moment-timezone";
import type { KeyboardEvent } from "react";
import { useNavigate } from "react-router-dom";
import { openLink } from "../../services/openLink";
import { fetchOuting } from "../../services/outings";
import MapIcon from "../../assets/showing/mapIcon.svg";
import { useCallback, useEffect, useState } from "react";
import ArrowDownward from "../../assets/arrowDownward.svg";
import AwaitingIcon from "../../assets/showing/awaitingIcon.svg";
import ConfirmedIcon from "../../assets/showing/confirmedIcon.svg";
import UnclaimedIcon from "../../assets/showing/unclaimedIcon.svg";
import CancelledIcon from "../../assets/showing/cancelledIcon.svg";
import ConfirmedCheckIcon from "../../assets/showing/confirmedCheckIcon.svg";
import type {
	BuyerOuting,
	BuyerOutingStatus,
} from "../../interfaces/outingInterface";

const statusStyles: Record<
	BuyerOutingStatus,
	{ bgColor: string; textColor: string; icon: string; label: string }
> = {
	WAITING_FOR_CONFIRMATION: {
		bgColor: "bg-[#FEF0E2]",
		textColor: "text-[#FF7A00]",
		icon: UnclaimedIcon,
		label: "Waiting for confirmation",
	},
	CONFIRMED: {
		bgColor: "bg-[#E6FFF3]",
		textColor: "text-[#0A9D57]",
		icon: ConfirmedIcon,
		label: "Confirmed",
	},
	COMPLETED: {
		bgColor: "bg-[#E6FFF3]",
		textColor: "text-[#0A9D57]",
		icon: ConfirmedIcon,
		label: "Completed",
	},
	CANCELLED: {
		bgColor: "bg-[#FEECEC]",
		textColor: "text-[#FF0000]",
		icon: CancelledIcon,
		label: "Cancelled",
	},
};

const ShowingView = ({
	filterStatus,
}: {
	filterStatus: "upcoming" | "completed";
}) => {
	const [expandedShowings, setExpandedShowings] = useState<string[]>([]);
	const [showings, setShowings] = useState<BuyerOuting[]>([]);
	const navigate = useNavigate();
	const ShowingsData = useCallback(async () => {
		try {
			const fetchResult = await fetchOuting();
			setShowings(Array.isArray(fetchResult) ? fetchResult : []);
		} catch (error) {
			console.error("Failed to fetch account data", error);
			setShowings([]);
		}
	}, []);

	useEffect(() => {
		ShowingsData();
	}, [ShowingsData]);

	const filteredShowings = showings.filter((showing) =>
		filterStatus === "upcoming"
			? showing.status !== "COMPLETED"
			: showing.status === "COMPLETED",
	);

	const toggleExpand = (id: string) => {
		setExpandedShowings((prev) =>
			prev.includes(id)
				? prev.filter((showingId) => showingId !== id)
				: [...prev, id],
		);
	};

	const generateGoogleMapsUrl = (): string => {
		const base = "https://www.google.com/maps/dir/";
		const queries = showings
			.flatMap((showing) =>
				showing.properties.map(
					(property) =>
						`${property.coordinates.latitude},${property.coordinates.longitude}`,
				),
			)
			.join("/");
		return `${base}${queries}`;
	};

	const handleKeyUp = (e: KeyboardEvent, callback: () => void) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			callback();
		}
	};

	return (
		<div className="h-full">
			<div className="flex flex-col items-center h-full text-black pt-4 px-2 pb-8">
				{filteredShowings.length === 0 && (
					<div className="text-black h-full flex justify-center items-center text-lg font-semibold">
						No {filterStatus === "upcoming" ? "upcoming" : "completed"} showings
						available
					</div>
				)}
				<div className="w-full space-y-6 text-black">
					{filteredShowings.map((showing) => {
						const { bgColor, textColor, icon, label } =
							statusStyles[showing.status as BuyerOutingStatus];
						return (
							<div
								key={showing._id}
								className="rounded-[18px] shadow-lg transition-transform duration-300 ease-in-out"
								style={{
									transform: expandedShowings.includes(showing._id)
										? "translateY(0)"
										: "translateY(5px)",
									willChange: "transform",
								}}
							>
								<div className="relative">
									<img
										src={showing.displayImageUrl}
										alt=""
										className="rounded-t-[18px] object-cover h-[156.57px] w-full"
									/>
									<div
										className={`absolute top-3 left-3 flex items-center space-x-2 ${bgColor} p-2 rounded-[4px]`}
									>
										<img src={icon} alt={`${showing.status} icon`} />
										<span className={`text-sm font-medium ${textColor}`}>
											{label}
										</span>
									</div>
								</div>
								<div className="py-4 px-6 space-y-2">
									<div className="flex items-center justify-between">
										<div>
											<div className="text-lg font-semibold text-blac">
												{showing.properties.length} Properties
											</div>
											<div className="text-xs font-light text-black">
												{moment
													.tz(showing.startAt, "America/Toronto")
													.format("MMMM Do YYYY h:mm a")}
											</div>
										</div>
										<button
											type="button"
											onClick={() => {
												const url = generateGoogleMapsUrl();
												openLink(url);
											}}
											onKeyUp={(e) =>
												handleKeyUp(e, () => {
													const url = generateGoogleMapsUrl();
													openLink(url);
												})
											}
										>
											<img src={MapIcon} alt="Map Icon" />
										</button>
									</div>
									<hr className="h-1px bg-[#000000]/50" />
									<div
										className={`overflow-hidden transition-all duration-300 ease-in-out ${
											expandedShowings.includes(showing._id)
												? "max-h-screen opacity-100"
												: "max-h-0 opacity-0"
										}`}
									>
										{showing.properties.map((property) => {
											return (
												<div
													key={property.listingId}
													onClick={() =>
														navigate(`/listing/${property.listingId}`)
													}
													onKeyUp={(e) =>
														handleKeyUp(e, () =>
															navigate(`/listing/${property.listingId}`),
														)
													}
													className="focus:outline-none focus-visible:ring-2 ring-black rounded"
												>
													{property.status === "WAITING_FOR_CONFIRMATION" && (
														<div className="flex items-center text-[#0075FF]">
															<img
																src={AwaitingIcon}
																alt="Awaiting Confirmation Icon"
																className="mr-2"
															/>
															<span>Awaiting Confirmation</span>
														</div>
													)}
													{property.status === "CONFIRMED" && (
														<div className="flex items-center text-z-dark-moss-green">
															<img
																src={ConfirmedCheckIcon}
																alt="Confirmed Icon"
																className="mr-2"
															/>
															<span>Confirmed</span>
														</div>
													)}
													{property.status === "CANCELLED" && (
														<div className="flex items-center text-[#FF0000]">
															<img
																src={CancelledIcon}
																alt="Cancelled Icon"
																className="mr-2"
															/>
															<span>Canceled</span>
														</div>
													)}
													{property.status === "COMPLETED" && (
														<div className="flex items-center text-z-dark-moss-green">
															<img
																src={ConfirmedCheckIcon}
																alt="Completed Icon"
																className="mr-2"
															/>
															<span>Completed</span>
														</div>
													)}

													<div className="text-black text-[14px] font-medium">
														{property.address.displayAddress}
													</div>

													{showing.properties.length - 1 > 0 && (
														<div className="py-1">
															<hr className="h-1px bg-[#000000]/50" />
														</div>
													)}
												</div>
											);
										})}
									</div>
									<button
										type="button"
										className="flex justify-center items-center gap-2 mt-4 cursor-pointer w-full"
										onClick={() => toggleExpand(showing._id)}
										onKeyUp={(e) =>
											handleKeyUp(e, () => toggleExpand(showing._id))
										}
									>
										<div className="text-center font-semibold text-base text-black">
											Details
										</div>
										<div>
											<img
												src={ArrowDownward}
												alt="Arrow Downward"
												className={`transform transition-transform ${
													expandedShowings.includes(showing._id)
														? "rotate-180"
														: ""
												}`}
											/>
										</div>
									</button>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export { ShowingView };
