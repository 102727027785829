import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import GrayArrowDownIcon from "../../assets/grayArrowDownIcon.svg";
import PropertyAnalysisIcon from "../../assets/icons/propertyAnalysisIcon.svg";
import { mockPropertyCard } from "../../mocks/newMockData/propertyCardData";
import {
	mockStatistics,
	zownForecastListing,
} from "../../mocks/newMockData/zownForecastData";
import { MortgageCalculatore } from "./MortgageCalculatore";
import { PropertyCard } from "./propertyCard";
import { ZownForecast } from "./zownForecast";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { ComparableSales } from "../ListingView/Analysis/comparableSales";

const mockListing = {
	listingPrice: "500000",
	mortgageRate: "3.5",
	minDownPaymentNeeded: 25000,
};

const PropertyAnalysis = () => {
	const [isExpanded, setIsExpanded] = useState(true);
	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};
	return (
		<div className="border bg-white mt-10 border-[#D9D9D9] shadow-[0_2px_12.6px_0_rgba(0,0,0,0.25)] rounded-[30px] xl:py-[45px] py-[40px]">
			<div
				className="text-black font-semibold text-xl flex items-center justify-between w-full cursor-pointer xl:px-10 px-6"
				onClick={toggleSection}
				onKeyDown={handleKeyDown}
			>
				<div className="flex items-center gap-2">
					<img width={40} src={PropertyAnalysisIcon} alt="" />
					<span className="text-[#333] text-3xl">Analysis</span>
				</div>

				<img
					src={GrayArrowDownIcon}
					alt="Toggle Section"
					className={`transition-transform duration-300 ${
						isExpanded ? "rotate-180" : ""
					}`}
				/>
			</div>
			<div
				id="analysis-content"
				className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
					isExpanded ? "max-h-auto" : "max-h-0"
				}`}
			>
				<div className="xl:px-10 px-6 mt-6">
					<hr className="h-px bg-[#F3F3F3] border-0 mb-6" />
					<MortgageCalculatore
						listingPrice={mockListing.listingPrice}
						mortgageRate={mockListing.mortgageRate}
						minDownPaymentNeeded={mockListing.minDownPaymentNeeded}
					/>
					<hr className="h-px bg-[#F3F3F3] border-0 mt-14 mb-16" />
				</div>
				<ZownForecast
					listing={zownForecastListing}
					zownForecast={mockStatistics.zownForecast}
				/>
				<div className="!mt-20">
					<div className="xl:px-10 px-6">
						<hr className="h-px bg-[#F3F3F3] border-0 mb-14" />
						<ComparableSales
							numberComparableSales={32}
							medianSalePrice={1080452}
							avgDom={13}
							mostRecentSale={"2024128"}
						/>
					</div>
					<div className="flex items-center space-x-6 mt-8 relative xl:px-10 px-6">
						<Swiper
							spaceBetween={20}
							slidesPerView={3}
							pagination={false}
							modules={[Navigation]}
							navigation={{
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							}}
							breakpoints={{
								1540: {
									slidesPerView: 3,
								},
								1280: {
									slidesPerView: 2,
								},
								1080: {
									slidesPerView: 1.4,
								},
							}}
						>
							{mockPropertyCard.map((property, index) => (
								<SwiperSlide
									className="p-2"
									key={index}
									style={{ width: "auto" }}
								>
									<PropertyCard
										width="100%"
										imageUrl={property.imageUrl}
										propertyType={property.propertyType}
										status={property.status}
										price={property.price}
										downPayment={property.downPayment}
										date={property.date}
										address={property.address}
										beds={property.beds}
										baths={property.baths}
										sqft={property.sqft}
										similarListings={property.similarListings}
									/>
								</SwiperSlide>
							))}
						</Swiper>
						<div className="browse-listing-slider swiper-button-prev similar !mx-0" />
						<div className="browse-listing-slider swiper-button-next similar !mx-0" />
					</div>
				</div>
			</div>
		</div>
	);
};

export { PropertyAnalysis };
