import { clsx } from "clsx";
import xButtonIcon from "../../../assets/xButtonIcon.svg";
import { useMapFiltersStore } from "../../../stores/filterMapStore";
import GrayArrowDownIcon from "../../../assets/grayArrowDownIcon.svg";
import {
	useCallback,
	useEffect,
	useRef,
	useState,
	type KeyboardEvent,
	type ReactNode,
} from "react";

import "./filterPanelStyles.css";
import {
	DaysOnMarketFilterEnum,
	AgeOfPropertyFilterEnum,
	StyleFilterEnum,
	SquareFootageFilterEnum,
	BasementFilterEnum,
	GarageFilterEnum,
	NumberOfKitchensFilterEnum,
} from "../../../services/exploreListings";

interface DropdownSelectorProps {
	title: string;
	openType: string;
	openDropdownType: string | null;
	dropdownValue: string | null | undefined;
	handleAdvancedFilter?: (option: string) => void;
	toggleDropdown: (
		openType: string,
		e?: KeyboardEvent<HTMLButtonElement>,
	) => void;
	advancedFilterTitle?: ReactNode;
	advancedFilterHandler?: () => void;
}
interface Option {
	label: string;
	value?: string;
}

const daysOnMarketOptions: Option[] = [
	{ label: "Any" },
	{ label: "0-7 Days", value: DaysOnMarketFilterEnum.ZeroToSevenDays },
	{ label: "8-14 Days", value: DaysOnMarketFilterEnum.EightToFourteenDays },
	{ label: "15-30 Days", value: DaysOnMarketFilterEnum.FifteenToThirtyDays },
	{ label: "30+ Days", value: DaysOnMarketFilterEnum.ThirtyPlusDays },
];
const ageOfPropertyOptions: Option[] = [
	{ label: "Any" },
	{
		label: "New (0 - 9 Years)",
		value: AgeOfPropertyFilterEnum.ZeroToNineYears,
	},
	{
		label: "Average (10 - 40 Years)",
		value: AgeOfPropertyFilterEnum.TenToFortyYears,
	},
	{ label: "Old (40+ Years)", value: AgeOfPropertyFilterEnum.FortyPlusYears },
];
const styleOptions: Option[] = [
	{ label: "Any" },
	{ label: "1 1/2 Storey", value: StyleFilterEnum.OneHalfStorey },
	{ label: "2-Storey", value: StyleFilterEnum.TwoStorey },
	{ label: "2 1/2 Storey", value: StyleFilterEnum.TwoHalfStorey },
	{ label: "3 Storey", value: StyleFilterEnum.ThreeStorey },
	{ label: "Apartment", value: StyleFilterEnum.Apartment },
	{ label: "Backsplit 3", value: StyleFilterEnum.BacksplitThree },
	{ label: "Backsplit 4", value: StyleFilterEnum.BacksplitFour },
	{ label: "Backsplit 5", value: StyleFilterEnum.BacksplitFive },
	{ label: "Bungalow", value: StyleFilterEnum.Bungalow },
	{ label: "Bungaloft", value: StyleFilterEnum.Bungaloft },
	{ label: "Chalet", value: StyleFilterEnum.Chalet },
	{ label: "Contemporary", value: StyleFilterEnum.Contemporary },
	{ label: "Garden House", value: StyleFilterEnum.GardenHouse },
	{ label: "Log", value: StyleFilterEnum.Log },
	{ label: "Loft", value: StyleFilterEnum.Loft },
	{ label: "Other", value: StyleFilterEnum.Other },
	{ label: "Sidesplit 3", value: StyleFilterEnum.SidesplitThree },
	{ label: "Sidesplit 4", value: StyleFilterEnum.SidesplitFour },
	{ label: "Sidesplit 5", value: StyleFilterEnum.SidesplitFive },
];

const sqFtOptions: Option[] = [
	{ label: "Any" },
	{ label: "<500", value: SquareFootageFilterEnum.LessThanFiveHundred },
	{
		label: "500-1100",
		value: SquareFootageFilterEnum.FiveHundredToElevenHundred,
	},
	{
		label: "1100-1500",
		value: SquareFootageFilterEnum.ElevenHundredToFifteenHundred,
	},
	{ label: "1500+", value: SquareFootageFilterEnum.FifteenHundredPlus },
];
const basementOptions: Option[] = [
	{ label: "Any" },
	{ label: "Apartment", value: BasementFilterEnum.Apartment },
	{ label: "Unfinished", value: BasementFilterEnum.Unfinished },
	{ label: "Finished", value: BasementFilterEnum.Finished },
];

const garageTypeOptions: Option[] = [
	{ label: "Any" },
	{ label: "Attached", value: GarageFilterEnum.Attached },
	{ label: "Detached", value: GarageFilterEnum.Detached },
];
const numberOfKitchenOptions: Option[] = [
	{ label: "Any" },
	{ label: "1", value: NumberOfKitchensFilterEnum.One },
	{ label: "2", value: NumberOfKitchensFilterEnum.Two },
	{ label: "3+", value: NumberOfKitchensFilterEnum.ThreePlus },
];

const DropdownSelector = ({
	title,
	openType,
	openDropdownType,
	dropdownValue,
	toggleDropdown,
	handleAdvancedFilter,
	advancedFilterTitle,
	advancedFilterHandler,
}: DropdownSelectorProps) => {
	const dropdownRef = useRef<HTMLUListElement | null>(null);
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [renderUpward, setRenderUpward] = useState(false);

	const {
		setDaysOnMarket,
		setAgeOfProperty,
		setStyle,
		setSqft,
		setBasement,
		setGarageType,
		setNumberOfKitchens,
	} = useMapFiltersStore();

	const handleToggleDropdown = (
		openType: string,
		event?: KeyboardEvent<HTMLButtonElement>,
	) => {
		toggleDropdown(openType, event);
		if (renderUpward) {
			setRenderUpward(false);
		}
	};

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (
				dropdownRef.current &&
				buttonRef.current &&
				!dropdownRef.current.contains(event.target as Node) &&
				!buttonRef.current.contains(event.target as Node)
			) {
				toggleDropdown(openType);
			}
		},
		[toggleDropdown, openType],
	);

	const labelsArray = (openType: string): Option[] => {
		switch (openType) {
			case "daysOnMarket":
				return daysOnMarketOptions;
			case "ageOfProperty":
				return ageOfPropertyOptions;
			case "style":
				return styleOptions;
			case "sqFt":
				return sqFtOptions;
			case "basement":
				return basementOptions;
			case "garage":
				return garageTypeOptions;
			case "kitchen":
				return numberOfKitchenOptions;
			// case "selectFilters":
			// 	return advancedFilterOptions;
			default:
				return [];
		}
	};

	const handleOptionSelect = (
		type: string,
		value: string | undefined, // Accept undefined
		event?: KeyboardEvent<HTMLButtonElement | HTMLLIElement>,
	) => {
		if (event) {
			if (event.key !== "Enter" && event.key !== " ") {
				return;
			}
		}

		switch (type) {
			case "daysOnMarket":
				setDaysOnMarket(value || ""); // Store empty string if value is undefined
				break;
			case "ageOfProperty":
				setAgeOfProperty(value || "");
				break;
			case "style":
				setStyle(value || "");
				break;
			case "sqFt":
				setSqft(value || "");
				break;
			case "basement":
				setBasement(value || "");
				break;
			case "garage":
				setGarageType(value || "");
				break;
			case "kitchen":
				setNumberOfKitchens(value || "");
				break;
			case "selectFilters":
				handleAdvancedFilter?.(value || "");
				break;
			default:
				console.warn(`Unhandled type: ${type}`);
				break;
		}

		toggleDropdown(type);
		setRenderUpward(false);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	useEffect(() => {
		if (openDropdownType === openType && dropdownRef.current) {
			const { bottom } = dropdownRef.current.getBoundingClientRect();
			const viewportHeight = window.innerHeight;

			if (bottom > viewportHeight) {
				setRenderUpward(true);
			} else {
				setRenderUpward(false);
			}
		}
	}, [openDropdownType, openType]);

	const getLabelForValue = (
		openType: string,
		value: string | null | undefined,
	): string => {
		const options = labelsArray(openType);
		const option = options.find((opt) => opt.value === value);
		return option ? option.label : "Select";
	};

	return (
		<div className="flex flex-col">
			<div className="relative my-1">
				{advancedFilterTitle && advancedFilterHandler && (
					<div className="w-full px-4 my-2 relative">
						<span className={"text-black"}>{advancedFilterTitle}</span>
						<button
							type="button"
							className="back-button  right-1"
							onClick={advancedFilterHandler}
						>
							<img src={xButtonIcon} alt={"remove-filter"} />
						</button>
					</div>
				)}
				<button
					ref={buttonRef}
					type="button"
					className="dropdown"
					onClick={() => handleToggleDropdown(openType)}
					onKeyDown={(e) => handleToggleDropdown(openType, e)}
				>
					<div className="flex flex-col">
						<div className="text-sm text-[#5D5E5A]">{title}</div>

						{getLabelForValue(openType, dropdownValue)}
					</div>

					<img
						src={GrayArrowDownIcon}
						alt=""
						className={`${
							openDropdownType === openType
								? "transition-transform duration-300 rotate-180"
								: ""
						}`}
					/>
				</button>

				{openDropdownType === openType && (
					<ul
						ref={dropdownRef}
						className={`absolute w-full border shadow-lg border-[#E1E0E3] overflow-auto max-h-[200px] rounded-[12px] bg-white z-50 ${
							renderUpward ? "bottom-full mb-1" : "top-full mt-1"
						}`}
					>
						{labelsArray(openType).map((option, idx, array) => {
							const value = option.value;
							const label = option.label;

							return (
								<li
									key={value || label}
									className={clsx(
										"text-black px-4 py-2  border-b border-b-1 hover:bg-gray-200 cursor-pointer",
										{
											"bg-[#E1E0E3]": label === dropdownValue,
											"rounded-t-[12px] border-[0px]":
												label === dropdownValue && idx === 0,
											"rounded-b-[12px] border-b-[0px]":
												label === dropdownValue &&
												idx === labelsArray(openType).length - 1,
										},
									)}
									onClick={() => handleOptionSelect(openType, value)}
									onKeyDown={(e) => handleOptionSelect(openType, value, e)}
								>
									{label}
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

export { DropdownSelector };
