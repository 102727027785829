import type { PropertyPreferences } from "../interfaces/propertyPreferences.ts";

export const propertyPreferences: PropertyPreferences = {
    beds: [
        { value: null, label: "Any" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5+" },
    ],
    baths: [
        { value: null, label: "Any" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5+" },
    ],
    parking: [
        { value: null, label: "Any" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5+" },
    ],
    dens: [
        { value: null, label: "Any" },
        { value: true, label: "Yes" },
        { value: false, label: "No" },
    ],
};