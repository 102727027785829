import Marquee from "react-fast-marquee";
import { useEffect, useState } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { foundProperties } from "../../services/foundProperties";
import {
	slideUpImage1,
	slideUpImage2,
	slideUpImage3,
	slideUpImage4,
	slideUpImage5,
	slideUpImage6,
	slideUpImage7,
	slideUpImage8,
	slideDownImage1,
	slideDownImage2,
	slideDownImage3,
	slideDownImage4,
	slideDownImage5,
	slideDownImage6,
	slideDownImage7,
	slideDownImage8,
} from "../../assets/static/images/index";

const imagesUp = [
	slideUpImage1,
	slideUpImage2,
	slideUpImage3,
	slideUpImage4,
	slideUpImage5,
	slideUpImage6,
	slideUpImage7,
	slideUpImage8,
];
const imagesDown = [
	slideDownImage1,
	slideDownImage2,
	slideDownImage3,
	slideDownImage4,
	slideDownImage5,
	slideDownImage6,
	slideDownImage7,
	slideDownImage8,
];

const PropertyMatchOverviewView = () => {
	const [numberOfProperties, setNumberOfProperties] = useState<
		number | string | undefined
	>(undefined);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchNumberOfProperties = async () => {
			setLoading(true);

			const timeout = setTimeout(() => {
				setNumberOfProperties("500+");
				setLoading(false);
			}, 3000);

			try {
				const response = await foundProperties();
				clearTimeout(timeout);
				setNumberOfProperties(response.totalCount);
			} catch (error) {
				console.error("Error fetching properties:", error);
				setNumberOfProperties("500+");
			} finally {
				setLoading(false);
			}
		};
		fetchNumberOfProperties();
	}, []);

	return (
		<div className="w-full h-full">
			<div className="mb-12">
				<Marquee direction="left">
					<div className="flex items-center flex-nowrap">
						{imagesUp.map((image, index) => (
							<div className="p-2" key={image}>
								<img
									src={image}
									alt={`${index}`}
									className="w-28 h-28 m-2 rounded-3xl shadow-lg"
								/>
							</div>
						))}
					</div>
				</Marquee>
				<Marquee direction="right">
					<div className="flex items-center flex-nowrap">
						{imagesDown.map((image, index) => (
							<div className="p-2" key={image}>
								<img
									src={image}
									className="w-28 h-28 m-2 rounded-3xl shadow-lg"
									alt={`${index}`}
								/>
							</div>
						))}
					</div>
				</Marquee>
			</div>
			<div className="mx-4">
				<h2 className="text-[44px] text-z-shark font-bold leading-[1.2]">
					We found{" "}
					{loading ? (
						<span className="text-z-dark-moss-green">
							<span className="dot-animation mr-1">.</span>
							<span className="dot-animation mr-1">.</span>
							<span className="dot-animation">.</span>
						</span>
					) : (
						<span className="text-z-dark-moss-green underline">
							{typeof numberOfProperties === "string" ||
							numberOfProperties === undefined
								? numberOfProperties
								: formatPrice(numberOfProperties)}
						</span>
					)}
					<br />
					homes for you!
				</h2>
				<div className="text-xl mt-9 mb-16 font-light text-z-shark">
					Now let's find your perfect match
				</div>
			</div>
		</div>
	);
};

export { PropertyMatchOverviewView };
