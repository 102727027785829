import {
	ImageBrowseListingDemo1,
	ImageBrowseListingDemo2,
	ImageBrowseListingDemo3,
	ImageBrowseListingDemo4,
} from "../../assets/BrowseListing/demo";
import { ListingData } from "../../interfaces/browseListing";

export const browseListingData: ListingData[] = [
	{
		images: [
			ImageBrowseListingDemo1,
			ImageBrowseListingDemo2,
			ImageBrowseListingDemo3,
			ImageBrowseListingDemo4,
			ImageBrowseListingDemo1,
			ImageBrowseListingDemo2,
			ImageBrowseListingDemo3,
			ImageBrowseListingDemo4,
			ImageBrowseListingDemo1,
			ImageBrowseListingDemo2,
			ImageBrowseListingDemo3,
			ImageBrowseListingDemo4,
			ImageBrowseListingDemo1,
			ImageBrowseListingDemo2,
			ImageBrowseListingDemo3,
			ImageBrowseListingDemo4,
			ImageBrowseListingDemo1,
			ImageBrowseListingDemo2,
			ImageBrowseListingDemo3,
			ImageBrowseListingDemo4,
		],
		title: "2356 Brookhurst Rd, Main Listing",
	},
];
