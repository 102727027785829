import { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateBuyerOutingInput } from "../../services/outings";
import { useShowingCartStore } from "../../stores/showingCartStore";
import { getContractUrl } from "../../services/account";
import { LoadingCircle } from "../common";

const ContractView = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [url, setUrl] = useState<string>("");
	const [contractRetrieved, setContractRetrieved] = useState(false);

	const { availableSlots, zlids, setAvailableSlots, setZlids } =
		useShowingCartStore();

	const getContract = useCallback(async () => {
		if (contractRetrieved) {
			return;
		}
		try {
			setLoading(true);
			const response = await getContractUrl(zlids);
			if (response.signUrl) {
				setUrl(response.signUrl);
			} else {
				setContractRetrieved(true);
				await createBuyerOuting();
			}
			setLoading(false);
		} catch (error) {
			console.error("Error confirming showings:", error);
			setLoading(false);
		}
	}, [zlids, contractRetrieved]);
	const createBuyerOuting = useCallback(async () => {
		try {
			setLoading(true);
			await CreateBuyerOutingInput(availableSlots, zlids);
			setAvailableSlots([]);
			setZlids([]);
			setLoading(false);
			navigate("/cart/booking-success");
		} catch (error) {
			console.error("Error confirming showings:", error);
			setLoading(false);
		}
	}, [availableSlots, zlids, setAvailableSlots, setZlids, navigate]);

	useEffect(() => {
		const handleMessage = async (event: MessageEvent) => {
			const receivedData = event.data;
			if (typeof receivedData === "boolean") {
				console.log("Received boolean from child iframe:", receivedData);

				if (receivedData) {
					await getContract();
				} else {
					navigate("/cart/pending-signature");
				}
			}
		};

		window.addEventListener("message", handleMessage, false);
		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, [getContract, navigate]);

	useEffect(() => {
		getContract();
	}, [getContract]);

	return (
		<>
			{loading && (
				<div className="h-full flex justify-center items-center">
					<LoadingCircle />
				</div>
			)}
			{!loading && url && (
				<iframe
					src={url}
					className="absolute w-full h-full"
					title="Contract Viewer"
				/>
			)}
		</>
	);
};

export { ContractView };
