interface IconProps {
	fill?: string;
}
const ChevronDownIcon = ({ fill = "#909090" }: IconProps) => {
	return (
		<svg
			width="18"
			height="9"
			viewBox="0 0 18 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.3897 1.44117C17.3904 1.62672 17.3494 1.81006 17.2699 1.9777C17.1904 2.14535 17.0743 2.29305 16.9302 2.40994L9.47811 8.40886C9.25588 8.59154 8.97712 8.69141 8.68943 8.69141C8.40175 8.69141 8.12299 8.59154 7.90076 8.40886L0.44868 2.1988C0.19504 1.98798 0.0355355 1.68504 0.00525558 1.35662C-0.0250244 1.0282 0.0764008 0.701202 0.287218 0.447563C0.498036 0.193923 0.800977 0.0344186 1.1294 0.00413871C1.45782 -0.0261412 1.78482 0.0752833 2.03846 0.286101L8.69564 5.8379L15.3528 0.472403C15.5351 0.320526 15.7571 0.22405 15.9926 0.194392C16.228 0.164733 16.467 0.203132 16.6813 0.305046C16.8955 0.406961 17.0761 0.568124 17.2017 0.769467C17.3273 0.97081 17.3925 1.20391 17.3897 1.44117Z"
				fill={fill}
			/>
		</svg>
	);
};
export default ChevronDownIcon;
