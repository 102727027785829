import { Button } from "../../common";
import { Modal } from "../../common/modal";
import { useModalStore } from "../../../stores/handleModalStore";

const FinanceSavingsModal = () => {
	const { setFinanceSavingsModal, financeSavingsModal } = useModalStore();
	return (
		<Modal
			closeIcon={true}
			setIsOpen={setFinanceSavingsModal}
			isOpen={financeSavingsModal}
		>
			<div className="flex flex-col">
				<div className="text-z-shark text-xl font-bold">
					Total Household Savings
				</div>
				<p className="text-[#828282] mt-5 leading-5 font-light">
					This is the total savings you've accumulated toward your home goal. We
					calculate the amount needed based on your home goal and show how much
					more is required to reach that target. Along with our Down Payment
					Assistance, this gives you a clear view of your path to homeownership.
				</p>
				<Button
					radius="50px"
					height="md"
					width="full"
					customClassName="font-bold text-lg mt-5"
					onClick={() => setFinanceSavingsModal(false)}
				>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export { FinanceSavingsModal };
