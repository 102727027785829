import clsx from "clsx";
import { Input } from "../input";
import { Button } from "../../common";
import { finance } from "../../../services/finance";
import { formatPrice } from "../../../utils/formatPrice";
import type { Finance } from "../../../interfaces/finance";
import { useFinanceStore } from "../../../stores/financeStore";
import { useModalStore } from "../../../stores/handleModalStore";
import { useEffect, useRef, useState, type ChangeEvent } from "react";
import { ChevronDownIcon, CreditScoreIcon2 } from "../../../assets/icons";
// import { fetchCurrentQualification, postCurrentQualification } from "../../../services/currentQualification";

const CurrentQualification = () => {
	const {
		incomePrice,
		savingPrice,
		creditScoreValue,
		totalDebt,
		completedSteps,
		setIncomePrice,
		setSavingPrice,
		setCreditScoreValue,
		completeStep,
		setTotalPriceCurrentQualification,
		totalPriceCurrentQualification,
		resetSteps,
		setTotalDebt,
	} = useFinanceStore();
	const { setDebtOverPageState } = useModalStore();
	const [isOpen, setIsOpen] = useState(false);
	const qualificationRef = useRef<HTMLDivElement>(null);

	const [debtError] = useState("");
	const [creditScoreError, setCreditScoreError] = useState("");

	const handleInputChange = (
		e: ChangeEvent<HTMLInputElement>,
		type: string,
	) => {
		let value = e.target.value.replace(/[^\d]/g, "") || "0";

		const formattedValue = formatPrice(Number.parseInt(value));

		switch (type) {
			case "income":
				setIncomePrice(formattedValue);
				break;
			case "savings":
				setSavingPrice(formattedValue);
				break;
			case "creditScore": {
				const score = Number.parseInt(value);
				if (score <= 0 || Number.isNaN(score)) {
					setCreditScoreError("Enter your credit score");
					setCreditScoreValue("");
				} else {
					if (value.length > 3) {
						value = value.slice(0, 3);
					}
					setCreditScoreError("");
					setCreditScoreValue(formatPrice(value));
				}
				break;
			}
			default:
				break;
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (creditScoreValue === "") {
			setCreditScoreError("Enter your credit score");
		} else {
			setCreditScoreError("");
		}
	}, [creditScoreValue, setCreditScoreError]);

	const handleSubmit = () => {
		const score = Number.parseInt(creditScoreValue || "0");
		let isValid = true;

		if (score <= 0) {
			setCreditScoreError("Enter your credit score");
			isValid = false;
		} else {
			setCreditScoreError("");
		}
		return isValid;
	};

	const handleEdit = () => {
		setDebtOverPageState(true);
	};

	const parseFormattedValue = (value: string) => {
		return Number.parseInt(value.replace(/[^\d]/g, "") || "0");
	};

	const calculateTotal = () => {
		const income = parseFormattedValue(incomePrice);
		const savings = parseFormattedValue(savingPrice);
		const creditScore = parseFormattedValue(creditScoreValue);
		const debt = parseFormattedValue(totalDebt);

		const total = income + savings + creditScore + debt;

		setTotalPriceCurrentQualification(total === 0 ? "" : total.toString());
		return formatPrice(total);
	};

	useEffect(() => {
		calculateTotal();
	}, [incomePrice, savingPrice, creditScoreValue, totalDebt]);

	const handleSaveAction = async () => {
		const payload: Finance = {
			totalPriceCurrentQualification,
			incomePrice,
			savingPrice,
			creditScoreValue,
			totalDebt,
		};
		if (handleSubmit()) {
			const total = calculateTotal();
			completeStep(2);

			console.log(payload);
			await finance(payload);

			setTotalPriceCurrentQualification(total);
			setIncomePrice(incomePrice);
			setSavingPrice(savingPrice);
			setCreditScoreValue(creditScoreValue);
			setTotalDebt(totalDebt);
		}
	};

	useEffect(() => {
		if (calculateTotal() === totalPriceCurrentQualification) {
			resetSteps(2);
		}
	}, [totalPriceCurrentQualification]);

	useEffect(() => {
		if (qualificationRef.current) {
			qualificationRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [completedSteps]);

	// useEffect(() => {
	//   const fetchCurrentDate = async() => {
	//     try {
	//       const qualificationData = await fetchCurrentQualification();
	//       setTotalPriceCurrentQualification(qualificationData.totalPriceCurrentQualification)
	//       setIncomePrice(qualificationData.incomePrice);
	//       setSavingPrice(qualificationData.savingPrice);
	//       setCreditScoreValue(qualificationData.creditScoreValue);
	//       setTotalDebt(qualificationData.totalDebt);
	//     } catch (error) {
	//       console.log(error);

	//     }
	//   }

	//   calculateTotal()
	//   fetchCurrentDate()
	// },[])

	return (
		<>
			{completedSteps[2] ? (
				<div
					className={clsx(
						"bg-[#F5FEEF] text-z-shark w-full rounded-[20px] shadow-[0_0_6px_0_rgba(0,0,0,0.20)] relative flex items-center flex-col py-6 px-3 overflow-hidden transition-height duration-300 ease-in-out",
						isOpen ? "h-[875px]" : "h-[190px]",
					)}
				>
					<div
						className={clsx(
							"absolute px-5 left-1/2 -translate-x-1/2 bottom-3 transition-all duration-300",
							isOpen && "rotate-180",
						)}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								setIsOpen((prev) => !prev);
							}
						}}
						onClick={() => setIsOpen((prev) => !prev)}
					>
						<ChevronDownIcon fill="#32302F" />
					</div>
					<div className="font-semibold">
						🎉 Your current pre-qualification is
					</div>
					<span className="text-4xl font-bold mt-2">
						${totalPriceCurrentQualification}
					</span>
					<div
						className={clsx(
							"flex flex-col w-full mt-8",
							isOpen ? "space-y-5" : "space-y-10",
						)}
					>
						<span className="text-lg font-semibold text-center">
							Where you are today
						</span>
						<div className="space-y-9">
							<Input
								inputType="tel"
								label="💸 Your current income is"
								placeholder="Enter Income"
								value={`$${incomePrice}`}
								onChange={(e) => handleInputChange(e, "income")}
							/>
							<Input
								inputType="tel"
								label="💰Your current savings are "
								placeholder="Enter Savings"
								value={`$${savingPrice}`}
								onChange={(e) => handleInputChange(e, "savings")}
							/>
							<Input
								inputType="tel"
								label="Your current credit score is"
								labelIcon={CreditScoreIcon2}
								placeholder="Enter Credit Score"
								value={`${creditScoreValue}`}
								isError={creditScoreError}
								onChange={(e) => handleInputChange(e, "creditScore")}
							/>
							<Input
								inputType="tel"
								readOnly={true}
								onFocus={handleEdit}
								label="📈 Your monthly debt is"
								placeholder="Enter Debt"
								value={totalDebt}
								isError={debtError}
							/>
						</div>
					</div>
					<Button
						radius="60px"
						customClassName="font-bold text-lg mt-12"
						width="full"
						height="md"
						onClick={handleSaveAction}
					>
						Save changes
					</Button>
					<span className="text-[#979797] text-center text-[10px] mt-4">
						Number based on information pulled from Flinks and your inputs. May
						vary after pre-approval.
					</span>
				</div>
			) : (
				<div
					ref={qualificationRef}
					className="bg-[#F5FEEF] text-z-shark w-full h-full flex justify-center items-center flex-col py-6 px-3"
				>
					<div className="font-semibold">
						🎉 Your current pre-qualification is
					</div>
					<span className="text-4xl font-bold mt-2">
						{totalPriceCurrentQualification === ""
							? "Pending"
							: `$${totalPriceCurrentQualification}`}
					</span>
					<div className="flex flex-col w-full space-y-5 mt-8">
						<span className="text-lg font-semibold text-center">
							Where you are today
						</span>

						<div className="space-y-9">
							<Input
								inputType="tel"
								label="💸 Your current income is"
								placeholder="Enter Income"
								value={`$${incomePrice || "0"}`}
								onChange={(e) => handleInputChange(e, "income")}
							/>
							<Input
								inputType="tel"
								label="💰Your current savings are "
								placeholder="Enter Savings"
								value={`$${savingPrice || "0"}`}
								onChange={(e) => handleInputChange(e, "savings")}
							/>
							<Input
								inputType="tel"
								label="Your current credit score is"
								labelIcon={CreditScoreIcon2}
								placeholder="Enter Credit Score"
								value={`${creditScoreValue}`}
								isError={creditScoreError}
								onChange={(e) => handleInputChange(e, "creditScore")}
							/>
							<Input
								readOnly={true}
								onFocus={handleEdit}
								label="📈 Your monthly debt is"
								placeholder="Enter Debt"
								value={totalDebt || "0"}
								isError={debtError}
							/>
						</div>
					</div>
					<Button
						radius="60px"
						customClassName="font-bold text-lg mt-12"
						width="330px"
						height="md"
						onClick={handleSaveAction}
					>
						Save
					</Button>
					<span className="text-[#979797] text-center text-[10px] mt-4">
						Number based on information pulled from Flinks and your inputs. May
						vary after pre-approval.
					</span>
				</div>
			)}
		</>
	);
};

export { CurrentQualification };
