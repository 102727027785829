import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import GrayArrowDownIcon from "../../assets/grayArrowDownIcon.svg";
import SimilarActiveListingsIcon from "../../assets/icons/similarActiveListingsIcon.svg";
import { mockPropertyCard } from "../../mocks/newMockData/propertyCardData";
import { PropertyCard } from "./propertyCard";
import "swiper/css";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";

const SimilarActiveListings = () => {
	const [isExpanded, setIsExpanded] = useState(true);
	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	return (
		<div className="border bg-white border-[#D9D9D9] shadow-[0_2px_12.6px_0_rgba(0,0,0,0.25)] rounded-[30px] xl:py-[45px] py-[40px] mt-10">
			<div
				className="text-black xl:px-10 px-6 font-semibold text-xl flex items-center justify-between w-full cursor-pointer"
				onClick={toggleSection}
				onKeyDown={handleKeyDown}
				aria-expanded={isExpanded}
			>
				<div className="flex items-center gap-5">
					<img width={40} src={SimilarActiveListingsIcon} alt="" />
					<span className="text-[#333] text-3xl">Similar Active Listings</span>
				</div>

				<img
					src={GrayArrowDownIcon}
					alt="Toggle Section"
					className={`transition-transform duration-300 ${
						isExpanded ? "rotate-180" : ""
					}`}
				/>
			</div>
			<div
				className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
					isExpanded ? "max-h-auto" : "max-h-0"
				}`}
			>
				<div className="xl:px-10 px-6">
					<div className="pb-5 mb-7 border-b border-[#F3F3F3] mt-7" />
				</div>
				<div className="flex items-center relative xl:px-10 px-6">
					<Swiper
						spaceBetween={20}
						slidesPerView={3}
						pagination={false}
						modules={[Navigation]}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
						}}
						breakpoints={{
							1535: {
								slidesPerView: 4,
							},
							1280: {
								slidesPerView: 3,
							},
							1080: {
								slidesPerView: 3,
							},
						}}
					>
						{mockPropertyCard.map((property, index) => (
							<SwiperSlide
								className="p-2"
								key={index}
								style={{ width: "auto" }}
							>
								<PropertyCard
									width="100%"
									key={index}
									imageUrl={property.imageUrl}
									propertyType={property.propertyType}
									status={property.status}
									price={property.price}
									downPayment={property.downPayment}
									date={property.date}
									address={property.address}
									beds={property.beds}
									baths={property.baths}
									sqft={property.sqft}
									similarListings={property.similarListings}
								/>
							</SwiperSlide>
						))}
					</Swiper>
					<div className="browse-listing-slider swiper-button-prev similar" />
					<div className="browse-listing-slider swiper-button-next similar" />
				</div>
			</div>
		</div>
	);
};

export default SimilarActiveListings;
