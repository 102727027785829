import { FaSearch } from "react-icons/fa";
import { ListCard } from "./Card/listCard";
import { SearchField } from "./searchField";
import { LoadingCircle } from "../common/loading";
import { SortOptions } from "../common/sortOptions";
import { SearchMapView } from "./SearchMap/searchMap";
import { useNavbarStore } from "../../stores/navBarStatus";
import { NoListingsCard } from "./NoListings/noListingsCard";
import { useFilterPanelStore } from "../../stores/filterStore";
import { useMapFiltersStore } from "../../stores/filterMapStore";
import { useSearchedCitiesStore } from "../../stores/exploreListings";
import { mapPropertyTypeForPost } from "../common/FilterPanel/filterPanel";
import { convertToNumberAndMultiplyBy100 } from "../../utils/convertAmount";
import type { BuyerMatchingPreferencesPropertyTypeEnum } from "../../interfaces/buyerMatchingPreferences";
import {
	useFilteredPropertiesStore,
	useListingStore,
} from "../../stores/exploreListings";
import {
	useCallback,
	useEffect,
	useRef,
	useState,
	type TouchEvent,
	type MouseEvent,
} from "react";
import {
	type SearchListingInterface,
	fetchExploreListings,
	mapStringToEnum,
	parseBasement,
	parseDaysOnMarket,
	parseGarage,
	parseNumKitchen,
	parseStyle,
	transformFilterValues,
} from "../../services/exploreListings";

import "swiper/css";
import "./exploreListingsViewStyles.css";
import { useMapStore } from "../../stores/mapCordinations";
import { fetchAccountData } from "../../services/account";
import { useUserProfileStore } from "../../stores/profileStore";

const BuyerExploreListingsView = () => {
	const [loading, setLoading] = useState(false);
	const [properties, setProperties] = useState<SearchListingInterface[]>([]);
	const [showSortOptions, setShowSortOptions] = useState(false);
	const [selectedSortOption, setSelectedSortOption] = useState("Default");

	const [mapOpen, setMapOpen] = useState<boolean>(false);
	const { filterPanelVisible } = useFilterPanelStore();
	const { showNavbar, hideNavbar } = useNavbarStore();
	const { mapLatitude, mapLongitude, mapZoom } = useMapStore();
	const { setListings } = useListingStore();
	const optionsRef = useRef<HTMLDivElement | null>(null);
	const { filteredProperties, setFilteredProperties } =
		useFilteredPropertiesStore();
	const {
		minPrice,
		maxPrice,
		beds,
		baths,
		dens,
		parking,
		propertyType,
		numberOfKitchens,
		garageType,
		basement,
		style,
		daysOnMarket,
		mapSelectedCities,
		reloadMapCities,
		setReloadMapCities,
		setSortFilter,
		sortFilter,
	} = useMapFiltersStore();
	const { cities } = useSearchedCitiesStore();

	const { setUserProfile } = useUserProfileStore();

	const propertiesToDisplay: SearchListingInterface[] =
		filteredProperties?.length > 0 ? filteredProperties : properties;

	const toggleMap = () => {
		setMapOpen(!mapOpen);
	};

	const toggleOptions = () => {
		setShowSortOptions(!showSortOptions);
	};

	const [startY, setStartY] = useState<number | null>(null);

	const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
		setStartY(event.touches[0].clientY);
	};
	useEffect(() => {
		if (mapOpen) {
			setSelectedSortOption("Default");
		}
	}, [mapOpen]);
	const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
		if (startY !== null) {
			const currentY = event.touches[0].clientY;
			if (startY - currentY > 50) {
				setMapOpen(false);
				setStartY(null);
			}
		}
	};

	const handleMouseMove = (event: MouseEvent) => {
		if (startY !== null) {
			const currentY = event.clientY;
			if (startY - currentY > 50) {
				setMapOpen(false);
				setStartY(null);
			}
		}
	};

	const handleMouseDown = (event: MouseEvent) => {
		setStartY(event.clientY);
	};

	const fetchProperties = useCallback(async () => {
		setLoading(true);
		const fetchedProperties = await fetchExploreListings(
			sortFilter.page,
			sortFilter.limit,
			sortFilter.sortBy,
			sortFilter.sortOrder,
			mapLongitude ?? undefined,
			mapLatitude ?? undefined,
			mapZoom ?? undefined,
			cities && cities.length > 0 ? (cities[0] as "") : "",

			{
				numberOfBedrooms: beds.length > 0 ? transformFilterValues(beds) : null,
				numberOfBathrooms:
					baths.length > 0 ? transformFilterValues(baths) : null,

				numberOfParkingSpaces:
					parking.length > 0 ? transformFilterValues(parking) : null,

				dens: dens,
				priceRange: [
					convertToNumberAndMultiplyBy100(minPrice.toString()),
					convertToNumberAndMultiplyBy100(maxPrice.toString()),
				],
				propertyType:
					propertyType.length > 0
						? propertyType
								.map(mapPropertyTypeForPost)
								.filter(
									(pt): pt is BuyerMatchingPreferencesPropertyTypeEnum =>
										pt !== null,
								)
						: null,

				numKitchens: parseNumKitchen(numberOfKitchens),
				garage: parseGarage(garageType),
				daysOnMarket: parseDaysOnMarket(daysOnMarket),
				style: parseStyle(style),
				basement: parseBasement(basement),
				selectedCities:
					mapSelectedCities.length > 0
						? mapStringToEnum(mapSelectedCities)
						: null,
			},
		);

		setListings(fetchedProperties);
		setProperties(fetchedProperties);
		setLoading(false);
	}, [
		sortFilter,
		cities,
		setListings,
		beds,
		baths,
		dens,
		parking,
		minPrice,
		maxPrice,
		propertyType,
		numberOfKitchens,
		garageType,
		daysOnMarket,
		style,
		basement,
		mapSelectedCities,
		mapLatitude,
		mapLongitude,
		mapZoom,
	]);

	useEffect(() => {
		const fetchNewProperties = async () => {
			const accountData = await fetchAccountData();

			await fetchProperties();

			if (accountData) {
				setUserProfile(accountData);
			}
		};

		if (reloadMapCities) {
			setFilteredProperties([]);
			fetchNewProperties();

			setReloadMapCities(false);
		}
	}, [
		reloadMapCities,
		setReloadMapCities,
		setFilteredProperties,
		fetchProperties,
		setUserProfile,
	]);

	useEffect(() => {
		const initializePage = async () => {
			const accountData = await fetchAccountData();
			await fetchProperties();

			if (accountData) {
				setUserProfile(accountData);
			}
		};

		initializePage();
	}, [fetchProperties, setUserProfile]);

	useEffect(() => {
		mapOpen && !filterPanelVisible ? hideNavbar() : showNavbar();
	}, [mapOpen, hideNavbar, showNavbar, filterPanelVisible]);

	useEffect(() => {
		if (showSortOptions && optionsRef.current) {
			const optionsRect = optionsRef.current.getBoundingClientRect();
			const isOffScreen = optionsRect.right > window.innerWidth;

			if (isOffScreen) {
				optionsRef.current.style.left = `-${
					optionsRect.right - window.innerWidth + 20
				}px`;
			}
		}
	}, [showSortOptions]);

	useEffect(() => {
		const defaultPage = "1";
		const defaultLimit = "100";

		switch (true) {
			case selectedSortOption === "Default": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "desc",
				});
				break;
			}
			case selectedSortOption === "Newest": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "desc",
				});
				break;
			}
			case selectedSortOption === "Oldest": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "asc",
				});
				break;
			}
			case selectedSortOption === "Price Low to High": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "price",
					sortOrder: "asc",
				});
				break;
			}
			case selectedSortOption === "Price High to Low": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "price",
					sortOrder: "desc",
				});
				break;
			}
			default:
				break;
		}
	}, [selectedSortOption, setFilteredProperties, setSortFilter]);

	return (
		<div
			className={`relative ${mapOpen && "overflow-hidden h-[100vh]"} ${!mapOpen && "pb-24"}`}
		>
			{mapOpen && (
				<div className="h-[100vh]">
					<SearchMapView />
				</div>
			)}

			<div
				className={`green-header ${
					mapOpen ? "-translate-y-[120vh]" : "translate-y-0"
				}`}
			/>

			<div
				className={`search-field-container ${
					window.bridge ? "pt-bridge-inset-top" : "pt-10"
				} z-20`}
			>
				<SearchField />
			</div>

			<div
				onClick={toggleMap}
				onKeyDown={toggleMap}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				className={`show-map-tab-map ${
					mapOpen
						? "fixed w-full bottom-0 h-24 overflow-hidden"
						: "absolute -translate-y-[3vh] show-map-tab-height top-[22vh]"
				}`}
			>
				{mapOpen && (
					<hr className="w-1/4 absolute top-3 border-t-4 border-gray-300" />
				)}
				<div className="flex items-center">
					{!mapOpen && <FaSearch className="text-black mr-2" />}
					<span className="text-black font-[550]">
						{mapOpen
							? `${filteredProperties.length} Homes In Search`
							: "Show Map"}
					</span>
				</div>
			</div>

			{!mapOpen && (
				<div className="listings-container translate-y-0">
					<div
						className={`w-full flex flex-col gap-4 relative ${
							filterPanelVisible ? "overflow-hidden" : "overflow-scroll"
						}`}
					>
						<div className="flex items-center justify-between p-4 shadow-sm">
							<span className="text-black font-semibold">
								{propertiesToDisplay?.length} Listings
							</span>
							<SortOptions
								showOptions={showSortOptions}
								optionsRef={optionsRef}
								onToggleOptions={toggleOptions}
								selectedOption={selectedSortOption}
								setSelectedOption={setSelectedSortOption}
							/>
						</div>
						{!loading &&
							propertiesToDisplay.length > 0 &&
							propertiesToDisplay.map((property) => (
								<div key={property.listingId} className="px-2">
									<ListCard key={property.listingId} property={property} />
								</div>
							))}

						{!loading && propertiesToDisplay.length === 0 && <NoListingsCard />}
						{loading && (
							<div className="flex items-center justify-center h-full w-full">
								<LoadingCircle />
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export { BuyerExploreListingsView };
