import { PercentageOverAskingPrice } from "../../interfaces/statistics";

export const mockPercentageOverAskingPrice: PercentageOverAskingPrice = {
	"0-7 days": {
	  "under -10 %": 5,
	  "-10 to -5": 10,
	  "-5 to 0": 15,
	  "0 to 5": 35,
	  "5 to 10": 25,
	  "over 10 %": 10,
	},
	"7-14 days": {
	  "under -10 %": 8,
	  "-10 to -5": 12,
	  "-5 to 0": 18,
	  "0 to 5": 30,
	  "5 to 10": 22,
	  "over 10 %": 10,
	},
	"14-30 days": {
	  "under -10 %": 10,
	  "-10 to -5": 15,
	  "-5 to 0": 20,
	  "0 to 5": 25,
	  "5 to 10": 20,
	  "over 10 %": 10,
	},
	"30+ days": {
	  "under -10 %": 20,
	  "-10 to -5": 25,
	  "-5 to 0": 25,
	  "0 to 5": 15,
	  "5 to 10": 10,
	  "over 10 %": 5,
	},
	average_dom: 12,
	suggested_offer: 450000,
  };