import { Button } from "../../common";
import { Modal } from "../../common/modal";
import { useModalStore } from "../../../stores/handleModalStore";

const BuyHomePlanModal = () => {
	const { homeBuyingPlanModal, setHomeBuyingPlanModal } = useModalStore();
	return (
		<Modal
			closeIcon={true}
			setIsOpen={setHomeBuyingPlanModal}
			isOpen={homeBuyingPlanModal}
		>
			<div className="flex flex-col">
				<div className="text-z-shark text-xl font-bold">
					Your Home Buying Plan
				</div>
				<p className="text-[#828282] mt-5 leading-5 font-light">
					This plan outlines what you need to do to afford a home, whether it's
					at your current buying power or a higher one. We'll suggest steps to
					increase your buying power if desired. Set your home goal by choosing
					your city, price range, and timeline, and we'll guide you on what
					needs to be done to reach it.
				</p>
				<Button
					radius="50px"
					height="md"
					width="full"
					customClassName="font-bold text-lg mt-5"
					onClick={() => setHomeBuyingPlanModal(false)}
				>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export { BuyHomePlanModal };
