import { useState } from 'react';
import DatePicker from 'react-mobile-datepicker';

interface DatePickerProps {
  date: string
  setDate: (selectDate: string) => void
  isOpen: boolean
  setIsOpen:(isOpen:boolean) => void
}
const DatePickerComponent = ({setDate, isOpen, setIsOpen }:DatePickerProps) => {
  const [time, setTime] = useState<Date>(new Date());
  const handleChange = (selectedTime: Date) => {
    setTime(selectedTime);
  };

  const monthMap = {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
};
const formatMonthYear = (date: Date) => {
  const month = monthMap[(date.getMonth() + 1).toString() as keyof typeof monthMap];
  const year = date.getFullYear();
  return `${month}, ${year}`;
};

const dateConfig = {
  month: {
    format: (value: Date) => monthMap[(value.getMonth() + 1).toString() as keyof typeof monthMap],
    caption: 'Mon',
    step: 1,
  },
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
};
  return (
      <div className="absolute top-0">
        <DatePicker
          value={time}
          theme={'ios'}
          isOpen={isOpen}
          onChange={handleChange}
          showHeader={false}
          showFooter={false}
          onCancel={() => {
            setDate(formatMonthYear(time))
            setIsOpen(false) 
          }}
          dateConfig={dateConfig}
        />
      </div>
  );
};

export {DatePickerComponent};
