import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authProvider";
import ZownBlueWideIcon from "../assets/zown-logo-blue-wide.svg";
import DleteAccountMissYouImage from "../assets/static/images/delete-account-miss-you.webp";

const DeleteAccountMissYouView = () => {
	const { setAuthenticated } = useAuth();

	const navigate = useNavigate();
	return (
		<div className="h-full flex justify-center items-center text-black space-y-8 pt-4 px-4">
			<div className="bg-white rounded-[16px] p-4 space-y-4 max-w-[375px]">
				<img src={ZownBlueWideIcon} alt="" className="w-[105px]" />
				<div className="font-medium text-2xl">We'll miss you!</div>
				<div>
					Your account is now disabled. Your data will be deleted within 30
					days.
				</div>
				<img src={DleteAccountMissYouImage} alt="" className="w-full" />
				<div className="w-full flex justify-end">
					<button
						type="button"
						onClick={() => {
							setAuthenticated(false);
							navigate("/");
						}}
						className="rounded-full font-bold text-white bg-z-dark-moss-green py-3 px-14"
					>
						Close
					</button>
				</div>
			</div>
		</div>
	);
};

export { DeleteAccountMissYouView };
