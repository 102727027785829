import { type FormEvent, useState } from "react";
import xButtonIcon from "../../../assets/xButtonIcon.svg";
import { useAddressLookupStore } from "../../../stores/addressLookupStore";
import { addressLookupSearchQuery } from "../../../services/addressLookup";

export const AddressLookupModal = () => {
	const [unitNumber, setUnitNumber] = useState<string>("");
	const [error, setError] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const {
		setSelectedAddress,
		setAddressLookupModalOpen,
		selectedAddress,
		setSuggestion,
	} = useAddressLookupStore();

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		setError(false);
		setIsDisabled(true);
		const combinedAddress = `${unitNumber}-${selectedAddress}`;
		const response = await addressLookupSearchQuery(combinedAddress);

		if (response?.valid_multi_unit_building_address) {
			if (response.Items.length > 0) {
				setSelectedAddress(combinedAddress);

				setSuggestion(response.Items[0]);
				setAddressLookupModalOpen(false);
			}
		} else {
			setError(true);
			setIsDisabled(false);
		}

		setUnitNumber("");
		setIsDisabled(false);
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 px-4">
			<div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative">
				<button
					type="button"
					className="absolute top-4 right-4"
					onClick={() => setAddressLookupModalOpen(false)}
				>
					<img src={xButtonIcon} alt="" />
				</button>

				<h2 className="text-2xl font-semibold text-center mb-4">
					Enter Unit Number
				</h2>

				<input
					type="text"
					className={`w-full rounded-[4.28px] px-3 py-3 bg-[#F9F9F9] ${
						error ? "border-red-500" : "border-gray-300"
					}`}
					value={unitNumber}
					onChange={(e) => setUnitNumber(e.target.value)}
				/>
				{error && (
					<p className="text-red-500 text-sm mt-1">
						Invalid unit number or address
					</p>
				)}

				<button
					type="button"
					onClick={handleSubmit}
					disabled={isDisabled || !selectedAddress}
					className={`w-full mt-4 bg-z-dark-moss-green font-bold text-white py-3.5 rounded-full ${
						isDisabled || !selectedAddress
							? "opacity-50 cursor-not-allowed"
							: ""
					}`}
				>
					Submit
				</button>
			</div>
		</div>
	);
};
