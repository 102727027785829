import { BuyerExploreListingsView } from "./buyerExploreListingsView";

const ExploreListingView = () => {
	return (
		<div>
			<BuyerExploreListingsView />
		</div>
	);
};

export { ExploreListingView };
