import { create } from "zustand"

interface FlinksState {
    connectFlinks:boolean
    setConnectFlinks: (connectFlinks:boolean) => void
}

const useFlinksStore = create<FlinksState>((set) => ({
    connectFlinks:false,
    setConnectFlinks:(connectFlinks:boolean) => set({connectFlinks}),
}))

export {useFlinksStore}