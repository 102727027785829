import { universalFetch } from "./common";
import type { Finance } from "../interfaces/finance";

export const finance = async (payload: Finance): Promise<void> => {
  try {
    const response = await universalFetch("/finance", "POST", {
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("finance response:", response);
  } catch (error) {
    console.error("Failed to fetch finance", error);
    throw error;
  }
};
