import { Button } from "../../common";
import { Modal } from "../../common/modal";
import { useModalStore } from "../../../stores/handleModalStore";

const FinanceIncomeModal = () => {
	const { setFinanceIncomeModal, financeIncomeModal } = useModalStore();
	return (
		<Modal
			closeIcon={true}
			setIsOpen={setFinanceIncomeModal}
			isOpen={financeIncomeModal}
		>
			<div className="flex flex-col">
				<div className="text-z-shark text-xl font-bold">
					Annual Household Income
				</div>
				<p className="text-[#828282] mt-5 leading-5 font-light">
					This is the minimum annual household income required to qualify for a
					mortgage based on your home goal. You can add multiple people to your
					application to increase the total income and improve your chances of
					qualifying for the home you want.
				</p>
				<Button
					radius="50px"
					height="md"
					width="full"
					customClassName="font-bold text-lg mt-5"
					onClick={() => setFinanceIncomeModal(false)}
				>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export { FinanceIncomeModal };
