import { useNavigate } from "react-router-dom";
import { BungalowHouse } from "../../assets/FinancesImg";
import WhiteArrowRightIcon from "../../assets/whiteRightArrow.svg";
import { Button } from "../common";
import { convertToFormattedAmount } from "../../utils/convertAmount";
import { formatPrice } from "../../utils/formatPrice";

interface CmaBoxProps {
	address: string;
	zlid: string;
	listingPrice: string;
	valuation: string;
}
const CmaInstant = ({ address, zlid, listingPrice, valuation }: CmaBoxProps) => {
	const navigate = useNavigate();

	const listPriceNumber = convertToFormattedAmount(
		Number.parseFloat(listingPrice),
	);
	const valuationNumber = Number.parseFloat(valuation);

	const isUnderlisted = listPriceNumber < valuationNumber;
	const label =
		valuationNumber === 0 || Number.isNaN(valuationNumber)
			? "N/A"
			: isUnderlisted
				? "Underlisted"
				: "Overlisted";
	const labelColor = isUnderlisted ? "#6ECE35" : "#FF6B6B";
	const labelBgColor = isUnderlisted ? "#DEF2D3" : "#FFE5E5";

	return (
		<div className="bg-[#EEF7E9] rounded-2xl lg:py-6 lg:px-7 p-4 grid xl:grid-cols-[190px_minmax(0,1fr)] xl:gap-10">
			<div className="flex xl:flex-col justify-between xl:justify-start">
				<div className="flex flex-col">
					<div className="font-medium sm:text-base text-xs text-z-shark">
					{address}
					</div>
					{/* {label !== "N/A" && ( */}
						<div
							// style={{
							// 	color: labelColor,
							// 	borderColor: labelColor,
							// 	backgroundColor: labelBgColor,
							// }}
							 className="border bg-[#DEF2D3] font-extrabold mt-2 lg:mb-5 mb-3 w-fit border-[#34A853] px-6 py-1.5 rounded lg:text-[#34A853] text-[#6ECE35]">
							{/* {label} */}
							Underlisted
						</div>
					{/* )} */}
				</div>
				<Button
					onClick={() => navigate(`/listing/${zlid}/cma`)}
					customClassName="justify-between h-[44px] sm:text-base text-sm sm:w-[190px] w-auto sm:p-4 p-1.5"
				>
					Instant CMA
					<img src={WhiteArrowRightIcon} className="sm:ml-0 ml-2" alt="CMA" />
				</Button>
			</div>
			{/* <div className="flex w-1/2 gap-3 mb-3">
					<div className="bg-z-abbey w-full p-2 rounded-md">
						<div className="text-xs">List Price</div>
						<div className="font-extrabold text-lg">
							${formatPrice(convertToFormattedAmount(listingPrice))}
						</div>
					</div>
					{valuationNumber !== 0 && !Number.isNaN(valuationNumber) && (
						<div className="bg-z-prussian-blue w-full text-white p-2 rounded-md">
							<div className="text-xs">Estimated Value</div>
							<div className="font-extrabold text-lg">
								${formatPrice(valuation)}
							</div>
						</div>
					)}
				</div> */}
			<div className="bg-[#CDEDBB] rounded-2xl px-5 py-[18px] relative lg:space-y-3 space-y-1 overflow-hidden">
				<div className="text-z-shark sm:text-sm text-xs font-normal">
					Estimated Value
				</div>
				<div className="font-bold text-z-shark sm:text-[28px] text-xl">
					${formatPrice(1513500)}
				</div>
				<div className="text-white bg-[#34A853] space-x-1 px-2 py-1 w-fit flex items-center rounded-full">
					<img
						width={12}
						className="-rotate-90"
						src={WhiteArrowRightIcon}
						alt="CMA"
					/>
					<span className="sm:text-sm text-xs">4.5%</span>
				</div>
				<div className="absolute lg:-top-4 lg:-right-4 top-0 right-0 !mt-0">
					<img
						className="mix-blend-darken lg:w-[230px] w-[150px]"
						src={BungalowHouse}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};

export { CmaInstant };
