import clsx from "clsx";

interface PropertyDetailsProps {
	icon?: string;
	label: string;
	value: string;
	customClassName?: string;
}

export const PropertyDetails = ({
	icon,
	label,
	value,
	customClassName,
}: PropertyDetailsProps) => (
	<div
		className={clsx("flex items-center gap-1 text-[#7D7D7D]", customClassName)}
	>
		{icon && <img src={icon} alt={label} />}
		<div className="text-sm">{value}</div>
	</div>
);

export const UnderlistedChip = () => (
	<div className="flex items-center justify-center gap-1 p-1 text-[#7D7D7D] bg-[#DEF2D3] border border-[#6ECE35] rounded-md">
		<div className="text-xs text-[#6ECE35] font-extrabold">Underlisted</div>
	</div>
);
