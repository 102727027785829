import moment from "moment";
import BathIcon from "../../assets/bathIcon.svg";
import BedIcon from "../../assets/bedIcon.svg";
import { HeartIcon } from "../../assets/heartIcon";
import SqftIcon from "../../assets/sqftIcon.svg";
import type { PropertyCardProps } from "../../interfaces/newInterfaces/propertyCard";
import { formatPrice } from "../../utils/formatPrice";

const PropertyCard = ({
	imageUrl,
	propertyType,
	status,
	price,
	downPayment,
	date,
	address,
	beds,
	baths,
	sqft,
	similarListings,
	width = "346px",
}: PropertyCardProps) => {
	return (
		<div
			style={{ width: width }}
			className="flex overflow-hidden flex-col rounded-[15px] bg-white bg-opacity-0 shadow-md"
		>
			<div className="flex flex-col w-full font-semibold text-white rounded-t-[15px]">
				<div className="flex overflow-hidden relative flex-col py-3 pr-2 w-full aspect-[1.632]">
					<img
						loading="lazy"
						src={imageUrl}
						alt="Property"
						className="object-cover absolute inset-0 size-full"
					/>
					<div className="absolute top-4 w-full z-10 pr-4 flex items-center justify-between">
						<div className="px-4 py-2.5 text-xs font-medium leading-none bg-z-dark-moss-green rounded-r-full w-fit">
							{propertyType}
						</div>
						<div className="h-8 w-8 rounded-full bg-[rgba(103,100,115,0.27)] flex items-center justify-center">
							<HeartIcon stroke="trasparent" fill="white" />
						</div>
					</div>
					<div className="absolute w-full clear-start flex items-center justify-between bottom-3 px-4 h-[28px]">
						<div
							className={`${
								similarListings ? "bg-z-dark-moss-green" : "bg-[#FF5757] "
							} rounded-[6px] px-2 h-full font-semibold text-sm text-center flex items-center`}
						>
							{status}
						</div>
						{!similarListings && (
							<div className="px-2 rounded-[6px] text-[#0A9D57] bg-[#D5EEE3] font-semibold text-xs h-full flex items-center justify-center">
								Above Asking
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="px-3 py-3 space-y-2">
				<div className="flex flex-col items-start w-full bg-white rounded-none">
					<div className="flex items-center justify-between w-full">
						<div
							className={`font-semibold text-xl ${
								similarListings ? "text-black" : "text-[#FF5757]"
							}`}
						>
							${formatPrice(price)}
						</div>
						<div className="text-[#676473] text-xs">
							{moment.utc(date).fromNow()}
						</div>
					</div>
					<div className="flex items-center justify-between w-full font-semibold text-sm">
						<div
							className={`${similarListings ? "text-z-dark-moss-green" : ""}`}
						>
							Down Payment Provided
						</div>
						<div className="w-[1px] h-[10px] bg-[#B3B1B9]" />
						<div className="">${formatPrice(downPayment)}</div>
					</div>
					<div className="mt-1.5 text-sm font-light text-z-shark">
						{address}
					</div>
				</div>
				<div className="flex items-center justify-between gap-2 w-full text-xs text-[#7D7D7D]">
					<div className="flex items-center gap-1.5">
						<img src={BedIcon} alt="Beds" className="w-[14px]" />
						<div>{beds} Beds</div>
					</div>
					<div className="w-[1px] h-[10px] bg-[#B3B1B9]" />
					<div className="flex items-center gap-1.5">
						<img src={BathIcon} alt="Baths" className="w-[14px]" />
						<div>{baths} Baths</div>
					</div>
					<div className="w-[1px] h-[10px] bg-[#B3B1B9]" />
					<div className="flex items-center gap-1.5">
						<img src={SqftIcon} alt="Sqft" className="w-[14px]" />
						<div>{formatPrice(sqft)} sqft</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { PropertyCard };
