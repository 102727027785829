import {
  WorkIcon,
  SchoolIcon,
  GoStationIcon,
  PlaceWorship,
} from "../../assets/icons";

const cities = [
  { id: 1, name: "New York" },
  { id: 2, name: "Los Angeles" },
  { id: 3, name: "Chicago" },
  { id: 4, name: "Houston" },
  { id: 5, name: "Phoenix" },
  { id: 6, name: "Philadelphia" },
  { id: 7, name: "San Antonio" },
  { id: 8, name: "San Diego" },
  { id: 9, name: "Dallas" },
  { id: 10, name: "San Jose" },
];

const pointsOfInterest = [
  { id: 1, name: "GO Station", icon: GoStationIcon },
  { id: 2, name: "Work", icon: WorkIcon },
  { id: 3, name: "School", icon: SchoolIcon },
  { id: 4, name: "Place of worship", icon: PlaceWorship },
];

const stationOptions = [
  { value: "Rouge Hill GO", label: "Rouge Hill GO" },
  { value: "Rouge Hill", label: "Rouge Hill" },
  { value: "Rouge", label: "Rouge" },
];

const schoolOptions = [
  {
    value: "William G Davis Public School",
    label: "William G Davis Public School",
  },
  {
    value: "Sir Oliver Mowat Collegiate Institute",
    label: "Sir Oliver Mowat Collegiate Institute",
  },
  {
    value: "Wilfred Laurier Univerisity",
    label: "Wilfred Laurier Univerisity",
  },
];

const placeWorshipOptions = [
  {
    value: "Mosque",
    label: "Mosque",
  },
  {
    value: "Church",
    label: "Church",
  },
  {
    value: "Temple",
    label: "Temple",
  },
  {
    value: "Synagogue",
    label: "Synagogue",
  },
];

export {
  cities,
  pointsOfInterest,
  stationOptions,
  schoolOptions,
  placeWorshipOptions,
};
