import Hammer from "hammerjs";
import { applyTransform } from "../../utils/applyTransform";

export const handlePan = (event: HammerInput, el: HTMLElement) => {
  el.classList.add("moving");

  const maxSwipeDistance = 60;
  let deltaX = event.deltaX;

  if (deltaX < -maxSwipeDistance) {
    deltaX = -maxSwipeDistance;
  }

  if (deltaX > 0) {
    deltaX = 0;
  }

  applyTransform(el, deltaX, 0, 0);

  const deleteIcon = el.querySelector<HTMLElement>(".delete-icon");
  const opacity = Math.min(Math.abs(deltaX) / maxSwipeDistance, 1);

  if (deleteIcon) {
    deleteIcon.style.opacity = `${opacity}`;
  }
};

export const handlePanEnd = (
  event: HammerInput,
  el: HTMLDivElement,
  initCards: () => void,
  onSwipeLeft: (card: HTMLDivElement) => void,
  onSwipeRight: (card: HTMLDivElement) => void
) => {
  const moveOutWidth = 60;
  const deltaX = event.deltaX;

  el.classList.remove("moving");

  if (deltaX < -moveOutWidth) {
    onSwipeLeft(el);
    el.style.transform = `translateX(-${moveOutWidth}px)`;
  } else if (deltaX > 0) {
    onSwipeRight(el);
    el.style.transform = "translateX(0px)";
  } else {
    el.style.transform = "translateX(0px)";
  }

  const handleTransitionEnd = () => {
    el.style.transition = "";
    el.removeEventListener("transitionend", handleTransitionEnd);
  };
  el.addEventListener("transitionend", handleTransitionEnd);
};

export const setupHammer = (
  el: HTMLDivElement,
  initCards: () => void,
  onSwipeLeft: (card: HTMLDivElement) => void,
  onSwipeRight: (card: HTMLDivElement) => void
) => {
  const hammertime = new Hammer(el);
  hammertime.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL });

  hammertime.on("pan", (event) => {
    event.preventDefault();
    handlePan(event, el);
  });

  hammertime.on("panend", (event) => {
    event.preventDefault();
    handlePanEnd(event, el, initCards, onSwipeLeft, onSwipeRight);
  });
};
