import { create } from "zustand";

interface PropertyCitySelectionStore {
  selectedCities: string[];
  setSelectedCities: (cityName: string) => void;
  removeCity: (cityName: string) => void;
}

const usePropertyCityStore = create<PropertyCitySelectionStore>((set, get) => ({
  selectedCities: [],

  setSelectedCities: (cityName) => {
    const { selectedCities } = get();
    if (cityName && typeof cityName === "string") {
      if (!selectedCities.includes(cityName)) {
        set({
          selectedCities: [...selectedCities, cityName],
        });
      }
    } else {
      console.warn("Invalid city name:", cityName);
    }
  },

  removeCity: (cityName) => {
    const { selectedCities } = get();
    if (selectedCities.includes(cityName)) {
      set({
        selectedCities: selectedCities.filter((city) => city !== cityName),
      });
    }
  },
}));

export { usePropertyCityStore };
