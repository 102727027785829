import { BrowseListingLayout } from "../../layouts/browseListingLayout";
import { CartComparison } from "./cartComparison";

const ComparePropertiesView = () => {
	return (
		<BrowseListingLayout pb="30px">
			<div className="flex flex-col w-full mt-[65px]">
				<CartComparison />
			</div>
		</BrowseListingLayout>
	);
};

export { ComparePropertiesView };
