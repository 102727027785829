interface IconProps {
	fill?: string;
	width?: string;
	height?: string;
}
const PlayIcon = ({
	fill = "#456930",
	width = "19",
	height = "20",
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 19 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				id="Vector"
				d="M3.48163 0.303751C3.1638 0.10495 2.79653 -0.000310117 2.42179 6.86301e-07H0.998675C0.73381 6.86301e-07 0.479793 0.105357 0.292505 0.292893C0.105217 0.480429 0 0.734783 0 1V19C0 19.2652 0.105217 19.5196 0.292505 19.7071C0.479793 19.8946 0.73381 20 0.998675 20H2.42179C2.79653 20.0003 3.1638 19.8951 3.48163 19.6962L18.5067 10.8625C18.6569 10.7743 18.7814 10.6483 18.8679 10.497C18.9545 10.3457 19 10.1743 19 10C19 9.82565 18.9545 9.65433 18.8679 9.50304C18.7814 9.35174 18.6569 9.22573 18.5067 9.1375L3.48163 0.303751Z"
				fill={fill}
			/>
		</svg>
	);
};

export default PlayIcon;
