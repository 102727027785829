import { DateList } from "./dateList";
import { Calendar } from "./calender";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClockIcon from "../../assets/clockIcon.svg";
import { useShowingCartStore } from "../../stores/showingCartStore";
import { DragDropContext, type DropResult } from "@hello-pangea/dnd";

interface DateItem {
	id: string;
	date: string;
}

const useDateState = () => {
	const [dates, setDates] = useState<DateItem[]>([]);
	const setStoreDates = useShowingCartStore((state) => state.setDates);

	useEffect(() => {
		const convertedDates = dates.map((item) => ({
			...item,
			date: new Date(item.date).toISOString(),
		}));
		setStoreDates(convertedDates.map((item) => item.date));
	}, [dates, setStoreDates]);

	const reorderDates = (startIndex: number, endIndex: number) => {
		const reorderedDates = Array.from(dates);
		const [movedItem] = reorderedDates.splice(startIndex, 1);
		reorderedDates.splice(endIndex, 0, movedItem);
		setDates(reorderedDates);
	};

	const removeDate = (id: string) => {
		const updatedDates = dates.filter((item) => item.id !== id);
		setDates(updatedDates);
	};

	return { dates, setDates, reorderDates, removeDate };
};

const Header = () => (
	<div className="text-center space-y-2">
		<div className="text-[22px] font-semibold text-black">
			When do you want to see these homes?
		</div>
		<div className="text-xs text-black">
			Select a day you're available and a backup date
		</div>
	</div>
);

interface SubmitButtonProps {
	enabled: boolean;
	onClick: () => void;
}

const SubmitButton = ({ enabled, onClick }: SubmitButtonProps) => (
	<button
		type="button"
		className={`bg-z-dark-moss-green w-full font-semibold text-[20px] text-white flex items-center justify-center gap-2 rounded-full py-3.5 shadow-md drop-shadow-md ${
			enabled ? "" : "opacity-50 cursor-not-allowed"
		}`}
		onClick={onClick}
		disabled={!enabled}
	>
		<img src={ClockIcon} alt="" />
		Choose a time
	</button>
);

const ShowingDateSelectionView = () => {
	const { dates, setDates, reorderDates, removeDate } = useDateState();
	const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
	const navigate = useNavigate();

	const onDragEnd = (result: DropResult) => {
		if (result.destination) {
			reorderDates(result.source.index, result.destination.index);
		}
	};

	const handleButtonClick = () => {
		navigate("/cart/time-selection");
	};

	return (
		<div className="pb-6 pt-2">
			<DragDropContext onDragEnd={onDragEnd}>
				<div className="h-full flex flex-col items-center text-black">
					<div className="px-4 space-y-6">
						<Header />
						<Calendar
							setButtonEnabled={setButtonEnabled}
							dates={dates}
							setDates={setDates}
						/>
						<DateList dates={dates} removeDate={removeDate} />
					</div>
					<div className="w-full">
						<hr className="h-px my-4 bg-[#EDF1F4] border-0" />
						<div className="px-4">
							<SubmitButton
								enabled={buttonEnabled}
								onClick={handleButtonClick}
							/>
						</div>
					</div>
				</div>
			</DragDropContext>
		</div>
	);
};

export { ShowingDateSelectionView };
