export enum BuyerMatchingPreferencesPoiTypeEnum {
  School = "SCHOOL",
  PublicStation = "PUBLIC_STATION",
  Work = "WORK",
  PlaceOfWorship = "PLACE_OF_WORSHIP",
  Daycare = "DAYCARE",
  Other = "OTHER",
}

export enum BuyerMatchingPreferencesPrioritiesEnum {
  PoiSchool = "POI_SCHOOL",
  PoiPublicTransit = "POI_PUBLIC_STATION",
  PoiWork = "POI_WORK",
  PoiWorship = "POI_WORSHIP",
  PoiDaycare = "POI_DAYCARE",
  PoiOther = "POI_OTHER",
  NearbyPark = "NEARBY_PARK",
  OnSchoolRoute = "ON_SCHOOL_ROUTE",
  CoveredGarage = "COVERED_GARAGE",
  TwoPlusParking = "TWO_PLUS_PARKING",
  FinishedBasement = "FINISHED_BASEMENT",
  FencedYard = "FENCED_YARD",
  Backyard = "BACKYARD",
  FixerUpper = "FIXER_UPPER",
  CondominiumPool = "CONDOMINIUM_POOL",
  CondominiumRecRoom = "CONDOMINIUM_REC_ROOM",
  NearbyRecCenter = "NEARBY_REC_CENTER",
  SwimmingPool = "SWIMMING_POOL",
  CondominiumPets = "CONDOMINIUM_PETS",
  CondominiumLocker = "CONDOMINIUM_LOCKER",
}

export enum BuyerMatchingPreferencesPropertyTypeEnum {
  Detached = "DETACHED",
  SemiDetached = "SEMI_DETACHED",
  Townhouse = "TOWNHOUSE",
  CondoTownhouse = "CONDO_TOWNHOUSE",
  Condo = "CONDO",
  Apartment = "APARTMENT",
}

export interface GeoJSONPoint {
  type: "Point";
  coordinates: [number, number];
}

export interface BuyerMatchingPreferencesPoi {
  designation?: string;
  location?: GeoJSONPoint;
  type: BuyerMatchingPreferencesPoiTypeEnum;
}

export interface BuyerMatchingPreferencesFilters {
  selectedCities?: string[];
  numberOfBedrooms?: number[] | null;
  numberOfBathrooms?: number[] | null;
  numberOfParkingSpaces?: number[] | null;
  dens?: boolean | null;
  propertyType?: BuyerMatchingPreferencesPropertyTypeEnum[];
}

export interface BuyerMatchingPreferences {
  pointsOfInterests?: BuyerMatchingPreferencesPoi[];
  priorities?: string[];
  requiredFeatures?: string[];
  optionalFeatures?: string[];
  lifeStyles?: string[];
  filters?: BuyerMatchingPreferencesFilters;
}
