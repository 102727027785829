import clsx from "clsx";
import { PiImageSquareBold } from "react-icons/pi";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { browseListingData } from "../../mocks/newMockData/browseListingData";
import styles from "./browseListing.style.module.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
interface BrowseListingSlider {
	setOverlayOpen: (listing: boolean) => void;
}
const BrowseListingSlider = ({ setOverlayOpen }: BrowseListingSlider) => {
	const splitImages = () => {
		const originalImages = browseListingData[0].images;
		const chunkSize = 5;
		const newList = [];

		for (let i = 0; i < originalImages.length; i += chunkSize) {
			const chunk = originalImages.slice(i, i + chunkSize);
			newList.push({ images: chunk });
		}

		return newList;
	};

	const images = splitImages();

	return (
		<div className="h-[500px] max-h-[500px] relative">
			<Swiper
				className="pb-8 browse-listing-slider h-full"
				loop={true}
				modules={[Pagination, Navigation]}
				autoplay={{ delay: 3000, disableOnInteraction: true }}
				slidesPerView={1}
				navigation={{
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				}}
				pagination={{
					clickable: true,
					bulletClass: `swiper-pagination-bullet ${styles.swiperPaginationBullet}`,
					bulletActiveClass: styles.swiperPaginationBulletActive,
				}}
			>
				{images.map((listing, index) => (
					<SwiperSlide
						key={index}
						className="h-full"
						onClick={() => setOverlayOpen(true)}
					>
						<div className="grid grid-cols-4 grid-rows-2 gap-2 h-full">
							{listing.images.map((item, itemIndex) => (
								<div
									key={itemIndex}
									className={clsx(
										"cursor-pointer rounded-[10px] overflow-hidden w-full h-full relative",
										itemIndex === 0 && "col-span-2 row-span-2",
									)}
								>
									{itemIndex === 4 && (
										<div className="flex items-center text-white space-x-2 text-2xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
											<PiImageSquareBold className="text-3xl" />
											<span className="font-bold">{listing.images.length}</span>
										</div>
									)}
									<img
										className={clsx(
											"h-full w-full absolute inset-0 object-cover",
											itemIndex === 4 && "blur-[7px]",
										)}
										src={item}
										alt={`Slide ${itemIndex + 1}`}
									/>
								</div>
							))}
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="browse-listing-slider swiper-button-prev" />
			<div className="browse-listing-slider swiper-button-next" />
		</div>
	);
};

export default BrowseListingSlider;
