import { create } from "zustand";

export type PropertyType =
	| "detached"
	| "semi-detached"
	| "townhouse"
	| "condo townhouse"
	| "condo"
	| "other";

interface FilterPanelVisibility {
	filterPanelVisible: boolean;
	toggleFilterPanelVisible: () => void;
	showFilterPanel: () => void;
	hideFilterPanel: () => void;
	setFilterPanelOrigin: (value: string) => void;
	filterPanelOrigin: string;
}

export const useFilterPanelStore = create<FilterPanelVisibility>((set) => ({
	filterPanelOrigin: "",
	filterPanelVisible: false,
	toggleFilterPanelVisible: () =>
		set((state) => ({ filterPanelVisible: !state.filterPanelVisible })),
	showFilterPanel: () => set(() => ({ filterPanelVisible: true })),
	hideFilterPanel: () => set(() => ({ filterPanelVisible: false })),
	setFilterPanelOrigin: (value: string) => set({ filterPanelOrigin: value }),
}));

export interface SearchFilterValues {
	soldStatusType?: "forSale" | "sold";
	bedCount: string;
	bathCount: string;
	parking: string;
	denCount: string;
	priceRange: { minVal?: number; maxVal?: number };
	propertyType: string[];
	daysOnMarket: string;
	ageOfProperty: string;
	style: string;
	sqFt: string;
	basement: string;
	garageType: string;
	numberOfKitchens: string;
}

export interface MatchFilterValues {
	matchLocation: string[];
	matchLifestyle: string[];
	matchMustHaves: string[];
	matchNiceHaves: string[];
	matchBedCount: string[];
	matchBathCount: string[];
	matchParking: string[];
	matchDenCount: boolean | null;
	matchPropertyType: string[];
	setMatchLocation: (updateFn: (prevTypes: string[]) => string[]) => void;
	setMatchLifestyle: (updateFn: (prevTypes: string[]) => string[]) => void;
	setMatchMustHaves: (updateFn: (prevTypes: string[]) => string[]) => void;
	setMatchNiceHaves: (updateFn: (prevTypes: string[]) => string[]) => void;
	setMatchBedCount: (value: string[]) => void;
	setMatchBathCount: (value: string[]) => void;
	setMatchParking: (value: string[]) => void;
	setMatchDenCount: (value: boolean | null) => void;
	setMatchPropertyType: (updateFn: (prevTypes: string[]) => string[]) => void;
	clearAllMatchFilters: () => void;
	getAllMatchFilters: () => {
		matchLocation: string[];
		matchLifestyle: string[];
		matchMustHaves: string[];
		matchNiceHaves: string[];
		matchBedCount: string[];
		matchBathCount: string[];
		matchParking: string[];
		matchDenCount: boolean | null;
		matchPropertyType: string[];
	};
}

export const useMatchFiltersStore = create<MatchFilterValues>((set, get) => ({
	matchLocation: [],
	matchLifestyle: [],
	matchMustHaves: [],
	matchNiceHaves: [],
	matchBedCount: [],
	matchBathCount: [],
	matchParking: [],
	matchDenCount: null,
	matchPropertyType: [] as string[],
	setMatchLocation: (updateFn: (prevTypes: string[]) => string[]) =>
		set((state) => ({
			matchLocation: updateFn(state.matchLocation ?? []),
		})),
	setMatchLifestyle: (updateFn: (prevTypes: string[]) => string[]) =>
		set((state) => ({
			matchLifestyle: updateFn(state.matchLifestyle ?? []),
		})),
	setMatchMustHaves: (updateFn: (prevTypes: string[]) => string[]) =>
		set((state) => ({
			matchMustHaves: updateFn(state.matchMustHaves ?? []),
		})),
	setMatchNiceHaves: (updateFn: (prevTypes: string[]) => string[]) =>
		set((state) => ({
			matchNiceHaves: updateFn(state.matchNiceHaves ?? []),
		})),

	setMatchBedCount: (value: string[]) => set({ matchBedCount: value }),
	setMatchBathCount: (value: string[]) => set({ matchBathCount: value }),
	setMatchParking: (value: string[]) => set({ matchParking: value }),

	setMatchDenCount: (value: boolean | null) => set({ matchDenCount: value }),

	setMatchPropertyType: (updateFn: (prevTypes: string[]) => string[]) => {
		const { matchPropertyType } = get();
		const updatedTypes = updateFn(matchPropertyType);
		set({
			matchPropertyType: updatedTypes,
		});
	},

	clearAllMatchFilters: () =>
		set({
			matchBedCount: [],
			matchBathCount: [],
			matchParking: [],
			matchDenCount: null,
			matchPropertyType: [],
		}),

	getAllMatchFilters: () => {
		const {
			matchLocation,
			matchLifestyle,
			matchMustHaves,
			matchNiceHaves,
			matchBedCount,
			matchBathCount,
			matchParking,
			matchDenCount,
			matchPropertyType,
		} = get();

		return {
			matchLocation,
			matchLifestyle,
			matchMustHaves,
			matchNiceHaves,
			matchBedCount,
			matchBathCount,
			matchParking,
			matchDenCount,
			matchPropertyType,
		};
	},
}));
