import { Outlet } from "react-router-dom";
import ZownLogoBlueWide from "../assets/zown-logo-blue-wide.svg";

export const LoginLayout = () => {
	return (
		<div
			className={`px-4 space-y-2 ${
				window.bridge ? "pt-bridge-inset-top" : "pt-10"
			}`}
		>
			<div className="w-full flex justify-center">
				<img src={ZownLogoBlueWide} alt="" className="w-[201px] h-[69px]" />
			</div>
			<Outlet />
		</div>
	);
};
