interface IconProps {
	fill: string;
	stroke: string;
}
const SuggestionsIcon = ({ fill, stroke }: IconProps) => {
	return (
		<svg
			width="38"
			height="38"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 1000002343">
				<rect
					id="Rectangle 29"
					x="0.5"
					y="0.5"
					width="29"
					height="29"
					rx="14.5"
					fill={fill}
					stroke={fill}
				/>
				<path
					id="Vector"
					d="M11.1081 11.7584L11.105 11.7563L11.1081 11.7584ZM11.1081 11.7584L11.1105 11.76M11.1081 11.7584L11.1105 11.76M11.1081 11.7584L11.1558 11.8873M11.1081 11.7584L11.1558 11.8873M11.1105 11.76L11.1558 11.8873M11.1105 11.76L11.1558 11.8873M15.3385 14.2332L15.3387 14.2332C15.815 14.0638 16.2449 13.7794 16.5953 13.4054C16.9457 13.0315 17.2074 12.5781 17.3622 12.0822L17.8111 10.6451L18.2601 12.0833L18.2602 12.0835C18.4152 12.5793 18.6772 13.0325 19.0277 13.4062C19.3784 13.78 19.8084 14.0641 20.2848 14.2333L21.7389 14.7499L20.2838 15.2667L20.2837 15.2667C19.8074 15.436 19.3777 15.7202 19.0272 16.094C18.6769 16.4677 18.415 16.9208 18.2601 17.4165L18.2601 17.4166L17.8111 18.8547L17.3622 17.4166L17.3621 17.4165C17.2072 16.9208 16.9454 16.4677 16.5951 16.094C16.2446 15.7202 15.8149 15.436 15.3386 15.2667L15.3385 15.2667L13.8835 14.75L15.3385 14.2332ZM9.83159 18.6278L9.83201 18.6276C10.4744 18.3988 10.9636 17.8677 11.1698 17.2091L11.1699 17.2088L11.2486 16.9568L11.3273 17.2088L11.3273 17.2088C11.429 17.5343 11.6012 17.8328 11.8326 18.0797C12.0641 18.3266 12.3489 18.5152 12.6656 18.6277L12.6656 18.6278L13.0085 18.7495L12.6656 18.8713L12.6656 18.8713C12.3489 18.9839 12.0641 19.1725 11.8326 19.4194C11.6012 19.6662 11.429 19.9647 11.3273 20.2903L11.3273 20.2903L11.2486 20.5422L11.1699 20.2903L11.1699 20.2903C11.0682 19.9647 10.896 19.6662 10.6646 19.4194C10.4331 19.1725 10.1483 18.9839 9.83165 18.8713L9.83159 18.8713L9.48876 18.7495L9.83159 18.6278ZM8.74221 19.0147C8.7421 19.0148 8.74198 19.0148 8.74187 19.0148L8.74221 19.0147ZM13.755 19.0147L13.7554 19.0148L13.755 19.0147ZM8.66388 11.7498C8.97144 11.5948 9.21788 11.3423 9.37362 11.0357C9.44427 11.1747 9.53409 11.3036 9.64118 11.4179C9.76894 11.5541 9.91884 11.6668 10.0845 11.7503C9.91884 11.8337 9.76894 11.9465 9.64118 12.0827C9.53421 12.1968 9.44449 12.3255 9.37387 12.4644C9.21814 12.1575 8.97161 11.9049 8.66388 11.7498ZM7.63908 11.7583L7.64223 11.7562L7.63908 11.7583Z"
					fill={stroke}
					stroke={stroke}
				/>
			</g>
		</svg>
	);
};

export default SuggestionsIcon;
