import { create } from "zustand";

interface ShowTopMatchesModalStoreState {
  showTopMatchesModal: boolean;
  setShowTopMatchesModal: (show: boolean) => void;
}

const useShowTopMatchesModalStore = create<ShowTopMatchesModalStoreState>(
  (set) => ({
    showTopMatchesModal: false,
    setShowTopMatchesModal: (show) => set({ showTopMatchesModal: show }),
  })
);

export { useShowTopMatchesModalStore };
