import clsx from "clsx";
import { useState } from "react";
import { IoIosList, IoIosSearch } from "react-icons/io";
import { PiMapTrifold } from "react-icons/pi";
import { VscSettings } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import FilterIcon from "../../assets/filterIcon.svg";
import { useFilterPanelStore } from "../../stores/filterStore";
import { useNavbarStore } from "../../stores/navBarStatus";

const SearchField = () => {
	const navigate = useNavigate();
	const { hideNavbar } = useNavbarStore();
	const [switchMode, setSwitchMode] = useState<"list" | "map">("map");

	// const { showFilterPanel, setFilterPanelOrigin } = useFilterPanelStore();

	const handleTextSearch = () => {
		navigate("/search");
		hideNavbar();
	};

	// const handleFilterClick = () => {
	// 	showFilterPanel();
	// 	setFilterPanelOrigin("searchField");
	// 	hideNavbar();
	// };

	return (
		<div className="flex justify-between items-center h-full w-full bg-white pl-8 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
			<div className="flex gap-2 items-center">
				<div
					className="w-[370px] flex items-center bg-[#F9FAFB] rounded-2xl overflow-hidden border border-[#D1D5DB]"
					onClick={handleTextSearch}
					onKeyDown={handleTextSearch}
				>
					<div className="pl-4">
						<IoIosSearch className="text-[#6B7280] text-2xl" />
					</div>
					<input
						type="text"
						placeholder="Enter an address, city or postal code"
						className="flex-1 py-4 px-2 text-lg text-black placeholder:text-[#bbb] placeholder:font-normal bg-[#F9FAFB] focus:outline-none"
						readOnly={true}
					/>
				</div>
				<div className="rounded-xl text-sm font-semibold border border-[#E7E7E7] h-[60px] flex items-center justify-center px-5">
					For Sale
				</div>
				<div className="rounded-xl text-sm font-semibold border border-[#E7E7E7] h-[60px] flex items-center justify-center px-5">
					Beds & Baths
				</div>
				<div className="rounded-xl text-sm font-semibold border border-[#E7E7E7] h-[60px] flex items-center justify-center px-5">
					$0 - Max
				</div>
				<div className="rounded-xl text-sm font-semibold border border-[#E7E7E7] h-[60px] flex items-center justify-center px-5">
					Active
				</div>
				<div className="rounded-xl text-sm font-semibold border border-[#E7E7E7] h-[60px] flex items-center justify-center px-5">
					Sold
				</div>
				<div className="rounded-xl text-sm font-semibold flex items-center gap-2 border text-white bg-z-dark-moss-green border-[#E7E7E7] h-[60px] flex items-center justify-center px-5">
					<VscSettings className="text-2xl" />
					More
				</div>
			</div>
			<div className="mr-11 flex items-center gap-2">
				<div className="h-[60px] flex items-center justify-center cursor-pointer text-sm font-semibold text-z-dark-moss-green rounded-xl px-5 border-[1.5px] border-z-dark-moss-green">
					Compare
				</div>
				<div className="flex items-center overflow-hidden rounded-2xl border border-[#CECECE]">
					<div
						className={clsx(
							"w-[60px] h-[60px] rounded-[15px] cursor-pointer flex items-center justify-center",
							switchMode === "list"
								? "bg-z-dark-moss-green text-white"
								: "text-z-dark-moss-green",
						)}
						onClick={() => setSwitchMode("list")}
						onKeyDown={() => setSwitchMode("list")}
					>
						<IoIosList className="text-3xl" />
					</div>
					<div
						className={clsx(
							"w-[60px] h-[60px] rounded-[15px] cursor-pointer flex items-center justify-center",
							switchMode === "map"
								? "bg-z-dark-moss-green text-white"
								: "text-z-dark-moss-green",
						)}
						onClick={() => setSwitchMode("map")}
						onKeyDown={() => setSwitchMode("map")}
					>
						<PiMapTrifold className="text-3xl" />
					</div>
				</div>
			</div>

			{/* <button
				type={"button"}
				onClick={handleFilterClick}
				onKeyDown={handleFilterClick}
				className="col-span-1 w-12 h-12 rounded-full drop-shadow-lg"
			>
				<img src={FilterIcon} alt="" />
			</button> */}
		</div>
	);
};

export { SearchField };
