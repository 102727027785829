import { useRef } from "react";
import Chart, { type TooltipItem } from "chart.js/auto";
import { formatPrice } from "../../../utils/formatPrice";
import type { MedianSoldPriceInterface } from "../../../interfaces/statistics";

interface MedianSoldPriceProps {
	medianSoldPrice: MedianSoldPriceInterface;
}

const MedianSoldPrice = ({ medianSoldPrice }: MedianSoldPriceProps) => {
	const chartRef = useRef<Chart | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	const data = medianSoldPrice.statistics.soldPrice.mth;
	const xValues = Object.keys(data);
	const yValues = Object.values(data).map((item) => item.med);

	if (canvasRef.current) {
		if (chartRef.current) {
			chartRef.current.destroy();
		}

		const ctx = canvasRef.current.getContext("2d");
		if (ctx) {
			const gradient = ctx.createLinearGradient(0, 0, 0, 600);
			gradient.addColorStop(0, "rgba(241, 120, 182, 0.4)");
			gradient.addColorStop(1, "rgba(241, 120, 182, 0)");

			chartRef.current = new Chart(ctx, {
				type: "line",
				data: {
					labels: xValues,
					datasets: [
						{
							fill: true,
							backgroundColor: gradient,
							pointRadius: 0,
							borderColor: "#F178B6",
							tension: 0.3,
							data: yValues,
						},
					],
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							mode: "index",
							intersect: false,
							callbacks: {
								label: (tooltipItem: TooltipItem<"line">) =>
									`$${formatPrice(tooltipItem.parsed.y)}`,
							},
						},
					},
					scales: {
						x: {
							grid: {
								display: false,
							},
						},
						y: {
							type: "linear",
							position: "left",
							ticks: {
								callback: (value) => `$${formatPrice(value)}`,
								stepSize: 50000,
							},
						},
					},
				},
			});
		}
	}

	return (
		<div className="space-y-4 px-4">
			<div>
				<div className="font-semibold text-lg">Median Sold Price</div>
				<canvas ref={canvasRef} id="myChart" className="h-auto max-h-[200px]" />
			</div>
		</div>
	);
};

export { MedianSoldPrice };
