import { type Dispatch, type SetStateAction, useState } from "react";
import GroceriesIcon from "../../assets/icons/groceriesIcon.svg";
import NearbyIcon from "../../assets/icons/nearbyIcon.svg";
import SchoolIcon from "../../assets/icons/schoolsIcon.svg";
import logo from "../../assets/zown-logo-icon-blue.svg";

interface IconButtonProps {
	label: string;
	icon: string;
	isActive: boolean;
	onClick: () => void;
}

interface MapSelectorProps {
	nearbyType: string;
	setNearbyType: Dispatch<SetStateAction<string>>;
}

const selectorLabels = [
	{ label: "Schools", type: "school", icon: SchoolIcon },
	{ label: "Groceries", type: "grocery", icon: GroceriesIcon },
	{ label: "Recently Sold", type: "homeSale", icon: logo },
	{ label: "What’s nearby", type: "nearby", icon: NearbyIcon },
];

const IconButton = ({ label, icon, isActive, onClick }: IconButtonProps) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={`inline-flex items-center text-black justify-center lg:text-xs xl:text-base w-full lg:p-2 xl:p-4 px-6 ${
				isActive ? "border-b-z-abbey font-semibold border-b-8" : "border-b-0"
			}`}
		>
			<img src={icon} alt={label} className="h-5 mr-2" /> {label}
		</button>
	);
};

const MapSelector = ({ nearbyType, setNearbyType }: MapSelectorProps) => {
	const [activeTab, setActiveTab] = useState(nearbyType);

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
		setNearbyType(tab);
	};

	return (
		<div className="flex flex-col items-center w-full bg-white z-10 text-sm font-medium text-center">
			<ul className="flex -mb-px justify-between overflow-x-auto no-scrollbar w-full scroll-snap-x divide-x divide-[#EFEFEF]">
				{selectorLabels.map((item) => (
					<li key={item.type} className="scroll-snap-item w-full">
						<IconButton
							label={item.label}
							icon={item.icon}
							isActive={activeTab === item.type}
							onClick={() => handleTabChange(item.type)}
						/>
					</li>
				))}
			</ul>
		</div>
	);
};

export { MapSelector };
