import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import {
	BathroomIcon,
	BedroomIcon,
	HomeIcon,
	ParkingIcon,
	SizeIcon,
} from "../../assets/BrowseListing";
import calculatorIcon from "../../assets/icons/calculatorIcon.svg";
import HeartCustomIcon from "../../assets/icons/heartCustomIcon";
import zownlogo from "../../assets/zown-logo-icon-blue.svg";
import { formatPrice } from "../../utils/formatPrice";

const DetailsHome = () => {
	const [like, setlike] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [addComparison, setAddComparison] = useState(false);
	const [isOverflowing, setIsOverflowing] = useState(false);

	const paragraphRef = useRef<HTMLParagraphElement>(null);

	useEffect(() => {
		const checkOverflow = () => {
			const paragraph = paragraphRef.current;

			if (paragraph) {
				setIsOverflowing(paragraph.scrollHeight > paragraph.clientHeight);
			}
		};
		checkOverflow();
		window.addEventListener("resize", checkOverflow);
		return () => {
			window.removeEventListener("resize", checkOverflow);
		};
	}, []);

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};
	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleExpand();
		}
	};
	const handleKeyDownComparison = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			setAddComparison((prev) => !prev);
		}
	};

	return (
		<div className="flex flex-col">
			<div className="flex items-center justify-between">
				<div className="font-bold text-[#333333] text-[28px]">
					2356 Brookhurst Rd
				</div>
				<div className="flex space-x-3">
					<div className="bg-z-dark-moss-green rounded-full text-white px-[14px] h-[36px flex items-center justify-center">
						17 hours on Zown
					</div>
					<div
						className="bg-[#E3E1E166] w-9 h-9 rounded-full cursor-pointer flex items-center justify-center"
						onClick={() => setlike((prev) => !prev)}
					>
						<HeartCustomIcon fill={like === false ? "#676473" : "#FF5757"} />
					</div>
				</div>
			</div>
			<div className="flex items-center justify-between">
				<span className="text-[#333333]">Mississauga - Clarkson</span>
				<div className="flex items-center space-x-2 mr-14">
					<img src={calculatorIcon} alt="calculator icon" />
					<span className="text-xs">
						Est. Mortgage:{" "}
						<span className="underline">${formatPrice(3098)}/mo</span>
					</span>
				</div>
			</div>
			<div className="flex items-center justify-between mt-5">
				<div className="space-x-3 flex items-center">
					<div className="bg-[#279E25] text-sm font-bold text-white w-fit rounded-md px-3 py-1">
						Undervalued
					</div>
					<div className="flex items-center">
						<img className="w-9" src={zownlogo} alt="zown logo" />
						<div className="text-[#676473]">Est: ${formatPrice(1450000)}</div>
					</div>
				</div>
				<div className="flex flex-col items-center cursor-pointer">
					<div
						className={clsx(
							"flex items-center space-x-2 rounded-full py-2 px-3",
							addComparison
								? "bg-z-dark-moss-green text-white"
								: "text-z-shark",
						)}
						onClick={() => setAddComparison((prev) => !prev)}
						onKeyDown={handleKeyDownComparison}
					>
						<FiPlusCircle className="text-2xl" />
						<span className="">Add to comparison</span>
					</div>
					<div className="pt-1 text-sm font-bold h-2">
						{addComparison && <span>View Comparison</span>}
					</div>
				</div>
			</div>
			<div className="flex items-center space-x-2 mt-6">
				<div className="relative flex">
					<span className="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-[#78DC70] opacity-75" />
					<span className="relative inline-flex rounded-full h-4 w-4 bg-[#78DC70]" />
				</div>
				<div className="font-bold text-z-shark">For Sale</div>
			</div>
			<div className="grid grid-flow-row-dense grid-cols-5 divide-x-[1px] divide-[#DEDEDE] mt-[52px]">
				<div className="flex flex-col items-center justify-center text-z-shark">
					<img src={HomeIcon} alt="home icon" />
					<div className="mt-4 mb-[2px] font-semibold">Semi Detached</div>
					<div className="text-sm">Property Type</div>
				</div>
				<div className="flex flex-col items-center justify-center text-z-shark">
					<img src={BedroomIcon} alt="bedroom icon" />
					<div className="mt-4 mb-[2px] font-semibold">3</div>
					<div className="text-sm">Bedrooms</div>
				</div>
				<div className="flex flex-col items-center justify-center text-z-shark">
					<img src={BathroomIcon} alt="bathroon icon" />
					<div className="mt-4 mb-[2px] font-semibold">2</div>
					<div className="text-sm">Bathrooms</div>
				</div>
				<div className="flex flex-col items-center justify-center text-z-shark">
					<img src={ParkingIcon} alt="parking icon" />
					<div className="mt-4 mb-[2px] font-semibold">2</div>
					<div className="text-sm">Parking</div>
				</div>
				<div className="flex flex-col items-center justify-center text-z-shark">
					<img src={SizeIcon} alt="size icon" />
					<div className="mt-4 mb-[2px] font-semibold">700-1100 sqft</div>
					<div className="text-sm">Size</div>
				</div>
			</div>
			<div className="mt-10">
				<div
					ref={paragraphRef}
					className={`leading-8 text-[#7A7674] overflow-hidden ${
						isExpanded ? "max-h-none" : "max-h-[100px] line-clamp-3"
					} transition-all duration-300 ease-in-out`}
				>
					Beautifully maintained 3 bedroom, 2 bathroom Semi in quiet, family
					friendly Clarkson neighbourhood. Main level features hardwood
					flooring, updated kitchen stainless steel appliances and granite
					countertops,updated kitchen with stainless steel appliances and
					granite countertops Beautifully maintained 3 bedroom, 2 bathroom Semi
					in quiet, family friendly Clarkson neighbourhood.
				</div>
				{isOverflowing && (
					<span
						onClick={toggleExpand}
						onKeyDown={handleKeyDown}
						className="text-[#7A7674] cursor-pointer mt-2 flex items-end justify-end"
					>
						{isExpanded ? "View less" : "View more"}
					</span>
				)}
			</div>
			<div className="text-[#7A7674] font-light mt-6">
				Listed by: ZOWN REALTY INC.
			</div>
		</div>
	);
};

export { DetailsHome };
