import { universalFetch } from "./common";
import type { ComparablesData } from "../interfaces/comparablesInterface";

export const comparableListings = async (
	listingId: string,
): Promise<ComparablesData> => {
	try {
		const response = await universalFetch(
			`/listings/${listingId}/comparables`,
			"GET",
		);

		console.log("comparableListings response:", response);
		return response as ComparablesData;
	} catch (error) {
		console.error("Failed to fetch comparableListings", error);
		throw error;
	}
};
