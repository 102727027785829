import clsx from "clsx";
import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { updatePreferences } from "../../services/preferences";
import { useUserProfileStore } from "../../stores/profileStore";
import { usePropertySearchingStore } from "../../stores/propertySearchingStore";

const removeDuplicates = (options: { type: string; displayName: string }[]) => {
	return options.filter(
		(option, index, self) =>
			index === self.findIndex((o) => o.type === option.type),
	);
};

const NeedsWants = () => {
	const { mustHaves, setMustHaves, niceHaves, setNiceHaves, filteredItems } =
		usePropertySearchingStore();
	const navigate = useNavigate();
	const filteredOptions = removeDuplicates(filteredItems());
	console.log(filteredOptions);
	const { userProfile } = useUserProfileStore();
	console.log(userProfile?.matchingPreferences?.optionalFeatures);
	console.log(userProfile?.matchingPreferences?.requiredFeatures);

	const handleMustHave = (value: { type: string }) => {
		const newMustHaves = mustHaves.includes(value.type)
			? mustHaves.filter((type) => type !== value.type)
			: [...mustHaves, value.type];

		setMustHaves(newMustHaves);
		localStorage.setItem("mustHavesSelect", JSON.stringify(newMustHaves));
	};

	const handleNiceHave = (value: { type: string }) => {
		const newNiceHaves = niceHaves.includes(value.type)
			? niceHaves.filter((type) => type !== value.type)
			: [...niceHaves, value.type];

		setNiceHaves(newNiceHaves);
		localStorage.setItem("niceHavesSelect", JSON.stringify(newNiceHaves));
	};

	const handleNav = async () => {
		await updatePreferences(
			undefined,
			undefined,
			undefined,
			mustHaves,
			niceHaves,
		);
		navigate("/profile/prioritize");
	};

	return (
		<div
			className={`pt-2 px-4 space-y-12 inset-0 ${
				window.bridge ? "pb-bridge-inset-bottom" : "pb-6"
			}`}
		>
			<div className="space-y-2">
				<h2 className="text-z-shark text-2xl font-bold ">Needs vs Wants</h2>
				<span className="text-z-shark text-sm">Select as many as you like</span>
			</div>
			<div className="space-y-12">
				<div className="space-y-4">
					<span className="text-xl font-semibold text-z-shark">Must Haves</span>
					<div className="">
						<div className="mb-4 flex flex-col space-y-4">
							<div
								style={{ gap: "12px" }}
								className="flex flex-row flex-wrap items-center overflow-x-auto"
							>
								{filteredOptions.map((option) => {
									const isSelected = mustHaves.includes(option.type);

									return (
										<div
											key={option.type}
											style={{
												width: "fit-content",
												height: "32px",
												minWidth: "fit-content",
											}}
											className={clsx(
												"border flex items-center justify-center rounded-full w-fit border-z-dark-moss-green whitespace-nowrap text-sm py-5 px-4",
												isSelected
													? "bg-z-dark-moss-green !text-white !border-transparent"
													: "bg-white text-z-dark-moss-green",
											)}
											onClick={() => handleMustHave(option)}
											onKeyDown={() => handleMustHave(option)}
										>
											<span className="text-base font-bold">
												{option.displayName}
											</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>

				<div className="space-y-4">
					<span className="text-xl font-semibold text-z-shark">
						Nice to Haves
					</span>
					<div className="">
						<div className="mb-4 flex flex-col space-y-4">
							<div
								style={{ gap: "12px" }}
								className="flex flex-row flex-wrap items-center overflow-x-auto"
							>
								{filteredOptions.map((option) => {
									const isSelected = niceHaves.includes(option.type);

									return (
										<div
											key={option.type}
											style={{
												width: "fit-content",
												height: "32px",
												minWidth: "fit-content",
											}}
											className={clsx(
												"border flex items-center justify-center rounded-full w-fit border-z-dark-moss-green whitespace-nowrap text-sm py-5 px-4",
												isSelected
													? "bg-z-dark-moss-green !text-white !border-transparent"
													: "bg-white text-z-dark-moss-green",
											)}
											onClick={() => handleNiceHave(option)}
											onKeyDown={() => handleNiceHave(option)}
										>
											<span className="text-base font-bold">
												{option.displayName}
											</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-full flex items-center justify-center">
				<Button
					customClassName="text-lg font-semibold"
					onClick={handleNav}
					radius="60px"
					width="330px"
					height="md"
					disable={mustHaves.length === 0 || niceHaves.length === 0}
				>
					Continue
				</Button>
			</div>
		</div>
	);
};

export { NeedsWants };
