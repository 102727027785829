import clsx from "clsx";

type LineType = {
	stepType?: boolean;
};

const VerticalLine = ({ stepType = false }: LineType) => {
	return (
		<div
			className={clsx(
				stepType
					? "w-9 h-[1px] border border-dashed border-[#B5C9A9] absolute top-1/2 -translate-y-1/2 left-[22px]"
					: "w-[2px] h-20 bg-z-shark my-5 mx-auto",
			)}
		/>
	);
};

export { VerticalLine };
