import { create } from "zustand";

interface AuthState {
  authEmail: string;
  setAuthEmail: (authEmail: string) => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  authEmail: "",
  setAuthEmail: (authEmail: string) => set({ authEmail }),
}));
