import { useState } from "react";
import { PiArrowLeft } from "react-icons/pi";
import { BrowseListingLayout } from "../../layouts/browseListingLayout";
import { mockStatistics } from "../../mocks/newMockData/aboutMarketData";
import { browseListingData } from "../../mocks/newMockData/browseListingData";
import { mockDemographicResponse } from "../../mocks/newMockData/neighbourhoodInformationData";
import { AboutMarket } from "./AboutMarket/aboutMarket";
import BrowseListingSlider from "./browseListingSlider";
import { DetailsHome } from "./detailsHome";
import { GreatDeal } from "./greatDeal";
import { NeighbourhoodInformation } from "./neighbourhoodInformation";
import { PropertyAnalysis } from "./propertyAnalysis";
import { PropertyDetails } from "./propertyDetails";
import SimilarActiveListings from "./similarActiveListings";

const BrowseListingView = () => {
	const [overlayOpen, setOverlayOpen] = useState<boolean>(false);

	const closeOverlay = () => {
		setOverlayOpen(false);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			closeOverlay();
		}
	};

	return (
		<BrowseListingLayout customBg={true}>
			<div className="pt-20 w-full relative">
				<BrowseListingSlider setOverlayOpen={setOverlayOpen} />
				<div className="grid grid-cols-[minmax(0,1fr)] lg:grid-cols-[minmax(0,1fr)_380px] lg:gap-7 xl:gap-12 mt-16">
					<div>
						<DetailsHome />
						<PropertyDetails />
						<PropertyAnalysis />
						<NeighbourhoodInformation />
						<AboutMarket
							listing={mockDemographicResponse}
							statistics={mockStatistics}
						/>
					</div>
					<GreatDeal />
				</div>
				<SimilarActiveListings />

				{overlayOpen && (
					<div className="fixed inset-0 bg-white w-full h-full z-50 flex items-center justify-center">
						<div className="pb-10 w-full h-full overflow-auto">
							<div className="flex items-center justify-between mx-auto sticky top-0 h-24 bg-white">
								<div className="max-w-[70%] flex items-center justify-between w-full mx-auto">
									<div
										className="flex items-center space-x-3 cursor-pointer"
										onClick={closeOverlay}
										onKeyDown={handleKeyDown}
									>
										<PiArrowLeft className="text-xl" />
										<span className="text-z-shark font-bold">Back</span>
									</div>
									<p className="mt-2 text-z-shark text-2xl">
										{browseListingData[0].title}
									</p>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4 max-w-screen-lg mx-auto mt-8">
								{browseListingData[0].images.map((image, index) => (
									<img
										key={index}
										src={image}
										alt={`Overlay Image ${index + 1}`}
										className={`rounded-lg w-full object-cover ${index === 0 ? "col-span-2 row-span-2 h-[500px]" : "h-[300px]"}`}
									/>
								))}
							</div>
						</div>
					</div>
				)}
			</div>
		</BrowseListingLayout>
	);
};

export { BrowseListingView };
