interface IconProps {
	fill: string;
}
const OutdoorIcon = ({ fill }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="33"
			viewBox="0 0 50 33"
			fill="none"
		>
			<path
				d="M9.55172 32.0019C14.2747 32.0019 18.1034 28.1731 18.1034 23.4502C18.1034 18.7273 14.2747 14.8984 9.55172 14.8984C4.82873 14.8984 1 18.7273 1 23.4502C1 28.1731 4.82873 32.0019 9.55172 32.0019Z"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.0352 7.41379H29.8627M29.8627 7.41379H30.9317L39.4834 23.4483M29.8627 7.41379L34.1386 1M34.1386 1H28.7938M34.1386 1H38.4145"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.55078 23.4485L17.0335 7.41406L24.5163 21.3106H30.9301"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.0335 7.41379C16.3209 5.27586 13.8266 1 9.55078 1"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M39.4853 32.0019C44.2082 32.0019 48.037 28.1731 48.037 23.4502C48.037 18.7273 44.2082 14.8984 39.4853 14.8984C34.7624 14.8984 30.9336 18.7273 30.9336 23.4502C30.9336 28.1731 34.7624 32.0019 39.4853 32.0019Z"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default OutdoorIcon;
