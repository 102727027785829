import { Input } from "../common";
import { type ChangeEvent, useRef } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { handleKeyPress } from "../../utils/keyboardUtils";
import DollarIcon from "../../assets/icons/dollarIcon.svg";
import { useInputFlowStore } from "../../stores/inputFlowStore";

const MAX_INCOME = 400000;

const AnnualIncomeInputView = () => {
	const { income, setIncome } = useInputFlowStore();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		let value = Number.parseInt(e.target.value.replace(/[^\d]/g, ""));
		if (Number.isNaN(value)) {
			value = 0;
		}
		if (value > MAX_INCOME) {
			value = MAX_INCOME;
		}
		setIncome(formatPrice(value));
	};
	return (
		<div
			className="space-y-10 px-4"
			onKeyDown={(e) => handleKeyPress(e, inputRef)}
		>
			<h3 className="text-z-dark-moss-green text-2xl font-bold">
				What's your annual household income? (before tax).
			</h3>
			<Input
				inputType="tel"
				ref={inputRef}
				value={income}
				onChange={handleChange}
				startIcon={DollarIcon}
				radius="20px"
				label="Annual Household Income"
			/>
		</div>
	);
};

export { AnnualIncomeInputView };
