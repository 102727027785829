import {
	ImageBrowseListingDemo1,
	ImageBrowseListingDemo2,
	ImageBrowseListingDemo3,
	ImageBrowseListingDemo4,
} from "../../assets/BrowseListing/demo";
import type { CartComparisonProperty } from "../../interfaces/newInterfaces/comparisonProperty";

export const cartComparisonProperty: CartComparisonProperty[] = [
	{
		listingId: "1",
		address: {
			address1: "123 Main St",
			address2: "123 Main St",
			city: "New York",
			state: "New York",
			postalCode: "32423423",
			country: "york",
		},
		displayImageUrl: ImageBrowseListingDemo1,
		totalZownSavings: 5000,
		downPaymentNeeded: 50000,
		downPaymentGiven: 23500,
		rateBuydownSavings: 1000,
		propertyType: "Townhouse",
		listPrice: 450000,
		propertyTax: 5000,
		maintenanceFee: 318,
		bedrooms: "2+1",
		bathrooms: 1.5,
		squareFeet: "2340",
		squareFeetLot: "2340",
		basement: "No",
		numberOfParkingSpaces: "1",
		backyard: "Yes",
		pricePerSqft: "693",
		listToSoldRatio: 2,
		dom: 21,
		openHouseDate: "2023-09-15",
		mlsNumber: "C1558446",
		squareFootage: "3420",
		lotSquareFootage: "3420",
	},
	{
		listingId: "2",
		address: {
			address1: "123 Main St",
			address2: "123 Main St",
			city: "New York",
			state: "New York",
			postalCode: "32423423",
			country: "york",
		},
		displayImageUrl: ImageBrowseListingDemo2,
		totalZownSavings: 5000,
		downPaymentNeeded: 50000,
		downPaymentGiven: 23500,
		rateBuydownSavings: 1000,
		propertyType: "Townhouse",
		listPrice: 450000,
		propertyTax: 5000,
		maintenanceFee: 318,
		bedrooms: "2+1",
		bathrooms: 1.5,
		squareFeet: "2340",
		squareFeetLot: "2340",
		basement: "Yes - Unfinished",
		numberOfParkingSpaces: "1",
		backyard: "Yes",
		pricePerSqft: "693",
		listToSoldRatio: 2,
		dom: 21,
		openHouseDate: "2023-09-15",
		mlsNumber: "C5558446",
		squareFootage: "3420",
		lotSquareFootage: "3420",
	},
	{
		listingId: "3",
		address: {
			address1: "123 Main St",
			address2: "123 Main St",
			city: "New York",
			state: "New York",
			postalCode: "32423423",
			country: "york",
		},
		displayImageUrl: ImageBrowseListingDemo3,
		totalZownSavings: 5000,
		downPaymentNeeded: 50000,
		downPaymentGiven: 23500,
		rateBuydownSavings: 1000,
		propertyType: "Apartment",
		listPrice: 450000,
		propertyTax: 5000,
		maintenanceFee: 318,
		bedrooms: "2",
		bathrooms: 1,
		squareFeet: "2340",
		squareFeetLot: "2340",
		basement: "No",
		numberOfParkingSpaces: "1",
		backyard: "Yes",
		pricePerSqft: "693",
		listToSoldRatio: 1,
		dom: 10,
		openHouseDate: "2023-09-15",
		mlsNumber: "C1558416",
		squareFootage: "3420",
		lotSquareFootage: "3420",
	},
	{
		listingId: "4",
		address: {
			address1: "123 Main St",
			address2: "123 Main St",
			city: "New York",
			state: "New York",
			postalCode: "32423423",
			country: "york",
		},
		displayImageUrl: ImageBrowseListingDemo4,
		totalZownSavings: 5200,
		downPaymentNeeded: 50000,
		downPaymentGiven: 23500,
		rateBuydownSavings: 1000,
		propertyType: "Apartment",
		listPrice: 840000,
		propertyTax: 2000,
		maintenanceFee: 318,
		bedrooms: "2",
		bathrooms: 1,
		squareFeet: "2340",
		squareFeetLot: "2340",
		basement: "No",
		numberOfParkingSpaces: "1",
		backyard: "No",
		pricePerSqft: "693",
		listToSoldRatio: 1,
		dom: 10,
		openHouseDate: "2023-09-10",
		mlsNumber: "C1558300",
		squareFootage: "3420",
		lotSquareFootage: "3420",
	},
	{
		listingId: "5",
		address: {
			address1: "123 Main St",
			address2: "123 Main St",
			city: "New York",
			state: "New York",
			postalCode: "32423423",
			country: "york",
		},
		displayImageUrl: ImageBrowseListingDemo3,
		totalZownSavings: 5200,
		downPaymentNeeded: 50000,
		downPaymentGiven: 23500,
		rateBuydownSavings: 1000,
		propertyType: "Apartment",
		listPrice: 840000,
		propertyTax: 2000,
		maintenanceFee: 318,
		bedrooms: "2",
		bathrooms: 1,
		squareFeet: "2340",
		squareFeetLot: "2340",
		basement: "No",
		numberOfParkingSpaces: "1",
		backyard: "No",
		pricePerSqft: "693",
		listToSoldRatio: 1,
		dom: 10,
		openHouseDate: "2023-09-10",
		mlsNumber: "C1558300",
		squareFootage: "3420",
		lotSquareFootage: "3420",
	},
	{
		listingId: "6",
		address: {
			address1: "123 Main St",
			address2: "123 Main St",
			city: "New York",
			state: "New York",
			postalCode: "32423423",
			country: "york",
		},
		displayImageUrl: ImageBrowseListingDemo2,
		totalZownSavings: 5200,
		downPaymentNeeded: 50000,
		downPaymentGiven: 23500,
		rateBuydownSavings: 1000,
		propertyType: "Apartment",
		listPrice: 840000,
		propertyTax: 2000,
		maintenanceFee: 318,
		bedrooms: "2",
		bathrooms: 1,
		squareFeet: "2340",
		squareFeetLot: "2340",
		basement: "No",
		numberOfParkingSpaces: "1",
		backyard: "No",
		pricePerSqft: "693",
		listToSoldRatio: 1,
		dom: 10,
		openHouseDate: "2023-09-10",
		mlsNumber: "C1558300",
		squareFootage: "3420",
		lotSquareFootage: "3420",
	},
	{
		listingId: "7",
		address: {
			address1: "123 Main St",
			address2: "123 Main St",
			city: "New York",
			state: "New York",
			postalCode: "32423423",
			country: "york",
		},
		displayImageUrl: ImageBrowseListingDemo1,
		totalZownSavings: 5200,
		downPaymentNeeded: 50000,
		downPaymentGiven: 23500,
		rateBuydownSavings: 1000,
		propertyType: "Apartment",
		listPrice: 840000,
		propertyTax: 2000,
		maintenanceFee: 318,
		bedrooms: "2",
		bathrooms: 1,
		squareFeet: "2340",
		squareFeetLot: "2340",
		basement: "No",
		numberOfParkingSpaces: "1",
		backyard: "No",
		pricePerSqft: "693",
		listToSoldRatio: 1,
		dom: 10,
		openHouseDate: "2023-09-10",
		mlsNumber: "C1558300",
		squareFootage: "3420",
		lotSquareFootage: "3420",
	},
];

export const fieldTitles: { [key: string]: string } = {
	// "address.city": "City",
	// transitDistance: "Distance to Transit",
	// walkScore: "Walk Score",
	// bikeScore: "Bike Score",
	// busScore: "Bus Score",
	downPaymentGiven: "Down Payment Boost",
	propertyType: "Property Type",
	mlsId: "Property Type",
	mlsNumber: "MLS ID",
	listPrice: "Price",
	propertyTax: "Property Tax",
	maintenanceFee: "Maintenance Fee",
	bedrooms: "Bedrooms",
	bathrooms: "Bathrooms",
	squareFootage: "Square Footage",
	lotSquareFootage: "Lot Square Footage",
	basement: "Basement",
	numberOfParkingSpaces: "Parking",
	backyard: "Backyard",
	pricePerSqft: "Price per SQFT",
	dom: "Days on Market",
	openHouseDate: "Open House Date",
	listToSoldRatio: "List to Sold Ratio",
};

export const sections = [
	// {
	// 	title: "Location",
	// 	fields: [
	// 		"address.city",
	// 		//  "transitDistance",
	// 		//   "walkScore",
	// 		//    "bikeScore",
	// 		//     "busScore"
	// 	],
	// },
	{
		title: "Details",
		fields: [
			"downPaymentGiven",
			"propertyType",
			"mlsNumber",
			"listPrice",
			"propertyTax",
			"maintenanceFee",
		],
	},
	{
		title: "Features",
		fields: [
			"bedrooms",
			"bathrooms",
			"squareFootage",
			"lotSquareFootage",
			"basement",
		],
	},
	{
		title: "More Details",
		fields: [
			"numberOfParkingSpaces",
			"backyard",
			"pricePerSqft",
			"listToSoldRatio",
			"dom",
			"openHouseDate",
		],
	},
];
