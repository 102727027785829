import millify from "millify";
import { debounce, zip } from "lodash";
import { MapCard } from "../Card/mapCard";
import useSupercluster from "use-supercluster";
import type { PointFeature } from "supercluster";
import { Swiper, SwiperSlide } from "swiper/react";
import HouseIcon from "../../../assets/icons/houseIcon.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMapFiltersStore } from "../../../stores/filterMapStore";
import { selectedCitiesCoordinates } from "../../../citiesWithLatLong";
import { useFilteredPropertiesStore } from "../../../stores/exploreListings";
import { mapPropertyTypeForPost } from "../../common/FilterPanel/filterPanel";
import ReactMapGL, { Marker, type MapRef, type ViewState } from "react-map-gl";
import type { BuyerMatchingPreferencesPropertyTypeEnum } from "../../../interfaces/buyerMatchingPreferences";
import {
	convertToFormattedAmount,
	convertToNumberAndMultiplyBy100,
} from "../../../utils/convertAmount";
import {
	fetchExploreListings,
	parseBasement,
	parseDaysOnMarket,
	parseGarage,
	parseNumKitchen,
	parseStyle,
	transformFilterValues,
} from "../../../services/exploreListings";

import "swiper/css";
import "mapbox-gl/dist/mapbox-gl.css";
import { SiV } from "react-icons/si";
import { useMapStore } from "../../../stores/mapCordinations";

function getDistanceFromLatLonInMeters(
	lat1: number,
	lon1: number,
	lat2: number,
	lon2: number,
) {
	const R = 6371000;
	const dLat = ((lat2 - lat1) * Math.PI) / 180;
	const dLon = ((lon2 - lon1) * Math.PI) / 180;
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos((lat1 * Math.PI) / 180) *
			Math.cos((lat2 * Math.PI) / 180) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const distance = R * c;
	return distance;
}

interface Coordinates {
	long: number;
	lat: number;
}

interface Listing {
	listingId: string;
	displayImageUrl: string;
	displayAddress: string;
	listPrice: number;
	propertyType: string;
	coordinates: Coordinates;
	repliersUpdatedOn: string;
	downPaymentContribution: number;
	numBedrooms: number;
	numBathrooms: number;
	sqft: string;
}

interface PointProperties extends Listing {
	cluster: false;
	propertyId: string | number;
}

const MAPBOX_TOKEN =
	"pk.eyJ1IjoiYmVhcmNvZGVzODcwIiwiYSI6ImNtMHBmMjVqZzAwZWkycXEwYmcxcHJjMGwifQ.UeHMFKKaRuaTR-NqOPPsnw";

const SearchMapView = () => {
	const [properties, setProperties] = useState<Listing[]>([]);
	const [clickedProperties, setClickedProperties] = useState<Listing[]>([]);
	const { setFilteredProperties } = useFilteredPropertiesStore();

	const initialViewState = {
		longitude: -79.4163,
		latitude: 43.70011,
		zoom: 13,
		bearing: 0,
		pitch: 0,
	};

	const [viewState, setViewState] = useState<ViewState>(initialViewState);
	const [markerClicked, setMarkerClicked] = useState(false);
	const { setMapState } = useMapStore();

	const mapRef = useRef<MapRef | null>(null);
	const {
		minPrice,
		maxPrice,
		beds,
		baths,
		dens,
		parking,
		propertyType,
		numberOfKitchens,
		garageType,
		basement,
		style,
		daysOnMarket,
		mapSelectedCities,
		reloadMapCities,
		setSortFilter,
		sortFilter,
		setReloadMapCities,
	} = useMapFiltersStore();

	const fetchPropertiesBasedOnView = useCallback(async () => {
		if (mapRef.current) {
			const map = mapRef.current.getMap();
			const { lng, lat } = map.getCenter();
			const bounds = map.getBounds();
			const northEast = bounds.getNorthEast();

			const radius = getDistanceFromLatLonInMeters(
				lat,
				lng,
				northEast.lat,
				northEast.lng,
			);

			const fetchedProperties = await fetchExploreListings(
				"1",
				"1000",
				"map",
				sortFilter.sortOrder,
				lng,
				lat,
				radius,
				undefined,
				{
					numberOfBedrooms:
						beds.length > 0 ? transformFilterValues(beds) : null,
					numberOfBathrooms:
						baths.length > 0 ? transformFilterValues(baths) : null,

					numberOfParkingSpaces:
						parking.length > 0 ? transformFilterValues(parking) : null,

					dens: dens,
					priceRange: [
						convertToNumberAndMultiplyBy100(minPrice.toString()),
						convertToNumberAndMultiplyBy100(maxPrice.toString()),
					],
					propertyType:
						propertyType.length > 0
							? propertyType
									.map(mapPropertyTypeForPost)
									.filter(
										(pt): pt is BuyerMatchingPreferencesPropertyTypeEnum =>
											pt !== null,
									)
							: null,

					numKitchens: parseNumKitchen(numberOfKitchens),
					garage: parseGarage(garageType),
					daysOnMarket: parseDaysOnMarket(daysOnMarket),
					style: parseStyle(style),
					basement: parseBasement(basement),
					selectedCities: null,
				},
			);

			if (fetchedProperties) {
				console.log("Fetched properties after move:", fetchedProperties);
				setProperties(fetchedProperties);
				setMapState(lat, lng, radius);
				setFilteredProperties(fetchedProperties);
			}
		}
	}, [
		setFilteredProperties,
		minPrice,
		maxPrice,
		beds,
		baths,
		dens,
		parking,
		propertyType,
		numberOfKitchens,
		garageType,
		daysOnMarket,
		style,
		basement,
		setMapState,
		sortFilter,
	]);

	useEffect(() => {
		const fetchNewProperties = async () => {
			await fetchPropertiesBasedOnView();
		};
		if (reloadMapCities) {
			if (mapSelectedCities.length > 0) {
				const selectedCity = mapSelectedCities[0];
				const cityCoordinates = selectedCitiesCoordinates[selectedCity];
				if (cityCoordinates && mapRef.current) {
					const [longitude, latitude] = cityCoordinates.map(Number);
					console.log("Longitude and latitude:", longitude, latitude);
					mapRef.current.flyTo({
						center: [longitude, latitude],
						zoom: 13,
						essential: true,
					});
				}
			}
			fetchNewProperties();

			setReloadMapCities(false);
		}
	}, [
		reloadMapCities,
		setReloadMapCities,
		fetchPropertiesBasedOnView,
		mapSelectedCities,
	]);

	const fetchProperties = useCallback(async () => {
		try {
			const fetchedProperties = await fetchExploreListings(
				"1",
				"1000",
				"map",
				sortFilter.sortOrder,
				-79.4163,
				43.70011,
				2000,
				undefined,
				{
					numberOfBedrooms:
						beds.length > 0 ? transformFilterValues(beds) : null,
					numberOfBathrooms:
						baths.length > 0 ? transformFilterValues(baths) : null,

					numberOfParkingSpaces:
						parking.length > 0 ? transformFilterValues(parking) : null,

					dens: dens,
					priceRange: [
						convertToNumberAndMultiplyBy100(minPrice.toString()),
						convertToNumberAndMultiplyBy100(maxPrice.toString()),
					],
					propertyType:
						propertyType.length > 0
							? propertyType
									.map(mapPropertyTypeForPost)
									.filter(
										(pt): pt is BuyerMatchingPreferencesPropertyTypeEnum =>
											pt !== null,
									)
							: null,

					numKitchens: parseNumKitchen(numberOfKitchens),
					garage: parseGarage(garageType),
					daysOnMarket: parseDaysOnMarket(daysOnMarket),
					style: parseStyle(style),
					basement: parseBasement(basement),
					selectedCities: null,
				},
			);
			if (fetchedProperties) {
				console.log("Fetched properties:", fetchedProperties);
				setProperties(fetchedProperties);
				setCoordinates(43.70011, -79.4163);
				setFilteredProperties(fetchedProperties);
				moveToSelectedCity();
			}
		} catch (error) {
			console.error("Error fetching properties:", error);
		}
	}, [
		setFilteredProperties,
		minPrice,
		maxPrice,
		beds,
		baths,
		dens,
		parking,
		propertyType,
		numberOfKitchens,
		garageType,
		daysOnMarket,
		style,
		sortFilter,
		basement,
	]);
	const moveToSelectedCity = useCallback(() => {
		if (mapSelectedCities.length > 0) {
			const selectedCity = mapSelectedCities[0];
			const cityCoordinates = selectedCitiesCoordinates[selectedCity];
			if (cityCoordinates && mapRef.current) {
				const [longitude, latitude] = cityCoordinates.map(Number);
				console.log("Longitude and latitude:", longitude, latitude);
				mapRef.current.flyTo({
					center: [longitude, latitude],
					zoom: 13,
					essential: true,
				});
			}
		}
	}, [mapSelectedCities]);
	useEffect(() => {
		fetchProperties();
	}, [fetchProperties]);

	const points: PointFeature<PointProperties>[] = properties.map(
		(property) => ({
			type: "Feature",
			properties: {
				cluster: false,
				propertyId: property.listingId,
				...property,
			},
			geometry: {
				type: "Point",
				coordinates: [
					Number.parseFloat(property.coordinates.long.toString()),
					Number.parseFloat(property.coordinates.lat.toString()),
				],
			},
		}),
	);

	const map = mapRef.current?.getMap();

	let bounds: [number, number, number, number] | undefined;

	if (map) {
		const mapBounds = map.getBounds();
		if (mapBounds) {
			bounds = mapBounds.toArray().flat() as [number, number, number, number];
		}
	}

	const { clusters, supercluster } = useSupercluster({
		points,
		bounds,
		zoom: viewState.zoom,
		options: { radius: 75, maxZoom: 20 },
	});

	const handleOnMove = useCallback(
		debounce((event) => {
			setViewState(event.viewState);
		}, 300),
		[],
	);

	const handleOnMoveEnd = useCallback(() => {
		if (!markerClicked) {
			setClickedProperties([]);
		}
		setMarkerClicked(false);
		fetchPropertiesBasedOnView();
	}, [fetchPropertiesBasedOnView, markerClicked]);

	const handleMarkerClick = useCallback(
		(clusterId: number | null, longitude: number, latitude: number) => {
			setMarkerClicked(true);
			if (clusterId) {
				const clusterExpansionZoom =
					supercluster?.getClusterExpansionZoom(clusterId);
				mapRef.current?.flyTo({
					center: [longitude, latitude],
					zoom: clusterExpansionZoom,
				});
				const clusteredPoints = supercluster?.getLeaves(
					clusterId,
					Number.POSITIVE_INFINITY,
				);

				if (clusteredPoints) {
					const clusteredProperties = clusteredPoints.map(
						(point) => point.properties,
					) as Listing[];
					setClickedProperties(clusteredProperties);
				}
			} else {
				const clickedProperty = properties.find(
					(property) =>
						property.coordinates.long === longitude &&
						property.coordinates.lat === latitude,
				);
				if (clickedProperty) {
					setClickedProperties([clickedProperty]);
				}
			}
		},
		[supercluster, properties],
	);

	const renderCustomMarker = useCallback(
		(property: Listing) => (
			<div className="bg-z-dark-moss-green font-medium text-[17px] border-white border-4 rounded-full px-2 py-1 shadow-lg text-white flex gap-2 items-center justify-center">
				<img src={HouseIcon} alt="House Icon" className="w-[16.49px]" />
				<span>
					{millify(convertToFormattedAmount(property.listPrice)) || "Property"}
				</span>
			</div>
		),
		[],
	);

	const renderClusterMarker = useCallback(
		(pointCount: number) => (
			<div className="cluster-marker bg-z-dark-moss-green border-white border-4 shadow-lg text-white rounded-full w-[50px] h-[50px] text-xl flex items-center justify-center">
				{pointCount}
			</div>
		),
		[],
	);

	return (
		<div className="fixed inset-0">
			<ReactMapGL
				mapboxAccessToken={MAPBOX_TOKEN}
				mapStyle="mapbox://styles/mapbox/streets-v11"
				ref={mapRef}
				initialViewState={initialViewState}
				onMove={handleOnMove}
				onMoveEnd={handleOnMoveEnd}
				style={{ width: "100%", height: "100%" }}
			>
				{clusters.map((cluster) => {
					const [longitude, latitude] = cluster.geometry.coordinates;
					const {
						cluster: isCluster,
						point_count: pointCount,
						cluster_id: clusterId,
					} = cluster.properties;

					if (isCluster) {
						return (
							<Marker
								key={`cluster-${clusterId}`}
								longitude={longitude}
								latitude={latitude}
							>
								<button
									type="button"
									onClick={() =>
										handleMarkerClick(clusterId, longitude, latitude)
									}
									style={{
										background: "none",
										border: "none",
										padding: 0,
										cursor: "pointer",
									}}
								>
									{renderClusterMarker(pointCount)}
								</button>
							</Marker>
						);
					}

					const propertyId = cluster.properties.propertyId as string;

					return (
						<Marker
							key={`property-${propertyId}`}
							longitude={longitude}
							latitude={latitude}
						>
							<button
								type="button"
								onClick={() => handleMarkerClick(null, longitude, latitude)}
								style={{
									background: "none",
									border: "none",
									padding: 0,
									cursor: "pointer",
								}}
							>
								{renderCustomMarker(cluster.properties)}
							</button>
						</Marker>
					);
				})}
			</ReactMapGL>

			{clickedProperties.length > 0 && (
				<Swiper
					slidesPerView={clickedProperties.length > 1 ? 1.2 : 1}
					spaceBetween={15}
					centeredSlides={true}
					loop={false}
					grabCursor={true}
					className="absolute bottom-[260px]"
				>
					{clickedProperties.map((property) => (
						<SwiperSlide key={property.listingId} className="w-full">
							<div className="flex w-full justify-center">
								<MapCard property={property} />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			)}
		</div>
	);
};

export { SearchMapView };
