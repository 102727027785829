import styles from "./intro.style.module.css";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import TimerIcon from "../../assets/timerIcon.svg";
import { Pagination, Autoplay } from "swiper/modules";
// import HomeIcon from "../../assets/icons/homeIcon.svg";
import IntroImage01 from "../../assets/introImage01.png";
import IntroImage02 from "../../assets/introImage02.png";
import IntroImage03 from "../../assets/introImage03.png";
import IntroImage04 from "../../assets/introImage04.png";
import IntroImage05 from "../../assets/introImage05.png";
import CheckMarkIcon from "../../assets/checkMarkIcon.svg";
// import CheckIcon from "../../assets/icons/checkCircleIcon.svg";
import { useAdvertisementStep } from "../../stores/advertisementFlowStore";
// import PaymentProvidedIcon from "../../assets/icons/paymentProvidedIcon.svg";
import {
	CanadaFlag,
	// homeOwnershipSlider1,
	// homeOwnershipSlider2,
	// homeOwnershipSlider3,
	// homeOwnershipSlider4,
	// homeOwnershipSlider5,
} from "../../assets/static/images/index";

import "swiper/css";
import "swiper/css/pagination";

const IntroView = () => {
	const navigate = useNavigate();
	const { setAdvertisementStep } = useAdvertisementStep();
	return (
		<div className="absolute inset-0">
			<div className="h-[183px] max-h-[] w-full bg-[#535560]">
				<Swiper
					className="h-full"
					loop={true}
					modules={[Pagination, Autoplay]}
					autoplay={{ delay: 3000, disableOnInteraction: true }}
					slidesPerView={1}
					pagination={{
						clickable: true,
						bulletClass: `swiper-pagination-bullet ${styles.swiperPaginationBullet}`,
						bulletActiveClass: styles.swiperPaginationBulletActive,
					}}
				>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={IntroImage01}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Jaselyn became a homeowner in March</span>
						</div>
					</SwiperSlide>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={IntroImage02}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Ilsa became a homeowner in May</span>
						</div>
					</SwiperSlide>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={IntroImage03}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Josh became a homeowner in June</span>
						</div>
					</SwiperSlide>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={IntroImage04}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Suren became a homeowner in June</span>
						</div>
					</SwiperSlide>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={IntroImage05}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Naja & Shabnam became homeowners in July</span>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
			<div className="mx-7 mt-6 space-y-5">
				<div className="bg-z-abbey w-fit rounded-full py-1 px-2 text-[10px] flex items-center gap-1">
					<img src={TimerIcon} alt="" />
					less than 60 seconds
				</div>
				<h3 className="text-z-shark text-4xl font-bold mb-2">
					Zown Down <br /> Payment Boost
				</h3>
				<div className="space-y-3">
					<div className="text-lg">
						See how much we can provide to
						<br /> <span className="font-bold">help you buy your home.</span>
					</div>
					<div className="space-y-3">
						<div className="text-z-shark font-light ml-1 mr-3 flex  items-center gap-1">
							<img src={CheckMarkIcon} alt="" />
							Qualify for up to
							<span className="font-bold">$50,000</span>
						</div>
						<div className="text-z-shark font-light ml-1 mr-3 flex items-center gap-1.5">
							<img src={CheckMarkIcon} alt="" />
							Buy
							<span className="font-bold">any home</span>
							on the market
						</div>
						<div className="text-z-shark font-light ml-1 mr-3 flex  items-center gap-1.5">
							<img src={CheckMarkIcon} alt="" />
							We
							<span className="font-bold"> don't take any ownership</span>
						</div>
						<div className="text-z-shark font-light ml-1 mr-3 flex  items-center gap-1.5">
							<img src={CheckMarkIcon} alt="" />

							<span className="font-bold">No repayments required</span>
						</div>
					</div>
				</div>
				<button
					type="button"
					onClick={() => {
						navigate("/login/about-yourself-view");
						setAdvertisementStep(0);
					}}
					className="mt-3 flex flex-col  py-2 bg-z-dark-moss-green items-center justify-center rounded-full text-white w-full shadow-md drop-shadow-lg"
				>
					<div className="font-extrabold text-xl">Apply now</div>
					<div className="text-xs">
						Limited to{" "}
						<span className="text-z-abbey">first 200 applicants</span>
					</div>
				</button>
			</div>
		</div>
	);
};

export { IntroView };
