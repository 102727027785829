interface IconProps {
	fill?: string;
	strokeWidth?: string;
}
const PlusIcon = ({ fill = "#32302F", strokeWidth = "1.5" }: IconProps) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="&#240;&#159;&#166;&#134; icon &#34;plus&#34;">
				<path
					id="Vector"
					d="M7.69531 1V14.3889"
					stroke={fill}
					strokeWidth={strokeWidth}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_2"
					d="M1 7.69531H14.3889"
					stroke={fill}
					strokeWidth={strokeWidth}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};
export default PlusIcon;
