import { Bridge } from "../../bridge.mts";
import { useNavigate } from "react-router-dom";
import BellIcon from "../../assets/bellIcon.svg";
import ZownIcon from "../../assets/zown-logo-icon-white.svg";

const EnableNotifications = () => {
	const navigate = useNavigate();

	const handleAllow = async () => {
		try {
			const requestPushNotification =
				await Bridge.requestPushNotificationAuthorization();
			console.log("requestPushNotification", requestPushNotification);
			navigate("/");
		} catch (error) {
			console.error("Error requesting push notification authorization", error);
		}
	};

	const handleDeny = () => {
		const currentTimestamp = new Date().toISOString();
		localStorage.setItem(
			"notificationSkipTimestamp",
			JSON.stringify({ requestedAt: currentTimestamp }),
		);

		navigate("/");
	};

	return (
		<div className="w-full text-black flex flex-col items-center text-center justify-center space-y-4 pt-5 pb-6 bg-white px-2">
			<img src={BellIcon} alt="" className="w-[140px]" />
			<div className="space-y-6">
				<div className="font-bold text-3xl">Stay Updated</div>
				<div className="max-w-[340px]">
					Enabling notifications keeps you informed about local market and your
					home search. It's the easiest way to stay-up-to-date on your home
					buying journey.
				</div>
			</div>
			<div className="bg-[#A6A6A6]/20 rounded-[24px] flex items-center py-3 px-3 gap-2 w-full">
				<div className="bg-z-prussian-blue w-[45px] h-[45px] flex items-center justify-center rounded-[8.5px]">
					<img src={ZownIcon} alt="" className="w-[45px] h-[45px]" />
				</div>
				<div className="flex flex-col items-start text-start w-full">
					<div className="flex justify-between w-full items-center">
						<div className="font-semibold">Price Update Alert!🚨</div>
						<div className="text-[#7F7F7F80] text-sm">10:31 AM</div>
					</div>
					<div>Price change for a home you saved</div>
				</div>
			</div>
			<div className="bg-[#A6A6A6]/20 rounded-[24px] flex items-center py-3 px-3 gap-2 w-full">
				<div className="bg-z-prussian-blue w-[45px] h-[45px] flex items-center justify-center rounded-[8.5px]">
					<img src={ZownIcon} alt="" className="w-[45px] h-[45px]" />
				</div>
				<div className="flex flex-col items-start text-start w-full">
					<div className="flex justify-between w-full items-center">
						<div className="font-semibold">Your Showings Await 🏠</div>
						<div className="text-[#7F7F7F80] text-sm">9:45 AM</div>
					</div>
					<div>
						Confirmed for November 23rd at 5:00 PM for 3 properties with your
						Realtor
					</div>
				</div>
			</div>
			<div className="bg-[#A6A6A6]/20 rounded-[24px] flex items-center py-3 px-3 gap-2 w-full">
				<div className="bg-z-prussian-blue w-[45px] h-[45px] flex items-center justify-center rounded-[8.5px]">
					<img src={ZownIcon} alt="" className="w-[45px] h-[45px]" />
				</div>
				<div className="flex flex-col items-start text-start w-full">
					<div className="flex justify-between w-full items-center">
						<div className="font-semibold">Your Pre-Approval is Here 🎉</div>
						<div className="text-[#7F7F7F80] text-sm">9:00 AM</div>
					</div>
					<div>Find out your latest pre-approval amount</div>
				</div>
			</div>
			<div className="space-y-2 flex flex-col items-center justify-center">
				<button
					type="button"
					className="text-z-dark-moss-green w-[270px] font-medium py-3.5 bg bg-transparent"
					onClick={() => {
						handleDeny();
					}}
				>
					Skip For Now
				</button>
				<button
					type="button"
					className="bg-z-dark-moss-green w-[270px] text-white rounded-full py-6 font-semibold text-xl"
					onClick={() => {
						handleAllow();
					}}
				>
					Enable Notifications
				</button>
			</div>
		</div>
	);
};

export { EnableNotifications };
