/* eslint-disable tsdoc/syntax */
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

/**
 * Custom error class for invalid phone numbers.
 */
class InvalidPhoneNumberError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "InvalidPhoneNumberError";
  }
}

/**
 * Custom error class for phone number formatting errors.
 */
class PhoneNumberFormatError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "PhoneNumberFormatError";
  }
}

/**
 * PhoneValidator class for validating and formatting phone numbers.
 */
export class PhoneValidator {
  private static phoneUtil = PhoneNumberUtil.getInstance();

  /**
   * Validates a phone number.
   * @param {string} phone - The phone number to validate.
   * @param {string} countryCode - The ISO 3166-1 two-letter country code.
   * @returns {boolean} - Returns true if the phone number is valid, false otherwise.
   * @throws {InvalidPhoneNumberError} - Throws an InvalidPhoneNumberError if the phone number is invalid.
   * @example
   * const isValid = PhoneValidator.isValid('+14155552671', 'US');
   * console.log(isValid); // true
   */
  public static isValid(phone: string, countryCode: string): boolean {
    try {
      const phoneNumber = this.phoneUtil.parseAndKeepRawInput(
        phone,
        countryCode
      );
      const isValid = this.phoneUtil.isValidNumber(phoneNumber);
      if (!isValid) {
        throw new InvalidPhoneNumberError(`Invalid phone number: ${phone}`);
      }
      return isValid;
    } catch (error) {
      throw new InvalidPhoneNumberError(
        error instanceof Error ? error.message : "Unknown error"
      );
    }
  }

  /**
   * Formats a phone number in E.164 format.
   * @param {string} phone - The phone number to format.
   * @param {string} countryCode - The ISO 3166-1 two-letter country code.
   * @returns {string} - Returns the phone number in E.164 format.
   * @throws {PhoneNumberFormatError} - Throws a PhoneNumberFormatError if the phone number can't be formatted.
   * @example
   * const formattedPhone = PhoneValidator.formatE164('+14155552671', 'US');
   * console.log(formattedPhone); // '+14155552671'
   */
  public static formatE164(phone: string, countryCode: string): string {
    try {
      const phoneNumber = this.phoneUtil.parseAndKeepRawInput(
        phone,
        countryCode
      );
      if (!this.phoneUtil.isValidNumber(phoneNumber)) {
        throw new InvalidPhoneNumberError(`Invalid phone number: ${phone}`);
      }
      return this.phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
    } catch (error) {
      throw new PhoneNumberFormatError(
        error instanceof Error ? error.message : "Unknown error"
      );
    }
  }
}
