import { useNavigate } from "react-router-dom";
import { type ChangeEvent, useState } from "react";
import { deleteAccount } from "../../services/account";
import LoseAccessIcon from "../../assets/Profile/loseAccessIcon.svg";
import DeleteDataIcon from "../../assets/Profile/deleteDataIcon.svg";
import DisconnectedFromBuyingPlanIcon from "../../assets/Profile/disconnectedFromBuyingPlanIcon.svg";

const DeleteAccountView = () => {
	const [agreementChecked, setAgreementChecked] = useState(false);
	const navigate = useNavigate();

	return (
		<div className="text-black space-y-6 px-2 pt-2 pb-6">
			<div className="text-2xl font-extrabold">
				Are you sure you want to delete your account?
			</div>
			<div>If you delete your account:</div>

			<div className="flex items-start w-full gap-4">
				<img src={LoseAccessIcon} alt="Lose Access" className="w-6 h-6" />
				<div className="font-bold flex-1">
					You'll lose access to Zown's exclusive 1.8% down payment assistance.{" "}
					<span className="font-normal">
						You won't be able to unlock this feature again unless you create a
						new account.
					</span>
				</div>
			</div>

			<div className="flex items-start w-full gap-4">
				<img src={DeleteDataIcon} alt="Delete Data" className="w-6 h-6" />
				<div className="font-bold flex-1">
					All data about your matches and preferences will be permanently
					deleted.{" "}
					<span className="font-normal">
						This includes all the property matches our algorithm has curated
						based on your preferences.
					</span>
				</div>
			</div>

			<div className="flex items-start w-full gap-4">
				<img
					src={DisconnectedFromBuyingPlanIcon}
					alt="Disconnected from Buying Plan"
					className="w-6 h-6"
				/>
				<div className="font-bold flex-1">
					You'll be disconnected from your personalized home buying plan and any
					saved progress.{" "}
					<span className="font-normal">
						This includes any financial planning tools, saved searches, and
						completed tasks towards your home buying journey.
					</span>
				</div>
			</div>

			<div>
				Once you delete your account, you'll be logged out of this app. If
				you're logged into any other Zown apps, it could take up to 1 hour for
				your session to expire.
			</div>
			<div>
				To access your account information after you delete your account,
				contact our customer service team.
			</div>
			<div>
				If you change your mind, you can always come back and open a new account
				with us.
			</div>

			<div className="font-bold">
				Are you sure you want to delete your account? (This can't be undone)
			</div>

			<div className="flex items-center w-full">
				<input
					id="default-checkbox"
					type="checkbox"
					checked={agreementChecked}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						setAgreementChecked(e.target.checked)
					}
					className="w-[30px] h-[30px] text-z-dark-moss-green bg-[#F9F9F9] rounded-[8px] border-[#A4A4A4]"
				/>
				<label
					htmlFor="default-checkbox"
					className="ms-2 text-sm font-medium h-full text-[#32302F]"
				>
					Yes, I want to delete my account.
				</label>
			</div>

			<div className="text-[#A7A7A7] text-xs">
				After you submit your request, we will disable your account. It may take
				up to 30 days to fully delete and remove all of your data.
			</div>

			<button
				type="button"
				disabled={!agreementChecked}
				onClick={async () => {
					try {
						await deleteAccount();
					} catch (error) {
						console.error("Account deletion failed:", error);
					}
					navigate("/profile/delete-account/miss-you");
				}}
				className="w-full rounded-full font-bold py-3 bg-z-dark-moss-green text-white disabled:bg-[#D4D4D4] disabled:text-[#7D7D7D]"
			>
				Delete Account
			</button>
			<button
				type="button"
				className="w-full rounded-full font-bold py-3 border-2 border-[#D4D4D4] text-black"
				onClick={() => navigate(-1)}
			>
				Cancel
			</button>
		</div>
	);
};

export { DeleteAccountView };
