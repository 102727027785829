import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../../utils/formatPrice";
import WhiteArrowRightIcon from "../../../assets/whiteRightArrow.svg";
import { convertToFormattedAmount } from "../../../utils/convertAmount";

interface CmaBoxProps {
	address: string;
	zlid: string;
	listingPrice?: string;
	valuation: number | undefined;
}

const CmaBox = ({ address, zlid, listingPrice, valuation }: CmaBoxProps) => {
	const navigate = useNavigate();

	const listPriceNumber = convertToFormattedAmount(
		Number.parseFloat(listingPrice ?? "0"),
	);

	const isUnderlisted = valuation !== undefined && listPriceNumber < valuation;
	const label =
		valuation === undefined || valuation === 0
			? "N/A"
			: isUnderlisted
				? "Underlisted"
				: "Overlisted";
	const labelColor = isUnderlisted ? "#6ECE35" : "#FF6B6B";
	const labelBgColor = isUnderlisted ? "#DEF2D3" : "#FFE5E5";

	return (
		<div className="px-4">
			<div className="w-full bg-[#EEF7E9] rounded-[15px] p-4 space-y-4">
				<div className="w-full justify-between flex items-start">
					<div className="space-y-1 w-full">
						<div className="text-xs font-medium w-full">{address}</div>
						{label !== "N/A" && (
							<div
								className="w-fit px-4 border-2 rounded-[5px] font-extrabold text-center"
								style={{
									color: labelColor,
									borderColor: labelColor,
									backgroundColor: labelBgColor,
								}}
							>
								{label}
							</div>
						)}
					</div>
					<div className="w-full flex items-end justify-end">
						<button
							type="button"
							className="text-xs font-bold w-fit text-white bg-z-dark-moss-green p-2 rounded-full flex items-center gap-2"
							onClick={() => navigate(`/listing/${zlid}/cma`)}
						>
							<div>Instant CMA</div>
							<img src={WhiteArrowRightIcon} alt="CMA" />
						</button>
					</div>
				</div>
				<div className="flex gap-3 w-full">
					<div className="bg-z-abbey w-full p-2 rounded-md">
						<div className="text-xs">List Price</div>
						<div className="font-extrabold text-lg">
							${formatPrice(convertToFormattedAmount(listingPrice))}
						</div>
					</div>
					{valuation !== 0 && valuation && (
						<div className="bg-z-prussian-blue w-full text-white p-2 rounded-md">
							<div className="text-xs">Estimated Value</div>
							<div className="font-extrabold text-lg">
								${formatPrice(valuation)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export { CmaBox };
