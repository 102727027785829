import Xbutton from "../../assets/xButtonIcon.svg";
import DragIcon from "../../assets/reorderBlackIcon.svg";
import { Droppable, Draggable } from "@hello-pangea/dnd";

interface DateItem {
	id: string;
	date: string;
}

interface DateListProps {
	dates: DateItem[];
	removeDate: (id: string) => void;
}

export const DateList = ({ dates, removeDate }: DateListProps) => (
	<div className="w-full items-center flex flex-col text-center text-z-black space-y-2">
		<div className="text-xs">Drag and reorder by preference</div>
		<Droppable droppableId="droppableDates">
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
					className="w-full"
				>
					{dates.map((item, index) => (
						<div key={item.id} className="mb-2">
							{index === 0 && (
								<div className="font-medium text-xs mb-1 text-start">
									Showing Date
								</div>
							)}
							{index === 1 && (
								<div className="font-medium text-xs mb-1 text-start">
									Backup Date
								</div>
							)}
							<Draggable
								key={item.id}
								draggableId={item.id.toString()}
								index={index}
							>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										className={`flex w-full items-center font-semibold mb-4 ${
											snapshot.isDragging ? "opacity-70 grabbing" : ""
										}`}
										style={{
											...provided.draggableProps.style,
											transition: "transform 0.2s ease",
										}}
									>
										<div className="bg-[#F9F9F9] flex items-center w-full p-2 rounded-md shadow-sm">
											<div className="mr-2">
												<img src={DragIcon} alt="" />
											</div>
											<div>{item.date}</div>
											<button
												type="button"
												className="ml-auto text-red-600"
												onClick={() => removeDate(item.id)}
											>
												<img src={Xbutton} alt="Remove" className="w-3 h-3" />
											</button>
										</div>
									</div>
								)}
							</Draggable>
						</div>
					))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	</div>
);
