import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Chart, { type TooltipItem } from "chart.js/auto";
import { formatPrice } from "../../utils/formatPrice";
import type { PercentageOverAskingPrice } from "../../interfaces/statistics";

interface OfferPredictionProps {
	zlid: string;
	dom: number;
	percentageOverAskingPrice?: PercentageOverAskingPrice | null;
	comunity: string;
}

const convertDataToGraphFormat = (data: Record<string, number> | undefined) => {
	if (!data) {
	  return { xValues: [], yValues: [] };
	}
  
	const orderedKeys = ["0-7 days", "7-14 days", "14-30 days", "30+ days"];
  
	const xValues: string[] = [];
	const yValues: number[] = [];
  
	for (const key of orderedKeys) {
	  if (key in data) {
		xValues.push(key);
		yValues.push(data[key]);
	  }
	}
  
	return { xValues, yValues };
  };

const OfferPrediction = ({
	zlid,
	dom,
	percentageOverAskingPrice,
	comunity,
}: OfferPredictionProps) => {
	const averageDaysOnMarket = percentageOverAskingPrice?.average_dom || 0;
	const offeredPrice = percentageOverAskingPrice?.suggested_offer;

	const navigate = useNavigate();

	const getBgColor = (min: number, max: number) => {
		return averageDaysOnMarket >= min && averageDaysOnMarket <= max
			? "#CFFFD1"
			: "#F3F3F3";
	};

	const getPointerPosition = () => {
		if (dom <= 7) {
			return `${(dom / 7) * 25}%`;
		}
		if (dom <= 14) {
			return `${25 + ((dom - 7) / 7) * 25}%`;
		}
		if (dom <= 30) {
			return `${50 + ((dom - 14) / 16) * 25}%`;
		}
		return "100%";
	};

	const getAvgDomPosition = () => {
		if (averageDaysOnMarket <= 7) {
			return "0%";
		}
		if (averageDaysOnMarket <= 14) {
			return "25%";
		}
		if (averageDaysOnMarket <= 30) {
			return "50%";
		}
		return "75%";
	};

	const chartRef = useRef<Chart | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	useEffect(() => {
		if (canvasRef.current && percentageOverAskingPrice) {
		  if (chartRef.current) {
			chartRef.current.destroy();
		  }
	  
		  const ctx = canvasRef.current.getContext("2d");
		  if (ctx) {
			const { xValues, yValues } = convertDataToGraphFormat(
			  percentageOverAskingPrice.doms_vs_prc_diff, // Ensure this exists
			);
	  
			const gradient = ctx.createLinearGradient(0, 0, 0, 600);
			gradient.addColorStop(0, "rgba(254, 209, 28, 0.7)");
			gradient.addColorStop(1, "rgba(254, 209, 28, 0)");
	  
			chartRef.current = new Chart(ctx, {
			  type: "line",
			  data: {
				labels: xValues,
				datasets: [
				  {
					label: "List to Sold Percentage",
					fill: true,
					backgroundColor: gradient,
					pointRadius: 0,
					borderColor: "#FECD07",
					tension: 0.7,
					data: yValues,
				  },
				],
			  },
			  options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
				  legend: {
					display: false,
					position: "top",
				  },
				  tooltip: {
					mode: "index",
					intersect: false,
					callbacks: {
					  label: (tooltipItem: TooltipItem<"line">) =>
						`${tooltipItem.dataset.label}: ${tooltipItem.parsed.y.toFixed(2)}%`,
					},
				  },
				},
				scales: {
				  y: {
					title: {
					  display: true,
					  text: "List to Sold Percentage",
					},
					beginAtZero: true,
					ticks: {
					  stepSize: 5,
					},
				  },
				  x: {
					title: {
					  display: true,
					  text: "Days on Market",
					},
					grid: {
					  display: false,
					},
					ticks: {
					  autoSkip: false,
					},
				  },
				},
			  },
			});
		  }
		}
	  
		return () => {
		  chartRef.current?.destroy();
		};
	  }, [percentageOverAskingPrice]);

	return (
		<div className="w-full px-4 space-y-10">
			<div className="space-y-4">
				<div>
					<div className="text-xl font-medium">Offer Prediction:</div>
					<div className="font-bold text-2xl">
						Percentage Over Asking Price Distribution
					</div>
				</div>
				<div className="">{comunity}</div>
				<div className="flex justify-between items-center">
				<div className="flex w-full justify-between items-center gap-3">
					{offeredPrice && (
						<div className="bg-z-dark-moss-green text-white px-4 py-[18px] rounded-[10px] w-fit">
							<div className="text-sm">Offer suggestion:</div>

							<div className="text-[32px] font-extrabold">
								${formatPrice(offeredPrice)}
							</div>
						</div>
					)}
					{/* <button
						type="button"
						className="text-[13px] min-h-10 font-bold underline"
						onClick={() => navigate(`/listing/${zlid}/cma`)}
					>
						Get an Instant CMA {">"}
					</button> */}
				</div>
				<div className="space-y-10 w-full">
					<div>
						<div className="text-sm font-bold">
							Days on Market in {comunity}
						</div>
						<div className="text-[#7D7D7D] text-xs">Last 60 days</div>
					</div>
				<div className="w-full flex justify-between items-center relative">
					<div
						className="text-xs w-full border-r border-r-[#C8C8C8] text-center flex items-center justify-center py-2 rounded-l-full"
						style={{ backgroundColor: getBgColor(0, 7) }}
					>
						0-7
					</div>
					<div
						className="text-xs w-full border-r border-r-[#C8C8C8] text-center flex items-center justify-center py-2"
						style={{ backgroundColor: getBgColor(7, 14) }}
					>
						7-14
					</div>
					<div
						className="text-xs w-full border-r border-r-[#C8C8C8] text-center flex items-center justify-center py-2"
						style={{ backgroundColor: getBgColor(14, 30) }}
					>
						14-30
					</div>
					<div
						className="text-xs w-full text-center flex items-center justify-center py-2 rounded-r-full"
						style={{
							backgroundColor: getBgColor(30, Number.POSITIVE_INFINITY),
						}}
					>
						30+
					</div>
					<div
						className="absolute flex flex-col items-center w-[2px] h-[60px] -translate-y-4"
						style={{ left: getPointerPosition() }}
					>
						<div className="text-xs font-bold mb-1 flex items-center">{dom} <span className="pl-1">days</span></div>
						<div className="h-full w-1 border-dashed border-r-2 border-z-dark-moss-green" />
					</div>

					<div
						className="bg-[#CFFFD1] text-xs px-1 font-medium absolute top-full w-fit text-center py-2 mt-2 rounded-[5px]"
						style={{ left: getAvgDomPosition() }}
					>
						Avg D.O.M
					</div>
				</div>
				</div>
			</div>
			</div>

			<canvas ref={canvasRef} id="myChart" className="h-auto max-h-[245px]" />
		</div>
	);
};

export { OfferPrediction };
