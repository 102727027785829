import { create } from "zustand";
import type { CartComparisonProperty } from "../../interfaces/newInterfaces/comparisonProperty";

interface CartComparisonStore {
	properties: CartComparisonProperty[];
	setProperties: (newProperties: CartComparisonProperty[]) => void;
	pinnedProperty: string | null;
	loading: boolean;
	setLoading: (isLoading: boolean) => void;
	expandedSections: { [key: string]: boolean };
	showOptions: boolean;
	compareProperties: CartComparisonProperty[];
	handlePin: (zlid: string) => void;
	toggleSection: (sectionTitle: string) => void;
	toggleOptions: () => void;
	handleAddProperty: (zlid: string) => void;
	handleDeletePropertyToCompare: (zlid: string) => void;
	removeProperty: (listingId: string) => void;
	removeAllProperties: () => void;
}

export const usePropertyStore = create<CartComparisonStore>((set, get) => ({
	properties: [],
	pinnedProperty: null,
	loading: false,
	setLoading: (isLoading: boolean) => {
		set({ loading: isLoading });
	},
	expandedSections: {
		Location: true,
		Details: true,
		Features: true,
		"More Details": true,
	},
	showOptions: false,
	compareProperties: [],
	setProperties: (newProperties: CartComparisonProperty[]) => {
		set({ properties: newProperties });
	},

	handlePin: (zlid: string) => {
		const { properties, pinnedProperty } = get();
		if (pinnedProperty === zlid) {
			set({ pinnedProperty: null });
		} else {
			set({ pinnedProperty: zlid });
		}

		const updatedProperties = properties.filter(
			(property) => property.listingId !== zlid,
		);
		const pinned = properties.find((property) => property.listingId === zlid);
		if (pinned) {
			set({ properties: [pinned, ...updatedProperties] });
		}
	},

	toggleSection: (sectionTitle: string) => {
		set((state) => ({
			expandedSections: {
				...state.expandedSections,
				[sectionTitle]: !state.expandedSections[sectionTitle],
			},
		}));
	},

	toggleOptions: () => {
		set((state) => ({ showOptions: !state.showOptions }));
	},

	handleAddProperty: (zlid: string) => {
		const { compareProperties, properties } = get();
		const propertyToAdd = compareProperties.find(
			(property) => property.listingId === zlid,
		);
		if (propertyToAdd) {
			set({
				properties: [
					...properties.filter((property) => property.listingId !== zlid),
					propertyToAdd,
				],
				compareProperties: compareProperties.filter(
					(property) => property.listingId !== zlid,
				),
			});
		}
	},

	handleDeletePropertyToCompare: (zlid: string) => {
		const { properties } = get();
		const propertyToRemove = properties.find(
			(property) => property.listingId === zlid,
		);
		if (propertyToRemove) {
			set((state) => ({
				compareProperties: [
					...state.compareProperties.filter(
						(property) => property.listingId !== zlid,
					),
					propertyToRemove,
				],
				properties: state.properties.filter(
					(property) => property.listingId !== zlid,
				),
			}));
		}
	},

	removeProperty: (listingId) =>
		set((state) => ({
			properties: state.properties.filter(
				(property) => property.listingId !== listingId,
			),
			compareProperties: state.compareProperties.filter(
				(property) => property.listingId !== listingId,
			),
		})),

	removeAllProperties: () =>
		set(() => ({
			properties: [],
			compareProperties: [],
		})),
}));
