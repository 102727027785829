import { useRef, useEffect } from "react";
import { logOut } from "../services/account";
import { useAuth } from "../context/authProvider";
import XbuttonIcon from "../assets/xButtonIcon.svg";
import { useUserProfileStore } from "../stores/profileStore";
import { useLogOutModalStore } from "../stores/showLogOutModalStore";

const LogOutModal = () => {
	const { setShowLogOutModal } = useLogOutModalStore();
	const { setAuthenticated } = useAuth();
	const { clearUserProfile } = useUserProfileStore();

	const modalRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				setShowLogOutModal(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [setShowLogOutModal]);

	const handleLogOut = async () => {
		try {
			await logOut();
			clearUserProfile();
			localStorage.clear();
			setAuthenticated(false);
			setShowLogOutModal(false);
		} catch (error) {
			console.error("Failed to log out", error);
			clearUserProfile();
			localStorage.clear();
			setAuthenticated(false);
			setShowLogOutModal(false);
		}
	};

	return (
		<div className="fixed inset-0 flex flex-col items-center justify-end z-50 bg-black bg-opacity-50 space-y-2">
			<div className="flex items-end justify-end w-full px-2">
				<button
					type="button"
					className="right-6 bg-white w-[46px] h-[46px] rounded-full flex items-center justify-center"
					onClick={() => setShowLogOutModal(false)}
				>
					<img src={XbuttonIcon} alt="Close" />
				</button>
			</div>
			<div
				ref={modalRef}
				className={`w-full px-6 pt-6 bg-white shadow-md rounded-t-[20px] relative ${
					window.bridge ? "pb-bridge-inset-bottom" : "pb-6"
				} `}
			>
				<h2 className="text-xl text-black font-semibold text-center mb-4">
					Are you sure you want to log out?
				</h2>

				<div className="text-center flex items-center justify-center gap-3">
					<button
						type="button"
						className="bg-z-dark-moss-green max-w-[200px] text-white font-semibold text-lg rounded-full w-full py-2"
						onClick={handleLogOut}
					>
						Log out
					</button>
					<button
						type="button"
						className="border-2 text-z-dark-moss-green max-w-[200px] border-z-dark-moss-green font-semibold text-lg rounded-full w-full py-2"
						onClick={() => setShowLogOutModal(false)}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export { LogOutModal };
