import clsx from "clsx";
import { useState } from "react";
import { xIcon } from "../../../assets/icons";
import { Button, SingleSelectGroup } from "../../common";
import { formatPrice } from "../../../utils/formatPrice";
import { useFinanceStore } from "../../../stores/financeStore";
import { useModalStore } from "../../../stores/handleModalStore";
import averageHomePricesData from "../../../averageHousePrice.json";

const PLANSPEND_NUMBER = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"0",
	"<",
];

type CityName = keyof typeof averageHomePricesData;

const averageHomePriceFilter = [
	{ value: "All" },
	...Object.keys(averageHomePricesData).map((city) => ({ value: city })),
];

const PlanSpendHome = () => {
	const { setPlanSpendData, spendPrice, setSpendPrice } = useFinanceStore();
	const { planSpendHomeState, setPlanSpendHomeState } = useModalStore();
	const [AveragePriceModal, setAveragePriceModal] = useState<boolean>(false);
	const [averagePriceFilter, setAveragePriceFilter] = useState("All");

	const handlePrice = (item: string) => {
		if (item === "<") {
			setSpendPrice((prev) => {
				if (prev.length === 0) return prev;
				return prev.slice(0, -1);
			});
		} else if (spendPrice.length < 11) {
			setSpendPrice((prev) => [...prev, item]);
		}
	};

	const totalPrice = (price: string[]) => {
		return price.length > 0 ? formatPrice(price.join("")) : "0";
	};

	const saveHandle = () => {
		if (spendPrice.length === 0) {
			setPlanSpendData("0");
		} else {
			setPlanSpendData(formatPrice(spendPrice.join("")));
		}
		setPlanSpendHomeState(false);
	};

	const convertToArray = (price: string) => price.split("");

	const calculateAveragePrices = () => {
		const allPrices: { [key: string]: number } = {};
		const count: { [key: string]: number } = {};

		for (const cityData of Object.values(averageHomePricesData)) {
			for (const [propertyType, price] of Object.entries(cityData)) {
				if (price !== null) {
					allPrices[propertyType] = (allPrices[propertyType] || 0) + price;
					count[propertyType] = (count[propertyType] || 0) + 1;
				}
			}
		}

		const averagePrices = [];
		for (const [propertyType, totalPrice] of Object.entries(allPrices)) {
			averagePrices.push({
				name: propertyType,
				price: count[propertyType]
					? Math.floor(totalPrice / count[propertyType] / 100).toString()
					: "0",
			});
		}

		return averagePrices;
	};

	const computedAverageHomePriceList =
		averagePriceFilter === "All"
			? calculateAveragePrices()
			: Object.entries(
					averageHomePricesData[averagePriceFilter as CityName] || {},
				).map(([name, price]) => ({
					name,
					price: price ? Math.floor(Number(price) / 100).toString() : "0",
				}));

	return (
		<>
			{planSpendHomeState && (
				<div
					className={`fixed inset-0 bg-white p-6 z-30 text-z-shark ${
						window.bridge ? "pt-bridge-inset-top pb-bridge-inset-bottom" : ""
					}`}
				>
					<h3 className="text-z-shark text-2xl font-bold mb-2">
						How much do you plan
						<br /> to spend on your home?
					</h3>
					<img
						onClick={() => setPlanSpendHomeState(false)}
						onKeyDown={(event) => {
							if (event.key === "Enter" || event.key === " ") {
								setPlanSpendHomeState(false);
							}
						}}
						className={`absolute ${
							window.bridge ? " top-bridge-inset-top" : "top-5"
						} right-5`}
						width={16}
						src={xIcon}
						alt="arrow left icon"
					/>
					<div className="flex items-center justify-center flex-col mt-10">
						<span className="text-4xl font-bold">
							${spendPrice.length > 0 ? totalPrice(spendPrice) : 0}
						</span>
						<div
							className="text-z-dark-moss-green font-medium mt-4"
							onClick={() => setAveragePriceModal(true)}
							onKeyDown={(event) => {
								if (event.key === "Enter" || event.key === " ") {
									setAveragePriceModal(true);
								}
							}}
						>
							Choose from average home prices
						</div>
					</div>

					<div className="grid grid-cols-3 gap-7 text-2xl font-medium text-center mt-20">
						{PLANSPEND_NUMBER.map((item) => (
							<div
								key={item}
								onClick={() => handlePrice(item)}
								onKeyDown={(event) => {
									if (event.key === "Enter" || event.key === " ") {
										handlePrice(item);
									}
								}}
								className={clsx({ "col-start-2": item === "0" })}
							>
								{item}
							</div>
						))}
					</div>
					<Button
						onClick={saveHandle}
						customClassName="text-lg font-semibold mt-14 mx-auto"
						radius="60px"
						width="330px"
						height="lg"
					>
						Save
					</Button>
					{AveragePriceModal && (
						<div className="fixed inset-0 w-full h-full">
							<div
								className="bg-black bg-opacity-60 absolute inset-0 backdrop-blur-sm"
								onClick={() => setAveragePriceModal(false)}
								onKeyUp={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										setAveragePriceModal(false);
									}
								}}
							/>
							<div
								className={`absolute bottom-0 left-0 right-0 rounded-t-3xl w-full z-40 bg-white flex items-center flex-col  ${
									window.bridge ? "pt-9 pb-bridge-inset-bottom" : "pt-9  pb-5"
								}`}
							>
								<img
									onClick={() => setAveragePriceModal(false)}
									onKeyDown={(event) => {
										if (event.key === "Enter" || event.key === " ") {
											setAveragePriceModal(false);
										}
									}}
									className="absolute top-5 right-5"
									width={12}
									src={xIcon}
									alt="arrow left icon"
								/>
								<div className="text-[22px] text-center font-bold px-7">
									Average home prices in your selected city
								</div>
								<div className="mt-6 w-full">
									<SingleSelectGroup
										activeBg="#EFF6FF"
										activeColor="#0075FF"
										variant="blue"
										gap="12px"
										selectedValue={averagePriceFilter}
										width="fit"
										onChange={setAveragePriceFilter}
										options={averageHomePriceFilter}
										customClassName="px-4"
										parentClassName="px-7"
									/>
								</div>
								<div className="flex flex-col space-y-5 w-full max-h-[390px] overflow-y-auto p-1 px-7">
									{computedAverageHomePriceList.map((data) => (
										<div
											key={data.name}
											onClick={() => {
												setSpendPrice(() => convertToArray(data.price));
												setAveragePriceModal(false);
											}}
											onKeyDown={(event) => {
												if (event.key === "Enter" || event.key === " ") {
													setSpendPrice(() => convertToArray(data.price));
													setAveragePriceModal(false);
												}
											}}
											className="flex items-center justify-between shadow-[0_0_6px_0_rgba(0,0,0,0.10)] min-h-[60px] rounded-2xl px-3"
										>
											<div className="text-xl font-semibold">{data.name}</div>
											<div className="">${formatPrice(data.price)}</div>
										</div>
									))}
								</div>
								<div className="text-[#D7D7D7] text-xs mt-4">
									Current home prices provided by TREBB.
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export { PlanSpendHome };
