interface PropertyDetailsProps {
  icon: string;
  label: string;
  value: string;
}

export const PropertyDetails = ({
  icon,
  label,
  value,
}: PropertyDetailsProps) => (
  <div className="flex items-center gap-1 text-[#7D7D7D]">
    <img src={icon} alt={label} />
    <div className="text-sm">{value}</div>
  </div>
);

export const UnderlistedChip = () => (
  <div className="flex items-center justify-center gap-1 p-1 text-[#7D7D7D] bg-[#DEF2D3] border border-[#6ECE35] rounded-md">
    <div className="text-xs text-[#6ECE35] font-extrabold">Underlisted</div>
  </div>
);
