import { useRef, useEffect } from "react";
import XbuttonIcon from "../assets/xButtonIcon.svg";
import PetIcon from "../assets/TopMatchesModal/petIcon.svg";
import { useUserProfileStore } from "../stores/profileStore";
import ParksIcon from "../assets/TopMatchesModal/parksIcon.svg";
import HouseIcon from "../assets/TopMatchesModal/houseIcon.svg";
import OutDoorIcon from "../assets/TopMatchesModal/outDoorIcon.svg";
import CookingIcon from "../assets/TopMatchesModal/cookingIcon.svg";
import BuildingIcon from "../assets/TopMatchesModal/buildingIcon.svg";
import ChildrenIcon from "../assets/TopMatchesModal/childrenIcon.svg";
import LocationIcon from "../assets/TopMatchesModal/locationIcon.svg";
import NightlifeIcon from "../assets/TopMatchesModal/nightLifeIcon.svg";
import SemiDetachedIcon from "../assets/TopMatchesModal/semiDetached.svg";
import { useShowTopMatchesModalStore } from "../stores/showTopMatchesModalStore";
import TopMatchesModalHomeImage from "../assets/static/images/top-matches-modal-home.webp";

const YourTopMatchesModal = () => {
	const { setShowTopMatchesModal } = useShowTopMatchesModalStore();
	const modalRef = useRef<HTMLDivElement | null>(null);
	const { userProfile } = useUserProfileStore();

	const cities =
		userProfile?.matchingPreferences?.filters?.selectedCities || [];

	const getCityLabel = () => {
		if (cities.length === 1) {
			return (
				<>
					Located in <span className="font-bold">{cities[0]}</span>
				</>
			);
		}
		return "Located in your selected areas";
	};

	const propertyTypes =
		userProfile?.matchingPreferences?.filters?.propertyType || [];

	const getPropertyTypeIcon = () => {
		if (propertyTypes.length > 1) {
			return HouseIcon;
		}

		const propertyType = propertyTypes[0];
		switch (propertyType) {
			case "CONDO":
			case "CONDO_TOWNHOUSE":
			case "TOWNHOUSE":
				return BuildingIcon;
			case "SEMI_DETACHED":
				return SemiDetachedIcon;
			case "DETACHED":
				return HouseIcon;
			default:
				return HouseIcon;
		}
	};

	const getPropertyTypeLabel = () => {
		if (propertyTypes.length > 1) {
			return (
				<>
					<div>Your preferred property type:</div>
					<div className="font-bold">Multiple properties</div>
				</>
			);
		}

		const propertyType = propertyTypes[0];
		switch (propertyType) {
			case "CONDO":
				return (
					<div className="w-full">
						<span className="font-bold">Condominium</span> homes
					</div>
				);
			case "CONDO_TOWNHOUSE":
				return (
					<div className="w-full">
						<span className="font-bold">Condo townhouse</span> homes
					</div>
				);
			case "TOWNHOUSE":
				return (
					<div className="w-full">
						<span className="font-bold">Townhouse</span> homes
					</div>
				);
			case "SEMI_DETACHED":
				return (
					<div className="w-full">
						<div className="font-bold">Semi-detached</div> homes
					</div>
				);
			case "DETACHED":
				return (
					<div className="w-full">
						<span className="font-bold">Detached</span> homes
					</div>
				);
			default:
				return (
					<div className="w-full">
						Your preferred <span className="font-bold">Property Type</span>
					</div>
				);
		}
	};

	const getLifestyleIconAndLabel = (lifestyle: string) => {
		switch (lifestyle) {
			case "CHILDREN":
				return {
					icon: ChildrenIcon,
					label: (
						<div className="w-full">
							<span className="font-bold">Children</span> friendly
						</div>
					),
				};
			case "NIGHTLIFE":
				return {
					icon: NightlifeIcon,
					label: (
						<div className="w-full">
							<div className="font-bold">Nightlife</div> nearby
						</div>
					),
				};
			case "PARKS":
				return {
					icon: ParksIcon,
					label: (
						<div className="w-full">
							<span className="font-bold">Parks</span> nearby
						</div>
					),
				};
			case "COOKING":
				return {
					icon: CookingIcon,
					label: (
						<div className="w-full">
							<span className="font-bold">Grocery</span> stores nearby
						</div>
					),
				};
			case "OUTDOOR":
				return {
					icon: OutDoorIcon,
					label: (
						<div className="w-full">
							<span className="font-bold">Outdoor activities </span> in the area
						</div>
					),
				};
			case "PETS":
				return {
					icon: PetIcon,
					label: (
						<div className="w-full">
							<span className="font-bold">Pet</span> friendly
						</div>
					),
				};
			default:
				return {
					icon: null,
					label: null,
				};
		}
	};

	const PropertyTypeIcon = getPropertyTypeIcon();

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				setShowTopMatchesModal(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [setShowTopMatchesModal]);

	const lifestyle = userProfile?.matchingPreferences?.lifeStyles?.[0] || null;
	const { icon: LifestyleIcon, label: LifestyleLabel } = lifestyle
		? getLifestyleIconAndLabel(lifestyle)
		: {};

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center px-2">
			<div className="absolute inset-0 bg-white bg-opacity-70 backdrop-blur-sm" />
			<div
				ref={modalRef}
				className="relative w-full max-w-md py-6 px-3 bg-white shadow-md rounded-[20px] space-y-4 z-10"
			>
				<button
					type="button"
					className="right-4 absolute w-[46px] h-[46px] rounded-full flex items-start justify-end"
					onClick={() => setShowTopMatchesModal(false)}
				>
					<img src={XbuttonIcon} alt="Close" />
				</button>
				<img src={TopMatchesModalHomeImage} alt="" />
				<h2 className="text-3xl text-black font-semibold text-start">
					Your Top Matches
				</h2>
				<div>
					Based on our research, these homes are perfectly matched for you based
					on
				</div>
				<div className="space-y-2">
					<div className="bg-[#456930]/5 rounded-[16px] p-3 flex items-center gap-2 w-full">
						<img src={LocationIcon} alt="Location" />
						{getCityLabel()}
					</div>
					{LifestyleIcon && LifestyleLabel && (
						<div className="bg-[#456930]/5 rounded-[16px] p-3 flex items-center gap-2 w-full">
							<img src={LifestyleIcon} alt="Lifestyle" />
							{LifestyleLabel}
						</div>
					)}
					<div className="bg-[#456930]/5 rounded-[16px] p-3 flex items-center gap-2 w-full">
						<img src={PropertyTypeIcon} alt="Property Type" />
						{getPropertyTypeLabel()}
					</div>
				</div>
				<div className="text-sm text-[#32302F]">
					<span className="font-semibold">+12</span> other factors
				</div>
				<button
					type="button"
					className="bg-z-dark-moss-green max-w-[193px] text-white font-semibold text-lg rounded-full w-full py-3"
					onClick={() => setShowTopMatchesModal(false)}
				>
					Ok, Got It
				</button>
			</div>
		</div>
	);
};

export { YourTopMatchesModal };
