import { create } from "zustand";

interface AddressLookupStore {
	addressLookupModalOpen: boolean;
	selectedAddress: string;
	suggestion: AddressSuggestion | null;
	setAddressLookupModalOpen: (open: boolean) => void;
	setSelectedAddress: (address: string) => void;
	setSuggestion: (suggestion: AddressSuggestion) => void;
}

export interface AddressSuggestion {
	Text: string;
	Type: "BuildingNumber" | "Address";
	Description?: string;
	UnitNumber?: string;
	Id: string;
}

export const useAddressLookupStore = create<AddressLookupStore>((set) => ({
	addressLookupModalOpen: false,
	suggestion: null,
	selectedAddress: "",
	setAddressLookupModalOpen: (open) => set({ addressLookupModalOpen: open }),
	setSelectedAddress: (address) => set({ selectedAddress: address }),
	setSuggestion: (suggestion) => set({ suggestion }),
}));
