import type {
	CircleLayerSpecification,
	SymbolLayerSpecification,
} from "mapbox-gl";

export const homeLayerStyle: CircleLayerSpecification = {
	id: "homeLayer",
	type: "circle",
	source: "my-data",
	filter: ["==", "icon", "home"],
	paint: {
		"circle-color": "#FECD07",
		"circle-radius": 0,
		"circle-stroke-width": 0,
	},
};

export const homeIconLayerStyle: SymbolLayerSpecification = {
	id: "homeIconLayer",
	type: "symbol",
	source: "my-data",
	filter: ["==", "icon", "home"],
	layout: {
		"icon-image": "home",
		"icon-size": 1,
		"icon-allow-overlap": true,
	},
};

export const nearbyLayerStyle: CircleLayerSpecification = {
	id: "nearbyLayer",
	type: "circle",
	source: "my-data",
	filter: ["!=", ["get", "icon"], "home"],
	paint: {
		"circle-color": "#FECD07",
		"circle-radius": 10,
		"circle-stroke-color": "rgba(254, 205, 7, 0.3)",
	},
};
