import { useState } from "react";
import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { Bars3BottomLeft } from "../../assets/icons";
import { useFilterPanelStore } from "../../stores/filterStore";
import { updatePreferences } from "../../services/preferences";
import { usePropertySearchingStore } from "../../stores/propertySearchingStore";
import {
	DragDropContext,
	Draggable,
	Droppable,
	type DropResult,
} from "@hello-pangea/dnd";
import { useUserProfileStore } from "../../stores/profileStore";
import {
	BuyerMatchingPreferencesPoiTypeEnum,
	BuyerMatchingPreferencesPrioritiesEnum,
} from "../../interfaces/buyerMatchingPreferences";

const poiToPriorityMap = {
	[BuyerMatchingPreferencesPoiTypeEnum.School]:
		BuyerMatchingPreferencesPrioritiesEnum.PoiSchool,
	[BuyerMatchingPreferencesPoiTypeEnum.PublicStation]:
		BuyerMatchingPreferencesPrioritiesEnum.PoiPublicTransit,
	[BuyerMatchingPreferencesPoiTypeEnum.Work]:
		BuyerMatchingPreferencesPrioritiesEnum.PoiWork,
	[BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship]:
		BuyerMatchingPreferencesPrioritiesEnum.PoiWorship,
	[BuyerMatchingPreferencesPoiTypeEnum.Daycare]:
		BuyerMatchingPreferencesPrioritiesEnum.PoiDaycare,
	[BuyerMatchingPreferencesPoiTypeEnum.Other]:
		BuyerMatchingPreferencesPrioritiesEnum.PoiOther,
};

const Prioritize = () => {
	const { showFilterPanel } = useFilterPanelStore();
	const { userProfile } = useUserProfileStore();
	console.log(userProfile?.matchingPreferences?.pointsOfInterests);

	const navigate = useNavigate();

	const { niceHaves, filteredItems } = usePropertySearchingStore();
	const [priorities, setPriorities] = useState<string[]>([
		...niceHaves,
		...(userProfile?.matchingPreferences?.pointsOfInterests
			?.map((poi) => poiToPriorityMap[poi.type])
			.filter(Boolean) ?? []),
	]);

	const allItems = filteredItems();

	const mapTypeToDisplayName = (type: string) => {
		const foundItem = allItems.find((item) => item.type === type);

		if (
			Object.values(BuyerMatchingPreferencesPrioritiesEnum).includes(
				type as BuyerMatchingPreferencesPrioritiesEnum,
			)
		) {
			const poi = userProfile?.matchingPreferences?.pointsOfInterests?.find(
				(poi) => poiToPriorityMap[poi.type] === type,
			);
			if (poi?.designation) {
				return `Near ${poi.designation}`;
			}
		}

		return foundItem ? foundItem.displayName : type;
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const reorderedPriorities = Array.from(priorities);
		const [removed] = reorderedPriorities.splice(result.source.index, 1);
		reorderedPriorities.splice(result.destination.index, 0, removed);

		setPriorities(reorderedPriorities);
	};

	const handleSave = async () => {
		await updatePreferences(undefined, priorities);
		showFilterPanel();
		navigate("/profile");
	};

	return (
		<div className="px-4 pt-2 absolute inset-0 text-z-shark ">
			<h2 className="text-z-shark text-2xl font-bold mb-3">
				Prioritize the things that matter the most to you
			</h2>
			<span className="text-z-shark text-sm">
				Drag the items to rearrange them
			</span>

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="priorities">
					{(provided) => (
						<ul
							{...provided.droppableProps}
							ref={provided.innerRef}
							className="mt-4 space-y-4 h-full "
						>
							{priorities.map((priority, index) => (
								<Draggable key={priority} draggableId={priority} index={index}>
									{(provided) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											className="h-11 w-full rounded bg-white flex items-center gap-2"
										>
											<div className="text-z-shark">{index + 1}.</div>
											<div className="bg-[#F9F9F9] w-[330px] sm:w-full rounded-xl  h-full items-center flex justify-between pl-5 pr-5">
												<span className="font-semibold text-z-shark truncate overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]">
													{mapTypeToDisplayName(priority)}
												</span>
												<div
													{...provided.dragHandleProps}
													className="w-16 h-full flex items-center justify-end"
												>
													<img
														src={Bars3BottomLeft}
														alt="bar bottom left icon"
													/>
												</div>
											</div>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>

			<Button
				customClassName={`text-lg font-semibold fixed left-1/2 -translate-x-1/2 ${
					window.bridge ? "bottom-bridge-inset-bottom" : "bottom-5"
				}`}
				radius="60px"
				width="330px"
				height="md"
				onClick={handleSave}
			>
				Save
			</Button>
		</div>
	);
};

export { Prioritize };
