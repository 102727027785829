import { useNavigate } from "react-router-dom";
import PendingSignatureIcon from "../../assets/pendingSignatureIcon.svg";

const PendingSignatureView = () => {
	const navigate = useNavigate();
	return (
		<div className="text-black space-y-4 px-4 pt-2 pb-6">
			<div className="flex flex-col items-center text-center space-y-4">
				<img src={PendingSignatureIcon} alt="" />
				<div className="text-[22px] font-semibold">Pending Signature</div>
				<div>
					Your signature is required to schedule home showings. Please sign the
					document to proceed with viewing available properties.
				</div>
			</div>
			<div className="w-full space-y-2">
				<button
					type="button"
					className="bg-[#32302F] text-white w-full rounded-[10px] py-3.5 text-[20px] font-semibold"
					onClick={() => navigate("/cart/contract")}
				>
					Sign Document
				</button>
			</div>
		</div>
	);
};

export { PendingSignatureView };
