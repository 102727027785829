import {
	ImageBrowseListingDemo1,
	ImageBrowseListingDemo2,
} from "../../assets/BrowseListing/demo";
import { PropertyCardProps } from "../../interfaces/newInterfaces/propertyCard";

export const mockPropertyCard: PropertyCardProps[] = [
	{
		imageUrl: `${ImageBrowseListingDemo1}`,
		propertyType: "Condo",
		status: "For Sale",
		price: "450000",
		downPayment: "90000",
		date: new Date(2023, 9, 15),
		address: "1234 Elm Street, Springfield",
		beds: "3",
		baths: "2",
		sqft: "1500",
		similarListings: false,
	},
	{
		imageUrl: `${ImageBrowseListingDemo2}`,
		propertyType: "Townhouse",
		status: "Sold",
		price: "650000",
		downPayment: "130000",
		date: new Date(2024, 8, 10),
		address: "5678 Oak Avenue, Rivertown",
		beds: "4",
		baths: "3",
		sqft: "2000",
		similarListings: true,
	},
	{
		imageUrl: `${ImageBrowseListingDemo2}`,
		propertyType: "Townhouse",
		status: "Sold",
		price: "650000",
		downPayment: "130000",
		date: new Date(2024, 8, 10),
		address: "5678 Oak Avenue, Rivertown",
		beds: "4",
		baths: "3",
		sqft: "2000",
		similarListings: true,
	},
	{
		imageUrl: `${ImageBrowseListingDemo2}`,
		propertyType: "Townhouse",
		status: "Sold",
		price: "650000",
		downPayment: "130000",
		date: new Date(2024, 8, 10),
		address: "5678 Oak Avenue, Rivertown",
		beds: "4",
		baths: "3",
		sqft: "2000",
		similarListings: true,
	},
	{
		imageUrl: `${ImageBrowseListingDemo2}`,
		propertyType: "Townhouse",
		status: "Sold",
		price: "650000",
		downPayment: "130000",
		date: new Date(2024, 8, 10),
		address: "5678 Oak Avenue, Rivertown",
		beds: "4",
		baths: "3",
		sqft: "2000",
		similarListings: true,
	},
];
