import { Button } from "../../common";
import { Modal } from "../../common/modal";
import { PlayIcon } from "../../../assets/icons";
import { useModalStore } from "../../../stores/handleModalStore";

const PaymentAssistanceInfoModal = () => {
	const {
		setPaymentAssistanceInfoModal,
		paymentAssistanceInfoModal,
		setWatchVideoModal,
	} = useModalStore();
	return (
		<Modal
			closeIcon={true}
			setIsOpen={setPaymentAssistanceInfoModal}
			isOpen={paymentAssistanceInfoModal}
		>
			<div className="flex flex-col">
				<div className="text-z-shark text-xl font-bold">
					How Our Down Payment Assistance Is Calculated
				</div>
				<p className="text-[#828282] mt-5 leading-5 font-light">
					Zown provides 1.5% of the purchase price as non-repayable down payment
					assistance when we receive a 2.5% buyer's agent commission.
					Additionally, we offer another 1% to buy down your interest rate,
					ensuring you receive a significantly lower rate than any bank rate for
					A lenders. Depending on the commission rate, your down payment
					assistance may vary, but 1.5% is our standard offer. There's no
					interest or repayments required. The only 'catch'—which isn't really a
					catch—is that when you decide to sell your home, we reserve the right
					to represent you in that sale with a 2.5% commission. However, you can
					choose when or if you ever sell your home.
				</p>
				<div
					className="text-z-dark-moss-green flex items-center justify-center space-x-2 mt-8 mb-10"
					onClick={() => setWatchVideoModal(true)}
					onKeyUp={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							setWatchVideoModal(true);
						}
					}}
				>
					<PlayIcon />
					<div className="text-lg font-bold">Watch Video</div>
				</div>
				<Button
					radius="50px"
					height="md"
					width="full"
					customClassName="font-bold text-lg"
					onClick={() => setPaymentAssistanceInfoModal(false)}
				>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export { PaymentAssistanceInfoModal };
