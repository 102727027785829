import { formatPrice } from "../../../utils/formatPrice";

interface ComparableSalesProps {
	numberComparableSales?: number;
	medianSalePrice?: number;
	avgDom?: number;
	mostRecentSale?: number;
}

const ComparableSales = ({
	numberComparableSales,
	medianSalePrice,
	avgDom,
	mostRecentSale,
}: ComparableSalesProps) => {
	return (
		<div className="space-y-4 px-4">
			<div className="font-semibold text-lg">Comparable Sales</div>
			<div className="space-y-3">
				<div className="flex justify-center w-full items-center gap-3">
					<div className="flex flex-col items-center text-center w-full border-[1px] border-[#E6E6E6] rounded-[9px] py-1.5 px-1 lg:py-3">
						<div className="text-xs lg:text-base text-[#878787]">
							Comparable Sales
						</div>
						<div className="font-bold text-xl">
							{numberComparableSales ? numberComparableSales : "N/A"}
						</div>
					</div>
					<div className="flex flex-col items-center text-center w-full border-[1px] border-[#E6E6E6] rounded-[9px] py-1.5 px-1 lg:py-3">
						<div className="text-xs lg:text-base text-[#878787]">
							Median Sale Price
						</div>
						<div className="font-bold text-xl">
							{medianSalePrice ? `$${formatPrice(medianSalePrice)}` : "N/A"}
						</div>
					</div>
				</div>
				<div className="flex justify-center w-full items-center gap-3">
					<div className="flex flex-col items-center text-center w-full border-[1px] border-[#E6E6E6] rounded-[9px] py-1.5 px-1 lg:py-3">
						<div className="text-xs lg:text-base text-[#878787]">
							Avg. Days On Market
						</div>
						<div className="font-bold text-xl">{avgDom ? avgDom : "N/A"}</div>
					</div>
					<div className="flex flex-col items-center text-center w-full border-[1px] border-[#E6E6E6] rounded-[9px] py-1.5 px-1 lg:py-3">
						<div className="text-xs lg:text-base text-[#878787]">
							Most Recent Sale
						</div>
						<div className="font-bold text-xl">
							{mostRecentSale ? `${mostRecentSale} days ago` : "N/A"}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { ComparableSales };
