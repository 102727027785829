import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import type { MainProfile } from "../interfaces/mainProfile";

interface UserProfileStore {
  userProfile: MainProfile | null;
  setUserProfile: (profile: MainProfile) => void;
  clearUserProfile: () => void;
}

const useUserProfileStore = create<UserProfileStore>()(
  persist(
    (set) => ({
      userProfile: null,
      setUserProfile: (profile: MainProfile) => set({ userProfile: profile }),
      clearUserProfile: () => set({ userProfile: null }),
    }),
    {
      name: "user-profile-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export { useUserProfileStore };
