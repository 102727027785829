import { Finance } from "../interfaces/finance";

export const financeData:Finance = {
    totalPriceCurrentQualification:'',
    incomePrice:'',
    savingPrice:'',
    creditScoreValue:'',
    totalDebt:'',
    homeGoalSelectedCities:[],
    planSpendData:'',
    whenBuyHomeData:'',
    financeSaving:[],
    financeIncome:[],
    financeDebt:[],
    financeReport:{
        currentHomeGoal: 650000,
        monthlyPayments: 2400,
        financialData:[
          {
            title: "Required Income",
            price: 130000,
            desc: `The income level you need to achieve your home goal and make your dream home a reality.`,
            incomeRange: [60],
            incomeRequirements: {
              requiredIncome: 130000,
              currentIncome: 90000,
              gap: 40000
            }
          },
          {
            title: "Required Savings",
            price: 47500,
            desc: `Savings required to reach your home goal, with Zown's assistance helping to reduce the amount you need.`,
            savingRange : [30,60],
            homeBasedCosts: {
              minimumDownPayment: 35000,
              downPaymentBoost: 10500
            },
            otherFeesPrice: 8250,
            otherFees:{
              legalCosts: 1250,
              PST: 1600,
              landTransferTax: 5400,
            }
          },
          {
            title: "Debt",
            price: 1200,
            desc: `Understand the debt limits you should maintain to meet your home goal and stay financially secure.`,
          },
        ]
      }
}