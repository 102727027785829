interface IconProps {
	fill: string;
}
const DiyIcon = ({ fill }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="28"
			viewBox="0 0 22 28"
			fill={fill}
		>
			<path
				d="M9 18.1801L5.41 14.5901L4 16.0001L9 21.0001L18 12.0001L16.59 10.5801L9 18.1801Z"
				fill={fill}
			/>
			<path
				d="M20 3H17V2C17 1.46957 16.7893 0.960859 16.4142 0.585786C16.0391 0.210714 15.5304 0 15 0H7C6.46957 0 5.96086 0.210714 5.58579 0.585786C5.21071 0.960859 5 1.46957 5 2V3H2C1.46957 3 0.960859 3.21071 0.585786 3.58579C0.210714 3.96086 0 4.46957 0 5V26C0 26.5304 0.210714 27.0391 0.585786 27.4142C0.960859 27.7893 1.46957 28 2 28H20C20.5304 28 21.0391 27.7893 21.4142 27.4142C21.7893 27.0391 22 26.5304 22 26V5C22 4.46957 21.7893 3.96086 21.4142 3.58579C21.0391 3.21071 20.5304 3 20 3ZM7 2H15V6H7V2ZM20 26H2V5H5V8H17V5H20V26Z"
				fill={fill}
			/>
		</svg>
	);
};

export default DiyIcon;
