const LoadingCircle = () => (
		<div className="lds-roller">
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
		</div>
	);


export { LoadingCircle };
