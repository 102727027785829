import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import styles from "./ownershipIntro.style.module.css";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import { useStep } from "../../stores/buyerHomeFeaturesStore";
import CheckIcon from "../../assets/icons/checkCircleIcon.svg";
import PaymentProvidedIcon from "../../assets/icons/paymentProvidedIcon.svg";
import {
	CanadaFlag,
	homeOwnershipSlider1,
	homeOwnershipSlider2,
	homeOwnershipSlider3,
	homeOwnershipSlider4,
	homeOwnershipSlider5,
} from "../../assets/static/images/index";

import "swiper/css";
import "swiper/css/pagination";

const data = [
	{
		amount: "$1M+",
		description: "in down payment provided",
		imageSrc: PaymentProvidedIcon,
	},
	{
		amount: "7000+",
		description: "applications submitted",
		imageSrc: CheckIcon,
	},
	{ amount: "100+", description: "homeowners supported", imageSrc: HomeIcon },
];

const OwnershipIntroView = () => {
	const navigate = useNavigate();
	const { setCurrentStep } = useStep();
	return (
		<div className="h-screen">
			<div className="h-[calc(100%_-_310px)] w-full bg-[#535560]">
				<Swiper
					className="h-full"
					loop={true}
					modules={[Pagination, Autoplay]}
					autoplay={{ delay: 3000, disableOnInteraction: true }}
					slidesPerView={1}
					pagination={{
						clickable: true,
						bulletClass: `swiper-pagination-bullet ${styles.swiperPaginationBullet}`,
						bulletActiveClass: styles.swiperPaginationBulletActive,
					}}
				>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={homeOwnershipSlider1}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Jaselyn became a homeowner in March</span>
						</div>
					</SwiperSlide>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={homeOwnershipSlider2}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Ilsa became a homeowner in May</span>
						</div>
					</SwiperSlide>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={homeOwnershipSlider3}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Josh became a homeowner in June</span>
						</div>
					</SwiperSlide>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={homeOwnershipSlider4}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Suren became a homeowner in June</span>
						</div>
					</SwiperSlide>
					<SwiperSlide className="h-full">
						<img
							className="h-full w-full object-cover"
							src={homeOwnershipSlider5}
							alt="home ownership"
						/>
						<div className="absolute bottom-6 left-6 flex items-center space-x-2 backdrop-blur-[1px] bg-[rgba(0,0,0,0.58)] rounded-3xl text-white text-[10px] py-1 px-3">
							<img src={CanadaFlag} alt="canada flag" />
							<span>Naja & Shabnam became homeowners in July</span>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
			<div className="mx-7 mt-6">
				<h3 className="text-z-shark text-lg font-bold mb-2">
					Your path to homeownership <br /> begins here
				</h3>
				{data.map((item, index) => (
					// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
					<div key={index} className="font-bold flex items-center py-2">
						<span className="text-z-dark-moss-green">{item.amount}</span>
						<span className="text-z-shark font-medium ml-1 mr-3">
							{item.description}
						</span>
						<img src={item.imageSrc} alt="" />
					</div>
				))}
				<Button
					onClick={() => {
						navigate("/buyer-input-flow/details-input");
						setCurrentStep(0);
					}}
					customClassName="mt-3"
					height="md"
					width="full"
					radius="56px"
				>
					Begin my journey
				</Button>
			</div>
		</div>
	);
};

export { OwnershipIntroView };
