import { clsx } from "clsx";
import { useState } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { getTrackBackground, Range } from "react-range";
import { ManageSteps } from "./FinancesSteps/manageSteps";
import { useFinanceStore } from "../../stores/financeStore";
import { useModalStore } from "../../stores/handleModalStore";
import { ChevronDownIcon, InfoIcon } from "../../assets/icons";
import { PaymentAssistanceImage } from "../../assets/FinancesImg";

const STEP = 25000;
const MIN = 400000;
const MAX = 2000000;

function calculateMinDownPayment(housePrice: number): number {
  if (housePrice < 500000) {
    return housePrice * 0.05;
  }
  if (housePrice >= 500000 && housePrice < 1000000) {
    return 25000 + 0.1 * (housePrice - 500000);
  }
  return 0.2 * housePrice;
}

const calculateMortgageSavings = (housePrice: number): number => {
  const dp = calculateMinDownPayment(housePrice);
  const loanAmount = housePrice - dp;

  const loanTermYears = 25;
  const months = loanTermYears * 12;

  const interestRate1 = 4.34 / 100 / 12;
  const interestRate2 = 4.84 / 100 / 12;

  const monthlyPayment1 =
    (loanAmount * (interestRate1 * (1 + interestRate1) ** months)) /
    ((1 + interestRate1) ** months - 1);
  const monthlyPayment2 =
    (loanAmount * (interestRate2 * (1 + interestRate2) ** months)) /
    ((1 + interestRate2) ** months - 1);

  const savings = (monthlyPayment2 - monthlyPayment1) * 60;

  return Math.round(savings);
};

const PaymentAssistance = () => {
  const { setPaymentAssistanceInfoModal } = useModalStore();
  const { completedSteps, basedBuyingGoal } = useFinanceStore();
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState<number[]>([400000]);

  const mortgageSavings = calculateMortgageSavings(values[0]);
  const zownsAssistance = values[0] * 0.015;
  const totalSavings = mortgageSavings + zownsAssistance;

  return (
    <div className="border border-[#E8E8E8] rounded-3xl overflow-hidden mb-8">
      <div className="relative h-60 overflow-hidden px-6 pt-9">
        <div className="w-[calc(100%_-_130px)] space-y-4 absolute z-20">
          <div className="text-z-shark text-[20px] font-extrabold">
            Unlock your down payment assistance
          </div>
          <div className="text-z-shark text-sm font-normal">
            {basedBuyingGoal
              ? "Complete all 3 steps to unlock your down payment assistance"
              : "Complete all 4 steps to unlock your down payment assistance"}
          </div>
          {completedSteps[1] === true && <ManageSteps />}
        </div>
        <div className="absolute right-0 inset-y-0">
          <img
            src={PaymentAssistanceImage}
            alt="payment assistance"
            width={130}
            className="h-full"
          />
        </div>
        <div
          className={clsx(
            "absolute px-5 left-1/2 -translate-x-1/2 bottom-3 transition-all duration-300",
            isOpen && "rotate-180"
          )}
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setIsOpen((prev) => !prev);
            }
          }}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <ChevronDownIcon fill="#32302F" />
        </div>
      </div>
      <div
        className={clsx(
          "bg-z-shark transition-height duration-300 ease-in-out",
          isOpen ? "h-[340px]" : "h-0"
        )}
      >
        <div className="p-6">
          <div className="flex items-center justify-center space-x-4">
            <span className="font-bold">Total Savings with Zown</span>
            <div
              onClick={() => setPaymentAssistanceInfoModal(true)}
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  setPaymentAssistanceInfoModal(true);
                }
              }}
            >
              <InfoIcon />
            </div>
          </div>
          <div className="flex items-center justify-between mt-8 mb-6">
            <div className="text-sm">Property Purchase Price</div>
            <div className="">${formatPrice(values[0])}</div>
          </div>
          <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={(values: number[]) => setValues(values)}
            renderTrack={({ props, children }) => {
              // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              const { key, ...restProps } = props as any;
              return (
                <div
                  key={key}
                  {...restProps}
                  className="w-full h-1 bg-gray-300"
                  style={{
                    background: getTrackBackground({
                      values,
                      colors: ["#456930", "rgba(53, 105, 48, 0.2)"],
                      min: MIN,
                      max: MAX,
                    }),
                  }}
                >
                  {children}
                </div>
              );
            }}
            renderThumb={({ props }) => {
              // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              const { key, ...restProps } = props as any;
              return (
                <div
                  key={key}
                  {...restProps}
                  className="h-8 w-8 bg-white rounded-full flex items-center justify-center focus:outline-none relative"
                  style={{
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
                    border: "1px solid rgba(0, 0, 0, 0.10)",
                  }}
                />
              );
            }}
          />
          <div className="space-y-4 mt-8">
            <div className="flex items-center justify-between">
              <div className="text-sm font-bold">Total Savings with Zown</div>
              <div className="text-[#83C25E] text-xl font-bold">
                ${formatPrice(totalSavings)}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Down Payment Assistance</div>
              <div className="text-[#83C25E]">
                ${formatPrice(zownsAssistance)}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Mortgage Payment Savings</div>
              <div className="text-[#83C25E]">
                ${formatPrice(mortgageSavings)}
              </div>
            </div>
          </div>
          <div className="mt-7 text-[#979797] text-[10px] text-center">
            Based on a 2.5% buying agent commission
          </div>
        </div>
      </div>
    </div>
  );
};

export { PaymentAssistance };
