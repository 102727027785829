interface IconProps {
	fill: string;
}
const PetsIcon = ({ fill }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="28"
			viewBox="0 0 30 28"
			fill={fill}
		>
			<path
				d="M3.70372 17.4229C1.74865 17.4229 0.21875 15.1194 0.21875 12.1763C0.21875 9.23325 1.74865 6.92969 3.70372 6.92969C5.65879 6.92969 7.18869 9.23325 7.18869 12.1763C7.18869 15.1194 5.65879 17.4229 3.70372 17.4229ZM3.70372 8.67217C2.88127 8.67217 1.96123 10.1707 1.96123 12.1763C1.96123 14.1819 2.88127 15.6804 3.70372 15.6804C4.52617 15.6804 5.4462 14.1819 5.4462 12.1763C5.4462 10.1707 4.52617 8.67217 3.70372 8.67217Z"
				fill={fill}
			/>
			<path
				d="M10.669 10.4549C8.7174 10.4549 7.1875 8.15831 7.1875 5.22745C7.1875 2.29659 8.7174 0 10.669 0C12.6206 0 14.1505 2.29659 14.1505 5.22745C14.1505 8.15831 12.6206 10.4549 10.669 10.4549ZM10.669 1.74248C9.84827 1.74248 8.92998 3.23231 8.92998 5.22745C8.92998 7.2226 9.84827 8.71242 10.669 8.71242C11.4897 8.71242 12.408 7.2226 12.408 5.22745C12.408 3.23231 11.4897 1.74248 10.669 1.74248Z"
				fill={fill}
			/>
			<path
				d="M19.3873 10.4549C17.4322 10.4549 15.9023 8.15831 15.9023 5.22745C15.9023 2.29659 17.4322 0 19.3873 0C21.3424 0 22.8723 2.29659 22.8723 5.22745C22.8723 8.15831 21.3424 10.4549 19.3873 10.4549ZM19.3873 1.74248C18.5649 1.74248 17.6448 3.23231 17.6448 5.22745C17.6448 7.2226 18.5649 8.71242 19.3873 8.71242C20.2098 8.71242 21.1298 7.2226 21.1298 5.22745C21.1298 3.23231 20.2098 1.74248 19.3873 1.74248Z"
				fill={fill}
			/>
			<path
				d="M26.3561 17.4229C24.401 17.4229 22.8711 15.1194 22.8711 12.1763C22.8711 9.23325 24.401 6.92969 26.3561 6.92969C28.3111 6.92969 29.841 9.23325 29.841 12.1763C29.841 15.1194 28.3111 17.4229 26.3561 17.4229ZM26.3561 8.67217C25.5336 8.67217 24.6136 10.1707 24.6136 12.1763C24.6136 14.1819 25.5336 15.6804 26.3561 15.6804C27.1785 15.6804 28.0985 14.1819 28.0985 12.1763C28.0985 10.1707 27.1785 8.67217 26.3561 8.67217Z"
				fill={fill}
			/>
			<path
				d="M21.1273 27.8816C19.6148 27.8816 18.592 27.3693 17.6911 26.918C16.8547 26.4998 16.1334 26.1391 15.0286 26.1391C13.9291 26.1391 13.2077 26.4998 12.3713 26.918C11.467 27.3693 10.4424 27.8816 8.92993 27.8816C7.96111 27.8816 7.04979 27.3536 6.42598 26.4336C5.33344 24.82 5.31601 22.3718 6.37719 19.7163C8.28869 14.9384 11.4408 12.1992 15.0269 12.1992C18.6129 12.1992 21.7668 14.9384 23.6766 19.7163C24.7378 22.3718 24.7203 24.82 23.6278 26.4336C23.0057 27.3536 22.0927 27.8816 21.1238 27.8816H21.1273ZM15.0286 24.3966C16.5428 24.3966 17.5674 24.9089 18.47 25.3602C19.3047 25.7784 20.0261 26.1391 21.1273 26.1391C21.5072 26.1391 21.894 25.8899 22.1885 25.4578C22.9412 24.3478 22.8925 22.4433 22.0613 20.3645C20.4286 16.2836 17.8654 13.9417 15.0286 13.9417C12.1919 13.9417 9.62866 16.2819 7.99596 20.3645C7.16479 22.4433 7.11774 24.3478 7.86875 25.4578C8.16149 25.8917 8.54832 26.1391 8.92993 26.1391C10.0312 26.1391 10.7543 25.7784 11.5924 25.3585C12.4951 24.9072 13.5196 24.3949 15.0286 24.3949V24.3966Z"
				fill={fill}
			/>
		</svg>
	);
};

export default PetsIcon;
