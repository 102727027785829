import { create } from "zustand";
import { PhoneValidator } from "../utils/phoneValidator";
import { isValidEmail } from "../utils/emailValidator";
import { formatPrice } from "../utils/formatPrice";
import { convertToFormattedAmount } from "../utils/convertAmount";

interface AdvertisementUserStore {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	income: string;
	savings: string;
	debt: string;
	firstNameError: string;
	lastNameError: string;
	emailError: string;
	phoneNumberError: string;
	incomeError: string;
	savingsError: string;
	debtError: string;
	setFirstName: (firstName: string) => void;
	setLastName: (lastName: string) => void;
	setEmail: (email: string) => void;
	setPhoneNumber: (phoneNumber: string) => void;
	setIncome: (income: string) => void;
	setSavings: (savings: string) => void;
	setDebt: (debt: string) => void;
	setFirstNameError: (error: string) => void;
	setLastNameError: (error: string) => void;
	setEmailError: (error: string) => void;
	setPhoneNumberError: (error: string) => void;
	setIncomeError: (error: string) => void;
	setSavingsError: (error: string) => void;
	setDebtError: (error: string) => void;
	validateNameFields: () => boolean;
	validateContactFields: () => boolean;
	validateFinancialFields: () => boolean;
	validateIncome: () => boolean;
	validateSavings: () => boolean;
	validateDebt: () => boolean;
	clearUserInfo: () => void;
}

const useAdvertisementUserStore = create<AdvertisementUserStore>(
	(set, get) => ({
		// State
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		income: formatPrice(convertToFormattedAmount(0)),
		savings: formatPrice(convertToFormattedAmount(0)),
		debt: formatPrice(convertToFormattedAmount(0)),
		firstNameError: "",
		lastNameError: "",
		emailError: "",
		phoneNumberError: "",
		incomeError: "",
		savingsError: "",
		debtError: "",

		// Setters
		setFirstName: (firstName) => set({ firstName }),
		setLastName: (lastName) => set({ lastName }),
		setEmail: (email) => set({ email }),
		setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
		setIncome: (income) => set({ income }),
		setSavings: (savings) => set({ savings }),
		setDebt: (debt) => set({ debt }),
		setFirstNameError: (error) => set({ firstNameError: error }),
		setLastNameError: (error) => set({ lastNameError: error }),
		setEmailError: (error) => set({ emailError: error }),
		setPhoneNumberError: (error) => set({ phoneNumberError: error }),
		setIncomeError: (error) => set({ incomeError: error }),
		setSavingsError: (error) => set({ savingsError: error }),
		setDebtError: (error) => set({ debtError: error }),

		validateIncome: () => {
			const { income, setIncomeError } = get();
			const isValid = income.trim() !== "";
			setIncomeError(isValid ? "" : "Income is required.");
			return isValid; // Return true if valid, false if not
		},

		validateSavings: () => {
			const { savings, setSavingsError } = get();
			const isValid = savings.trim() !== "";
			setSavingsError(isValid ? "" : "Savings is required.");
			return isValid; // Return true if valid, false if not
		},

		validateDebt: () => {
			const { debt, setDebtError } = get();
			const isValid = debt.trim() !== "";
			setDebtError(isValid ? "" : "Debt is required.");
			return isValid; // Return true if valid, false if not
		},

		validateNameFields: () => {
			const { firstName, lastName, setFirstNameError, setLastNameError } =
				get();

			const isFirstNameValid = firstName.trim() !== "";
			const isLastNameValid = lastName.trim() !== "";

			setFirstNameError(isFirstNameValid ? "" : "First Name is required.");
			setLastNameError(isLastNameValid ? "" : "Last Name is required.");

			// Return true if both name fields are valid
			return isFirstNameValid && isLastNameValid;
		},

		validateContactFields: () => {
			const { email, phoneNumber, setEmailError, setPhoneNumberError } = get();

			const isEmailValid = isValidEmail(email);
			setEmailError(isEmailValid ? "" : "Invalid email format.");

			let isPhoneValid = true;
			try {
				isPhoneValid = PhoneValidator.isValid(phoneNumber, "CA");
				if (!isPhoneValid) {
					isPhoneValid = PhoneValidator.isValid(phoneNumber, "US");
				}
			} catch {
				isPhoneValid = false;
			}
			setPhoneNumberError(
				isPhoneValid ? "" : "Please enter a valid phone number.",
			);

			return isEmailValid && isPhoneValid;
		},

		validateFinancialFields: () => {
			const {
				income,
				savings,
				debt,
				setIncomeError,
				setSavingsError,
				setDebtError,
			} = get();

			const isIncomeValid = income.trim() !== "";
			const isSavingsValid = savings.trim() !== "";
			const isDebtValid = debt.trim() !== "";

			setIncomeError(isIncomeValid ? "" : "Income is required.");
			setSavingsError(isSavingsValid ? "" : "Savings is required.");
			setDebtError(isDebtValid ? "" : "Debt is required.");

			// Return true if all financial fields are valid
			return isIncomeValid && isSavingsValid && isDebtValid;
		},

		clearUserInfo: () =>
			set({
				firstName: "",
				lastName: "",
				email: "",
				phoneNumber: "",
				income: "",
				savings: "",
				debt: "",
				firstNameError: "",
				lastNameError: "",
				emailError: "",
				phoneNumberError: "",
				incomeError: "",
				savingsError: "",
				debtError: "",
			}),
	}),
);

interface AdvertisementStep {
	advertisementCurrentStep: number;
	setAdvertisementStep: (currentStep: number) => void;
}
const useAdvertisementStep = create<AdvertisementStep>((set) => ({
	advertisementCurrentStep: 0,
	setAdvertisementStep: (state: number) =>
		set({
			advertisementCurrentStep: state,
		}),
}));

export { useAdvertisementUserStore, useAdvertisementStep };
