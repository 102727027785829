import { create } from "zustand";

interface BottomBarStore {
  bottomBarHeight: number;
  setBottomBarHeight: (height: number) => void;
}

export const useBottomBarStore = create<BottomBarStore>((set) => ({
  bottomBarHeight: 0,
  setBottomBarHeight: (height) => set({ bottomBarHeight: height }),
}));
