import { universalFetch } from "./common";

export const initiateAuth = async (email: string) => {
  try {
    const response = await universalFetch("/auth/initiate", "POST", { email });
    return response;
  } catch (error) {
    console.error("Failed to initiate auth:", error);
    throw error;
  }
};

export const initiateGuestAuth = async (email: string) => {
  try {
    const response = await universalFetch("/auth/guest", "POST", { email });
    return response;
  } catch (error) {
    console.error("Failed to initiate auth:", error);
    throw error;
  }
};

export const verifyAuth = async (email: string, code: string) => {
  const response = await universalFetch<Response>("/auth/verify", "POST", {
    email,
    code,
  });

  if (response.status !== 200) {
    throw new Error(`Failed to verify auth: ${response.statusText}`);
  }

  return response;
};
