interface IconProps {
	fill: string;
}
const ChildrenIcon = ({ fill }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="39"
			height="36"
			viewBox="0 0 39 36"
			fill="none"
		>
			<path
				d="M17.0555 1C8.18831 1 1 8.18831 1 17.0555C1 20.1771 1.8908 23.0905 3.43204 25.5555"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M30.6797 25.5551C32.2208 23.0901 33.1116 20.1767 33.1116 17.0551V13.2773H37.8338"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.4418 34.997C8.35543 34.997 6.66406 33.3056 6.66406 31.2192C6.66406 29.1327 8.35543 27.4414 10.4418 27.4414C12.5282 27.4414 14.2196 29.1327 14.2196 31.2192C14.2196 33.3056 12.5282 34.997 10.4418 34.997Z"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.6684 34.997C21.5819 34.997 19.8906 33.3056 19.8906 31.2192C19.8906 29.1327 21.5819 27.4414 23.6684 27.4414C25.7549 27.4414 27.4462 29.1327 27.4462 31.2192C27.4462 33.3056 25.7549 34.997 23.6684 34.997Z"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.0586 1V18"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.94922 18H32.1714"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ChildrenIcon;
