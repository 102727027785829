import { Button, Select } from "../common";
import schoolJson from "../../schools.json";
import { placeWorshipOptions } from "./data";
import { useNavigate } from "react-router-dom";
import stationJson from "../../goStations.json";
import { PoiSelect } from "../common/poiSelect";
import { usePointsInterest } from "./hook/pointsInterest";
import { MinusIcon, LocationIcon, PlusIcon } from "../../assets/icons";
import { AddressAutocomplete } from "../common/googleAddressLoop/addressAutocomplete";

interface SelectOption {
	value: string;
	label: string;
	lat: number;
	lon: number;
}

const PointsOfInterestView = () => {
	const {
		removeSelectedAddress,
		addPointOfInterest,
		removePointOfInterest,
		selectedAddresses,
		setSelectedAddresses,
		selectedPoints,
		remainingPopularPoints,
		pointsInterestError,
		setPointsInterestError,
		setCurrentStep,
		placeWorship,
		setPlaceWorship,
		selectedStation,
		setSelectedStation,
		selectedSchool,
		setSelectedSchool,
		selectedWorkAddress,
		setSelectedWorkAddress,
	} = usePointsInterest();

	const navigate = useNavigate();

	const stationOptions = stationJson.map((stop) => ({
		value: stop.stop_name,
		label: stop.stop_name,
		lat: Number.parseFloat(stop.stop_lat) || 0,
		lon: Number.parseFloat(stop.stop_lon) || 0,
	}));

	const schoolOptions = schoolJson.features.map((feature) => ({
		value: feature.properties.title,
		label: feature.properties.title,
		lat: Number.parseFloat(feature.geometry.coordinates[1]) || 0,
		lon: Number.parseFloat(feature.geometry.coordinates[0]) || 0,
	}));
	return (
		<div className="w-full h-full px-4">
			<div className="pb-6">
				<h2 className="text-z-shark text-2xl font-bold mb-2">
					Points of Interest
				</h2>

				{pointsInterestError && (
					<div className="bg-black bg-opacity-15 fixed top-0 right-0 left-0 bottom-0 w-full h-full z-20">
						<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-11/12 flex items-center justify-center flex-col py-7 px-6 rounded-2xl bg-white">
							<div className="text-2xl font-semibold text-z-shark">
								Skip Points of Interest?
							</div>
							<div className="text-base text-z-shark mt-4 mb-6">
								You haven't selected any areas or points of interest. Skipping
								this step means we won't be able to prioritize locations that
								matter to you. Are you sure you want to continue?
							</div>
							<div className="text-[#ADADAD] text-sm">
								This can be changed later
							</div>
							<div className="flex flex-col w-full mt-8 space-y-5">
								<Button
									onClick={() => {
										setPointsInterestError(false);
									}}
									variant="primary"
									customClassName="font-bold text-lg bg-white"
									height="45px"
									width="full"
									radius="60px"
								>
									Add Point of Interest
								</Button>
								<Button
									onClick={() => {
										navigate("/buyer-input-flow/lifestyle");
										setCurrentStep(10);
									}}
									customClassName="font-bold text-lg bg-white"
									height="45px"
									isOutlined
									width="full"
									radius="60px"
								>
									Skip
								</Button>
							</div>
						</div>
					</div>
				)}
				<span className="text-z-shark text-sm">
					Where would you like to live near
				</span>
				<div className="mt-3 relative mb-10">
					<AddressAutocomplete
						onSelect={(address, latitude, longitude) => {
							setSelectedAddresses((prev) => [
								...prev,
								{ id: Date.now(), name: address, latitude, longitude },
							]);
						}}
						placeholder="Enter an address"
						className="h-16 w-full text-z-shark font-medium text-sm px-3 rounded-xl border border-[#EAEAEA] placeholder:text-[#D1D1D1]"
					/>
				</div>
				{remainingPopularPoints.length > 0 && (
					<div className="mt-6 space-y-3">
						<span className="text-sm font-semibold text-z-shark">
							Popular points
						</span>
						<div className="flex items-center flex-wrap gap-x-3 gap-y-4">
							{remainingPopularPoints.map((point) => (
								<div
									key={point.id}
									onClick={() => addPointOfInterest(point)}
									onKeyUp={(event) => {
										if (event.key === "Enter" || event.key === " ") {
											addPointOfInterest(point);
										}
									}}
									className="px-5 space-x-6 py-3 rounded-full bg-[#EFF6FF] flex items-center justify-between"
								>
									<div className="flex items-center space-x-2">
										<img src={point.icon} alt={`${point.name} icon`} />
										<span className="text-z-shark text-sm font-semibold">
											{point.name}
										</span>
										<PlusIcon />
									</div>
								</div>
							))}
						</div>
					</div>
				)}
				{selectedPoints.length > 0 && (
					<div className="space-y-10 mt-10">
						{selectedPoints.map((point) => (
							<div key={point.id}>
								<div className="flex items-center justify-between">
									<div className="flex items-center space-x-3">
										<img className="w-8" src={point.icon} alt="" />
										<div className="text-xl font-semibold text-z-shark">
											{point.name}
										</div>
									</div>
									<div
										className="w-4 h-4 flex items-center"
										onClick={() => removePointOfInterest(point)}
										onKeyUp={(event) => {
											if (event.key === "Enter" || event.key === " ") {
												removePointOfInterest(point);
											}
										}}
									>
										<MinusIcon />
									</div>
								</div>
								<div className="mt-3">
									{point.id === 1 && (
										<div>
											{selectedStation === "" ? (
												<PoiSelect
													options={stationOptions}
													value={selectedStation}
													onChange={(option: SelectOption) => {
														setSelectedStation(
															option.value,
															option.lat !== undefined ? option.lat : 0,
															option.lon !== undefined ? option.lon : 0,
														);
													}}
													search={true}
													placeholder="Select a station"
												/>
											) : (
												<div className="text-z-shark text-sm font-normal ml-11">
													{selectedStation}
												</div>
											)}
										</div>
									)}
									{point.id === 2 &&
										(selectedWorkAddress === "" ? (
											<div className="mt-3 relative mb-10">
												<AddressAutocomplete
													onSelect={(address, latitude, longitude) => {
														setSelectedWorkAddress(
															address,
															latitude,
															longitude,
														);
													}}
													placeholder="Enter your work address"
													className="h-16 w-full text-z-shark font-medium text-sm px-3 rounded-xl border border-[#EAEAEA] placeholder:text-[#D1D1D1]"
												/>
											</div>
										) : (
											<div className="text-z-shark text-sm font-normal ml-11">
												{selectedWorkAddress}
											</div>
										))}
									{point.id === 3 &&
										(selectedSchool === "" ? (
											<PoiSelect
												options={schoolOptions}
												value={selectedSchool}
												onChange={(option: SelectOption) => {
													setSelectedSchool(
														option.value,
														option.lat !== undefined ? option.lat : 0,
														option.lon !== undefined ? option.lon : 0,
													);
												}}
												search={true}
												placeholder="Select a school"
											/>
										) : (
											<div className="text-z-shark text-sm font-normal ml-11">
												{selectedSchool}
											</div>
										))}
									{point.id === 4 &&
										(placeWorship === "" ? (
											<Select
												options={placeWorshipOptions}
												value={placeWorship}
												onChange={setPlaceWorship}
												placeholder="Select one"
											/>
										) : (
											<div className="text-z-shark text-sm font-normal ml-11">
												{placeWorship}
											</div>
										))}
								</div>
							</div>
						))}
					</div>
				)}
				{selectedAddresses.length > 0 && (
					<div className="mt-5">
						{selectedAddresses.map((address) => (
							<div
								key={address.id}
								className="flex items-center justify-between mt-8"
							>
								<div className="flex items-center space-x-5">
									<img className="w-6" src={LocationIcon} alt="location icon" />
									<div className="text-sm text-z-shark">{address.name}</div>
								</div>
								<div
									className="w-4 h-4 items-center flex"
									onClick={() => removeSelectedAddress(address.id)}
									onKeyUp={(event) => {
										if (event.key === "Enter" || event.key === " ") {
											removeSelectedAddress(address.id);
										}
									}}
								>
									<MinusIcon />
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export { PointsOfInterestView };
