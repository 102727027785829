/**
 * Formats a number or string as a price with commas as thousand separators.
 * Removes decimals and trailing ".00" if present.
 *
 * @param {number | string} price - The number or string to be formatted.
 * @returns {string} The formatted price as a string.
 *
 * @example
 * // Returns "1,234"
 * formatPrice(1234);
 *
 * @example
 * // Returns "0"
 * formatPrice(0);
 */
export const formatPrice = (price: number | string): string => {
  if (price === null || price === undefined || price === 0) {
    return "0";
  }

  const num = typeof price === "string" ? Number.parseFloat(price) : price;

  const roundedNum = Math.round(num);

  const numStr = roundedNum.toString();
  const [whole] = numStr.split(".");

  const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedWhole;
};
