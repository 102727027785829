import CafeIcon from "../../../assets/icons/cafeIcon.svg";
import StoreIcon from "../../../assets/icons/storeIcon.svg";
import SchoolIcon from "../../../assets/icons/schoolIcon.svg";
import HouseIcon from "../../../assets/icons/homeIconBlack.svg";
import { type Dispatch, type SetStateAction, useState } from "react";

interface IconButtonProps {
	label: string;
	icon: string;
	isActive: boolean;
	onClick: () => void;
}

interface MapSelectorProps {
	nearbyType: string;
	setNearbyType: Dispatch<SetStateAction<string>>;
}

const selectorLabels = [
	{ label: "Schools", type: "school", icon: SchoolIcon },
	{ label: "Groceries", type: "grocery", icon: StoreIcon },
	{ label: "Homes", type: "homeSale", icon: HouseIcon },
	{ label: "Nearby", type: "nearby", icon: CafeIcon },
];

const IconButton = ({ label, icon, isActive, onClick }: IconButtonProps) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={`inline-flex items-center text-black justify-center w-full p-4 px-6 ${
				isActive
					? "border-b-z-abbey font-bold border-b-4 border border-[#F2F2F2]"
					: "border-b-0 border border-[#F2F2F2]"
			}`}
		>
			<img src={icon} alt={label} className="w-6 h-6 mr-1" /> {label}
		</button>
	);
};

const MapSelector = ({ nearbyType, setNearbyType }: MapSelectorProps) => {
	const [activeTab, setActiveTab] = useState(nearbyType);

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
		setNearbyType(tab);
	};

	return (
		<div className="flex flex-col items-center w-full bg-white z-10 text-sm font-medium text-center ">
			<ul className="flex -mb-px justify-start overflow-x-auto no-scrollbar w-full scroll-snap-x ">
				{selectorLabels.map((item) => (
					<li key={item.type} className="scroll-snap-item">
						<IconButton
							label={item.label}
							icon={item.icon}
							isActive={activeTab === item.type}
							onClick={() => handleTabChange(item.type)}
						/>
					</li>
				))}
			</ul>
		</div>
	);
};

export { MapSelector };
