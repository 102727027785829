import millify from "millify";
import { useEffect, useRef } from "react";
import Chart, { type TooltipItem } from "chart.js/auto";
import { formatPrice } from "../../../utils/formatPrice";
import type { Listing } from "../../../interfaces/listing";
import ArrowUpIcon from "../../../assets/Listing/arrowUpZownForecast.svg";
import type { ZownForecastInterface } from "../../../interfaces/statistics";
import ArrowDownIcon from "../../../assets/Listing/arrowDownZownForecast.svg";

interface ZownForecastProps {
	listing: Listing;
	zownForecast: ZownForecastInterface;
}

const ZownForecast = ({ listing, zownForecast }: ZownForecastProps) => {
	const chartRef = useRef<Chart | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	const getPercentageDifference = (current: number, previous: number) => {
		return ((current - previous) / previous) * 100;
	};

	const finalValuation = zownForecast.final_ai_eval_for_target_property;
	const dates = zownForecast.history_price_estimations_and_future_forecasts
		? Object.keys(
				zownForecast.history_price_estimations_and_future_forecasts,
			).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
		: [];

	const currentDate = new Date();
	const currentMonth = `${currentDate.getFullYear()}-${String(
		currentDate.getMonth() + 1,
	).padStart(2, "0")}`;

	const todayIndex = dates.indexOf(currentMonth);

	if (todayIndex === -1) {
		console.error("Current month not found in dates");
		return null;
	}

	const todayVal =
		zownForecast.history_price_estimations_and_future_forecasts?.[
			currentMonth
		] ?? null;
	const oneMonthAgoDate = todayIndex > 0 ? dates[todayIndex - 1] : null;
	const sixMonthsLaterDate =
		todayIndex + 6 < dates.length ? dates[todayIndex + 6] : null;

	const oneMonthAgoVal = oneMonthAgoDate
		? (zownForecast.history_price_estimations_and_future_forecasts?.[
				oneMonthAgoDate
			] ?? null)
		: null;
	const sixMonthsLaterVal = sixMonthsLaterDate
		? (zownForecast.history_price_estimations_and_future_forecasts?.[
				sixMonthsLaterDate
			] ?? null)
		: null;

	const oneMonthDifference =
		todayVal !== null && oneMonthAgoVal !== null
			? getPercentageDifference(todayVal, oneMonthAgoVal)
			: null;

	const sixMonthsDifference =
		todayVal !== null && sixMonthsLaterVal !== null
			? getPercentageDifference(sixMonthsLaterVal, todayVal)
			: null;
	const oneMonthColor =
		oneMonthDifference && oneMonthDifference > 0 ? "#0A9D57" : "#FF5757";
	const oneMonthArrow =
		oneMonthDifference && oneMonthDifference > 0 ? ArrowUpIcon : ArrowDownIcon;

	const sixMonthsColor =
		sixMonthsDifference && sixMonthsDifference > 0 ? "#0A9D57" : "#FF5757";
	const sixMonthsArrow =
		sixMonthsDifference && sixMonthsDifference > 0
			? ArrowUpIcon
			: ArrowDownIcon;

	useEffect(() => {
		const fetchZownForecast = async () => {
			const forecasts =
				zownForecast.history_price_estimations_and_future_forecasts;
			if (!forecasts) {
				return;
			}

			const xValues = dates.slice(-30);
			const yValues = xValues.map((date) => forecasts[date] || 0);

			if (canvasRef.current) {
				if (chartRef.current) {
					chartRef.current.destroy();
				}

				const ctx = canvasRef.current.getContext("2d");
				if (ctx) {
					const gradient = ctx.createLinearGradient(0, 0, 0, 600);
					gradient.addColorStop(0, "rgba(254, 209, 28, 0.7)");
					gradient.addColorStop(1, "rgba(254, 209, 28, 0)");

					chartRef.current = new Chart(ctx, {
						type: "line",
						data: {
							labels: xValues,
							datasets: [
								{
									fill: true,
									backgroundColor: gradient,
									pointRadius: 0,
									borderColor: "#FECD07",
									tension: 0.5,
									data: yValues,
								},
							],
						},
						options: {
							responsive: true,
							maintainAspectRatio: false,
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									mode: "index",
									intersect: false,
									callbacks: {
										label: (tooltipItem: TooltipItem<"line">) =>
											`$${formatPrice(tooltipItem.parsed.y)}`,
									},
								},
							},
							scales: {
								y: {
									display: false,
									grid: {
										display: false,
									},
								},
								x: {
									type: "category",
									grid: {
										display: false,
									},
									ticks: {
										autoSkip: true,
										maxRotation: 0,
										minRotation: 0,
									},
								},
							},
						},
					});
				}
			}
		};

		fetchZownForecast();
	}, [zownForecast, dates]);

	useEffect(() => {
		return () => {
			chartRef.current?.destroy();
		};
	}, []);

	return (
		<div className="space-y-4 px-4">
			<div className="flex">
				<div className="space-y-2">
					<div className="w-full flex justify-between">
						<div className="space-y-2">
							<div className="text-lg font-semibold">Zown Forecast</div>
							{finalValuation && (
								<div className="font-bold text-xl bg-z-abbey w-fit rounded-full px-4 py-0.5">
									${formatPrice(finalValuation)}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="w-full flex flex-col items-end space-y-2">
					<div className="flex items-center justify-between gap-1.5">
						<div className="text-[10px]">Last 30 days</div>
						{oneMonthDifference !== null && (
							<div
								className="text-sm rounded-full py-1 px-2 font-semibold flex items-center gap-1"
								style={{
									backgroundColor: `${oneMonthColor}20`,
									color: oneMonthColor,
								}}
							>
								<img src={oneMonthArrow} alt="Trend Arrow" />
								{Math.abs(oneMonthDifference).toFixed(1)}%
							</div>
						)}
					</div>
					<div className="flex items-center justify-between gap-1.5">
						<div className="text-[10px]">6 Month Forecast</div>

						{sixMonthsDifference !== null && (
							<div
								className="text-sm rounded-full py-1 px-2 font-semibold flex items-center gap-1"
								style={{
									backgroundColor: `${sixMonthsColor}20`,
									color: sixMonthsColor,
								}}
							>
								<img src={sixMonthsArrow} alt="Trend Arrow" />
								{Math.abs(sixMonthsDifference).toFixed(1)}%
							</div>
						)}
					</div>
				</div>
			</div>
			{zownForecast.minimum_possible_price_for_property &&
				zownForecast.maximum_possible_price_for_property && (
					<div>
						<div className="text-xs">
							Estimated Range: $
							{millify(zownForecast.minimum_possible_price_for_property)}- $
							{millify(zownForecast.maximum_possible_price_for_property)}
						</div>
					</div>
				)}
			<canvas ref={canvasRef} id="myChart" className="h-auto max-h-[245px]" />
		</div>
	);
};

export { ZownForecast };
