import clsx from "clsx";
import moment from "moment";
import React, { ReactNode, useCallback, useEffect } from "react";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PinIcon from "../../assets/Cart/pinIcon.svg";
import ArrowOpen from "../../assets/arrowOpen.svg";
import BathIcon from "../../assets/bathIcon.svg";
import BedIcon from "../../assets/bedIcon.svg";
import CirclePlusIcon from "../../assets/circlePlusIcon.svg";
import AddHomeIcon from "../../assets/icons/addHomeIcon.svg";
import ListingXbutton from "../../assets/listingXButton.svg";
import type { CartComparisonProperty } from "../../interfaces/newInterfaces/comparisonProperty";
import {
	fieldTitles,
	sections,
} from "../../mocks/newMockData/comparisonProperties";
import { usePropertyStore } from "../../stores/newStores/cartComparisonStore";
import { formatPrice } from "../../utils/formatPrice";
import { Tooltip } from "../common/tooltip";
import { useCartComparison } from "./hooks/useCartComparison";

const CartComparisonTable = () => {
	const { scrollLeft, scrollRight, scrollRef, optionsRef, handleKeyDown } =
		useCartComparison();
	const {
		properties,
		pinnedProperty,
		loading,
		expandedSections,
		showOptions,
		compareProperties,
		handlePin,
		toggleOptions,
		handleAddProperty,
		handleDeletePropertyToCompare,
	} = usePropertyStore();

	const navigate = useNavigate();

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (
				optionsRef.current &&
				!optionsRef.current.contains(event.target as Node)
			) {
				toggleOptions();
			}
		},
		[toggleOptions],
	);

	useEffect(() => {
		if (showOptions) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			removeEventListener("mousedown", handleClickOutside);
		};
	}, [showOptions, handleClickOutside]);

	return (
		<>
			{!loading && properties.length > 0 && (
				<>
					<div className="flex items-center justify-between px-2 relative">
						<div className="rounded-full font-bold text-sm bg-z-abbey py-1 px-2 block lg:hidden">
							Checkout Comparison
						</div>
						<div className="flex items-center space-x-4 absolute right-0 -top-1">
							<button
								type="button"
								className="px-5 h-[2.5rem] rounded-3xl bg-[#EEFAFF]"
								onClick={scrollLeft}
							>
								<HiArrowLongLeft className="text-4xl" />
							</button>
							<button
								type="button"
								className="px-5 h-[2.5rem] rounded-3xl bg-[#EEFAFF]"
								onClick={scrollRight}
							>
								<HiArrowLongRight className="text-4xl" />
							</button>
						</div>
					</div>
					<div ref={scrollRef} className="overflow-x-auto no-scrollbar">
						<table className="border-collapse w-full">
							<thead className="w-full">
								<tr className="bg-white w-full">
									<th className="sticky min-w-[19.375rem] h-[15.1875rem] max-h-[15.1875rem] left-0 top-0 bg-white rounded-r-0 z-30 pb-10">
										<div className="relative h-full after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-1 after:w-[calc(100%_-_1.25rem)] after:mx-auto after:bg-z-dark-moss-green after:rounded-lg">
											{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
											<div
												className="flex flex-col w-full h-[calc(100%_-_1.875rem)] cursor-pointer justify-center items-center rounded-[.625rem]"
												onClick={toggleOptions}
											>
												<img src={AddHomeIcon} alt="" />
												<div className="text-sm mt-3 font-normal">
													Add more properties to compare
												</div>
											</div>
											<div className="text-z-dark-moss-green pointer-events-auto text-sm font-semibold absolute bottom-3 left-1/2 -translate-x-1/2">
												ALL DETAILS
											</div>
										</div>
										{showOptions && (
											<div
												ref={optionsRef}
												className="absolute top-full mt-2 shadow-lg drop-shadow-md rounded-[.625rem] text-sm w-[18.8125rem] bg-white text-start z-20"
											>
												{compareProperties.map((property) => (
													<button
														type="button"
														key={property.listingId}
														onClick={() =>
															handleAddProperty(property.listingId)
														}
														className="py-3 px-2 border-b w-full border-[#DCDCDC] cursor-pointer flex items-center justify-between"
													>
														<div>{property.address.address1}</div>

														<div className="text-xl font-thin rounded-full flex items-center justify-center text-center">
															<img src={CirclePlusIcon} alt="Add Property" />
														</div>
													</button>
												))}
											</div>
										)}
									</th>
									{properties.map((property) => (
										<th
											key={property.listingId}
											className={`pr-3 min-w-[20rem] h-[15.1875rem] pb-10 pt-7 ${
												pinnedProperty === property.listingId
													? "sticky left-[19.375rem] bg-white z-20 rounded-r-[.625rem]"
													: ""
											}`}
										>
											{pinnedProperty === property.listingId && (
												<div className="text-z-shark font-medium absolute -top-[.3125rem]">
													Pinned
												</div>
											)}
											<div className="relative">
												{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
												<div
													onClick={() =>
														navigate(`/listing/${property.listingId}`)
													}
													className="rounded-[.625rem] w-full"
												>
													<img
														src={property.displayImageUrl}
														alt={property.address.address1}
														className="w-full min-w-[8.75rem] h-full max-h-[15.1875rem] object-cover rounded-2xl"
													/>
												</div>
												<div className="absolute p-2.5 right-0 bottom-0 bg-z-abbey rounded-br-[.625rem] rounded-tl-[.625rem]">
													<img src={ArrowOpen} alt="Arrow Open Icon" />
												</div>
												<div
													className="absolute cursor-pointer bottom-2 left-2 flex items-center justify-start rounded-full"
													onClick={() => handlePin(property.listingId)}
													onKeyDown={(e) =>
														handleKeyDown(e, property.listingId)
													}
												>
													<div
														className={`w-[2rem] h-[2rem] drop-shadow-lg rounded-full flex items-center justify-center  ${
															pinnedProperty === property.listingId
																? "bg-z-abbey"
																: "bg-white"
														}`}
													>
														<img width={16} src={PinIcon} alt="Pin Property" />
													</div>
												</div>
												<div className="text-white font-bold text-xs absolute top-2 px-2 flex justify-between w-full items-center">
													<div
														className="truncate max-w-[calc(100%-1.625rem)]"
														style={{
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}
													>
														{property.address.address1}
													</div>
													<button
														type="button"
														className="h-[1.375rem] drop-shadow-lg w-[1.375rem]"
														onClick={() =>
															handleDeletePropertyToCompare(property.listingId)
														}
													>
														<img src={ListingXbutton} alt="Remove Property" />
													</button>
												</div>
											</div>
										</th>
									))}
								</tr>
							</thead>
							{sections.map((section, index) => (
								<React.Fragment key={section.title}>
									<tbody
									// key={section.title}
									>
										{/* <tr>
										<th
											className="p-4 text-left w-full flex items-center gap-2 sticky top-0 left-0 bg-white z-20 cursor-pointer"
											onClick={() => toggleSection(section.title)}
											onKeyDown={(e) => handleSectionKeyDown(e, section.title)}
										>
											{section.title}
											<img
												src={ArrowIcon}
												alt=""
												className={`transform transition-transform ${
													expandedSections[section.title] ? "rotate-90" : ""
												}`}
											/>
										</th>
									</tr> */}
										{expandedSections[section.title] &&
											section.fields.map((field, fieldIndex) => (
												<tr
													key={field}
													className={
														fieldIndex % 2 === 1 ? "bg-white" : "bg-[#EEFAFF]"
													}
												>
													<td
														className={`p-4 rounded-l-[0.7rem] font-bold text-base sticky left-0 z-20 ${
															fieldIndex % 2 === 1 ? "bg-white" : "bg-[#EEFAFF]"
														}`}
													>
														<span className="flex items-center">
															{fieldTitles[field] || field}
															{field === "listToSoldRatio" && (
																<Tooltip title="The list-to-sold ratio shows the average percentage difference from the asking price that homes have sold for in this area over the past 60 days. For example, if a home listed at $500,000 sells for $515,000, the ratio is 3%.">
																	<IoIosInformationCircleOutline className="ml-1 text-xl cursor-pointer" />
																</Tooltip>
															)}
														</span>
													</td>
													{properties.map((property, i) => (
														<td
															key={property.listingId}
															className={`text-base ${
																fieldIndex % 2 === 1
																	? "bg-white"
																	: "bg-[#EEFAFF]"
															} ${
																i === properties.length - 1
																	? "rounded-r-[1.75rem]"
																	: ""
															} ${
																pinnedProperty === property.listingId
																	? "sticky left-[19.375rem] z-20"
																	: ""
															}`}
														>
															<span
																className={clsx(
																	"flex items-center w-fit",

																	{
																		"bg-z-dark-moss-green px-2 py-1 rounded-md text-white":
																			field === "downPaymentGiven",
																		"text-z-dark-moss-green underline":
																			field === "mlsNumber",
																		"font-bold": field === "listPrice",
																	},
																)}
															>
																{field === "bedrooms" && (
																	<img className="mr-1" src={BedIcon} alt="" />
																)}
																{field === "bathrooms" && (
																	<img className="mr-1" src={BathIcon} alt="" />
																)}
																{field === "address.city"
																	? property.address.city
																	: field === "downPaymentGiven"
																		? `$${formatPrice(
																				property.downPaymentGiven,
																			)}`
																		: [
																					"listPrice",
																					"propertyTax",
																					"pricePerSqft",
																					"maintenanceFee",
																				].includes(field)
																			? `$${formatPrice(property[field as keyof CartComparisonProperty])}`
																			: field === "squareFootage" ||
																					field === "lotSquareFootage"
																				? formatPrice(property.squareFootage)
																				: field === "openHouseDate"
																					? moment(
																							property.openHouseDate,
																						).format("ddd. MMM D")
																					: property[
																							field as keyof CartComparisonProperty
																						]}
																{(field === "squareFootage" ||
																	field === "lotSquareFootage") && (
																	<div className="ml-1">sqft</div>
																)}
																{field === "dom" && (
																	<div className="ml-1">days</div>
																)}
																{field === "listToSoldRatio" && <div>%</div>}
															</span>
														</td>
													))}
												</tr>
											))}
									</tbody>
									{index < sections.length - 1 && (
										<tr className="h-[5rem] w-full before:content[''] before:w-full before:h-[.0625rem] before:bg-[#EDEDED] relative before:absolute before:top-1/2">
											<td colSpan={properties.length + 1} />
										</tr>
									)}
								</React.Fragment>
							))}
						</table>
					</div>
				</>
			)}
		</>
	);
};

export { CartComparisonTable };
