import { useState } from "react";
import PropertyDetailsIcon from "../../assets/Listing/propertyDetailsIcon.svg";
import GrayArrowDownIcon from "../../assets/grayArrowDownIcon.svg";

import { mockPercentageOverAskingPrice } from "../../mocks/newMockData/offerPredictionData";
import { ChanceOfferAccepted } from "./chanceOfferAccepted";
import { CmaInstant } from "./cmaInstant";
import { LisintgDescriptionMap } from "./map";
import { OfferPrediction } from "./offerPrediction";
import { ListingPropertyInfo } from "./propertyInfo";

const categoryList = [
	{ name: "Parks", value: 10 },
	{ name: "Groceries", value: 10 },
	{ name: "Coffee Shops", value: 10 },
	{ name: "Transit", value: 10 },
	{ name: "Cycling", value: 10 },
	{ name: "Car Friendly", value: 10 },
];

const listingHistoryData = [
	{
		dateStart: "2024-02-15",
		dateEnd: "2024-02-29",
		listPrice: "$779,000",
		price: "$799,999",
		event: "SOLD",
		listingId: "W4881498",
	},
	{
		dateStart: "2024-03-01",
		dateEnd: "2024-03-15",
		listPrice: "$689,000",
		price: "$729,999",
		event: "PENDING",
		listingId: "X5982491",
	},
	{
		dateStart: "2024-03-16",
		dateEnd: "2024-03-30",
		listPrice: "$849,000",
		price: "$899,999",
		event: "AVAILABLE",
		listingId: "Y1234567",
	},
];

const mockRooms = [
	{
		label: "Living (Level: Main)",
		size: "12.9 x 9.5 ft",
		details: "Combined W/Dining, Hardwood Floor, W/O To Balcony",
	},
	{
		label: "Living (Level: Main)",
		size: "12.9 x 9.5 ft",
		details: "Combined W/Dining, Hardwood Floor, W/O To Balcony",
	},
	{
		label: "Living (Level: Main)",
		size: "12.9 x 9.5 ft",
		details: "Combined W/Dining, Hardwood Floor, W/O To Balcony",
	},
	{
		label: "Living (Level: Main)",
		size: "12.9 x 9.5 ft",
		details: "Combined W/Dining, Hardwood Floor, W/O To Balcony",
	},
];

const listingProperty = {
	tax: "7544",
	year: "2023",
	propertyType: "Detached, 2-Storey",
	buildingAge: "-",
	size: "2500-3000 sqft",
	lot: "100 x 300 feet",
	parking: "Attached 2 garage, 8 parking",
	basement: "Sep Entrance",
	mlsNumber: "E2884884",
	dataSource: "TRREB",
	statusChange: "2 weeks ago",
	listingDate: new Date("2023-01-01"),
	updatedDate: new Date(),
	style: "2-Storey",
	community: "Downtown",
	municipality: "Cityville",
	bed: "4",
	bath: "3",
	colling: "Central Air",
	rooms: mockRooms,
	heatingType: "Forced Air",
	heatingFuel: "Gas",
	amenities: "Pool, Gym",
	garageType: "Attached",
	crossStreet: "Main St & First Ave",
};

const PropertyDetails = () => {
	const [isExpanded, setIsExpanded] = useState(true);
	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	return (
		<div className="border bg-white border-[#D9D9D9] shadow-[0_2px_12.6px_0_rgba(0,0,0,0.25)] rounded-[30px] xl:py-[45px] py-[40px] xl:px-10 px-6 mt-10">
			<div
				className="text-black font-semibold text-xl flex items-center justify-between w-full cursor-pointer"
				onClick={toggleSection}
				onKeyDown={handleKeyDown}
				aria-expanded={isExpanded}
			>
				<div className="flex items-center gap-5">
					<img width={40} src={PropertyDetailsIcon} alt="" />
					<span className="text-[#333] text-3xl">Property Details</span>
				</div>

				<img
					src={GrayArrowDownIcon}
					alt="Toggle Section"
					className={`transition-transform duration-300 ${
						isExpanded ? "rotate-180" : ""
					}`}
				/>
			</div>
			<div
				className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
					isExpanded ? "max-h-auto" : "max-h-0"
				}`}
			>
				<div className="pb-12 mb-12 border-b border-[#F3F3F3] mt-7">
					<LisintgDescriptionMap height="265px" lat={56.1304} lng={106.3468} />
					{/* <div className="grid grid-rows-3 grid-cols-2 xl:gap-x-72 lg:gap-x-5 gap-y-9 mt-7">
						{categoryList.map((category, index) => (
							<div
								key={index}
								className="relative flex items-center justify-between before:absolute before:top-1/2 before:left-0 before:right-0 before:-translate-y-1/2 before:border before:border-dashed before:border-[#B6B9CC] before:content-[''] before:w-full before:h-[1px]"
							>
								<div className="text-xl text-[#B6B9CC] font-medium bg-white z-0 pr-7">
									{category.name}
								</div>
								<div className="z-0 pl-7 bg-white">
									<span className="bg-[#D7FFEC] text-[#0A9D57] w-11 h-11 rounded-full text-xl font-bold flex justify-center items-center">
										{category.value}
									</span>
								</div>
							</div>
						))}
					</div> */}
					<div className="mt-8">
						<CmaInstant
							address="265 King St N, Toronto"
							listingPrice="13242532"
							zlid="test value"
							valuation="test value"
						/>
					</div>
				</div>
				<ListingPropertyInfo textPosition="text-end" {...listingProperty} />
				<div className="space-y-5 pt-12 mt-12 border-t border-[#F3F3F3]">
					<span className="text-xl font-semibold text-z-shark">
						Listing History
					</span>
					<div className="flex flex-col rounded-[20px] overflow-hidden border border-[#D1D1D1] border-t-0">
						<div className="grid grid-cols-6 bg-[#DCE7E0] py-4 h-[72px] divide-x divide-[#456930] text-center *:flex *:items-center *:justify-center">
							<span>Date Start</span>
							<span>Date End</span>
							<span>List Price</span>
							<span>Price</span>
							<span>Event</span>
							<span>Listing ID</span>
						</div>
						<div className="divide-y divide-[#D1D1D1] text-sm">
							{listingHistoryData.map((item, index) => (
								<div
									key={index}
									className="grid grid-cols-6 h-[58px] py-3 *:flex *:items-center *:justify-center divide-x divide-[#DFDFDF]"
								>
									<span>{item.dateStart}</span>
									<span>{item.dateEnd}</span>
									<span>{item.listPrice}</span>
									<span>{item.price}</span>
									<span>{item.event}</span>
									<span className="text-[#0A9D57]">{item.listingId}</span>
								</div>
							))}
						</div>
					</div>
					<div className="flex flex-col !mb-16 pb-16 border-b border-t pt-16 !mt-16 border-[#F3F3F3]">
						<OfferPrediction
							zlid="12345"
							dom={10}
							percentageOverAskingPrice={mockPercentageOverAskingPrice}
							comunity="West Rouge"
						/>
					</div>
					<ChanceOfferAccepted inGta={true} listingPrice="850000" dom={12000} />
				</div>
			</div>
		</div>
	);
};

export { PropertyDetails };
