import { universalFetch } from "./common";
import type {
  BuyerMatchingPreferencesPoi,
  BuyerMatchingPreferencesFilters,
} from "../interfaces/buyerMatchingPreferences";

export const updatePreferences = async (
  pointsOfInterests?: BuyerMatchingPreferencesPoi[],
  priorities?: string[],
  filters?: BuyerMatchingPreferencesFilters,
  requiredFeatures?: string[],
  optionalFeatures?: string[],
  lifeStyles?: string[]
) => {
  try {
    const response = await universalFetch("/matchingPreferences", "PUT", {
      pointsOfInterests: pointsOfInterests,
      priorities: priorities,
      filters: filters,
      requiredFeatures: requiredFeatures,
      optionalFeatures: optionalFeatures,
      lifeStyles: lifeStyles,
    });

    return response;
  } catch (error) {
    console.error("Failed to updateAccountData", error);
    throw error;
  }
};
