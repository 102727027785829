import { create } from "zustand";

interface VerificationStore {
  verificationFailed: boolean;
  setVerificationFailed: (status: boolean) => void;
}

const useVerificationStore = create<VerificationStore>((set) => ({
  verificationFailed: false,
  setVerificationFailed: (status: boolean) =>
    set({ verificationFailed: status }),
}));

export { useVerificationStore };
