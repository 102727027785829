interface IconProps {
	fill: string;
}
const NightlifeIcon = ({ fill }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="37"
			viewBox="0 0 24 37"
			fill="none"
		>
			<path
				d="M1.49219 14.0508H22.3117"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.875 29.2734V35.2529"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.17969 35.2539H20.5746"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.7436 16.7617C22.7436 23.6653 17.8513 29.2643 11.8718 29.2643C5.89231 29.2643 1 23.6653 1 16.7617"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M19.5391 7.30469C21.5503 9.85956 22.7462 13.3929 22.7462 17.3067"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1 17.3042C1 13.499 2.14154 10.0744 4.0441 7.51953"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.6758 11.8718V4.80513L19.4809 1"
				stroke={fill}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default NightlifeIcon;
