import clsx from "clsx";
import type { ReactNode } from "react";
import { xIcon } from "../../assets/icons";

interface ModalProps {
	children: ReactNode;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	closeIcon?: boolean;
	className?: string;
	bg?: string;
	customClassName?: string;
	disableOutsideClick?: boolean;
}

const Modal = ({
	children,
	isOpen,
	setIsOpen,
	closeIcon,
	className,
	bg = "#fff",
	customClassName,
	disableOutsideClick = false,
}: ModalProps) => {
	return (
		<>
			{isOpen && (
				<div className="fixed inset-0 w-full h-full z-30">
					<div
						className="bg-black bg-opacity-60 absolute inset-0 backdrop-blur-sm"
						onClick={() => !disableOutsideClick && setIsOpen(false)}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								!disableOutsideClick && setIsOpen(false);
							}
						}}
					/>
					<div
						style={{ background: bg }}
						className={clsx(
							"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-11/12 flex items-center justify-center flex-col py-7 px-6 rounded-2xl",
							customClassName,
						)}
					>
						{closeIcon && (
							<div
								className="absolute top-3 right-4 text-xl"
								onClick={() => setIsOpen(false)}
								onKeyUp={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										setIsOpen(false);
									}
								}}
							>
								<img src={xIcon} width={14} alt="close icon" />
							</div>
						)}
						<div className={className}>{children}</div>
					</div>
				</div>
			)}
		</>
	);
};

export { Modal };
