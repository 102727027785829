import { Input } from "../common";
import { type ChangeEvent, useEffect, useRef } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { handleKeyPress } from "../../utils/keyboardUtils";
import DollarIcon from "../../assets/icons/dollarIcon.svg";
import { useAdvertisementUserStore } from "../../stores/advertisementFlowStore";
import { useUserProfileStore } from "../../stores/profileStore";
import { convertToFormattedAmount } from "../../utils/convertAmount";

const SavingView = () => {
	const { savings, setSavings, savingsError } = useAdvertisementUserStore();
	const { userProfile } = useUserProfileStore();

	const inputRef = useRef<HTMLInputElement>(null);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^\d]/g, "");

		setSavings(formatPrice(value));
	};
	useEffect(() => {
		if (
			userProfile?.finances?.savings &&
			userProfile.finances.savings.length > 0
		) {
			const incomeAmount = userProfile.finances.savings[0].amount?.amount;
			if (incomeAmount) {
				setSavings(formatPrice(convertToFormattedAmount(incomeAmount)));
			}
		}
	}, [userProfile, setSavings]);

	return (
		<div
			className="relative space-y-8 px-4 w-full"
			onKeyDown={(e) => handleKeyPress(e, inputRef)}
		>
			<h3 className="text-3xl font-bold">
				Enter your{" "}
				<span className="text-z-dark-moss-green">
					total
					<br />
					household savings
				</span>
			</h3>
			<div className="text-z-dark-moss-green font-semibold">
				(including RRSP, FHSA & TFSA).
			</div>
			<div className={`${savingsError ? "space-y-8 " : "space-y-4"}`}>
				<Input
					inputType="tel"
					ref={inputRef}
					value={savings}
					onChange={handleChange}
					startIcon={DollarIcon}
					isError={savingsError}
					radius="1.25rem"
					label="Total Household Savings"
				/>
			</div>
		</div>
	);
};

export { SavingView };
