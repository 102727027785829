import type { ReactNode } from "react";
import { Header } from "../components/common/BrowseListing/header";

interface BrowseListing {
	children: ReactNode;
	customBg?: boolean;
	pb?: string;
}
const BrowseListingLayout = ({
	children,
	customBg = false,
	pb = "120px",
}: BrowseListing) => {
	return (
		<div>
			<Header />
			<div
				style={{
					background: customBg
						? "linear-gradient(180deg, rgba(159, 225, 120, 0.00) 0%, rgba(159, 225, 120, 0.10) 100%), #FFF"
						: "#fff",
					paddingBottom: pb,
				}}
			>
				<div className="container mx-auto">{children}</div>
			</div>
		</div>
	);
};

export { BrowseListingLayout };
