import { create } from "zustand";
import type { CartProperties } from "../interfaces/cartProperties";

interface ShowingCartStoreState {
  dates: string[];
  zlids: string[];
  availableSlots: { startAt: Date; endAt: Date }[];
  cartProperties: CartProperties[];
  setDates: (dates: string[]) => void;
  setZlids: (zlid: string[]) => void;
  setAvailableSlots: (availableSlots: { startAt: Date; endAt: Date }[]) => void;
  setCartProperties: (cartProperties: CartProperties[]) => void;
}

const useShowingCartStore = create<ShowingCartStoreState>((set) => ({
  dates: [],
  zlids: [],
  availableSlots: [],
  cartProperties: [],
  setZlids: (zlids) => set({ zlids }),
  setDates: (dates) => set({ dates }),
  setCartProperties: (cartProperties) => set({ cartProperties }),
  setAvailableSlots: (availableSlots) => set({ availableSlots }),
}));

export { useShowingCartStore };
