import { Button } from "../../common";
import { useFinanceStore } from "../../../stores/financeStore";
import { useModalStore } from "../../../stores/handleModalStore";
import { ClockIcon, CheckMarkerIcon } from "../../../assets/icons";

const StepOne = () => {
	const { completedSteps } = useFinanceStore();
	const { setWatchVideoModal } = useModalStore();
	return (
		<div className="text-z-shark flex items-center justify-center flex-col">
			{completedSteps[1] ? (
				<div className="flex items-center space-x-2">
					<div className="bg-z-dark-moss-green rounded-full w-4 h-4 flex items-center justify-center">
						<img src={CheckMarkerIcon} width={10} alt="check marker icon" />
					</div>
					<div className="text-sm">Step 1 Complete</div>
				</div>
			) : (
				<div className="text-sm mb-1">Step 1</div>
			)}
			<div className="text-xl font-bold mb-3">Learn how Zown works</div>
			<div className="text-[#828282] text-[14px] font-medium text-center">
				Understand how our down payment assistance works
			</div>
			<div className="flex items-center space-x-1 my-5">
				<img src={ClockIcon} alt="clock icon" />
				<div className="text-xs">{"< 60 seconds"}</div>
			</div>
			<Button
				customClassName="font-bold text-lg"
				onClick={() => setWatchVideoModal(true)}
				radius="60px"
				height="md"
				width="320px"
			>
				Watch a quick video
			</Button>
		</div>
	);
};

export { StepOne };
