import { MedianSoldPrice } from "./medianSoldPrice";
import { BestMonthsToSell } from "./bestMonthsToSell";
import { formatPrice } from "../../../utils/formatPrice";
import { SoldActiveListings } from "./soldActiveListings";
import type { Listing } from "../../../interfaces/listing";
import { useEffect, useState, type KeyboardEvent } from "react";
import { MedianSoldPriceAvgDom } from "./medianSoldPriceAvgDom";
import GrayArrowDownIcon from "../../../assets/grayArrowDownIcon.svg";
import type { StatisticsResponse } from "../../../interfaces/statistics";
import AboutMarketIcon from "../../../assets/Listing/aboutMarketIcon.svg";
import MarketFacourabilityMarkerIcon from "../../../assets/Listing/marketFacourabilityMarker.svg";

interface AboutMarketProps {
	listing: Listing;
	statistics?: StatisticsResponse | null;
}

const AboutMarket = ({ listing, statistics }: AboutMarketProps) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [avgSoldPrice, setAvgSoldPrice] = useState(0);
	const [avgDaysOnMarket, setAvgDaysOnMarket] = useState(0);
	const [homesSold, setHomesSold] = useState(0);

	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	useEffect(() => {
		setAvgSoldPrice(statistics?.listingStatistics?.avgSoldPrice ?? 0);
		setAvgDaysOnMarket(statistics?.listingStatistics?.avgDaysOnMarket ?? 0);
		setHomesSold(statistics?.listingStatistics?.homesSold ?? 0);
	}, [statistics]);

	const marketFavourabilityPercentage = (() => {
		if (avgDaysOnMarket <= 14) {
			return 100 - (avgDaysOnMarket / 14) * 33;
		}

		if (avgDaysOnMarket <= 30) {
			return 67 - ((avgDaysOnMarket - 14) / 16) * 34;
		}

		return 33 - ((avgDaysOnMarket - 30) / 30) * 33;
	})();

	const clampedPercentage = Math.min(
		Math.max(marketFavourabilityPercentage, 5),
		95,
	);
	const markerPosition = `${clampedPercentage}%`;

	return (
		<div className="border bg-white mt-10 border-[#D9D9D9] shadow-[0_2px_12.6px_0_rgba(0,0,0,0.25)] rounded-[30px] xl:pt-[45px] xl:py-[45px] py-[40px] pb-[20px] xl:px-10 px-6">
			<div className="w-full">
				<div
					className="text-black font-semibold text-xl flex items-center justify-between w-full cursor-pointer"
					onClick={toggleSection}
					onKeyDown={handleKeyDown}
					aria-expanded={isExpanded}
					aria-controls="about-market-content"
				>
					<div className="flex items-center gap-4">
						<img width={40} src={AboutMarketIcon} alt="" />
						<span className="text-[#333] text-2xl">About the Market</span>
					</div>
					<img
						src={GrayArrowDownIcon}
						alt="Toggle Section"
						className={`transition-transform duration-300 ${
							isExpanded ? "rotate-180" : ""
						}`}
					/>
				</div>

				<div
					id="about-market-content"
					className={`overflow-hidden transition-[max-height] duration-300 ease-in-out space-y-6 ${
						isExpanded ? "max-h-auto" : "max-h-0"
					}`}
				>
					<hr className="h-px bg-[#E1E0E3] border-0 mt-10 mb-10" />
					<div className="px-4 space-y-8">
						<div className="font-semibold text-xl">Market Favourability</div>
						<div className="relative flex justify-between">
							<div
								className="absolute -top-6"
								style={{
									left: markerPosition,
									transform: "translateX(-50%)",
								}}
							>
								<img src={MarketFacourabilityMarkerIcon} alt="Market Marker" />
							</div>

							<div className="rounded-l-[12px] w-full text-sm text-center text-white font-medium bg-z-dark-moss-green py-3">
								Buyer's Market
							</div>
							<div className="w-full text-sm text-center text-black font-medium bg-z-abbey py-3">
								Balanced
							</div>
							<div className="rounded-r-[12px] w-full text-sm text-center text-white font-medium bg-z-prussian-blue py-3">
								Seller's Market
							</div>
						</div>

						<div className="flex justify-between w-full text-center items-start">
							<div className="flex items-center text-center justify-center flex-col w-full border-r border-r-[#E1E0E3] px-1 space-y-2">
								<div className="text-[#676473] text-sm">
									Average Sold Price
								</div>
								<div className="font-medium text-xl">${formatPrice(avgSoldPrice)}</div>
							</div>
							<div className="flex items-center text-center justify-center flex-col w-full border-r border-r-[#E1E0E3] px-1 space-y-2">
								<div className="text-[#676473] text-sm">
									Average Days on Market
								</div>
								<div className="font-medium text-xl">{formatPrice(avgDaysOnMarket)}</div>
							</div>
							<div className="flex items-center text-center justify-center flex-col w-full px-1 space-y-2">
								<div className="text-[#676473] text-sm">
									Homes Sold
								</div>
								<div className="font-medium text-xl">{formatPrice(homesSold)}</div>
							</div>
						</div>
					</div>
					<hr className="h-px  bg-[#E1E0E3] border-0 !mt-8" />

					<div className="space-y-4 px-4 !mt-8 !mb-8 pb-4">
            <div className="font-semibold text-lg">Historic Growth Rate</div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col items-center border-r border-r-[#E1E0E3] justify-center w-full space-y-1">
                <div className="text-sm text-[#676473]">Clarkson</div>
                <div className="text-[#4052B5] font-medium text-xl">Sign-in</div>
              </div>
              <div className="flex flex-col items-center border-r border-r-[#E1E0E3] justify-center w-full space-y-1">
                <div className="text-sm text-[#676473]">Clarkson</div>
                <div className="text-[#00D4A1] font-medium text-xl">Sign-in</div>
              </div>
              <div className="flex flex-col items-center justify-center w-full space-y-1">
                <div className="text-sm text-[#676473]">Clarkson</div>
                <div className="text-[#A02CE7] font-medium text-xl">Sign-in</div>
              </div>
            </div>
          </div>
					{statistics?.bestMonthsToSell && (
						<>
							<hr className="h-px mx-4 bg-[#E1E0E3] border-0" />
							<BestMonthsToSell
								bestMonthsToSell={statistics?.bestMonthsToSell}
							/>
						</>
					)}

					{statistics?.medianSoldPrice && (
						<>
							<hr className="h-px mx-4 bg-[#E1E0E3] border-0" />
							<MedianSoldPrice medianSoldPrice={statistics.medianSoldPrice} />
						</>
					)}
					{statistics?.soldActiveListings && (
						<>
							<hr className="h-px mx-4 bg-[#E1E0E3] border-0" />
							<SoldActiveListings
								soldActiveListings={statistics.soldActiveListings}
							/>
						</>
					)}
					{statistics?.averageDaysOnMarket && (
						<>
							<hr className="h-px mx-4 bg-[#E1E0E3] border-0" />
							<MedianSoldPriceAvgDom
								medianSoldPriceAvgDom={statistics.averageDaysOnMarket}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export { AboutMarket };
