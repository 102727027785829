import { type KeyboardEvent, useState, useEffect, useRef } from "react";

interface DropdownProps {
	options: string[];
	value: string;
	placeholder?: string;
	onChange: (value: string) => void;
}

const Dropdown = ({ options, value, placeholder, onChange }: DropdownProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [position, setPosition] = useState<"bottom" | "top">("bottom");
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleSelect = (option: string) => {
		onChange(option);
		setIsOpen(false);
	};

	const handleKeyPress = (
		event: KeyboardEvent<HTMLLIElement>,
		option: string,
	) => {
		if (event.key === "Enter" || event.key === " ") {
			handleSelect(option);
		}
	};

	const handleDropdownToggle = () => {
		setIsOpen((prev) => {
			const newIsOpen = !prev;
			if (!newIsOpen && dropdownRef.current) {
				const dropdownRect = dropdownRef.current.getBoundingClientRect();
				const windowHeight = window.innerHeight;
				if (dropdownRect.bottom + 200 > windowHeight) {
					setPosition("top");
				} else {
					setPosition("bottom");
				}
			}
			return newIsOpen;
		});
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div ref={dropdownRef} className="relative w-full">
			<button
				type="button"
				onClick={handleDropdownToggle}
				onKeyDown={(e) => e.key === "Enter" && handleDropdownToggle()}
				className={`block w-full bg-[#F9F9F9] ${
					value ? "text-black" : "text-[#BCBCBC]"
				} rounded-md px-3 py-4 text-left`}
			>
				{value || placeholder}
			</button>
			{isOpen && (
				<ul
					className={`absolute z-10 w-full bg-white shadow-lg rounded-md max-h-60 overflow-y-auto ${
						position === "top" ? "bottom-full mb-1" : "mt-1"
					}`}
				>
					{options.map((option) => (
						<li
							key={option}
							onClick={() => handleSelect(option)}
							onKeyDown={(event) => handleKeyPress(event, option)}
							className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
						>
							{option}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export { Dropdown };
