import { useEffect, useRef, useState } from "react";
import {
	useStep,
	useHomeFeaturesStore,
} from "../../../stores/buyerHomeFeaturesStore";
import { BuyerMatchingPreferencesPoiTypeEnum } from "../../../interfaces/buyerMatchingPreferences";
import { pointsOfInterest } from "../data";

export interface City {
	id: string;
	name: string;
	type?: string;
	icon?: string;
	latitude?: number | null;
	longitude?: number | null;
}

const usePointsInterest = () => {
	const { setCurrentStep } = useStep();

	const {
		selectedAddresses,
		setSelectedAddresses,
		selectedStation,
		setSelectedStation,
		selectedSchool,
		setSelectedSchool,
		selectedWorkAddress,
		setSelectedWorkAddress,
		setPointsInterestError,
		pointsInterestError,
		setRemainingPopularPoints,
		remainingPopularPoints,
		selectedPoints,
		setSelectedPoints,
		placeWorship,
		setPlaceWorship,
	} = useHomeFeaturesStore();

	const typeToNameMap = {
		[BuyerMatchingPreferencesPoiTypeEnum.School]: "School",
		[BuyerMatchingPreferencesPoiTypeEnum.PublicStation]: "GO Station",
		[BuyerMatchingPreferencesPoiTypeEnum.Work]: "Work",
		[BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship]: "Place of worship",
		[BuyerMatchingPreferencesPoiTypeEnum.Other]: "Address",
	};

	const getNameFromType = (type) => typeToNameMap[type] || type;

	const processingPOIRef = useRef(new Set());
	const nameToTypeMap = {
		School: BuyerMatchingPreferencesPoiTypeEnum.School,
		"GO Station": BuyerMatchingPreferencesPoiTypeEnum.PublicStation,
		Work: BuyerMatchingPreferencesPoiTypeEnum.Work,
		"Place of worship": BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship,
		Address: BuyerMatchingPreferencesPoiTypeEnum.Other,
	};

	const getTypeFromName = (name) =>
		nameToTypeMap[name] || BuyerMatchingPreferencesPoiTypeEnum.Other;

	const addPointOfInterest = (poi: City) => {
		console.log(`Adding POI: ${JSON.stringify(poi)}`);
		const type = getNameFromType(poi.type) || poi.name;
		const name = poi.name;

		console.log(`Type: ${type}, Name: ${name}`);
		const alreadyExists = selectedPoints.some(
			(point) => point.name === name || point.type === type
		);
		if (alreadyExists) {
			console.log(`Skipping duplicate addition for POI: ${name}, type: ${type}`);
			return;
		}

		setSelectedPoints((prev) => [
			...prev,
			{
				...poi,
				type,
				name,
				latitude: poi.latitude ?? undefined,
				longitude: poi.longitude ?? undefined,
			},
		]);

		setRemainingPopularPoints((prev) =>
			prev.filter((point) => point.name !== type && point.name !== name),
		);
	};

	const removePointOfInterest = (point: City) => {
		const type = getNameFromType(point.type) || point.type;
		const name = point.name;

		// Remove from selectedPoints
		setSelectedPoints((prev) => prev.filter((item) => item.id !== point.id));
		console.log(`Removing POI: ${name}, type: ${type}`);

		// Reset state for specific POI type
		switch (type) {
			case "School":
				setSelectedSchool("", 0, 0);
				break;
			case "GO Station":
				setSelectedStation("", 0, 0);
				break;
			case "Work":
				setSelectedWorkAddress("", 0, 0);
				break;
			case "Place of worship":
				setPlaceWorship("");
				break;
			case BuyerMatchingPreferencesPoiTypeEnum.Other:
				setSelectedAddresses((prev) =>
					prev.filter((address) => address.id !== point.id),
				);
				break;
			default:
				break;
		}
		console.log(type);
		setRemainingPopularPoints((prev) => {
			const isInInitialPoints = initialPopularPoints.some(
				(p) => p.name === type,
			);

			if (isInInitialPoints && !prev.some((p) => p.name === type)) {
				const initialPoint = initialPopularPoints.find((p) => p.name === type);
				return [...prev, { ...initialPoint, type }];
			}

			return prev;
		});
	};

	useEffect(() => {
		processingPOIRef.current.clear();
	});

	const [initialPopularPoints] = useState(pointsOfInterest);

	useEffect(() => {
		processingPOIRef.current.clear();
	}, []);

	const removeSelectedAddress = (id: number) => {
		setSelectedAddresses((prev) => prev.filter((address) => address.id !== id));
	};

	return {
		removeSelectedAddress,
		addPointOfInterest,
		removePointOfInterest,
		setCurrentStep,
		remainingPopularPoints,
		setRemainingPopularPoints,
		selectedAddresses,
		setSelectedAddresses,
		selectedPoints,
		setSelectedPoints,
		pointsInterestError,
		setPointsInterestError,
		selectedStation,
		setSelectedStation,
		selectedSchool,
		setSelectedSchool,
		selectedWorkAddress,
		setSelectedWorkAddress,
		placeWorship,
		setPlaceWorship,
	};
};

export { usePointsInterest };
