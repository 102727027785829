import { Button } from "../../common";
import { useNavigate } from "react-router-dom";
import { InfoIcon, LockIcon } from "../../../assets/icons";
import { useFinanceStore } from "../../../stores/financeStore";
import { useModalStore } from "../../../stores/handleModalStore";

const StepFour = () => {
	const { setStartShoppingModal } = useModalStore();
	const { completedSteps } = useFinanceStore();

	const navigate = useNavigate();
	return (
		<div className="text-z-shark flex items-center justify-center flex-col">
			<div className="text-sm mb-1">Step 4</div>
			<div className="text-xl font-bold mb-3">Start home shopping</div>
			<div className="flex items-center space-x-2">
				<div className="text-[#828282] font-medium">
					Get pre-approved to begin your journey
				</div>
				<div
					onClick={() => setStartShoppingModal(true)}
					onKeyUp={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							setStartShoppingModal(true);
						}
					}}
				>
					<InfoIcon fill="#828282" />
				</div>
			</div>
			{completedSteps[3] ? (
				<div>
					<Button
						onClick={() => navigate("/profile/get-preapproved")}
						customClassName="!text-base font-bold mt-5"
						radius="60px"
						height="md"
						width="320px"
					>
						Get pre-approved
					</Button>
					<div className="flex flex-col items-center justify-center mt-5 space-y-3">
						<span className="text-z-shark font-medium">
							Already have a pre-approval?
						</span>
						<Button
							onClick={() => navigate("/profile/upload-preapproved")}
							customClassName="!bg-[#FECD07] !text-z-shark font-semibold !text-base font-bold"
							radius="60px"
							height="md"
							width="320px"
						>
							Click here to upload it
						</Button>
					</div>
				</div>
			) : (
				<Button
					disable={true}
					disableClass="!text-[#9D9D9D] !bg-[#D9D9D9] font-normal !text-base font-semibold"
					customClassName="font-bold text-lg mt-5"
					radius="60px"
					height="md"
					width="320px"
					startIcon={LockIcon}
				>
					Complete step 3 to unlock
				</Button>
			)}
		</div>
	);
};

export { StepFour };
