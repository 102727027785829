import { Button } from "../../common";
import { Modal } from "../../common/modal";
import { useModalStore } from "../../../stores/handleModalStore";

const ConnectBankAccountModal = () => {
	const { setBankConnectionModal, bankConnectionModal } = useModalStore();
	return (
		<Modal
			closeIcon={true}
			setIsOpen={setBankConnectionModal}
			isOpen={bankConnectionModal}
		>
			<div className="flex flex-col">
				<div className="text-z-shark text-xl font-bold">
					Connect Your Bank Account
				</div>
				<p className="text-[#828282] mt-5 leading-5 font-light">
					We use Flinks to securely connect your bank account. This allows us to
					see your income and savings, helping us provide the best estimate of
					what you can afford and how to get you into the home you want. Your
					data is protected with top-tier security measures.
				</p>
				<Button
					radius="50px"
					height="md"
					width="full"
					customClassName="font-bold text-lg mt-5"
					onClick={() => setBankConnectionModal(false)}
				>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export { ConnectBankAccountModal };
