import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../assets/whiteRightArrow.svg";
import ZownIcon from "../../assets/zown-logo-blue-wide.svg";
import WelcomeViewImg from "../../assets/welcomeViewImg.png";
// import AppleIcon from "../../assets/SocialMedia/appleIcon.svg";
// import GoogleIcon from "../../assets/SocialMedia/googleIcon.svg";

const WelcomeView = () => {
	const navigate = useNavigate();
	return (
		<div
			className={`h-full space-y-6 ${
				window.bridge ? "pb-bridge-inset-bottom" : "pb-10"
			}`}
		>
			<div>
				<img
					src={ZownIcon}
					className={`w-[125px] absolute left-4 ${
						window.bridge ? "top-bridge-inset-top" : " top-6"
					}`}
					alt=""
				/>
				<img
					src={WelcomeViewImg}
					alt=""
					className="h-[60vh] w-full object-cover"
				/>
			</div>
			<div className="px-4 space-y-4">
				<div className="font-futura-pt-heavy text-center">
					<div className="font-bold text-5xl">Unlock Your</div>
					<div className="font-bold text-5xl text-z-dark-moss-green">
						Down Payment
					</div>
				</div>
				<div className="text-center text-xl">
					Find your perfect match, boost your down payment and get home faster.
				</div>
				<div className="flex justify-between w-full gap-2">
					<button
						type="button"
						onClick={() => navigate("/login/email-view")}
						className="bg-[#32302F] w-full font-bold text-white py-3.5 px-6 rounded-[20px] flex justify-between items-center gap-2"
					>
						Get Started
						<img src={ArrowIcon} alt="" className="-rotate-45" />
					</button>
					{/* <button type="button" className="bg-[#F1F1F1] rounded-[20px] px-5">
            <img src={GoogleIcon} alt="" />
          </button>
          <button type="button" className="bg-[#F1F1F1] rounded-[20px] px-5">
            <img src={AppleIcon} alt="" />
          </button> */}
				</div>
			</div>
		</div>
	);
};

export { WelcomeView };
