import { useNavigate } from "react-router-dom";

const GetPreApproved = () => {
	const navigate = useNavigate();
	return (
		<div className="flex flex-col text-white relative bg-z-dark-moss-green p-5 space-y-3 rounded-[20px]">
			<div className="font-extrabold text-3xl text-white">
				Want to go see
				<br /> this home?
			</div>
			<button
				type="button"
				className="font-extrabold rounded-[7px] w-full bg-[#8CA87D] py-3"
				onClick={() => navigate("/profile/get-preapproved")}
			>
				Get pre-approved
			</button>
		</div>
	);
};

export { GetPreApproved };
