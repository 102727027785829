import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { openLink } from "../../services/openLink";
import ChatIcon from "../../assets/mainPageChatIcon.svg";
// import { useUserProfileStore } from "../../stores/profileStore";
// import { useRestTopPicksStore } from "../../stores/resetTopPicksStore";
import BellNotificationIcon from "../../assets/bellNotificationIcon.svg";
import MainViewTopBarImage from "../../assets/static/main-view-topBar-image.webp";

interface CartTopBarProps {
	setTopBarHeight: (height: number) => void;
}

const MainViewTopBar = ({ setTopBarHeight }: CartTopBarProps) => {
	const topBarRef = useRef<HTMLDivElement | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (topBarRef.current) {
			setTopBarHeight(topBarRef.current.offsetHeight);
		}
	}, [setTopBarHeight]);

	return (
		<div
			ref={topBarRef}
			className="absolute top-0 z-10 w-full text-white bg-black pt-bridge-inset-top"
		>
			<div className="flex flex-col h-[110px]">
				<img
					src={MainViewTopBarImage}
					alt=""
					className="h-[210px] absolute top-0"
				/>
				<div
					className={`w-full h-full flex flex-col items-center justify-between absolute top-0 right-0 left-0 px-4 bottom-0 ${
						window.bridge ? "pt-bridge-inset-top pb-2" : " pb-1.5 pt-4"
					}`}
				>
					<div className="flex items-center gap-3 justify-end w-full">
						<button
							type="button"
							onClick={() =>
								openLink(
									"https://wa.me/12898166416?text=Hello,%20I%20am%20looking%20for%20assistance%20with%20the%20Zown%20mobile%20app.%20Can%20you%20help%20me%20with%20this",
								)
							}
						>
							<img src={ChatIcon} alt="" />
						</button>
						<button
							type="button"
							onClick={() => navigate("/profile/notification")}
						>
							<img src={BellNotificationIcon} alt="bell icon" />
						</button>
					</div>

					<div className="flex items-end justify-between w-full">
						<div className="">
							<div className="font-bold text-2xl">Your Top Picks</div>
							<div className="font-light text-[15px]">
								Homes personalized for you
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { MainViewTopBar };
