interface CartIconProps {
	fill: string;
}
export const CartIcon = ({ fill }: CartIconProps) => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1417_7224)">
			<path
				d="M6.74651 17.0671C7.141 17.0671 7.4608 16.7334 7.4608 16.3218C7.4608 15.9101 7.141 15.5764 6.74651 15.5764C6.35202 15.5764 6.03223 15.9101 6.03223 16.3218C6.03223 16.7334 6.35202 17.0671 6.74651 17.0671Z"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.6049 17.0671C14.9994 17.0671 15.3192 16.7334 15.3192 16.3218C15.3192 15.9101 14.9994 15.5764 14.6049 15.5764C14.2104 15.5764 13.8906 15.9101 13.8906 16.3218C13.8906 16.7334 14.2104 17.0671 14.6049 17.0671Z"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.03418 1.41553H3.89133L5.80561 11.3957C5.87093 11.7388 6.04983 12.0471 6.311 12.2664C6.57217 12.4858 6.89897 12.6024 7.23419 12.5957H14.177C14.5123 12.6024 14.8391 12.4858 15.1002 12.2664C15.3614 12.0471 15.5403 11.7388 15.6056 11.3957L16.7485 5.14224H4.60561"
                stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1417_7224">
				<rect width="18" height="18" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
