import { GoogleMap, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import MapDotIcon from "../../assets/MapDotIcon.svg";
import { silverThemeStyles } from "../../mapSilverTheme";
import { useGoogleMaps } from "../../utils/useGoogleMaps";
import { LoadingCircle } from "../common";

type LisintgDescriptionMapProps = {
	lat: number;
	lng: number;
	height?: string;
};

const LisintgDescriptionMap = ({
	lat,
	lng,
	height = "189px",
}: LisintgDescriptionMapProps) => {
	const [map, setMap] = useState<google.maps.Map | null>(null);

	const { isLoaded, loadError } = useGoogleMaps();

	useEffect(() => {
		if (map && isLoaded) {
			new google.maps.Marker({
				position: { lat, lng },
				map,
				icon: MapDotIcon,
			});
		}
	}, [map, isLoaded, lat, lng]);

	useEffect(() => {
		if (map) {
			map.setCenter({ lat, lng });
		}
	}, [map, lat, lng]);


	if (loadError) {
		return (
			<div className="flex justify-center items-center">
				<LoadingCircle />
			</div>
		);
	}
	if (!isLoaded) {
		return (
			<div className="flex justify-center items-center">
				<LoadingCircle />
			</div>
		);
	}

	return (
		<GoogleMap
			mapContainerStyle={{
				width: "100%",
				height: height,
				borderRadius: "15px",
				outline: "none",
			}}
			center={{ lat, lng }}
			zoom={16}
			options={{
				styles: silverThemeStyles,
				disableDefaultUI: true,
				keyboardShortcuts: false,
				panControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
			}}
			onLoad={(map) => setMap(map)}
		>
			<Marker position={{ lat, lng }} icon={MapDotIcon} />
		</GoogleMap>
	);
};

export { LisintgDescriptionMap };
