import { universalFetch } from "./common";

export const foundProperties = async (): Promise<void> => {
  try {
    const response = await universalFetch("/filters-count", "GET");

    console.log("foundProperties response:", response);
    return response;
  } catch (error) {
    console.error("Failed to fetch foundProperties", error);
    throw error;
  }
};
