interface BuyerOutingPropertyListingAddress {
	displayAddress: string;
	city: string;
}
export enum BuyerOutingStatus {
	WaitingForConfirmation = "WAITING_FOR_CONFIRMATION",
	Confirmed = "CONFIRMED",
	Completed = "COMPLETED",
	Cancelled = "CANCELLED",
}

interface OutingDatesSchedule {
	startAt: Date;
	endAt: Date;
}
interface BuyerOutingPropertyListing {
	listingId: string;
	status: BuyerOutingStatus;
	showingDates: OutingDatesSchedule;
	coordinates: { latitude: number; longitude: number };
	address: BuyerOutingPropertyListingAddress;
}

export interface BuyerOuting {
	properties: BuyerOutingPropertyListing[];
	status: BuyerOutingStatus;
	displayImageUrl: string;
	_id: string;
	startAt: Date;
}
