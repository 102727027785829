export enum SelectedCitiesEnum  {
	Abitibi70 = "Abitibi 70",
	AddingtonHighlands = "Addington Highlands",
	AdelaideMetcalfe = "Adelaide-Metcalfe",
	AdjalaTosorontio = "Adjala-Tosorontio",
	AdmastonBromley = "Admaston/Bromley",
	Ajax = "Ajax",
	AkwesasnePart59 = "Akwesasne (Part) 59",
	AlfredandPlantagenet = "Alfred and Plantagenet",
	AlgomaUnorganizedNorthPart = "Algoma, Unorganized, North Part",
	AlgonquinHighlands = "Algonquin Highlands",
	AlnwickHaldimand = "Alnwick/Haldimand",
	Amaranth = "Amaranth",
	Amherstburg = "Amherstburg",
	Armour = "Armour",
	Armstrong = "Armstrong",
	Arnprior = "Arnprior",
	Aroland = "Aroland",
	ArranElderslie = "Arran-Elderslie",
	AshfieldColborneWawanosh = "Ashfield-Colborne-Wawanosh",
	AsphodelNorwood = "Asphodel-Norwood",
	Assiginack = "Assiginack",
	Athens = "Athens",
	Atikokan = "Atikokan",
	Attawapiskat91A = "Attawapiskat 91A",
	Augusta = "Augusta",
	Aurora = "Aurora",
	Aylmer = "Aylmer",
	Baldwin = "Baldwin",
	Bancroft = "Bancroft",
	Barrie = "Barrie",
	Bayham = "Bayham",
	BearIsland1 = "Bear Island 1",
	BearskinLake = "Bearskin Lake",
	Beckwith = "Beckwith",
	Belleville = "Belleville",
	BigGrassyRiver35G = "Big Grassy River 35G",
	BigIslandMainland93 = "Big Island Mainland 93",
	Billings = "Billings",
	BlackRiverMatheson = "Black River-Matheson",
	BlandfordBlenheim = "Blandford-Blenheim",
	BlindRiver = "Blind River",
	Bluewater = "Bluewater",
	Bonfield = "Bonfield",
	BonnechereValley = "Bonnechere Valley",
	Bracebridge = "Bracebridge",
	BradfordWestGwillimbury = "Bradford West Gwillimbury",
	Brampton = "Brampton",
	Brant = "Brant",
	Brantford = "Brantford",
	Brethour = "Brethour",
	Brighton = "Brighton",
	Brock = "Brock",
	Brockton = "Brockton",
	Brockville = "Brockville",
	BrookeAlvinston = "Brooke-Alvinston",
	BruceMines = "Bruce Mines",
	BrudenellLyndochandRaglan = "Brudenell, Lyndoch and Raglan",
	BurksFalls = "Burk's Falls",
	Burlington = "Burlington",
	BurpeeandMills = "Burpee and Mills",
	Caledon = "Caledon",
	Callander = "Callander",
	Calvin = "Calvin",
	Cambridge = "Cambridge",
	CarletonPlace = "Carleton Place",
	Carling = "Carling",
	CarlowMayo = "Carlow/Mayo",
	Casey = "Casey",
	Casselman = "Casselman",
	CatLake63C = "Cat Lake 63C",
	CavanMonaghan = "Cavan Monaghan",
	CentralElgin = "Central Elgin",
	CentralFrontenac = "Central Frontenac",
	CentralHuron = "Central Huron",
	CentralManitoulin = "Central Manitoulin",
	CentreHastings = "Centre Hastings",
	CentreWellington = "Centre Wellington",
	Chamberlain = "Chamberlain",
	Champlain = "Champlain",
	Chapleau = "Chapleau",
	Chapleau74A = "Chapleau 74A",
	ChapleauCreeFoxLake = "Chapleau Cree Fox Lake",
	Chapple = "Chapple",
	CharltonandDack = "Charlton and Dack",
	ChathamKent = "Chatham-Kent",
	Chatsworth = "Chatsworth",
	ChiefsPointNo28 = "Chief's Point No. 28",
	ChippewasofRamaFirstNation = "Chippewas of Rama First Nation",
	ChristianIsland30A = "Christian Island 30A",
	ClarenceRockland = "Clarence-Rockland",
	Clarington = "Clarington",
	Clearview = "Clearview",
	Cobalt = "Cobalt",
	Cobourg = "Cobourg",
	Cochrane = "Cochrane",
	CochraneUnorganizedNorthPart = "Cochrane, Unorganized, North Part",
	CochraneUnorganizedSouthEastPart = "Cochrane, Unorganized, South East Part",
	CochraneUnorganizedSouthWestPart = "Cochrane, Unorganized, South West Part",
	Coleman = "Coleman",
	Collingwood = "Collingwood",
	Conmee = "Conmee",
	ConstanceLake92 = "Constance Lake 92",
	Cornwall = "Cornwall",
	Couchiching16A = "Couchiching 16A",
	Cramahe = "Cramahe",
	CurveLakeFirstNation35 = "Curve Lake First Nation 35",
	DawnEuphemia = "Dawn-Euphemia",
	Dawson = "Dawson",
	DeepRiver = "Deep River",
	DeerLake = "Deer Lake",
	Deseronto = "Deseronto",
	Dokis9 = "Dokis 9",
	Dorion = "Dorion",
	DouroDummer = "Douro-Dummer",
	DrummondNorthElmsley = "Drummond/North Elmsley",
	Dryden = "Dryden",
	Dubreuilville = "Dubreuilville",
	DuckLake76B = "Duck Lake 76B",
	DuttonDunwich = "Dutton/Dunwich",
	Dysartetal = "Dysart et al",
	EagleLake27 = "Eagle Lake 27",
	EastFerris = "East Ferris",
	EastGarafraxa = "East Garafraxa",
	EastGwillimbury = "East Gwillimbury",
	EastHawkesbury = "East Hawkesbury",
	EastZorraTavistock = "East Zorra-Tavistock",
	EdwardsburghCardinal = "Edwardsburgh/Cardinal",
	ElizabethtownKitley = "Elizabethtown-Kitley",
	ElliotLake = "Elliot Lake",
	Emo = "Emo",
	Englehart = "Englehart",
	EnglishRiver21 = "English River 21",
	Enniskillen = "Enniskillen",
	Erin = "Erin",
	Espanola = "Espanola",
	Essa = "Essa",
	Essex = "Essex",
	Evanturel = "Evanturel",
	FactoryIsland1 = "Factory Island 1",
	Faraday = "Faraday",
	FauquierStrickland = "Fauquier-Strickland",
	FortAlbanyPart67 = "Fort Albany (Part) 67",
	FortErie = "Fort Erie",
	FortFrances = "Fort Frances",
	FortHope64 = "Fort Hope 64",
	FortSevern89 = "Fort Severn 89",
	FortWilliam52 = "Fort William 52",
	FrenchRiverRiviredesFranais = "French River / Rivi�re des Fran�ais",
	FrenchRiver13 = "French River 13",
	FrontofYonge = "Front of Yonge",
	FrontenacIslands = "Frontenac Islands",
	Gananoque = "Gananoque",
	GardenRiver14 = "Garden River 14",
	Gauthier = "Gauthier",
	GeorgianBay = "Georgian Bay",
	GeorgianBluffs = "Georgian Bluffs",
	Georgina = "Georgina",
	Gillies = "Gillies",
	GinoogamingFirstNation = "Ginoogaming First Nation",
	Goderich = "Goderich",
	GordonBarrieIsland = "Gordon/Barrie Island",
	GoreBay = "Gore Bay",
	GrandValley = "Grand Valley",
	Gravenhurst = "Gravenhurst",
	GreaterMadawaska = "Greater Madawaska",
	GreaterNapanee = "Greater Napanee",
	GreaterSudburyGrandSudbury = "Greater Sudbury / Grand Sudbury",
	Greenstone = "Greenstone",
	GreyHighlands = "Grey Highlands",
	Grimsby = "Grimsby",
	GrosCap49 = "Gros Cap 49",
	Guelph = "Guelph",
	GuelphEramosa = "Guelph/Eramosa",
	GullRiver55 = "Gull River 55",
	HaldimandCounty = "Haldimand County",
	HaltonHills = "Halton Hills",
	Hamilton = "Hamilton",
	Hanover = "Hanover",
	Harris = "Harris",
	HastingsHighlands = "Hastings Highlands",
	HavelockBelmontMethuen = "Havelock-Belmont-Methuen",
	Hawkesbury = "Hawkesbury",
	HeadClaraandMaria = "Head, Clara and Maria",
	Hearst = "Hearst",
	HenveyInlet2 = "Henvey Inlet 2",
	HiawathaFirstNation = "Hiawatha First Nation",
	HighlandsEast = "Highlands East",
	Hilliard = "Hilliard",
	Hilton = "Hilton",
	HiltonBeach = "Hilton Beach",
	Hornepayne = "Hornepayne",
	Horton = "Horton",
	Howick = "Howick",
	Hudson = "Hudson",
	Huntsville = "Huntsville",
	HuronEast = "Huron East",
	HuronShores = "Huron Shores",
	HuronKinloss = "Huron-Kinloss",
	Ignace = "Ignace",
	Ingersoll = "Ingersoll",
	Innisfil = "Innisfil",
	IroquoisFalls = "Iroquois Falls",
	James = "James",
	Johnson = "Johnson",
	Joly = "Joly",
	Kapuskasing = "Kapuskasing",
	KasabonikaLake = "Kasabonika Lake",
	KawarthaLakes = "Kawartha Lakes",
	Kearney = "Kearney",
	KeeWayWin = "Kee-Way-Win",
	Kenora = "Kenora",
	KenoraUnorganized = "Kenora, Unorganized",
	Kerns = "Kerns",
	KettlePoint44 = "Kettle Point 44",
	KillaloeHagartyandRichards = "Killaloe, Hagarty and Richards",
	Killarney = "Killarney",
	Kincardine = "Kincardine",
	King = "King",
	KingfisherLake1 = "Kingfisher Lake 1",
	Kingston = "Kingston",
	Kingsville = "Kingsville",
	KirklandLake = "Kirkland Lake",
	Kitchener = "Kitchener",
	KitchenuhmaykoosibAaki84 = "Kitchenuhmaykoosib Aaki 84",
	LaVallee = "La Vallee",
	LaSalle = "LaSalle",
	LacSeul28 = "Lac Seul 28",
	LakeHelen53A = "Lake Helen 53A",
	LakeofBays = "Lake of Bays",
	LakeoftheWoods = "Lake of the Woods",
	Lakeshore = "Lakeshore",
	LambtonShores = "Lambton Shores",
	LanarkHighlands = "Lanark Highlands",
	LansdowneHouse = "Lansdowne House",
	LarderLake = "Larder Lake",
	Latchford = "Latchford",
	LaurentianHills = "Laurentian Hills",
	LaurentianValley = "Laurentian Valley",
	Leamington = "Leamington",
	LeedsandtheThousandIslands = "Leeds and the Thousand Islands",
	Limerick = "Limerick",
	Lincoln = "Lincoln",
	London = "London",
	LongLake58 = "Long Lake 58",
	Loyalist = "Loyalist",
	LucanBiddulph = "Lucan Biddulph",
	MChigeeng22 = "M'Chigeeng 22",
	MacdonaldMeredithandAberdeenAdditional = "Macdonald, Meredith and Aberdeen Additional",
	Machin = "Machin",
	MadawaskaValley = "Madawaska Valley",
	Madoc = "Madoc",
	Magnetawan = "Magnetawan",
	Malahide = "Malahide",
	ManitouRapids11 = "Manitou Rapids 11",
	ManitoulinUnorganizedWestPart = "Manitoulin, Unorganized, West Part",
	Manitouwadge = "Manitouwadge",
	Mapleton = "Mapleton",
	Marathon = "Marathon",
	Markham = "Markham",
	MarkstayWarren = "Markstay-Warren",
	MarmoraandLake = "Marmora and Lake",
	MartenFalls65 = "Marten Falls 65",
	Matachewan = "Matachewan",
	Matachewan72 = "Matachewan 72",
	Mattawa = "Mattawa",
	Mattawan = "Mattawan",
	MatticeValCt = "Mattice-Val C�t�",
	McDougall = "McDougall",
	McGarry = "McGarry",
	McKellar = "McKellar",
	McMurrichMonteith = "McMurrich/Monteith",
	McNabBraeside = "McNab/Braeside",
	Meaford = "Meaford",
	Melancthon = "Melancthon",
	MerrickvilleWolford = "Merrickville-Wolford",
	MiddlesexCentre = "Middlesex Centre",
	Midland = "Midland",
	Milton = "Milton",
	MindenHills = "Minden Hills",
	Minto = "Minto",
	Mississauga = "Mississauga",
	MississippiMills = "Mississippi Mills",
	Mono = "Mono",
	Montague = "Montague",
	Moonbeam = "Moonbeam",
	Moosonee = "Moosonee",
	Moravian47 = "Moravian 47",
	Morley = "Morley",
	MorrisTurnberry = "Morris-Turnberry",
	Mulmur = "Mulmur",
	MuskokaLakes = "Muskoka Lakes",
	MuskratDamLake = "Muskrat Dam Lake",
	NairnandHyman = "Nairn and Hyman",
	Neebing = "Neebing",
	NeguaguonLake25D = "Neguaguon Lake 25D",
	Neskantaga = "Neskantaga",
	NewCreditPart40A = "New Credit (Part) 40A",
	NewTecumseth = "New Tecumseth",
	Newbury = "Newbury",
	Newmarket = "Newmarket",
	Neyaashiinigmiing27 = "Neyaashiinigmiing 27",
	NiagaraFalls = "Niagara Falls",
	NiagaraontheLake = "Niagara-on-the-Lake",
	Nipigon = "Nipigon",
	Nipissing = "Nipissing",
	Nipissing10 = "Nipissing 10",
	NipissingUnorganizedNorthPart = "Nipissing, Unorganized, North Part",
	NipissingUnorganizedSouthPart = "Nipissing, Unorganized, South Part",
	NorfolkCounty = "Norfolk County",
	NorthAlgonaWilberforce = "North Algona Wilberforce",
	NorthBay = "North Bay",
	NorthDumfries = "North Dumfries",
	NorthDundas = "North Dundas",
	NorthFrontenac = "North Frontenac",
	NorthGlengarry = "North Glengarry",
	NorthGrenville = "North Grenville",
	NorthHuron = "North Huron",
	NorthKawartha = "North Kawartha",
	NorthMiddlesex = "North Middlesex",
	NorthPerth = "North Perth",
	NorthStormont = "North Stormont",
	NortheasternManitoulinandtheIslands = "Northeastern Manitoulin and the Islands",
	NorthernBrucePeninsula = "Northern Bruce Peninsula",
	NorthwestAngle33B = "Northwest Angle 33B",
	Norwich = "Norwich",
	Oakville = "Oakville",
	OilSprings = "Oil Springs",
	OliverPaipoonge = "Oliver Paipoonge",
	Oneida41 = "Oneida 41",
	Opasatika = "Opasatika",
	Orangeville = "Orangeville",
	Orillia = "Orillia",
	OroMedonte = "Oro-Medonte",
	Oshawa = "Oshawa",
	Osnaburgh63A = "Osnaburgh 63A",
	OtonabeeSouthMonaghan = "Otonabee-South Monaghan",
	Ottawa = "Ottawa",
	OwenSound = "Owen Sound",
	ParryIslandFirstNation = "Parry Island First Nation",
	ParrySound = "Parry Sound",
	ParrySoundUnorganizedCentrePart = "Parry Sound, Unorganized, Centre Part",
	ParrySoundUnorganizedNorthEastPart = "Parry Sound, Unorganized, North East Part",
	PaysPlat51 = "Pays Plat 51",
	Peawanuck = "Peawanuck",
	Pelee = "Pelee",
	Pelham = "Pelham",
	Pembroke = "Pembroke",
	Penetanguishene = "Penetanguishene",
	Perry = "Perry",
	Perth = "Perth",
	PerthEast = "Perth East",
	PerthSouth = "Perth South",
	Petawawa = "Petawawa",
	Peterborough = "Peterborough",
	Petrolia = "Petrolia",
	PicMobertNorth = "Pic Mobert North",
	PicMobertSouth = "Pic Mobert South",
	Pickering = "Pickering",
	PickleLake = "Pickle Lake",
	Pikangikum14 = "Pikangikum 14",
	PikwakanaganGoldenLake39 = "Pikwakanagan (Golden Lake 39)",
	PlummerAdditional = "Plummer Additional",
	PlymptonWyoming = "Plympton-Wyoming",
	PointEdward = "Point Edward",
	PoplarHill = "Poplar Hill",
	PortColborne = "Port Colborne",
	PortHope = "Port Hope",
	Powassan = "Powassan",
	Prescott = "Prescott",
	Prince = "Prince",
	PrinceEdwardCounty = "Prince Edward County",
	Puslinch = "Puslinch",
	QuinteWest = "Quinte West",
	RainyLake17B = "Rainy Lake 17B",
	RainyLake18C = "Rainy Lake 18C",
	RainyLake26A = "Rainy Lake 26A",
	RainyRiver = "Rainy River",
	RainyRiverUnorganized = "Rainy River, Unorganized",
	Ramara = "Ramara",
	RankinLocation15D = "Rankin Location 15D",
	RatPortage38A = "Rat Portage 38A",
	RedLake = "Red Lake",
	RedRock = "Red Rock",
	Renfrew = "Renfrew",
	RichmondHill = "Richmond Hill",
	RideauLakes = "Rideau Lakes",
	Russell = "Russell",
	Ryerson = "Ryerson",
	SabaskongBay35D = "Sabaskong Bay 35D",
	SablesSpanishRivers = "Sables-Spanish Rivers",
	SachigoLake1 = "Sachigo Lake 1",
	Sagamok = "Sagamok",
	SandyLake88 = "Sandy Lake 88",
	Sarnia = "Sarnia",
	Sarnia45 = "Sarnia 45",
	Saugeen29 = "Saugeen 29",
	SaugeenShores = "Saugeen Shores",
	SaultSteMarie = "Sault Ste. Marie",
	Schreiber = "Schreiber",
	Scugog = "Scugog",
	Seguin = "Seguin",
	Selwyn = "Selwyn",
	SerpentRiver7 = "Serpent River 7",
	Severn = "Severn",
	Shawanaga17 = "Shawanaga 17",
	Sheguiandah24 = "Sheguiandah 24",
	Shelburne = "Shelburne",
	Sheshegwaning20 = "Sheshegwaning 20",
	ShoalLake39A = "Shoal Lake 39A",
	Shuniah = "Shuniah",
	SiouxLookout = "Sioux Lookout",
	SiouxNarrowsNestorFalls = "Sioux Narrows-Nestor Falls",
	SixNationsPart40 = "Six Nations (Part) 40",
	SlateFalls = "Slate Falls",
	SmithsFalls = "Smiths Falls",
	SmoothRockFalls = "Smooth Rock Falls",
	SouthAlgonquin = "South Algonquin",
	SouthBruce = "South Bruce",
	SouthBrucePeninsula = "South Bruce Peninsula",
	SouthDundas = "South Dundas",
	SouthFrontenac = "South Frontenac",
	SouthGlengarry = "South Glengarry",
	SouthHuron = "South Huron",
	SouthRiver = "South River",
	SouthStormont = "South Stormont",
	SouthWestOxford = "South-West Oxford",
	Southgate = "Southgate",
	SouthwestMiddlesex = "Southwest Middlesex",
	Southwold = "Southwold",
	Spanish = "Spanish",
	Springwater = "Springwater",
	StCatharines = "St. Catharines",
	StClair = "St. Clair",
	StJoseph = "St. Joseph",
	StMarys = "St. Marys",
	StThomas = "St. Thomas",
	StCharles = "St.-Charles",
	StirlingRawdon = "Stirling-Rawdon",
	StoneMills = "Stone Mills",
	Stratford = "Stratford",
	StrathroyCaradoc = "Strathroy-Caradoc",
	Strong = "Strong",
	SuckerCreek23 = "Sucker Creek 23",
	SudburyUnorganizedNorthPart = "Sudbury, Unorganized, North Part",
	SummerBeaver = "Summer Beaver",
	Sundridge = "Sundridge",
	TarbuttandTarbuttAdditional = "Tarbutt and Tarbutt Additional",
	Tay = "Tay",
	TayValley = "Tay Valley",
	Tecumseh = "Tecumseh",
	Tehkummah = "Tehkummah",
	Temagami = "Temagami",
	TemiskamingShores = "Temiskaming Shores",
	TerraceBay = "Terrace Bay",
	ThamesCentre = "Thames Centre",
	TheArchipelago = "The Archipelago",
	TheBlueMountains = "The Blue Mountains",
	TheDalles38C = "The Dalles 38C",
	TheNationLaNation = "The Nation / La Nation",
	TheNorthShore = "The North Shore",
	Thessalon = "Thessalon",
	Thessalon12 = "Thessalon 12",
	Thornloe = "Thornloe",
	Thorold = "Thorold",
	ThunderBay = "Thunder Bay",
	ThunderBayUnorganized = "Thunder Bay, Unorganized",
	Tillsonburg = "Tillsonburg",
	TimiskamingUnorganizedEastPart = "Timiskaming, Unorganized, East Part",
	TimiskamingUnorganizedWestPart = "Timiskaming, Unorganized, West Part",
	Timmins = "Timmins",
	Tiny = "Tiny",
	Toronto = "Toronto",
	TrentHills = "Trent Hills",
	TrentLakes = "Trent Lakes",
	Tweed = "Tweed",
	Tyendinaga = "Tyendinaga",
	Uxbridge = "Uxbridge",
	ValRitaHarty = "Val Rita-Harty",
	Vaughan = "Vaughan",
	Wabaseemoong = "Wabaseemoong",
	WabigoonLake27 = "Wabigoon Lake 27",
	Wahnapitei11 = "Wahnapitei 11",
	Wainfleet = "Wainfleet",
	Wapekeka2 = "Wapekeka 2",
	Warwick = "Warwick",
	WasagaBeach = "Wasaga Beach",
	Waterloo = "Waterloo",
	Wawa = "Wawa",
	Wawakapewin = "Wawakapewin",
	WeagamowLake87 = "Weagamow Lake 87",
	Webequie = "Webequie",
	Welland = "Welland",
	Wellesley = "Wellesley",
	WellingtonNorth = "Wellington North",
	WestElgin = "West Elgin",
	WestGrey = "West Grey",
	WestLincoln = "West Lincoln",
	WestNipissingNipissingOuest = "West Nipissing / Nipissing Ouest",
	WestPerth = "West Perth",
	Westport = "Westport",
	Whitby = "Whitby",
	WhitchurchStouffville = "Whitchurch-Stouffville",
	WhiteRiver = "White River",
	WhitefishBay32A = "Whitefish Bay 32A",
	WhitefishBay33A = "Whitefish Bay 33A",
	WhitefishBay34A = "Whitefish Bay 34A",
	WhitefishLake6 = "Whitefish Lake 6",
	WhitefishRiver4 = "Whitefish River 4",
	Whitesand = "Whitesand",
	Whitestone = "Whitestone",
	WhitewaterRegion = "Whitewater Region",
	WikwemikongUnceded = "Wikwemikong Unceded",
	Wilmot = "Wilmot",
	Windsor = "Windsor",
	Wollaston = "Wollaston",
	Woodstock = "Woodstock",
	Woolwich = "Woolwich",
	Zorra = "Zorra",
}
