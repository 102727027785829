import { Input } from "../common";
import { type ChangeEvent, useRef, useState } from "react";
import { handleKeyPress } from "../../utils/keyboardUtils";
import { useAdvertisementUserStore } from "../../stores/advertisementFlowStore";

const AboutYourSelfView = () => {
	const firstNameRef = useRef<HTMLInputElement>(null);
	const lastNameRef = useRef<HTMLInputElement>(null);
	const phoneNumberRef = useRef<HTMLInputElement>(null);

	const {
		firstName,
		lastName,
		setFirstName,
		setLastName,
		firstNameError,
		setFirstNameError,
		lastNameError,
		setLastNameError,
	} = useAdvertisementUserStore();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		switch (name) {
			case "firstName": {
				setFirstName(value);
				break;
			}
			case "lastName": {
				setLastName(value);
				break;
			}
			default:
				break;
		}
	};

	return (
		<div className="h-full w-full px-4 flex justify-center items-center">
			<div
				className="flex items-start flex-col w-full pb-4"
				onKeyDown={(e) => {
					if (e.target === firstNameRef.current) {
						handleKeyPress(e, firstNameRef);
					} else if (e.target === lastNameRef.current) {
						handleKeyPress(e, lastNameRef);
					} else if (e.target === phoneNumberRef.current) {
						handleKeyPress(e, phoneNumberRef);
					}
				}}
			>
				<h2 className="text-z-shark text-2xl font-bold">
					Tell us a little about yourself
				</h2>
				<div className="mt-8 w-full space-y-10">
					<Input
						ref={firstNameRef}
						name="firstName"
						onChange={handleChange}
						autoComplete="given-name"
						value={firstName}
						radius="20px"
						label="First Name"
						placeholder="Enter First Name"
						isError={firstNameError}
					/>
					<Input
						ref={lastNameRef}
						name="lastName"
						onChange={handleChange}
						autoComplete="family-name"
						value={lastName}
						radius="20px"
						label="Last Name"
						placeholder="Enter Last Name"
						isError={lastNameError}
					/>
				</div>
			</div>
		</div>
	);
};

export { AboutYourSelfView };
