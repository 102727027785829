import moment from "moment";
import { Dropdown } from "./preferencesDropDown";

interface TimePreference {
	date: string;
	startAt: string;
	endAt: string;
	error?: string;
}

interface TimePreferenceItemProps {
	preference: TimePreference;
	index: number;
	onInputChange: (
		index: number,
		field: keyof TimePreference,
		value: string,
	) => void;
	showingsCount: number;
}

const generateTimeOptions = (showings: number) => {
	return Array.from({ length: 22 }, (_, i) => {
		const hour = Math.floor(i / 2) + 9;
		const minute = i % 2 === 0 ? "00" : "30";
		const time = `${String(hour).padStart(2, "0")}:${minute}`;
		return time;
	}).filter((time) => {
		const [hour, minute] = time.split(":").map(Number);
		return moment()
			.hour(hour)
			.minute(minute)
			.add(showings, "hours")
			.isSameOrBefore(moment().hour(20).minute(0));
	});
};

const TimePreferenceItem = ({
	index,
	preference,
	onInputChange,
	showingsCount,
}: TimePreferenceItemProps) => {
	const timeOptions = generateTimeOptions(showingsCount);

	return (
		<div className="bg-white rounded">
			<div className="flex flex-col items-center">
				<div className="mb-2">
					<div className="block text-sm text-black font-semibold italic">
						{moment(preference.date).format("dddd MMMM DD, YYYY")}
					</div>
				</div>
				<div className="w-full">
					<div className="flex justify-between items-center gap-4 w-full">
						<div className="w-full">
							<Dropdown
								options={timeOptions}
								value={preference.startAt}
								onChange={(value) => onInputChange(index, "startAt", value)}
								placeholder="Start time"
							/>
						</div>
					
					</div>
					{preference.error && (
						<div className="text-[#FF5757] text-xs mt-1">
							{preference.error}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export { TimePreferenceItem };
